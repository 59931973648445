import React, { useState, useEffect } from 'react'
import { Modal } from 'antd'
import { useHttp } from '../../../hooks/useHttps'
import Layout from '../../../containers/Layout'
import axios from 'axios'
import {Link} from "react-router-dom"
import config from '../../../config'
import { Select } from 'antd'
export default function Dashboard () {
  const [createContributionModal, setCreateContribution] = useState(false)
  const [amount, setAmount] = useState('')
  const [name, setName] = useState('')
  const [employer, setEmployer] = useState('')
  const [departmentDatas, setDepartmentDatas] = useState([])
  const [department, setDepartment] = useState('')
  const [departmentSelections, setDepartmentSelections] = useState([])
  const [type, setType] = useState('')
  const [serverError, setServerError] = useState(false)

  const { Option } = Select

  const createContributionFunc = () => {
    setCreateContribution(true)
  }

  const cancelCreateContribution = () => {
    setCreateContribution(false)
  }

  const handleChangeDepartments = value => {
    setDepartment(value)
  }

  const [loadEmployer, employers] = useHttp(
    `${config.baseUrl}/v1/employees/filtered/employer`,
    []
  )


  const getDepartments = e => {
    setEmployer(e.target.value)
    getDepartmentLists(e.target.value)
  }

  const getDepartmentLists = id => {
    axios
      .get(`${config.baseUrl}/v1/departments/filtered/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
      })
      .then(res => {
        if (res.data.status) {
          setDepartmentDatas(res.data.result)
        } else {

        }
      })
      .catch(err => {
        if (err) {
          setServerError(true)
          setTimeout(() => {
            setServerError(false)
          }, 2000)
        }
      })
  }

  const createCoperative = () => {}

  const departmentSelect = []
  departmentDatas.map(position => {
    departmentSelections.push(
      <Option key={position.code}>{position.name}</Option>
    )
  })

  return (
    <Layout>
      <div className='row'>
        <div className='col-sm-12 col-md-12'>
          <div class='card '>
            <div class='card-header font-weight-bold'>QUICK MENU</div>
            <div class='card-body mb-0 pb-0'>
              <div className='container'>
                <div class='row'>
                  <div
                    class='col-lg-3 col-md-3 col-sm-12'
                    style={{ cursor: 'pointer' }}
                    onClick={createContributionFunc}
                  >
                    <div class='card folder'>
                      <div
                        class='card-body'
                        style={{ background: '#f5f5f5', borderRadius: '20px' }}
                      >
                        <div class='folder-info'>
                          <a href='#' className='h5'>
                            Create Contribution
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link to="/join_contribution">
                  <div
                    class='col-lg-3 col-md-3 col-sm-12'
                    style={{ cursor: 'pointer' }}
                  >
                    <div class='card folder'>
                      <div
                        class='card-body'
                        style={{ background: '#f5f5f5', borderRadius: '20px' }}
                      >
                        <div class='folder-info'>
                          <a href='#' className=' h5'>
                            Join Contribution{' '}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  </Link>
                  <div
                    class='col-lg-3 col-md-3 col-sm-12'
                    style={{ cursor: 'pointer' }}
                  >
                    <div class='card folder'>
                      <div
                        class='card-body'
                        style={{ background: '#f5f5f5', borderRadius: '20px' }}
                      >
                        <div class='folder-info'>
                          <a href='#' className='h5'>
                            Manage Contribution
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class='col-lg-3 col-md-3 col-sm-12'
                    style={{ cursor: 'pointer' }}
                  >
                    <div class='card folder'>
                      <div
                        class='card-body'
                        style={{ background: '#f5f5f5', borderRadius: '20px' }}
                      >
                        <div class='folder-info'>
                          <a href='#' className='h5'>
                            Contribution History
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class='row'>
        <div class='col-md-12 col-lg-12'>
          <div class='card table-widget'>
            <div class='card-body'>
              <h5 class='card-title'>Last 10 Contribution History </h5>
              <div class='table-responsive'>
                <table class='table'>
                  <thead>
                    <tr>
                      <th scope='col'>Definement</th>
                      <th scope='col'>Type</th>
                      <th scope='col'>Amount</th>
                      <th scope='col'>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope='row'>
                        <img
                          src='../../assets/images/avatars/profile-image.png'
                          alt=''
                        />
                        Anna Doe
                      </th>
                      <td>Modern</td>
                      <td>#53327</td>
                      <td>$20</td>
                      <td>
                        <span class='badge bg-info'>Shipped</span>
                      </td>
                    </tr>
                    <tr>
                      <th scope='row'>
                        <img
                          src='../../assets/images/avatars/profile-image.png'
                          alt=''
                        />
                        John Doe
                      </th>
                      <td>Alpha</td>
                      <td>#13328</td>
                      <td>$25</td>
                      <td>
                        <span class='badge bg-success'>Paid</span>
                      </td>
                    </tr>
                    <tr>
                      <th scope='row'>
                        <img
                          src='../../assets/images/avatars/profile-image.png'
                          alt=''
                        />
                        Anna Doe
                      </th>
                      <td>Lime</td>
                      <td>#35313</td>
                      <td>$20</td>
                      <td>
                        <span class='badge bg-danger'>Pending</span>
                      </td>
                    </tr>
                    <tr>
                      <th scope='row'>
                        <img
                          src='../../assets/images/avatars/profile-image.png'
                          alt=''
                        />
                        John Doe
                      </th>
                      <td>Circl Admin</td>
                      <td>#73423</td>
                      <td>$23</td>
                      <td>
                        <span class='badge bg-primary'>Shipped</span>
                      </td>
                    </tr>
                    <tr>
                      <th scope='row'>
                        <img
                          src='../../assets/images/avatars/profile-image.png'
                          alt=''
                        />
                        Nina Doe
                      </th>
                      <td>Space</td>
                      <td>#54773</td>
                      <td>$20</td>
                      <td>
                        <span class='badge bg-success'>Paid</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title='Create Contribution'
        visible={createContributionModal}
        footer={null}
        maskClosable={false}
        onCancel={cancelCreateContribution}
      >
        <div className='mb-3'>
          <div className='form-floating'>
            <select
              class='form-select'
              aria-label='Default select example'
              onChange={getDepartments}
            >
              <option selected>Select Employer</option>
              {employers.map(employer => {
                return (
                  <option value={employer.employerResponse.id}>
                    {employer.employerResponse.businessName}
                  </option>
                )
              })}
            </select>
            <label for='floatingInput'>Employer</label>
          </div>
        </div>
        <div className='mb-3'>
          <div className='form-floating'>
            <select
              class='form-select'
              aria-label='Default select example'
              onChange={setType}
              value={type}
            >
              <option selected>Select</option>
              <option>JOINT</option>
              <option>CYCLE</option>
            </select>
            <label for='floatingInput'>Type</label>
          </div>
        </div>
        <div class='mb-3'>
          <select
            class='form-select'
            aria-label='Default select example'
            onChange={e => setType(e.target.value)}
            value={type}
          >
            <option selected>Select Employer</option>
            <option>JOINT</option>
            <option>CYCLE</option>
          </select>
        </div>

        <div className='mb-3'>
          <div className='form-floating'>
            <input
              type='text'
              className='form-control'
              id='floatingInput'
              onChange={setName}
              placeholder='country name'
              value={name}
            />
            <label for='floatingInput'>
              {type === 'JOINT' ? 'Amount' : 'Target Amount'}
            </label>
          </div>
        </div>
          <div className='mb-3'>
            <div className='form-floating'>
              <input
                type='text'
                className='form-control'
                id='floatingInput'
                onChange={setAmount}
                placeholder='country name'
                value={amount}
              />
              <label for='floatingInput'>Name</label>
            </div>
          </div>


          <div class='mb-3'>
            <label>Departments</label>
            <Select
              mode='multiple'
              allowClear
              style={{ width: '100%' }}
              placeholder='Please select'
              defaultValue={departmentSelect}
              onChange={handleChangeDepartments}
            >
              {departmentSelect}
            </Select>
          </div>

        <div class='mb-3'>
          <label>Invites</label>
          <Select
            mode='multiple'
            allowClear
            style={{ width: '100%' }}
            placeholder='Please select'
            defaultValue={departmentSelect}
            onChange={handleChangeDepartments}
          >
            {departmentSelect}
          </Select>
        </div>

        <div className='d-flex justify-content-end align-items-center'>
          <button
            type='submit'
            class='btn btn-primary'
            style={{ background: '#1FC157', color: 'white' }}
            onClick={createCoperative}
          >
            Submit
          </button>
        </div>
      </Modal>
    </Layout>
  )
}
