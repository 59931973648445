import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import PinInput from "react-pin-input";
import Layout from "../../../containers/Layout";
import { useHistory } from "react-router-dom";
import ReactDatatable from "@ashvin27/react-datatable";
import { useHttp } from "../../../hooks/useHttps";
import cardImg from "./card-new.jpeg";
import {
  Upload,
  DatePicker,
  Dropdown,
  Drawer,
  Button,
  Space,
  Spin,
  Menu,
  Select,
  Alert,
  message,
  Modal,
  Switch,
  Tabs,
  notification,
} from "antd";
import axios from "axios";
import config from "../../../config";
const { Option } = Select;

export default function Employers() {
  const [loading, employers] = useHttp(
    `${config.baseUrl}/v1/employees/filtered/employer`,
    []
  );
  const [loadCards, setLoadCards] = useState(false);
  const [cardLists, setCardLists] = useState([1, 2, 3, 4, 5, 6]);
  const [active, setActive] = useState("1");
  const [noCards, setNoCards] = useState(false);
  const [selectedCard, setSelectedCard] = useState("");
  const history = useHistory();

  const onChangeTab = (key) => {
  };
  const items = [
    {
      key: "1",
      label: "Card Info ",
      children: "",
    },
    {
      key: "2",
      label: "Spending Controls",
      children: "",
    },
    {
      key: "3",
      label: "Transactions",
      children: "",
    },
    {
      key: "4",
      label: "Authorizations",
      children: "",
    },
  ];

  const viewCardDetails = (id) => {
    history.push(`/card-details/${id}`);
  };

  const getBillPayment = (code) => {
    axios
      .get(`${config.baseUrl}/v1/bill-payment/billers/{category}/${code}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setLoadCards(false);
            setCardLists(res.data.result);
            setNoCards(false);
          } else {
            setLoadCards(false);
            setCardLists([]);
            setNoCards(false);
          }
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const onChange = (checked) => {
  };

  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div className="card-header">
              <div>
                <div className="font-weight-bold b" style={{ fontWeight: 600, border:0 }}>
                  MISHEAL HARRY CHUKWU EBUKA{" "}
                  <span className="badge bg-success">PHYSICAL</span>
                </div>
                <div className="">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="">
                      <div
                        className="font-weight-bold b"
                        style={{ fontWeight: 600,border:0  }}
                      >
                        NGN 23,908.00
                      </div>
                      <div>Created at Mar 20, 2024 3:12:34 AM</div>
                    </div>
                    <div className="">
                      <div className="d-flex align-items-center">
                        <div>
                          <i className="fa fa-elipsis"></i>
                        </div>
                        <div className="pl-5 ml-4">
                          <Switch defaultChecked={false} onChange={onChange} />{" "}
                          <span className="badge bg-warning">INACTIVE</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body mb-0 pb-5">
              {cardLists?.length > 0 && (
                <div class="row">
                  <Tabs
                    defaultActiveKey="1"
                    items={items}
                    onChange={onChangeTab}
                  />

                  {active === "1" && (
                    <div className="row">
                      <div className="col-md-7 col-12">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div>ACCOUNT</div>
                          <div>MISHEAL HARRY</div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div>CARD HOLDER</div>
                          <div>MISHEAL HARRY</div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div>CURRENCY</div>
                          <div>MISHEAL HARRY</div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div>BRAND</div>
                          <div>MISHEAL HARRY</div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div>TYPE</div>
                          <div>MISHEAL HARRY</div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div>PAN</div>
                          <div>MISHEAL HARRY</div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div>EXPIRY</div>
                          <div>MISHEAL HARRY</div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div>CVV</div>
                          <div>MISHEAL HARRY</div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div>DEFAULT PIN</div>
                          <div>MISHEAL HARRY</div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div>2FA ENROLLED</div>
                          <div>MISHEAL HARRY</div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div>STATUS</div>
                          <div>MISHEAL HARRY</div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div>DATE</div>
                          <div>MISHEAL HARRY</div>
                        </div>
                      </div>
                      <div className="col-md-1 col-12 ml-3"></div>
                      <div className="col-md-4 col-12 ml-3">
                        <img src={cardImg} className="w-100" />
                      </div>
                    </div>
                  )}
                </div>
              )}

              {noCards && (
                <div className="text-center pb-5">
                  <p className="text-center">Cards Not Available Yet</p>
                </div>
              )}

              {loadCards && (
                <div className="row">
                  <div className="text-center pb-5">
                    <Spin />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <Modal
        title="Input Pin"
        visible={pinModal}
        okText="Skip"
        footer={null}
        maskClosable={false}
        onCancel={cancelPinSetup}
      >
        <div className="container text-center">
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}

          {success && (
            <p
              className="text-center  font-weight-bold"
              style={{ color: "#1FC157" }}
            >
              {successMsg}
            </p>
          )}
          {error && (
            <p className="text-center text-danger font-weight-bold">
              {errorMsg}
            </p>
          )}

          {serverError && (
            <p className="text-center text-danger font-weight-bold">
              Failed to connect to SalarioPay
            </p>
          )}

          <div className="text-center">
            <div>
              <label className="text-left pt-4">Pin</label>
            </div>

            <PinInput
              length={4}
              initialValue={pin}
              secret
              onChange={(value, index) => setPin(value)}
              type="numeric"
              inputMode="number"
              style={{ padding: "10px" }}
              inputStyle={{ borderColor: "green" }}
              inputFocusStyle={{ borderColor: "blue" }}
              onComplete={(value, index) => {}}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </div>
          <br />
          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal> */}
    </Layout>
  );
}
