import React from "react";
import { Link } from "react-router-dom";
import Cookie from "js-cookie";
const userRoles = Cookie.getJSON("salarioPayUserRole");
const activeRole = sessionStorage.getItem("activeRole");
export default function DropDown(props) {
  return (
    <>
      <Link to="/profile" class="dropdown-item" h>
        <i data-feather="user"></i>Profile
      </Link>
      <Link to="/account-beneficiaries" class="dropdown-item" h>
        <i data-feather="user"></i>Beneficiaries
      </Link>
      <Link to="/billing-methods" class="dropdown-item" h>
        <i data-feather="user"></i>Billing Methods
      </Link>
      {userRoles?.length > 1 && (
        <a
          href={`/choose-profile?switch=true&currentRole=${activeRole}`}
          class="dropdown-item"
        >
          <i data-feather="user"></i>Switch Profile
        </a>
      )}
      <Link to="/payslip" class="dropdown-item" href="#">
        <i data-feather="inbox"></i>Payslip
      </Link>
      <Link to="/payment_method" class="dropdown-item" href="#">
        <i data-feather="edit"></i>Payment Method
      </Link>

      <div class="dropdown-divider"></div>

      <Link to="/security" class="dropdown-item">
        <i data-feather="unlock"></i>Security
      </Link>
      <a
        class="dropdown-item"
        onClick={() => {
          sessionStorage.setItem("employeeID", "");
          props.logout();
        }}
      >
        <i data-feather="log-out"></i>Logout
      </a>
    </>
  );
}
