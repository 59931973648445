import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../../containers/Layout";
import {
  Upload,
  DatePicker,
  Dropdown,
  Drawer,
  Button,
  Space,
  Spin,
  Menu,
  Alert,
  message,
  Modal,
  notification,
} from "antd";
import axios from "axios";
import { useHttp } from "../../../hooks/useHttps";
import config from "../../../config";
export default function Employers() {
  const [loadInvestment, setLoadInvestment] = useState(true);
  const [investmentData, setInvestmentData] = useState(false);
  const [noInvestment, setNoInvestment] = useState(false);
  const [investments, setInvestments] = useState([]);

  const [serverError, setServerError] = useState([]);

  const getInvestments = () => {
    axios
      .get(
        `${config.baseUrl}/v1/investments/categories
      `,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setLoadInvestment(false);
            setInvestmentData(true);
            setNoInvestment(false);
          } else {
            setLoadInvestment(false);
            setInvestmentData(false);
            setNoInvestment(true);
          }
          setInvestments(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 2000);
        }
      });
  };

  useEffect(() => {
    getInvestments();
  }, []);

  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div className="card-header">Investment</div>
            <div class="card-body mb-0 pb-0">
              {investmentData && (
                <div class="row">
                  {investments.map((investment) => {
                    return (
                      <div
                        class="col-sm-6 col-md-3"
                        style={{ cursor: "pointer" }}
                      >
                        <div class="card card-file-manager">
                          <div class="card-file-header text-danger">
                            <img src={investment.icon} className="w-25" />
                          </div>
                          <div class="card-body mb-0 pb-4 pt-4">
                            <h6 class="card-subtitle text-center text-muted">
                              {investment.name}
                            </h6>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {noInvestment && (
                <div className="row">
                  <div className="text-center pb-5">
                    <p className="text-center">
                      Investment Billers Not Available Yet
                    </p>
                    <hr />
                  </div>
                </div>
              )}
              {loadInvestment && (
                <div className="row">
                  <div className="text-center pb-5">
                    <Spin />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal okText="Submit" footer={false} maskClosable={false}></Modal>
    </Layout>
  );
}
