import React, { Fragment, useEffect, useState } from "react";
import Layout from "../../../containers/Layout";
import {
  Spin,
  Alert,
  Button,
  Modal,
  notification,
  Pagination,
  Select,
  Drawer,
  Switch,
} from "antd";
import Cookie, { set } from "js-cookie";
import { NumberFormatter } from "../../../formatter/index";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useForm, Controller } from "react-hook-form";
import Tab from "../../tab";
import axios from "axios";
import config from "../../../config";
import { useHttp } from "../../../hooks/useHttps";
const { Option } = Select;
export default function Employers() {
  const [payLevels, setPayLevels] = useState([]);
  const [levelName, setLevelName] = useState("");
  const [deptName, setDeptName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [minSalary, setMinSalary] = useState("");
  const [maxSalary, setMaxSalary] = useState("");
  const [groupName, setGroupName] = useState("");
  const [payCategory, setPayCategory] = useState("");
  const [midPoint, setMidPoint] = useState("");
  const [payCal, setPayCal] = useState("");
  const [desc, setDesc] = useState("");
  const [departmentInfo, setDepartmentInfo] = useState({});
  const [levelInfo, setLevelInfo] = useState({});
  const [payFormular, setPayFormular] = useState("");
  const [currency, setCurrency] = useState("");
  const [bonusEligible, setBonusEligible] = useState(false);
  const [positionId, setPositionid] = useState("");
  const [payLevelId, setPayLevelId] = useState("");
  const [levelOptions, setLevelOptions] = useState([]);
  const [currencyLists, setCurrencyLists] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [name, setName] = useState("");
  const [payType, setPayType] = useState("");
  const [country, setCountry] = useState("");
  const [employerCountries, setEmployerCountries] = useState([]);
  const [payValue, setPayValue] = useState("");
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [id, setID] = useState({});
  const [baseSalary, setBaseSalary] = useState("");
  const [updateSwitch, setUpdateSwitch] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  //paginations
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [department, setDepartment] = useState("");
  const [level, setLevel] = useState("");
  const [load, setLoad] = useState(false);
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [rowId, setRowID] = useState("");
  const [paystructureStatus, setPaystrucutreStatus] = useState("");
  const [viewDetailsModal, setViewDetailsModal] = useState(false);

  const switchChange = (checked) => {
    setBonusEligible(checked);
  };

  const [loadPayCal, payCalDatas] = useHttp(
    `${config.baseUrl}/v1/options/VALUE_TYPE`,
    []
  );
  const [loadPayType, payTypeDatas] = useHttp(
    `${config.baseUrl}/v1/options/PAY_TYPE`,
    []
  );

  const onViewDetails = () => {
    setViewDetailsModal(true);
  };

  const getLevelsData = () => {
    axios
      .get(
        `${config.baseUrl}/v1/employee-level/filtered/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setLevelOptions(
            res.data.result?.map((d) => ({
              value: d?.id,
              label: d?.level,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getDept = () => {
    axios
      .get(
        `${config.baseUrl}/v1/departments/filtered/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setDepartmentOptions(
            res.data.result?.map((d) => ({
              value: d?.id,
              label: d?.name,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const findLevel = (val) => {
    var findLevelInfo = levelOptions?.find((x) => x?.value === val);
    return findLevelInfo?.label;
  };

  const findDept = (val) => {
    var findDeptInfo = departmentOptions?.find((x) => x?.value === val);
    return findDeptInfo?.label;
  };

  useEffect(() => {
    getDept();
    getLevelsData();
  }, []);

  const showDrawer = (record, e) => {
    e.preventDefault();
    setRowID(record.id);
    setPaystrucutreStatus(record.status);
    setVisibleDraw(true);
    setDesc(record.description);
    setDepartment(record.department?.id);
    setLevel(record?.level?.id);
    getCurrenccies(record?.country);
    setCountry(record?.country);
    setCurrency(record?.currency);
    setMinSalary(record?.minSalary);
    setMaxSalary(record?.maxSalary);
    setDepartmentInfo(record?.department);
    setLevelInfo(record?.level);
    setMidPoint(record.midpoint);
    setUpdateFlag(true);
  };
  const closeDrawer = () => {
    setVisibleDraw(false);
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  const getPayLevelByCountry = (country) => {
    setLoad(true);
    axios
      .get(
        `${
          config.baseUrl
        }/v1/pay-level/paged?&employerId=${sessionStorage.getItem(
          "employer_id"
        )}&page=0&size=10`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setTotalItems(res.data.totalPages * 10);
          var resp = res.data.result;
          setPayLevels(resp);
        } else {
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
        }
      });
  };

  const getPayLevel = (country) => {
    setLoad(true);
    axios
      .get(
        `${
          config.baseUrl
        }/v1/pay-level/paged?&employerId=${sessionStorage.getItem(
          "employer_id"
        )}&page=0&size=10`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setTotalItems(res.data.totalPages * 10);
          var resp = res.data.result;
          setPayLevels(resp);
        } else {
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
        }
      });
  };

  const openUpdateModal = () => {
    setOpenModal(true);
    setUpdateSwitch(true);
    closeDrawer();
  };

  const closeModal = () => {
    setOpenModal(false);
    setUpdateFlag(false);
    setCurrency("");
    setDesc("");
    setMinSalary("");
    setMaxSalary("");
    setMidPoint("");
    setDepartment("");
    setLevel("");
    setCountry("");
  };

  const updatePayLevel = (e) => {
    e.preventDefault();

    const data = {
      employerId: sessionStorage.getItem("employer_id"),
      country,
      currency,
      departmentId: department,
      description: desc,
      levelId: level,
      maxSalary: maxSalary,
      midpoint: midPoint,
      minSalary: minSalary,
    };
    setLoad(true);
    axios
      .put(`${config.baseUrl}/v1/pay-level/${rowId}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getPayLevel();
          closeModal();
          setSuccessMsg(res.data.message);
          setVisibleDraw(false);
        } else {
          Notification("error", "Error", res.data.message);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };

  const getCountriesInfo = () => {
    axios
      .get(
        `${config.baseUrl}/v1/employers/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const countryInfo = res.data.result.countries;
          setEmployerCountries(countryInfo);
          getPayLevel(countryInfo[0].code);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getCurrenccies = (code) => {
    axios
      .get(`${config.baseUrl}/v1/currencies/filtered/${code}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const list = res.data.result;
          setCurrencyLists(list);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const deleteConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure you want to delete this pay level ? `,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deletePaylevel();
      },
      onCancel() {},
    });
  };

  const statusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure you want to change status of this pay level  ? `,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        statusPayLevel();
      },
      onCancel() {},
    });
  };

  const deletePaylevel = (id) => {
    setLoad(true);
    axios
      .delete(`${config.baseUrl}/v1/pay-level/${rowId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setVisibleDraw(false)
        setLoad(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getPayLevel(employerCountries[0].code);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
          Notification("error", "Error", "Failed to connect to SalarioPay");
        }
      });
  };

  const statusPayLevel = (id) => {
    setLoad(true);
    axios
      .put(
        `${config.baseUrl}/v1/pay-level/status/${rowId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setVisibleDraw(false)
        setLoad(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getPayLevel(employerCountries[0].code);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
          Notification("error", "Error", "Failed to connect to SalarioPay");
        }
      });
  };

  const createPaystructure = () => {
    setOpenModal(true);
    setUpdateSwitch(false);
    setPayCal("");
    setPayType("");
    setPayValue("");
  };

  useEffect(() => {
    getCountriesInfo();
  }, []);

  const formatNumber = (num) => {
    return Intl.NumberFormat().format(num);
  };

  const addPayLevel = (e) => {
    e.preventDefault();

    const data = {
      employerId: sessionStorage.getItem("employer_id"),
      country,
      currency,
      departmentId: department,
      description: desc,
      levelId: level,
      maxSalary: maxSalary,
      midpoint: midPoint,
      minSalary: minSalary,
    };

    if (
      data.country &&
      data.currency &&
      data?.departmentId &&
      data?.levelId &&
      data?.maxSalary &&
      data?.minSalary &&
      data?.midpoint
    ) {
      setLoad(true);
      axios
        .post(`${config.baseUrl}/v1/pay-level`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setPayValue("");
            setPayFormular("");
            setPayType("");
            setPayCal("");
            setGroupName("");
            Notification("success", "Success", res.data.message);
            closeModal();
            closeModal();
            getPayLevel(employerCountries[0].code);
          } else {
            Notification("error", "Error", res.data.message);
            setErrorMsg(res.data.message);
            setTimeout(() => {
              setError(false);
            }, 2500);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setFieldError(true);
      setTimeout(() => {
        setFieldError(false);
      }, 1500);
    }
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const getPaged = (queryString) => {
    axios
      .get(`${config.baseUrl}/v1/pay-level/paged?${queryString}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setTotalItems(res.data.totalPages * 10);
          var resp = res.data.result;
          setPayLevels(resp);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `page=${page - 1}&groupId=${sessionStorage.getItem(
      "paygroup_id"
    )}&employerId=${sessionStorage.getItem("employer_id")}&size=${pageSize}`;
    getPaged(queryString);
  };

  var payCalPercentageTotal = payLevels?.filter(
    (d) => d?.payCal === "PERCENTAGE"
  );
  var payCalFlatAmtTotal = payLevels?.filter(
    (d) => d?.payCal === "FLAT_AMOUNT"
  );

  var percentageTotal = payCalPercentageTotal.reduce(
    (n, { payValue }) => n + parseInt(payValue),
    0
  );

  var flatAmtTotal = payCalFlatAmtTotal.reduce(
    (n, { payValue }) => n + parseInt(payValue),
    0
  );

  return (
    <Layout>
      <Tab />
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="card">
            <div class="">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-top">
                  <div>
                    <h5 class="card-title px-3">
                      Manage Pay Level{" "}
                      {employerCountries.length > 0 && (
                        <Select
                          defaultValue={employerCountries[0].code}
                          style={{ width: 100 }}
                          onChange={(val) => getPayLevel(val)}
                          value={employerCountries[0].code}
                        >
                          {employerCountries.map((country) => {
                            return (
                              <Option key={country.code} value={country.code}>
                                {country.code}
                              </Option>
                            );
                          })}
                        </Select>
                      )}
                    </h5>
                  </div>
                  <div></div>
                </div>
                <div style={{ padding: "30px" }}>
                  <button
                    className="btn btn-info m-b-xs "
                    style={{ background: "#1FC157", color: "white" }}
                    onClick={createPaystructure}
                  >
                    Add Pay Level
                  </button>{" "}
                </div>
              </div>

              {load && (
                <p className="text-center py-5">
                  <Spin />
                </p>
              )}
              {!load && (
                <div className="container">
                  {payLevels?.length > 0 ? (
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Min Salary</th>
                            <th scope="col">Mid Point</th>
                            <th scope="col">Max Salary</th>

                            <th scope="col">Date</th>
                            <th scope="col">Status</th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {payLevels.map((data) => {
                            return (
                              <tr>
                                <td>
                                  {data?.currency}
                                  {data.minSalary
                                    ? formatNumber(data?.minSalary)
                                    : 0}
                                </td>

                                <td>
                                  {data?.currency}
                                  {data.midpoint
                                    ? formatNumber(data?.midpoint)
                                    : 0}
                                </td>
                                <td>
                                  {data.currency}
                                  {data.maxSalary
                                    ? formatNumber(data?.maxSalary)
                                    : 0}
                                </td>
                                <td>
                                  {data.createdAt
                                    ? data.createdAt.slice(0, 10)
                                    : ""}
                                </td>
                                <td>
                                  <span
                                    className={
                                      {
                                        Enabled: "badge bg-success",
                                        Disabled: "badge bg-danger",
                                        ACTIVE: "badge bg-success",
                                        COMPLETED: "badge bg-success",
                                        SUCCESS: "badge bg-success",
                                        FAILED: "badge bg-danger",
                                        IDLE: "badge bg-info",
                                        PENDING: "badge bg-warning",
                                        PROCESSING: "badge bg-warning",
                                        INACTIVE: "badge bg-danger",
                                      }[data.status ? "ACTIVE" : "INACTIVE"]
                                    }
                                  >
                                    {data.status ? "ACTIVE" : "INACTIVE"}
                                  </span>
                                </td>
                                <td>
                                  <button
                                    type="submit"
                                    onClick={showDrawer.bind(this, data)}
                                    class="btn btn-primary"
                                    style={{
                                      background: "#1FC157",
                                      color: "white",
                                    }}
                                  >
                                    Action
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div>
                      <p className="text-center pt-5 pb-5">
                        No Pay Level Record.
                      </p>
                    </div>
                  )}
                  {payLevels?.length > 0 && (
                    <div className="text-center mt-5 mb-5">
                      <Pagination
                        current={page}
                        total={totalItems}
                        defaultPageSize={10}
                        onChange={pagination}
                        itemRender={itemRender}
                      />{" "}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={updateSwitch ? "Update Pay Level " : "Add Pay Level "}
        visible={openModal}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeModal}
      >
        <form>
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}

          {fieldError && (
            <div className="pb-3">
              <Alert message="All fields are required!" type="error" showIcon />
            </div>
          )}
          {serverError && (
            <p className="text-center text-danger font-weight-bold">
              Failed to connect to SalarioPay
            </p>
          )}

          {updateSwitch ? (
            <>
              <div>
                <div>
                  <div className="mb-3">
                    <label>Department</label>
                    <select
                      class="form-select"
                      name="department"
                      aria-label="Default select example"
                      onChange={(e) => setDepartment(e.target.value)}
                      value={department}
                    >
                      <option selected>Select</option>
                      {departmentOptions?.map((dept) => {
                        return (
                          <option value={dept?.value}>{dept?.label}</option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label> Level</label>
                    <select
                      class="form-select"
                      name="department"
                      aria-label="Default select example"
                      onChange={(e) => setLevel(e.target.value)}
                      value={level}
                    >
                      <option selected>Select</option>
                      {levelOptions?.map((dept) => {
                        return (
                          <option value={dept?.value}>{dept?.label}</option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label>Min Salary</label>
                    <Controller
                      name="minSalary"
                      control={control}
                      {...register("minSalary", {
                        required: false,
                        onChange: (e) => setMinSalary(e.target.value),
                        value: minSalary,
                      })}
                      render={({ field }) => (
                        <NumberFormatter
                          {...field}
                          label=""
                          value={minSalary}
                          name="minSalary"
                          error={errors.minSalary?.message}
                          prefix={"₦"}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-3">
                    <label>Mid Point</label>
                    <Controller
                      name="midSalary"
                      control={control}
                      {...register("midSalary", {
                        required: false,
                        onChange: (e) => setMidPoint(e.target.value),
                        value: midPoint,
                      })}
                      render={({ field }) => (
                        <NumberFormatter
                          {...field}
                          label=""
                          value={midPoint}
                          name="midSalary"
                          error={errors.midPoint?.message}
                          prefix={"₦"}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-3">
                    <label>Max Salary</label>
                    <Controller
                      name="maxSalary"
                      control={control}
                      {...register("maxSalary", {
                        required: false,
                        onChange: (e) => setMaxSalary(e.target.value),
                        value: maxSalary,
                      })}
                      render={({ field }) => (
                        <NumberFormatter
                          {...field}
                          label=""
                          value={maxSalary}
                          name="maxSalary"
                          error={errors.maxSalary?.message}
                          prefix={"₦"}
                        />
                      )}
                    />
                  </div>

                  <div className="mb-3">
                    <label>Country</label>
                    <select
                      onChange={(e) => {
                        setCountry(e.target.value);
                        getCurrenccies(e.target.value);
                      }}
                      class="form-select"
                      aria-label="Default select example"
                      value={country}
                    >
                      <option selected>Select </option>
                      {employerCountries.map((element) => {
                        return (
                          <option value={element.code}>{element.name}</option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label>Currency</label>
                    <select
                      onChange={(e) => setCurrency(e.target.value)}
                      class="form-select"
                      aria-label="Default select example"
                      value={currency}
                    >
                      <option selected>Select </option>
                      {currencyLists?.map((element) => {
                        return (
                          <option value={element.currencyCode}>
                            {element.currencyName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label>Description</label>
                    <div className="">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        onChange={(e) => setDesc(e.target.value)}
                        value={desc}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div>
              <div>
                <div className="mb-3">
                  <label>Department</label>
                  <select
                    class="form-select"
                    name="department"
                    aria-label="Default select example"
                    onChange={(e) => setDepartment(e.target.value)}
                    value={department}
                  >
                    <option selected>Select</option>
                    {departmentOptions?.map((dept) => {
                      return <option value={dept?.value}>{dept?.label}</option>;
                    })}
                  </select>
                </div>
                <div className="mb-3">
                  <label> Level</label>
                  <select
                    class="form-select"
                    name="department"
                    aria-label="Default select example"
                    onChange={(e) => setLevel(e.target.value)}
                    value={level}
                  >
                    <option selected>Select</option>
                    {levelOptions?.map((dept) => {
                      return <option value={dept?.value}>{dept?.label}</option>;
                    })}
                  </select>
                </div>
                <div className="mb-3">
                  <label>Min Salary</label>
                  <Controller
                    name="minSalary"
                    control={control}
                    {...register("minSalary", {
                      required: false,
                      onChange: (e) => setMinSalary(e.target.value),
                      value: minSalary,
                    })}
                    render={({ field }) => (
                      <NumberFormatter
                        {...field}
                        label=""
                        value={minSalary}
                        name="minSalary"
                        error={errors.minSalary?.message}
                        prefix={"₦"}
                      />
                    )}
                  />
                </div>
                <div className="mb-3">
                  <label>Mid Point</label>
                  <Controller
                    name="midSalary"
                    control={control}
                    {...register("midSalary", {
                      required: false,
                      onChange: (e) => setMidPoint(e.target.value),
                      value: midPoint,
                    })}
                    render={({ field }) => (
                      <NumberFormatter
                        {...field}
                        label=""
                        value={midPoint}
                        name="midSalary"
                        error={errors.midPoint?.message}
                        prefix={"₦"}
                      />
                    )}
                  />
                </div>
                <div className="mb-3">
                  <label>Max Salary</label>
                  <Controller
                    name="maxSalary"
                    control={control}
                    {...register("maxSalary", {
                      required: false,
                      onChange: (e) => setMaxSalary(e.target.value),
                      value: maxSalary,
                    })}
                    render={({ field }) => (
                      <NumberFormatter
                        {...field}
                        label=""
                        value={maxSalary}
                        name="maxSalary"
                        error={errors.maxSalary?.message}
                        prefix={"₦"}
                      />
                    )}
                  />
                </div>

                <div className="mb-3">
                  <label>Country</label>
                  <select
                    onChange={(e) => {
                      setCountry(e.target.value);
                      getCurrenccies(e.target.value);
                    }}
                    class="form-select"
                    aria-label="Default select example"
                    value={country}
                  >
                    <option selected>Select </option>
                    {employerCountries.map((element) => {
                      return (
                        <option value={element.code}>{element.name}</option>
                      );
                    })}
                  </select>
                </div>
                <div className="mb-3">
                  <label>Currency</label>
                  <select
                    onChange={(e) => setCurrency(e.target.value)}
                    class="form-select"
                    aria-label="Default select example"
                    value={currency}
                  >
                    <option selected>Select </option>
                    {currencyLists?.map((element) => {
                      return (
                        <option value={element.currencyCode}>
                          {element.currencyName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="mb-3">
                  <label>Description</label>
                  <div className="">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      onChange={(e) => setDesc(e.target.value)}
                      value={desc}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={updateSwitch ? updatePayLevel : addPayLevel}
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        title={"View Details"}
        visible={viewDetailsModal}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={() => {
          setViewDetailsModal(false);
        }}
      >
        <div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>Min Salary</div>
            <div>
              {" "}
              {currency} {minSalary ? formatNumber(minSalary) : ""}
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>Mid Point</div>
            <div>
              {currency} {midPoint ? formatNumber(midPoint) : ""}
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>Max Salary</div>
            <div>
              {currency} {maxSalary ? formatNumber(maxSalary) : ""}
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>Department</div>
            <div>{departmentInfo?.name}</div>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>Level</div>
            <div>{levelInfo?.level?.replace(/_+/g, " ")}</div>
          </div>
          <div className="d-flex justify-content-between  align-items-center mb-3">
            <div>Description</div>
            <div>
              <span
                className={
                  {
                    Enabled: "badge bg-success",
                    Disabled: "badge bg-danger",
                    ACTIVE: "badge bg-success",
                    COMPLETED: "badge bg-success",
                    SUCCESS: "badge bg-success",
                    FAILED: "badge bg-danger",
                    IDLE: "badge bg-info",
                    PENDING: "badge bg-warning",
                    PROCESSING: "badge bg-warning",
                    INACTIVE: "badge bg-danger",
                  }[paystructureStatus ? "ACTIVE" : "INACTIVE"]
                }
              >
                {paystructureStatus ? "ACTIVE" : "INACTIVE"}
              </span>
            </div>
          </div>
        </div>
      </Modal>
      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}
      >
        <div
          style={{ color: "#000000", cursor: "pointer" }}
          onClick={onViewDetails}
        >
          View Details
        </div>
        <hr />
        <div
          style={{ color: "#000000", cursor: "pointer" }}
          onClick={openUpdateModal}
        >
          Update
        </div>

        <hr />
        <div
          style={{ color: "#000000", cursor: "pointer" }}
          onClick={deleteConfirm}
        >
          Delete
        </div>

        <hr />

        <div
          style={{ color: "#000000", cursor: "pointer" }}
          onClick={statusConfirm}
        >
          {paystructureStatus ? "Disable" : "Enable"}
        </div>

        <hr />
      </Drawer>
    </Layout>
  );
}
