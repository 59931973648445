import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";



export default function Chart() {
  const [chartData, setChartdata] = useState([]);
  const getPaygroup = () => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/dashboard/employer/employee-summary/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setChartdata(
            res.data.result.map((d) => ({
              name: d.monthName,
              employeeCount: d.employeeCount,
              amt: d.employeeCount,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };
  useEffect(() => {
    getPaygroup();
  }, []);
  return (
    <BarChart
      width={1300}
      height={400}
      data={chartData}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      
      <Bar type="monotone" dataKey="" fill="#ffffff" />
      <Bar type="monotone" dataKey="employeeCount" fill="#1FC157" />
    </BarChart>
  );
}
