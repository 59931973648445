import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../../containers/Layout";
import ReactDatatable from "@ashvin27/react-datatable";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHttp } from "../../../hooks/useHttps";

import {
  Upload,
  DatePicker,
  Dropdown,
  Drawer,
  Button,
  Space,
  Spin,
  Menu,
  Alert,
  message,
  Select,
  Modal,
  notification,
} from "antd";
import axios from "axios";
import config from "../../../config";
const { Option } = Select;

export default function ProcessorAction(props) {
  const [processorsData, setProcessorsActionData] = useState([]);
  const [processorsActionCreate, setProcessorsActionCreate] = useState(false);
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [currencyOptions, setCurrency] = useState([]);
  const [countryOptions, setCountries] = useState([]);
  const [processorUpdate, setProcessorUpdate] = useState(false);
  const [statusSuccess, setSuccessStatus] = useState(false);
  const [type, setType] = useState([]);
  const [statusMsg, setStatusMsg] = useState("");
  const [priority, setPriority] = useState("");
  const processorId = props.history.location.state.processorId
    ? props.history.location.state.processorId
    : "";

  const [loadingTypes, types] = useHttp(
    `${config.baseUrl}/v1/processors/actionTypes`,
    []
  );
  const [loadingIdentities, identities] = useHttp(
    `${config.baseUrl}/v1/processors/identities`,
    []
  );

  const [loadingCurrencies, currencies] = useHttp(
    `${config.baseUrl}/v1/currencies/filtered`,
    []
  );

  const [loadingCountries, countries] = useHttp(
    `${config.baseUrl}/v1/countries/filtered`,
    []
  );

  const handleChangeCurrencies = (value) => {
    setCurrency(value);
  };

  const handleChangeIdentities = (value) => {
    setIdentity(value);
  };

  const handleChangeCountries = (value) => {
    setCountries(value);
  };

  const handleChangeType = (value) => {
    setType(value);
  };

  const countrySelect = [];
  countries.map((country) => {
    countrySelect.push(<Option key={country.code}>{country.name}</Option>);
  });

  const currencySelect = [];
  currencies.map((currency) => {
    currencySelect.push(
      <Option key={currency.currencyCode}>{currency.currencyName}</Option>
    );
  });

  const identitySelect = [];
  identities.map((identity) => {
    identitySelect.push(
      <Option key={identity}>{identity}</Option>
    );
  });

  const typeSelect = [];
  types.map((type) => {
    typeSelect.push(
      <Option key={type}>{type ? type.replace("_", " ") : ""}</Option>
    );
  });

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [rowId, setRowId] = useState("");
  const [processorStatus, setProcessorStatus] = useState("");
  const [identity, setIdentity] = useState([]);
  const [load, setLoad] = useState(false);

  const showDrawer = (record, e) => {
    e.preventDefault();
    setVisibleDraw(true);
    setRowId(record.id);
    setProcessorStatus(record.status);
  };

  const closeDrawer = () => setVisibleDraw(false);

  const closeAdd = () => {
    setProcessorsActionCreate(false);
    setCountries([]);
    setCurrency([]);
    setType([]);
    setPriority("");
  };

  const deleteProcessor = (id) => {
    axios
      .delete(`${config.baseUrl}/v1/processors/actions/${rowId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          setStatusMsg(res.data.message);

          setTimeout(() => {
            setSuccessStatus(false);
            getProcessorsAction();
            window.location.reload(false);
          }, 2000);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const createProcessors = () => {
    setProcessorsActionCreate(true);
    setProcessorUpdate(false);
  };

  const columns = [
    {
      key: "type",
      text: "Type",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "priority",
      text: "Priority",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "date",
      text: "Date",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },

    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <Button
              type="primary"
              danger
              style={{
                background: "#1FC157",
                color: "white",
                cursor: "pointer",
              }}
              onClick={showDrawer.bind(this, record)}
            >
              Actions
            </Button>
          </Fragment>
        );
      },
    },
  ];

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];
  const getProcessorsAction = () => {
    axios
      .get(
        `${config.baseUrl}/v1/processors/action/paged?processorId=${processorId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const processorsData = res.data.result;
          setProcessorsActionData(
            processorsData.map((data) => ({
              type: data.type ? data.type.replace(/_+/g, " ") : "",
              priority: data.priority,
              date: data.createdAt ? data.createdAt.slice(0, 10) : "",
              id: data.id,
              status: data.status ? "Enabled" : "Disabled",
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const updateSwitch = () => {
    setProcessorUpdate(true);
    setProcessorsActionCreate(true);
    setVisibleDraw(false);
  };

  const refresh = () => {
    getProcessorsAction();
  };

  const addProcessorAction = (e) => {
    e.preventDefault();
    const data = {
      countries: countryOptions,
      priority: priority,
      processorId,
      type: type,
      identities: identity,
    };
    console.log(data);
    if (data.countries && data.priority && data.type ) {
      setLoad(true);
      axios
        .post(`${config.baseUrl}/v1/processors/action`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setCountries([]);
            setCurrency([]);
            setType([]);
            setPriority("");
            setSuccess(true);
            setSuccessMsg(res.data.message);
            setTimeout(() => {
              setSuccess(false);
              getProcessorsAction();
              setProcessorsActionCreate(false);
            }, 2000);
          } else {
            setError(true);
            setErrorMsg(res.data.message);
            setTimeout(() => {
              setError(false);
            }, 2500);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setFieldError(true);
      setTimeout(() => {
        setFieldError(false);
      }, 1500);
    }
  };

  const changeStatusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure change status of this Processor Action?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        changeStatus();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const deleteStatusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure you want to delete this Processor Action?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteProcessor();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const changeStatus = () => {
    axios
      .put(
        `${config.baseUrl}/v1/processors/action/status/${rowId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          setStatusMsg(res.data.message);
          getProcessorsAction();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    getProcessorsAction();
  }, []);

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    button: {
      excel: true,
      print: true,
      extra: true,
    },
  };
  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <h5 class="card-title">Processors Action</h5>
                <button
                  className="btn btn-info m-b-xs mr-2"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={createProcessors}
                >
                  Add Action
                </button>{" "}
              </div>
              <div className="text-center">
                {deleteSuccess && (
                  <div className="pb-4">
                    <Alert message="Processor Deleted" type="error" showIcon />
                  </div>
                )}
              </div>

              <div className="pt-5">
                <div className="text-center">
                  {deleteSuccess && (
                    <div className="pb-4">
                      <Alert
                        message="Processor Status Changed"
                        type="error"
                        showIcon
                      />
                    </div>
                  )}
                  {statusSuccess && (
                    <div className="pb-4">
                      <Alert message={statusMsg} type="error" showIcon />
                    </div>
                  )}
                </div>
                <ReactDatatable
                  config={configTable}
                  records={processorsData}
                  columns={columns}
                  extraButtons={extraButtons}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}
      >
        <Link
          to={{
            pathname: "/view-processor-action",
            state: { processorActionId: rowId, processorId: processorId },
          }}
        >
          <div style={{ cursor: "pointer" }}>View Action</div>
        </Link>
        <hr />

        <div
          style={{ color: "red", cursor: "pointer" }}
          onClick={deleteStatusConfirm}
        >
          Delete Processor
        </div>
        <hr />
        <div style={{ cursor: "pointer" }} onClick={changeStatusConfirm}>
          {processorStatus === "Enabled" ? "Disable" : "Enable"}
        </div>

        <hr />
      </Drawer>
      <Modal
        title="Add Processor"
        visible={processorsActionCreate}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeAdd}
      >
        <form>
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}
          {success && (
            <div className="pb-3">
              <Alert message={successMsg} type="success" showIcon />
            </div>
          )}
          {error && (
            <div className="pb-3">
              <Alert message={errorMsg} type="error" showIcon />
            </div>
          )}
          {fieldError && (
            <div className="pb-3">
              <Alert message="All fields are required!" type="error" showIcon />
            </div>
          )}
          {serverError && (
            <p className="text-center text-danger font-weight-bold">
              Failed to connect to SalarioPay
            </p>
          )}
          <div>
            <div className="mb-3">
              <div className="form-floating">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option selected>Select</option>
                  {types.map((type) => {
                    return <option value={type}>{type ? type.replace(/_+/g, ' ') :""}</option>;
                  })}
                </select>
                <label for="floatingInput">Action Type</label>
              </div>
            </div>

            <div class="mb-3">
              <label class="form-label">Countries</label>
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                onChange={handleChangeCountries}
              >
                {countrySelect}
              </Select>
            </div>
            {type === "IDENTITY" &&

            <div class="mb-3">
              <label class="form-label">Identities</label>
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                onChange={handleChangeIdentities}
              >
                {identitySelect}
              </Select>
            </div>}
          

            <div className="mb-3">
              <div className="form-floating">
                <input
                  type="number"
                  className="form-control"
                  id="floatingInput"
                  placeholder="country name"
                  onChange={(e) => setPriority(e.target.value)}
                  value={priority}
                />
                <label for="floatingInput">Priority</label>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={addProcessorAction}
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>
    </Layout>
  );
}
