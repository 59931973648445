import React, { Fragment, useEffect, useState } from "react";
import Layout from "../../../containers/Layout";
import {
  Spin,
  Alert,
  Button,
  Modal,
  notification,
  Pagination,
  Select,
  Drawer,
  Switch,
} from "antd";
import Cookie, { set } from "js-cookie";
import { NumberFormatter } from "../../../formatter/index";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useForm, Controller } from "react-hook-form";
import Tab from "../../tab";
import axios from "axios";
import config from "../../../config";
import { useHttp } from "../../../hooks/useHttps";
const { Option } = Select;
export default function Employers() {
  const [paymentStructure, setPaymentMethods] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [currencyLists, setCurrencyLists] = useState([]);
  const [payLevelInfo, setPayLevelInfo] = useState({});
  const [positionInfo, setPositionInfo] = useState({});
  const [openViewDetails, setOpenViewDetails] = useState(false);
  const [positionOptions, setPositionOptions] = useState([]);
  const [payCategory, setPayCategory] = useState("");
  const [paylevelList, setPayLevelList] = useState([]);
  const [positionName, setPositionName] = useState("");
  const [payLevelName, setPayLevelName] = useState("");
  const [payCal, setPayCal] = useState("");
  const [payFormular, setPayFormular] = useState("");
  const [currency, setCurrency] = useState("");
  const [bonusEligible, setBonusEligible] = useState(false);
  const [positionId, setPositionid] = useState("");
  const [payLevelId, setPayLevelId] = useState("");
  const [name, setName] = useState("");
  const [payType, setPayType] = useState("");
  const [country, setCountry] = useState("");
  const [employerCountries, setEmployerCountries] = useState([]);
  const [payValue, setPayValue] = useState("");
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [id, setID] = useState({});
  const [baseSalary, setBaseSalary] = useState("");
  const [updateSwitch, setUpdateSwitch] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  //paginations
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [load, setLoad] = useState(false);
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [rowId, setRowID] = useState("");
  const [payScaleStatus, setPayScaleStatus] = useState("");

  const switchChange = (checked) => {
    setBonusEligible(checked);
  };

  const [loadPayCal, payCalDatas] = useHttp(
    `${config.baseUrl}/v1/options/VALUE_TYPE`,
    []
  );
  const [loadPayType, payTypeDatas] = useHttp(
    `${config.baseUrl}/v1/options/PAY_TYPE`,
    []
  );

  const [loadGroupName, groupNameDatas] = useHttp(
    `${config.baseUrl}/v1/pay-structures/payGroup/${sessionStorage.getItem(
      "employer_id"
    )}`,
    []
  );

  const showDrawer = (record, e) => {
    e.preventDefault();
    setRowID(record.id);
    setName(record?.name);
    getCurrenccies(record?.country);
    getPaylevel(record?.country);
    setPayScaleStatus(record.status);
    setVisibleDraw(true);
    setBaseSalary(record.baseSalary);
    setCountry(record.country);
    setPayLevelId(record?.payLevel?.id);
    setPayLevelInfo(record?.payLevel);
    setPositionInfo(record?.position);
    setPositionid(record?.position?.id);
    setCurrency(record.currency);
    setUpdateFlag(true);
  };
  const closeDrawer = () => {
    setVisibleDraw(false);
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  const getPayScale = () => {
    setLoad(true);
    axios
      .get(
        `${
          config.baseUrl
        }/v1/pay-scale/paged?&employerId=${sessionStorage.getItem(
          "employer_id"
        )}&page=0&size=10`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setTotalItems(res.data.totalPages * 10);
          var resp = res.data.result;
          setPaymentMethods(resp);
        } else {
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
        }
      });
  };

  const openUpdateModal = () => {
    setOpenModal(true);
    setUpdateSwitch(true);
    closeDrawer();
  };

  const closeModal = () => {
    setOpenModal(false);
    setUpdateFlag(false);
    setCountry("");
    setCurrency("");
    setBaseSalary("");
    setPayLevelId("");
    setPositionid("");
  };

  const updatePayscale = (e) => {
    e.preventDefault();
    const data = {
      employerId: sessionStorage.getItem("employer_id"),
      baseSalary: baseSalary,
      bonusEligibility: bonusEligible,
      country: country,
      currency: currency,
      name: name,
      payLevelId: payLevelId,
      positionId: positionId,
    };
    setLoad(true);
    axios
      .put(`${config.baseUrl}/v1/pay-structures/${rowId}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getPayScale(employerCountries[0].code);
          closeModal();
          setSuccessMsg(res.data.message);
          setVisibleDraw(false);
        } else {
          Notification("error", "Error", res.data.message);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };

  const getCountriesInfo = () => {
    axios
      .get(
        `${config.baseUrl}/v1/employers/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const countryInfo = res.data.result.countries;
          setEmployerCountries(countryInfo);
          getPayScale(countryInfo[0].code);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getPositions = () => {
    axios
      .get(
        `${config.baseUrl}/v1/positions/filtered/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setPositionOptions(
            res.data.result?.map((d) => ({
              value: d?.id,
              label: d?.name,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getPaylevel = (country) => {
    axios
      .get(`${config.baseUrl}/v1/pay-level/country/${country}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const list = res.data.result;
          setPayLevelList(list);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const deleteConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure you want to delete this pay scale ? `,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deletePayScale();
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    getPositions();
    getCountriesInfo();
  }, []);

  const statusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure you want to change status of this pay scale  ? `,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        statusPayScale();
      },
      onCancel() {},
    });
  };

  const deletePayScale = (id) => {
    setLoad(true);
    axios
      .delete(`${config.baseUrl}/v1/pay-scale/${rowId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        setVisibleDraw(false)
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getPayScale(employerCountries[0].code);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
          Notification("error", "Error", "Failed to connect to SalarioPay");
        }
      });
  };

  const statusPayScale = (id) => {
    setLoad(true);
    axios
      .put(
        `${config.baseUrl}/v1/pay-scale/status/${rowId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
                setVisibleDraw(false)
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getPayScale(employerCountries[0].code);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
          Notification("error", "Error", "Failed to connect to SalarioPay");
        }
      });
  };

  const createPaystructure = () => {
    setOpenModal(true);
    setUpdateSwitch(false);
    setPayCal("");
    setPayType("");
    setPayValue("");
  };

  useEffect(() => {
    getPayScale();
  }, []);

  const getCurrenccies = (code) => {
    axios
      .get(`${config.baseUrl}/v1/currencies/filtered/${code}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const list = res.data.result;
          setCurrencyLists(list);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const addPayScale = (e) => {
    e.preventDefault();

    const data = {
      employerId: sessionStorage.getItem("employer_id"),
      baseSalary: baseSalary,
      bonusEligibility: bonusEligible,
      country: country,
      currency: currency,
      name: name,
      payLevelId: payLevelId,
      positionId: positionId,
    };

    if (
      data.baseSalary &&
      data.bonusEligibility &&
      data.country &&
      data.currency &&
      data.name &&
      data.payLevelId &&
      data?.positionId
    ) {
      setLoad(true);
      axios
        .post(`${config.baseUrl}/v1/pay-scale`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setPayValue("");
            setPayFormular("");
            setPayType("");
            setPayCal("");
            setGroupName("");
            Notification("success", "Success", res.data.message);
            closeModal();
            getPayScale(employerCountries[0].code);
          } else {
            Notification("error", "Error", res.data.message);
            setErrorMsg(res.data.message);
            setTimeout(() => {
              setError(false);
            }, 2500);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setFieldError(true);
      setTimeout(() => {
        setFieldError(false);
      }, 1500);
    }
  };

  const formatNumber = (num) => {
    return new Intl.NumberFormat().format(num);
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const getPaged = (queryString) => {
    axios
      .get(`${config.baseUrl}/v1/pay-scale/paged?${queryString}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setTotalItems(res.data.totalPages * 10);
          var resp = res.data.result;
          setPaymentMethods(
            resp.map((data) => ({
              createdAt: data?.createdAt,
              id: data.id,
              employerId: data.employerId,
              groupName: data.groupName,
              payType: data.payType,
              payValue: data.payValue,
              payCal: data.payCal,
              payFormular: data.payFormular,
              status: data?.status ? "Enabled" : "Disabled",
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `page=${page - 1}&groupId=${sessionStorage.getItem(
      "paygroup_id"
    )}&employerId=${sessionStorage.getItem("employer_id")}&size=${pageSize}`;
    getPaged(queryString);
  };

  var payCalPercentageTotal = paymentStructure?.filter(
    (d) => d?.payCal === "PERCENTAGE"
  );
  var payCalFlatAmtTotal = paymentStructure?.filter(
    (d) => d?.payCal === "FLAT_AMOUNT"
  );

  var percentageTotal = payCalPercentageTotal.reduce(
    (n, { payValue }) => n + parseInt(payValue),
    0
  );

  var flatAmtTotal = payCalFlatAmtTotal.reduce(
    (n, { payValue }) => n + parseInt(payValue),
    0
  );

  return (
    <Layout>
      <Tab />
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="card">
            <div class="">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-top">
                  <div>
                    <h5 class="card-title px-3">Manage Pay Scale </h5>
                  </div>
                  <div></div>
                </div>
                <div style={{ padding: "30px" }}>
                  <button
                    className="btn btn-info m-b-xs "
                    style={{ background: "#1FC157", color: "white" }}
                    onClick={createPaystructure}
                  >
                    Add Pay Scale
                  </button>{" "}
                </div>
              </div>

              {load && (
                <p className="text-center py-5">
                  <Spin />
                </p>
              )}
              {!load && (
                <div className="container">
                  {paymentStructure?.length > 0 ? (
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Pay Level</th>
                            <th scope="col">Base Salary</th>
                            <th scope="col">Date Created</th>
                            <th scope="col">Status</th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paymentStructure.map((data) => {
                            return (
                              <tr>
                                <td>
                                  {data.name
                                    ? data.name.replace(/_+/g, " ")
                                    : ""}
                                </td>
                                <td>{data?.payLevel?.description?.replace(/_+/g, " ")}</td>

                                <td>
                                  {data?.currency}{" "}
                                  {data.baseSalary
                                    ? formatNumber(data?.baseSalary)
                                    : 0}
                                </td>
                                <td>
                                  {data.createdAt
                                    ? data.createdAt.slice(0, 10)
                                    : ""}
                                </td>
                                <td>
                                  <span
                                    className={
                                      {
                                        Enabled: "badge bg-success",
                                        Disabled: "badge bg-danger",
                                        ACTIVE: "badge bg-success",
                                        COMPLETED: "badge bg-success",
                                        SUCCESS: "badge bg-success",
                                        FAILED: "badge bg-danger",
                                        IDLE: "badge bg-info",
                                        PENDING: "badge bg-warning",
                                        PROCESSING: "badge bg-warning",
                                        INACTIVE: "badge bg-danger",
                                      }[data.status ? "ACTIVE" : "INACTIVE"]
                                    }
                                  >
                                    {data.status ? "ACTIVE" : "INACTIVE"}
                                  </span>
                                </td>
                                <td>
                                  <button
                                    type="submit"
                                    onClick={showDrawer.bind(this, data)}
                                    class="btn btn-primary "
                                    style={{
                                      background: "#1FC157",
                                      color: "white",
                                    }}
                                  >
                                    Action
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div>
                      <p className="text-center pt-5 pb-5">
                        No Pay Scale Record.
                      </p>
                    </div>
                  )}

                  {paymentStructure?.length > 0 && (
                    <div className="text-center mt-5 mb-5">
                      <Pagination
                        current={page}
                        total={totalItems}
                        defaultPageSize={10}
                        onChange={pagination}
                        itemRender={itemRender}
                      />{" "}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={updateSwitch ? "Update Pay Scale " : "Add Pay Scale "}
        visible={openModal}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeModal}
      >
        <form>
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}

          {fieldError && (
            <div className="pb-3">
              <Alert message="All fields are required!" type="error" showIcon />
            </div>
          )}
          {serverError && (
            <p className="text-center text-danger font-weight-bold">
              Failed to connect to SalarioPay
            </p>
          )}

          {updateSwitch ? (
            <>
              <div>
                <div>
                  <div className="mb-3">
                    <label>Name</label>
                    <div className="">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label>Base Salary</label>
                    <Controller
                      name="baseSalary"
                      control={control}
                      {...register("baseSalary", {
                        required: false,
                        onChange: (e) => setBaseSalary(e.target.value),
                        value: baseSalary,
                      })}
                      render={({ field }) => (
                        <NumberFormatter
                          {...field}
                          label=""
                          value={baseSalary}
                          name="baseSalary"
                          error={errors.annualGrossery?.message}
                          prefix={"₦"}
                        />
                      )}
                    />
                  </div>

                  <div className="mb-3">
                    <label>Country</label>
                    <select
                      onChange={(e) => {
                        setCountry(e.target.value);
                        getPaylevel(e.target?.value);
                        getCurrenccies(e?.target?.value);
                      }}
                      class="form-select"
                      aria-label="Default select example"
                      value={country}
                    >
                      <option selected>Select </option>
                      {employerCountries.map((element) => {
                        return (
                          <option value={element.code}>{element.name}</option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label>Currency</label>
                    <select
                      onChange={(e) => setCurrency(e.target.value)}
                      class="form-select"
                      aria-label="Default select example"
                      value={currency}
                    >
                      <option selected>Select </option>
                      {currencyLists.map((element) => {
                        return (
                          <option value={element.currencyCode}>
                            {element.currencyName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label>Pay Level</label>
                    <select
                      onChange={(e) => setPayLevelId(e.target.value)}
                      class="form-select"
                      aria-label="Default select example"
                      value={payLevelId}
                    >
                      <option selected>Select </option>
                      {paylevelList.map((element) => {
                        return (
                          <option value={element.id}>
                            {element.description}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label>Position</label>
                    <select
                      onChange={(e) => setPositionid(e.target.value)}
                      class="form-select"
                      aria-label="Default select example"
                      value={positionId}
                    >
                      <option selected>Select </option>
                      {positionOptions.map((element) => {
                        return (
                          <option value={element.value}>{element.label}</option>
                        );
                      })}
                    </select>
                  </div>
                  <div>
                    <div>
                      <label>Bonus Eligibility</label>
                    </div>

                    <Switch
                      defaultChecked={false}
                      checked={bonusEligible}
                      onChange={switchChange}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div>
              <div>
                <div className="mb-3">
                  <label>Name</label>
                  <div className="">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label>Base Salary</label>
                  <Controller
                    name="baseSalary"
                    control={control}
                    {...register("baseSalary", {
                      required: false,
                      onChange: (e) => setBaseSalary(e.target.value),
                      value: baseSalary,
                    })}
                    render={({ field }) => (
                      <NumberFormatter
                        {...field}
                        label=""
                        value={baseSalary}
                        name="baseSalary"
                        error={errors.annualGrossery?.message}
                        prefix={"₦"}
                      />
                    )}
                  />
                </div>

                <div className="mb-3">
                  <label>Country</label>
                  <select
                    onChange={(e) => {
                      setCountry(e.target.value);
                      getPaylevel(e.target?.value);
                      getCurrenccies(e?.target?.value);
                    }}
                    class="form-select"
                    aria-label="Default select example"
                    value={country}
                  >
                    <option selected>Select </option>
                    {employerCountries.map((element) => {
                      return (
                        <option value={element.code}>{element.name}</option>
                      );
                    })}
                  </select>
                </div>
                <div className="mb-3">
                  <label>Currency</label>
                  <select
                    onChange={(e) => setCurrency(e.target.value)}
                    class="form-select"
                    aria-label="Default select example"
                    value={currency}
                  >
                    <option selected>Select </option>
                    {currencyLists.map((element) => {
                      return (
                        <option value={element.currencyCode}>
                          {element.currencyName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="mb-3">
                  <label>Pay Level</label>
                  <select
                    onChange={(e) => setPayLevelId(e.target.value)}
                    class="form-select"
                    aria-label="Default select example"
                    value={payLevelId}
                  >
                    <option selected>Select </option>
                    {paylevelList.map((element) => {
                      return (
                        <option value={element.id}>
                          {element.description}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="mb-3">
                  <label>Position</label>
                  <select
                    onChange={(e) => setPositionid(e.target.value)}
                    class="form-select"
                    aria-label="Default select example"
                    value={positionId}
                  >
                    <option selected>Select </option>
                    {positionOptions.map((element) => {
                      return (
                        <option value={element.value}>{element.label}</option>
                      );
                    })}
                  </select>
                </div>
                <div>
                  <div>
                    <label>Bonus Eligibility</label>
                  </div>

                  <Switch
                    defaultChecked={false}
                    checked={bonusEligible}
                    onChange={switchChange}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={updateSwitch ? updatePayscale : addPayScale}
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        title={"View Details"}
        visible={openViewDetails}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={() => {
          setOpenViewDetails(false);
        }}
      >
        <div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>Name</div>
            <div>{name?.replace(/_+/g, " ")}</div>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>Base Salary</div>
            <div>
              {currency} {baseSalary ? formatNumber(baseSalary) : ""}
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>Pay Level</div>
            <div>{payLevelInfo?.description}</div>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>Position</div>
            <div>{positionInfo?.name}</div>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>Date Created</div>
            <div>{payLevelInfo?.createdAt?.slice(0, 10)}</div>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>Status</div>
            <div>
              <span
                className={
                  {
                    Enabled: "badge bg-success",
                    Disabled: "badge bg-danger",
                    ACTIVE: "badge bg-success",
                    COMPLETED: "badge bg-success",
                    SUCCESS: "badge bg-success",
                    FAILED: "badge bg-danger",
                    IDLE: "badge bg-info",
                    PENDING: "badge bg-warning",
                    PROCESSING: "badge bg-warning",
                    INACTIVE: "badge bg-danger",
                  }[payScaleStatus ? "ACTIVE" : "INACTIVE"]
                }
              >
                {payScaleStatus ? "ACTIVE" : "INACTIVE"}
              </span>
            </div>
          </div>
        </div>
      </Modal>

      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}
      >
        <div
          style={{ color: "#000000", cursor: "pointer" }}
          onClick={() => {
            setOpenViewDetails(true);
          }}
        >
          View Details
        </div>
        <hr />
        <div
          style={{ color: "#000000", cursor: "pointer" }}
          onClick={openUpdateModal}
        >
          Update
        </div>

        <hr />
        <div
          style={{ color: "#000000", cursor: "pointer" }}
          onClick={deleteConfirm}
        >
          Delete
        </div>

        <hr />

        <div
          style={{ color: "#000000", cursor: "pointer" }}
          onClick={statusConfirm}
        >
          {payScaleStatus ? "Disable" : "Enable"}
        </div>

        <hr />
      </Drawer>
    </Layout>
  );
}
