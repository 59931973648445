import React, { useState } from "react";
import { useLocation } from "react-router-dom";
// import Tabs from "../containers/Tabs";
import "./tab.css";
import { Link } from "react-router-dom";
import CompanyInfo from "../views/Profile/company_information";
import Security from "../views/Profile/security";
import Subscription from "../views/Profile/subscription/subscription";
import Billing from "./Profile/accounts";
import { Nav, NavItem, NavLink } from "reactstrap";

function App(props) {
  const location = useLocation();
  const [active, setActive] = useState("");
  const locationA = window.location.pathname.replace("/", "");
  const locationB = location.pathname.replace("/", "");




  return (
    <div className="card">
      <div className="card-body pt-3 pb-3">
        <div className="link-text">
          <Nav className="d-flex justify-content-between align-items-center">
            <NavItem>
              <Link
                to="/view_employee"
                className={
                  locationB && locationA === "view_employee"
                    ? "tab-list-active"
                    : "text-dark"
                }
              >
                Employee Detail
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to="/employee_salary_detail"
                className={
                  locationB && locationA === "employee_salary_detail"
                    ? "tab-list-active "
                    : "text-dark"
                }
              >
                Salary Detail
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to="/employee_allowance"
                className={
                  locationB && locationA === "employee_allowance"
                    ? "tab-list-active"
                    : "text-dark"
                }
              >
                Allowances
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to="/employee_deduction"
                className={
                  locationB && locationA === "employee_deduction"
                    ? "tab-list-active"
                    : "text-dark"
                }
              >
                Deductions
              </Link>
            </NavItem>

            <NavItem>
              <Link
                to="/employee_payment-method"
                className={
                  locationB && locationA === "employee_payment-method"
                    ? "tab-list-active"
                    : "text-dark"
                }
              >
                Payment Methods
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to="/employee_payroll-detail"
                className={
                  locationB && locationA === "employee_payroll-detail"
                    ? "tab-list-active"
                    : "text-dark"
                }
              >
                Payroll Details
              </Link>
            </NavItem>
            {/* <NavItem>
              <Link
                to="/leave_request"
                className={
                  locationB && locationA === "leave_request"
                    ? "tab-list-active"
                    : "text-dark"
                }
              >
                Leave Request
              </Link>
            </NavItem> */}
            <NavItem>
              <Link
                to="/employee-offboarding"
                className={
                  locationB && locationA === "employee-offboarding"
                    ? "tab-list-active"
                    : "text-dark"
                }
              >
                Employee OffBoarding
              </Link>
            </NavItem>
            
          </Nav>
        </div>
      </div>
    </div>
  );
}

export default App;
