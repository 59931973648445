import React, { useState, useEffect } from "react";
import Tabs from "../../../employeeTabs";
import Layout from "../../../../containers/Layout";
import { useHttp } from "../../../../hooks/useHttps";
import axios from "axios";
import config from "../../../../config";
import { notification, Spin } from "antd";
import Tab from "../../workhub/tabs";
export default function EmployeeInfo(props) {
  const [employeeInfo, setInfo] = useState({});
  const [loadEmployee, setLoadEmployee] = useState(false);
  const getEmployeeInfo = () => {
    setLoadEmployee(true);
    axios
      .get(
        `${config.baseUrl}/v1/employees/${sessionStorage.getItem(
          "employeeID"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadEmployee(false);
        if (res.data.status) {
          const Info = res.data.result;
          setInfo(Info);
        } else {
        }
      })
      .catch((err) => {
        setLoadEmployee(false);
        if (err) {
        }
      });
  };

  useEffect(() => {
    getEmployeeInfo();
  }, []);

  return (
    <Layout>
      <Tab />
      {loadEmployee && (
        <div className="col-sm-12 col-md-12">
          <div class="card">
            <div className="text-center py-5">
              <Spin />
            </div>
          </div>
        </div>
      )}
      {!loadEmployee && (
        <div>
          <div className="col-sm-12 col-md-12">
            <div class="card">
              <div class="profile-img text-center pt-3">
                <img alt="" width="150" style={{ borderRadius: "100%" }} />
              </div>
              <div class="card-header font-weight-bold">
                <b>Personal Informations</b>
              </div>
              <div class="card-body">
                <form>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          First Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={
                            employeeInfo.firstName ? employeeInfo.firstName : ""
                          }
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Last Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={
                            employeeInfo.lastName ? employeeInfo.lastName : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Other Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          disabled
                          aria-describedby="emailHelp"
                          value={employeeInfo ? employeeInfo.otherName : ""}
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Marital Status
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employeeInfo ? employeeInfo.maritalStatus : ""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Gender
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employeeInfo ? employeeInfo.gender : ""}
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Contact Address
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={
                            employeeInfo ? employeeInfo.contactAddress : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Date of Birth
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={
                            employeeInfo
                              ? employeeInfo?.dateOfBirth?.slice(0, 10)
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Mobile
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employeeInfo ? employeeInfo.mobile : ""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Country
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employeeInfo ? employeeInfo.country : ""}
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Next of Kin
                        </label>

                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employeeInfo ? employeeInfo.nextOfKin : ""}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Next of Kin Phone Number
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={
                            employeeInfo ? employeeInfo.nextOfKinPhone : ""
                          }
                        />
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Next of Kin Address
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={
                            employeeInfo ? employeeInfo.nextOfKinAddress : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header pt-5 mt-3 font-weight-bold">
                <b>Work Informations</b>
              </div>
              <div class="card-body">
                <form>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Employee Code
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employeeInfo ? employeeInfo.employeeCode : ""}
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Work Email
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employeeInfo ? employeeInfo.workEmail : ""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Department
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employeeInfo ? employeeInfo.department : ""}
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Department
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employeeInfo ? employeeInfo.position : ""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Employee Level
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employeeInfo ? employeeInfo.level : ""}
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Employee Type
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employeeInfo ? employeeInfo.employeeType : ""}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          PFA
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employeeInfo ? employeeInfo.pfaName : ""}
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          RSA PIN
                        </label>
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employeeInfo ? employeeInfo.pfaRsaPin : ""}
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Tax State
                        </label>

                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employeeInfo ? employeeInfo.taxState : ""}
                        />
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          TAX IDENTIFICATION NUMBER
                        </label>
                        <input
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={employeeInfo ? employeeInfo.tin : ""}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        Hire Date
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        disabled
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={
                          employeeInfo
                            ? employeeInfo?.hireDate?.slice(0, 10)
                            : ""
                        }
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
