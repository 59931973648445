import React, { useState, useEffect } from "react";
import Layout from "../../../containers/Layout";
import config from "../../../config";
import {
  Modal,
  notification,
  Alert,
  Spin,
  Pagination,
  Button,
  DatePicker,
} from "antd";
import axios from "axios";
import { useHttp } from "../../../hooks/useHttps";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import Tabs from "./tabs";
const dateFormat = "YYYY/MM/DD";

export default function EmployeeOffOnboarding(props) {
  const [noPayrollDetailsData, setNoPayrollDetailsDatas] = useState(false);
  const [loadPayrollDetailsData, setLoadPayrollDetailsDatas] = useState(true);
  const [payrollDatas, setPayrollDetailsDatas] = useState([]);
  const [noticeDuration, setNoticeDuration] = useState("");
  const [loadOffboarding, setLoadOffBoarding] = useState(false);
  const [salaryBeforeNotice, setSalaryBeforeNotice] = useState("");
  const [salaryWithinNotice, setSalaryWithinNotice] = useState("");
  const [monthlyNetSalary, setMonthlyNetSalary] = useState("");

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [offBoardingSummary, setOffboardingSummary] = useState(false);
  const [summaryData, setSummaryData] = useState([]);
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [prorateType, setProrateType] = useState("");
  const [load, setLoad] = useState(false);
  const [offBoardingDetails, setOffBoardingDetails] = useState({});
  const [status, setStatus] = useState(false);
  const [currency, setCurrency] = useState("");
  const [type, setType] = useState("");
  const [debt, setDebt] = useState(0);

  const [loadingType, offBoardingType] = useHttp(
    `${config.baseUrl}/v1/options/OFFBOARDING_TYPE`,
    []
  );

  const [loadingProrate, prorateDataType] = useHttp(
    `${config.baseUrl}/v1/options/PRORATE_TYPES`,
    []
  );

  const [intiateOnboarding, setInitiateOnboarding] = useState(false);
  const [startPeriod, setStartPeriod] = useState("");
  const [payableAmount, setPayableAmount] = useState("");
  const [loanAmount, setLoanAmt] = useState("");
  const [endPeriod, setEndPeriod] = useState("");
  const [fill, setFill] = useState(false);
  const days = 86400000; //number of milliseconds in a day
  const currDay = new Date();

  const convertISO = (originalDateString) => {
    // Parse the original date string into a Date object
    const originalDate = new Date(originalDateString);
    // Set the time components to the desired values
    originalDate.setHours(16);
    originalDate.setMinutes(33);
    originalDate.setSeconds(49);
    originalDate.setMilliseconds(752);
    // Convert the Date object to ISO 8601 format with timezone offset
    const isoDateString = originalDate.toISOString();
    return isoDateString;
  };

  function onChangeStartDate(date, dateString) {
    setStartPeriod(date);
    var specifiedDate = new Date(date);
    const noticeDaysCount = new Date(date + noticeDuration * days);
    var cDate = moment(noticeDaysCount).format("YYYY-MM-DD");
    setEndPeriod(convertISO(cDate));
  }
  function onChangeEndDate(date, dateString) {
    // const parsedDate = moment(date, "DD-MM-YYYY");
    // const outputDate = dateString.format("YYYY-MM-DDTHH:mm:ss[Z]");
    setEndPeriod(date);
  }

  const confirmCancelOnboarding = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure you want to cancel this employee offboarding`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        cancelOffboarding();
      },
      onCancel() {},
    });
  };

  const closeSummary = () => {
    setOffboardingSummary(false);
  };

  const getDetails = () => {
    setLoadOffBoarding(true);
    axios
      .get(
        `${config.baseUrl}/v1/employees/offboarding/${sessionStorage.getItem(
          "employeeID"
        )}
      `,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        setLoadOffBoarding(false);
        if (res.data) {
          setPayableAmount(
            res.data.result?.payableSalary
              ? Intl.NumberFormat("en-US").format(
                  res.data.result?.payableSalary
                )
              : ""
          );
          setLoanAmt(
            res.data.result?.loanAmount
              ? Intl.NumberFormat("en-US").format(res.data.result?.loanAmount)
              : 0
          );
          setCurrency(res.data.result?.currency);
          const contactDetails = res.data.result;
          const startPeriod = contactDetails.noticeStartDate
            ? contactDetails.noticeStartDate.slice(0, 10)
            : "";
          const endPeriod = contactDetails.noticeEndDate
            ? contactDetails.noticeEndDate.slice(0, 10)
            : "";
          setDebt(
            contactDetails.prorateValue
              ? Intl.NumberFormat("en-US").format(contactDetails.prorateValue)
              : 0
          );
          setStatus(contactDetails.status);
          setProrateType(contactDetails.prorateType);
          setType(contactDetails.type);
          setSalaryBeforeNotice(contactDetails?.salaryBeforeNotice);
          setMonthlyNetSalary(contactDetails?.monthlyNetSalary);
          setSalaryWithinNotice(contactDetails?.salaryWithinNotice);
          setNoticeDuration(contactDetails?.noticeDuration);
          setEndPeriod(endPeriod);
          setStartPeriod(startPeriod);
        } else {
        }
      })
      .catch((err) => {
        setLoadOffBoarding(false);
        setLoad(false);
        if (err) {
        }
      });
  };

  const cancelOffboarding = () => {
    setLoad(true);
    axios
      .put(
        `${
          config.baseUrl
        }/v1/employees/cancel-offboarding/${sessionStorage.getItem(
          "employeeID"
        )}
      `,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getContactDetails();
          setType("");
          setDebt("");
          setEndPeriod("");

          setStartPeriod("");
          getDetails();
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
        }
      });
  };

  const getOffboardingSummary = (e) => {
    e.preventDefault();
    const data = {
      employeeId: sessionStorage.getItem("employeeID"),
      noticeEndDate: endPeriod,
      prorateValue: debt,
      noticeStartDate: startPeriod,
      prorateType,
      noticeDuration,
      type,
    };
    if (startPeriod && endPeriod && type) {
      setLoad(true);
      axios
        .post(`${config.baseUrl}/v1/employees/offboarding-summary`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setOffboardingSummary(true);
            setSummaryData(res.data.result);
          } else {
            Notification("error", "Error", res.data.message);
          }
        })
        .catch((err) => {
          setLoad(false);
          setNoPayrollDetailsDatas(true);
          if (err) {
          }
        });
    } else {
      Notification(
        "error",
        "Error",
        " OffBoarding Type, Start Date, End Date are required!"
      );
    }
  };

  const onInitiateOnboarding = (e) => {
    e.preventDefault();
    const data = {
      employeeId: sessionStorage.getItem("employeeID"),
      noticeEndDate: endPeriod,
      prorateValue: debt,
      noticeDuration,
      noticeStartDate: startPeriod,
      prorateType,
      type,
    };
    if (startPeriod && endPeriod && type) {
      setLoad(true);
      axios
        .post(`${config.baseUrl}/v1/employees/initiate-offboarding`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            getDetails();
            setOffBoardingDetails(false);
            closeSummary();
            Notification("success", "Success", res.data.message);
          } else {
            Notification("error", "Error", res.data.message);
          }
        })
        .catch((err) => {
          setLoad(false);
          setNoPayrollDetailsDatas(true);
          if (err) {
          }
        });
    } else {
      Notification(
        "error",
        "Error",
        " OffBoarding Type, Start Date, End Date are required!"
      );
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  function addDaysToDate(date, daysToAdd) {
    var result = new Date(date); // Make a copy of the original date
    result.setDate(result.getDate() + daysToAdd); // Add the specified number of days
    return result;
  }

  const getContactDetails = () => {
    axios
      .get(
        `${config.baseUrl}/v1/employees/${sessionStorage.getItem(
          "employeeID"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const contactDetails = res.data.result.offBoarding;
          const startPeriod = contactDetails.startDate
            ? contactDetails.startDate.slice(0, 10)
            : "";
          const endPeriod = contactDetails.endDate
            ? contactDetails.endDate.slice(0, 10)
            : "";

          setDebt(contactDetails.outstandingDebt);
          setStatus(contactDetails.status);
          setType(contactDetails.type);
          setEndPeriod(endPeriod);
          setStartPeriod(startPeriod);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  return (
    <Layout>
      <Tabs />
      <div>
        <div className="col-sm-12 col-md-12">
          <div class="card">
            <div class="card-header font-weight-bold">Offboarding</div>
            <div class="card-body">
              {load && (
                <p className="text-center">
                  <Spin />
                </p>
              )}

              {loadOffboarding && (
                <p className="text-center">
                  <Spin />
                </p>
              )}

              {serverError && (
                <p className="text-center text-danger font-weight-bold">
                  Failed to connect to SalarioPay
                </p>
              )}
              <br />
              {!loadOffboarding && (
                <form>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div className="mb-3">
                        <div className="form-floating">
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setType(e.target.value)}
                            value={type}
                            disabled={status && type ? true : false}
                          >
                            <option selected value="0">
                              Select Type
                            </option>
                            {offBoardingType.map((type) => {
                              return (
                                <option value={type.code}>{type.value}</option>
                              );
                            })}
                          </select>
                          <label for="floatingInput">
                            Type <span className="text-danger">*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div className="mb-3">
                        <div className="form-floating">
                          <input
                            type="number"
                            className="form-control"
                            id="floatingInput"
                            placeholder="Notice Duration (Days)"
                            onChange={(e) => setNoticeDuration(e.target.value)}
                            value={noticeDuration}
                            disabled={
                              status && noticeDuration !== null ? true : false
                            }
                          />
                          <label for="floatingInput">
                            Notice Duration (Days){" "}
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div className="mb-3">
                        {status ? (
                          <div className="mb-3">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control"
                                id="floantingInput"
                                value={startPeriod}
                                disabled={true}
                              />
                              <label for="floatingInput">
                                Notice Start Date{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </div>
                          </div>
                        ) : (
                          <DatePicker
                            onChange={onChangeStartDate}
                            style={{
                              width: "100%",
                              height: "3.3rem",
                              borderRadius: "10px",
                            }}
                            placeholder="Notice Start Date"
                          />
                        )}
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6">
                      <div className="mb-3">
                        <div className="mb-3">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="floantingInput"
                              value={endPeriod ? endPeriod?.slice(0, 10) : ""}
                              disabled={endPeriod ? true : false}
                            />
                            <label for="floatingInput">
                              Notice End Date{" "}
                              <span className="text-danger">*</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {status && (
                    <div className="row">
                      {status && (
                        <div class="col-sm-12 col-md-6">
                          <div className="mb-3">
                            <div className="form-floating">
                              <input
                                type="number"
                                className="form-control"
                                id="floatingInput"
                                value={
                                  monthlyNetSalary
                                    ? monthlyNetSalary
                                        ?.toFixed(2)
                                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                    : 0
                                }
                                disabled={true}
                              />
                              <label for="floatingInput">
                                Monthly Net Salary
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      <div class="col-sm-12 col-md-6">
                        <div className="mb-3">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput"
                              placeholder="country name"
                              value={currency + " " + payableAmount}
                              disabled={true}
                            />
                            <label for="floatingInput">
                              Payable Salary {currency}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <div className="mb-3">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="floatingInput"
                              placeholder="country name"
                              value={currency + " " + loanAmount}
                              disabled={true}
                            />
                            <label for="floatingInput">Loan Amount</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div class="d-flex justify-content-between align-items-center">
                    {!status && (
                      <button
                        type="submit"
                        class="btn btn-primary"
                        style={{
                          background: "#1FC157",
                          marginTop: "1rem",
                          color: "white",
                        }}
                        onClick={getOffboardingSummary}
                      >
                        Initiate OffBoarding
                      </button>
                    )}
                    {status && (
                      <button
                        type="submit"
                        class="btn btn-primary"
                        style={{
                          background: "red",
                          marginTop: "1rem",
                          color: "white",
                        }}
                        onClick={confirmCancelOnboarding}
                      >
                        Cancel OffBoarding
                      </button>
                    )}
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={"Offboarding Details"}
        visible={offBoardingSummary}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeSummary}
        width={500}
        centered={true}
      >
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="card">
              <div class="">
                {load && (
                  <p className="text-center">
                    <Spin />
                  </p>
                )}

                <div className="d-flex justify-content-between align-items-center">
                  <div className="font-weight-bold">
                    <b>Type:</b>
                  </div>
                  <div className="">{summaryData?.type}</div>
                </div>
                <br />
                <div className="d-flex justify-content-between align-items-center">
                  <div className="font-weight-bold">
                    <b>Notice Duration:</b>
                  </div>
                  <div className="">{summaryData?.noticeDuration}</div>
                </div>
                <br />
                <div className="d-flex justify-content-between align-items-center">
                  <div className="font-weight-bold">
                    <b>Notice Start Date:</b>
                  </div>
                  <div className="">{summaryData?.noticeStartDate}</div>
                </div>
                <br />
                <div className="d-flex justify-content-between align-items-center">
                  <div className="font-weight-bold">
                    <b>Notice End Date :</b>{" "}
                  </div>
                  <div className="">{summaryData?.noticeEndDate}</div>
                </div>
                <br />

                <div className="d-flex justify-content-between align-items-center">
                  <div className="font-weight-bold">
                    <b>Prorate Type:</b>
                  </div>
                  <div className="">
                    {summaryData?.prorateType?.replace(/_+/g, " ")}
                  </div>
                </div>
                <br />
                <div className="d-flex justify-content-between align-items-center">
                  <div className="font-weight-bold">
                    <b>Prorate Value:</b>
                  </div>
                  <div className="">
                    {summaryData?.currency} {summaryData?.prorateValue}
                  </div>
                </div>
                <br />
                <div className="d-flex justify-content-between align-items-center">
                  <div className="font-weight-bold">
                    <b>Monthly Net Salary </b>
                  </div>
                  <div className="">
                    {summaryData?.currency}{" "}
                    {summaryData?.monthlyNetSalary
                      ? (summaryData?.monthlyNetSalary)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                      : 0}
                  </div>
                </div>

                <br />
                <div className="d-flex justify-content-between align-items-center">
                  <div className="font-weight-bold">
                    <b>Payable Salary </b>
                  </div>
                  <div className="">
                    {summaryData?.currency}{" "}
                    {summaryData?.payableSalary
                      ? (summaryData?.payableSalary)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                      : 0}
                  </div>
                </div>
                <br />

                <div className="d-flex justify-content-between align-items-center">
                  <div className="font-weight-bold">
                    <b>Loan Amount</b>
                  </div>
                  <div className="">
                    {summaryData?.currency} {summaryData?.loanAmount}
                  </div>
                </div>
                <br />

                <br />
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <button
                  type="submit"
                  class="btn btn-primary"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={onInitiateOnboarding}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Layout>
  );
}
