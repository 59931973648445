import React from "react";
import Employee from "./employee";
import Employer from "./employer";
import Vendor from "./vendor";
import SuperAdmin from "./superAdmin";

const role = sessionStorage.getItem("activeRole");
export default function DropDown(props) {
  return (
    <div>
      {" "}
      {
        {
          EMPLOYER: <Employer logout={props.logout} />,
          EMPLOYEE: <Employee logout={props.logout} />,
          VENDOR: <Vendor logout={props.logout} />,
          SUPER_ADMIN: <SuperAdmin logout={props.logout} />,
          ACCOUNTANT: <Employee logout={props.logout} />,
          HUMAN_RESOURCE: <Employer logout={props.logout} />,

        }[role]
      }{" "}
    </div>
  );
}
