import React, { useState } from "react";
import Layout from "../../../containers/Layout";
import { DatePicker, Pagination } from "antd";
import axios from "axios";
import { useHttp } from "../../../hooks/useHttps";
import config from "../../../config";
import moment from "moment";
import OnboardingTabs from "./tab";

export default function Payslip() {
  const [startDate, setStartPeriod] = useState("");
  const [endDate, setEndPeriod] = useState("");
  const [load, setLoad] = useState(false);
  const [payslips, setPaylips] = useState([]);
  const [noPayslips, setNoPaySlips] = useState(false);
  const [employeeId, setEmployerId] = useState("");
  const [loadingEmployers, employers] = useHttp(
    `${config.baseUrl}/v1/employees/filtered/employer`,
    []
  );

  return (
    <Layout>
      <div className="row">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Onboarding Details </h5>
            <OnboardingTabs />
            <div className="row"></div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
