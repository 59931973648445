import React from "react";
import { useLocation } from "react-router-dom";
import "./tab.css";
import { Link } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
function App(props) {
  const location = useLocation();
  const locationA = window.location.pathname.replace("/", "");
  const locationB = location.pathname.replace("/", "");

  return (
    <div className="card">
      <div className="card-body pt-3 pb-3">
        <div className="link-text">
          <Nav className="d-flex justify-content- align-items-center">
            <NavItem>
              <Link
                to="/account-beneficiaries"
                className={
                  locationB && locationA === "account-beneficiaries"
                    ? "tab-list-active"
                    : "text-dark"
                }
              >
                Bank Transfer Beneficiaries
              </Link>
            </NavItem>
            <NavItem className="" style={{marginLeft:'2rem'}}>
              <Link
                to="/bills-payment-beneficiaries"
                className={
                  locationB && locationA === "bills-payment-beneficiaries"
                    ? "tab-list-active pl-5"
                    : "text-dark pl-5"
                }
              >
                Bill Payment Beneficiaries
              </Link>
            </NavItem>
          </Nav>
        </div>
      </div>
    </div>
  );
}

export default App;
