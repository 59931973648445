import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Layout from '../../../containers/Layout'
import ReactDatatable from '@ashvin27/react-datatable'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  Upload,
  DatePicker,
  Dropdown,
  Drawer,
  Button,
  Space,
  Spin,
  Menu,
  Alert,
  message,
  Modal,
  notification
} from 'antd'
import axios from 'axios'
import config from '../../../config'
export default function Employers () {
  const [subscriptionPlanData, setSubscriptionPlanData] = useState([])
  const [name, setName] = useState('')
  const [size, setSize] = useState('')
  const [monthlyPrice, setMonthlyPrice] = useState('')
  const [plansName, setPlansName] = useState([])
  const [cycle, setCycle] = useState('')
  const [type, setType] = useState('')
  const [monthlyDiscount, setMonthlyDiscount] = useState('')
  const [yearlyPrice, setYearlyPrice] = useState('')
  const [yearlyDiscount, setYearlyDiscount] = useState('')
  const [subscriptionModal, setsubscriptionModal] = useState(false)
  const [subscriptionStatus, setSubscriptionStatus] = useState('')
  const [subscriptionSwitch, setSubscriptionSwitch] = useState(false)

  const [rowId, setRowId] = useState('')

  const [visibleDraw, setVisibleDraw] = useState(false)
  const [deleteSuccess, setDeleteSuccess] = useState(false)

  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [success, setSuccess] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [serverError, setServerError] = useState(false)
  const [fieldError, setFieldError] = useState(false)

  const [load, setLoad] = useState(false)

  const showDrawer = (record, e) => {
    e.preventDefault()
    setRowId(record.id)
    setName(record.name)
    setSize(record.size)
    setCycle(record.cycle)
    setType(record.type)
    setMonthlyDiscount(record.monthlyPrice)
    setSubscriptionStatus(record.status)
    setYearlyDiscount(record.yearlyDiscount)
    setYearlyPrice(record.yearlyPrice)
    setVisibleDraw(true)
  }

  const closeDrawer = () => setVisibleDraw(false)

  const closeSubscriptionModal = () => {
    setsubscriptionModal(false)
    setName('')
    setVisibleDraw(false)
    setSize('')
    setMonthlyPrice('')
    setMonthlyDiscount('')
    setYearlyPrice('')
    setYearlyDiscount('')
  }
  const deletePlan = () => {
    axios
      .delete(`${config.baseUrl}/v1/subscriptions/${rowId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
      })
      .then(res => {
        if (res.data.status) {
          setDeleteSuccess(true)
          getPlans()
          setTimeout(() => {
            setDeleteSuccess(false)
          }, 2000)
        } else {
        }
      })
      .catch(err => {
        if (err) {
        }
      })
  }

  const createPosition = () => {
    setsubscriptionModal(true)
  }

  const changeStatusConfirm = e => {
    e.preventDefault()
    Modal.confirm({
      title: `Are you sure you want to change the status of this Subscription?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk () {
        changeStatus()
      },
      onCancel () {
        console.log('Cancel')
      }
    })
  }

  const deleteStatusConfirm = e => {
    e.preventDefault()
    Modal.confirm({
      title: `Are you sure you want to delete this Subscription?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk () {
        deletePlan()
      },
      onCancel () {
        console.log('Cancel')
      }
    })
  }

  const columns = [
    {
      key: 'name',
      text: 'Plan Name',
      tHeadClassName: 'tab-head',
      align: 'left',
      sortable: true
    },

    {
      key: 'amount',
      text: 'Amount',
      className: 'postcode',
      TrOnlyClassName: 'tab-head',
      sortable: true
    },

    {
      key: 'cycle',
      text: 'Cycle',
      className: 'postcode',
      TrOnlyClassName: 'tab-head',
      sortable: true
    },

    {
      key: 'trial',
      text: 'TRIAL',
      className: 'postcode',
      TrOnlyClassName: 'tab-head',
      sortable: true
    },

    {
      key: 'startDate',
      text: 'Start Date',
      className: 'postcode',
      TrOnlyClassName: 'tab-head',
      sortable: true
    },

    {
      key: 'endDate',
      text: 'End Date',
      className: 'postcode',
      TrOnlyClassName: 'tab-head',
      sortable: true
    },
    {
      key: 'status',
      text: 'Status',
      className: 'postcode',
      TrOnlyClassName: 'tab-head',
      sortable: true
    },

    {
      key: 'action',
      text: 'Action',
      className: 'action',
      width: 100,
      align: 'left',
      sortable: false,
      cell: record => {
        return (
          <Fragment>
            <Button
              type='primary'
              danger
              style={{
                background: '#1FC157',
                color: 'white',
                cursor: 'pointer'
              }}
              onClick={showDrawer.bind(this, record)}
            >
              Actions
            </Button>
          </Fragment>
        )
      }
    }
  ]

  const extraButtons = [
    {
      className: 'btn btn-primary buttons-pdf',
      title: 'Export TEst',
      children: [
        <span>
          <i
            className='glyphicon glyphicon-print fa fa-print'
            aria-hidden='true'
          ></i>
        </span>
      ],
      onClick: event => {
        console.log(event)
      }
    },
    {
      className: 'btn btn-primary buttons-pdf',
      title: 'Export TEst',
      children: [
        <span>
          <i
            className='glyphicon glyphicon-print fa fa-print'
            aria-hidden='true'
          ></i>
        </span>
      ],
      onClick: event => {
        console.log(event)
      },
      onDoubleClick: event => {
        console.log('doubleClick')
      }
    }
  ]
  const getPlans = () => {
    axios
      .get(`${config.baseUrl}/v1/subscriptions/paged`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
      })
      .then(res => {
        if (res.data.status) {
          const planDatas = res.data.result
          console.log()
          setSubscriptionPlanData(
            res.data.result.map(data => ({
              amount: data.amount,
              name: data.plan.name,
              cycle: data.cycle,
              trial: data.isTrial ? 'YES' : 'NO',
              startDate: data.startDate ? data.startDate.slice(0, 10) : 'NULL',
              status: data.status ? 'Enabled' : 'Disabled',
              id: data.id,
              endDate: data.endDate ? data.endDate.slice(0, 10) : 'NULL'
            }))
          )
        } else {
        }
      })
      .catch(err => {
        if (err) {
        }
      })
  }

  const refresh = () => {
    getPlans()
  }

  const addPlan = e => {
    e.preventDefault()
    const data = {
      cycle,
      employerId: sessionStorage.getItem('employer_id'),
      planId: name,
      type
    }
    if (data.cycle && data.planId && data.type) {
      setLoad(true)
      axios
        .post(`${config.baseUrl}/v1/subscriptions/new`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
        })
        .then(res => {
          setLoad(false)
          if (res.data.status) {
            setName('')
            setSize('')
            setMonthlyPrice('')
            setMonthlyDiscount('')
            setYearlyPrice('')
            setYearlyDiscount('')
            getPlans()
            setSuccess(true)
            setSuccessMsg(res.data.message)
            setTimeout(() => {
              setSuccess(false)
              getPlans()
              setsubscriptionModal(false)
            }, 2000)
          } else {
            setError(true)
            setErrorMsg(res.data.message)
            setTimeout(() => {
              setError(false)
            }, 2500)
          }
        })
        .catch(err => {
          if (err) {
            setLoad(false)
            setServerError(true)
            setTimeout(() => {
              setServerError(false)
            }, 1500)
          }
        })
    } else {
      setFieldError(true)
      setTimeout(() => {
        setFieldError(false)
      }, 1500)
    }
  }

  const setUpdateSwitch = () => {
    setSubscriptionSwitch(true)
    setsubscriptionModal(true)
    setVisibleDraw(false)
  }

  const changeStatus = () => {
    axios
      .put(
        `${config.baseUrl}/v1/subscriptions/status/${rowId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
        }
      )
      .then(res => {
        if (res.data.status) {
          getPlans()
        } else {
        }
      })
      .catch(err => {
        if (err) {
        }
      })
  }

  useEffect(() => {
    getPlans()
    getPlansName()
  }, [])

  const editPlan = e => {
    e.preventDefault()
    const data = {
      cycle,
      employerId: sessionStorage.getItem('employer_id'),
      planId: name,
      type,
      employerId: sessionStorage.getItem('employer_id')
    }

    setLoad(true)
    axios
      .put(`${config.baseUrl}/v1/subscriptions/upgrade`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
      })
      .then(res => {
        setLoad(false)
        if (res.data.status) {
          getPlans()
          setSuccess(true)
          setSuccessMsg(res.data.message)
          setTimeout(() => {
            setSuccess(false)
            getPlans()
            setsubscriptionModal(false)
          }, 2000)
        } else {
          setError(true)
          setErrorMsg(res.data.message)
          setTimeout(() => {
            setError(false)
          }, 2500)
        }
      })
      .catch(err => {
        if (err) {
          setLoad(false)
          setServerError(true)
          setTimeout(() => {
            setServerError(false)
          }, 1500)
        }
      })
  }

  const getPlansName = () => {
    axios
      .get(`${config.baseUrl}/v1/subscription-plans/paged`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
      })
      .then(res => {
        if (res.data.status) {
          setPlansName(res.data.result)
        } else {
        }
      })
      .catch(err => {
        if (err) {
        }
      })
  }

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    button: {
      excel: true,
      print: true,
      extra: true
    }
  }

  console.log(subscriptionPlanData)
  return (
    <Layout>
      <div class='row'>
        <div class='col'>
          <div class='card'>
            <div class='card-body'>
              <h5 class='card-title'>Subscription</h5>
              <div className='d-flex justify-content-end align-items-center'>
                <button
                  className='btn btn-info m-b-xs mr-2'
                  style={{ background: '#1FC157', color: 'white' }}
                  onClick={createPosition}
                >
                  Add Subscription
                </button>{' '}
              </div>
              <div className='text-center'>
                {deleteSuccess && (
                  <div className='pb-4'>
                    <Alert
                      message='Subscription Deleted'
                      type='error'
                      showIcon
                    />
                  </div>
                )}
              </div>

              <div className='pt-5'>
                <ReactDatatable
                  config={configTable}
                  records={subscriptionPlanData}
                  columns={columns}
                  extraButtons={extraButtons}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title='Actions'
        placement='right'
        visible={visibleDraw}
        onClose={closeDrawer}
      >
        <div
          style={{ color: '#1FC157', cursor: 'pointer' }}
          onClick={setUpdateSwitch}
        >
          Update Subscription
        </div>
        <hr />
        <div
          style={{ color: 'red', cursor: 'pointer' }}
          onClick={deleteStatusConfirm}
        >
          Delete Subscription
        </div>
        <hr />
        <div style={{ cursor: 'pointer' }} onClick={changeStatusConfirm}>
          {subscriptionStatus ? 'Disable' : 'Enable'}
        </div>

        <hr />
      </Drawer>
      <Modal
        title={
          !subscriptionSwitch ? 'Add Subscription ' : 'Update Subscription'
        }
        visible={subscriptionModal}
        okText='Submit'
        footer={false}
        maskClosable={false}
        onCancel={closeSubscriptionModal}
      >
        <form>
          {load && (
            <p className='text-center'>
              <Spin />
            </p>
          )}
          {success && (
            <div className='pb-3'>
              <Alert message={successMsg} type='success' showIcon />
            </div>
          )}
          {error && (
            <div className='pb-3'>
              <Alert message={errorMsg} type='error' showIcon />
            </div>
          )}
          {fieldError && (
            <div className='pb-3'>
              <Alert message='All fields are required!' type='error' showIcon />
            </div>
          )}
          {serverError && (
            <p className='text-center text-danger font-weight-bold'>
              Failed to connect to SalarioPay
            </p>
          )}
          {!subscriptionSwitch ? (
            <>
              <div class='mb-3'>
                <select
                  class='form-select'
                  aria-label='Default select example'
                  onChange={e => setName(e.target.value)}
                  value={name}
                >
                  <option selected>Select Plan Name</option>
                  {plansName.map(plans => {
                    return <option value={plans.id}>{plans.name}</option>
                  })}
                </select>
              </div>
              <div className='mb-3'>
                <div className='form-floating'>
                  <input
                    type='email'
                    className='form-control'
                    id='floatingInput'
                    placeholder='country name'
                    onChange={e => setCycle(e.target.value)}
                    value={cycle}
                  />
                  <label for='floatingInput'>Cycle</label>
                </div>
              </div>

              <div class='mb-3'>
                <select
                  class='form-select'
                  aria-label='Default select example'
                  onChange={e => setType(e.target.value)}
                  value={type}
                >
                  <option>Select Type</option>
                  <option>PAID</option>
                  <option>TRIAL</option>
                </select>
              </div>
            </>
          ) : (
            <div>
              <div class='mb-3'>
                <select
                  class='form-select'
                  aria-label='Default select example'
                  onChange={e => setName(e.target.value)}
                  value={name}
                >
                  <option selected>Select Plan Name</option>
                  {plansName.map(plans => {
                    return <option value={plans.id}>{plans.name}</option>
                  })}
                </select>
              </div>
              <div className='mb-3'>
                <div className='form-floating'>
                  <input
                    type='email'
                    className='form-control'
                    id='floatingInput'
                    placeholder='country name'
                    onChange={e => setCycle(e.target.value)}
                    value={cycle}
                  />
                  <label for='floatingInput'>Cycle</label>
                </div>
              </div>

              <div class='mb-3'>
                <select
                  class='form-select'
                  aria-label='Default select example'
                  onChange={e => setType(e.target.value)}
                  value={type}
                >
                  <option>Select Type</option>
                  <option>PAID</option>
                  <option>TRIAL</option>
                </select>
              </div>
            </div>
          )}
          <div className='d-flex justify-content-end align-items-center'>
            <button
              type='submit'
              class='btn btn-primary'
              style={{ background: '#1FC157', color: 'white' }}
              onClick={subscriptionSwitch ? editPlan : addPlan}
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>
    </Layout>
  )
}
