import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Layout from "../../../containers/Layout";
import "./index.css";
import master from "../../../img/fullcard.png";
import { Pagination } from "antd";
import ReactDatatable from "@ashvin27/react-datatable";
import { Spin, Alert, Button, Modal } from "antd";
import Cookie from "js-cookie";
import axios from "axios";
import { NumberFormatter } from "../../../containers/NumberFormat";
import Media from "react-media";
import config from "../../../config";
import { Controller, useForm } from "react-hook-form";
import PinInput from "react-pin-input";
import CryptoJS from "crypto-js";
import { useHttp } from "../../../hooks/useHttps";
var userInfo = Cookie.getJSON("salarioPayUserInfo");
export default function Employers() {
  const [createCard, setCreateCard] = useState(false);
  const [loadCard, setLoadCard] = useState(true);
  const [checkCardLoader, setCheckCardLoader] = useState(true);
  const [loadNoCard, setLoadNoCard] = useState(false);
  const [loadMyCard, setLoadMyCard] = useState(false);
  const [postalError, setPostalError] = useState(false);

  const [actionType, setActionType] = useState("");
  const [loanInterest, setLoanInterest] = useState("");

  const [sendMoneyModal, setSendMoneyModal] = useState(false);
  const [withdrawoneyModal, setWithdrawMoneyModal] = useState(false);

  const [cardDetail, setCardDetail] = useState([]);
  const [cardEnrollInfo, setCardEnrollInfo] = useState({});
  const [pin, setPin] = useState("");
  const [salary, setSalary] = useState("");
  const [pinModal, setPinModal] = useState(false);

  const [sendMoneyMethod, setSendMoneyMethod] = useState("");

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm({ mode: "onBlur" });

  const [amount, setAmount] = useState("");
  const [recipient, setReciepient] = useState("");
  const [currency, setCurrency] = useState("");
  const [bank, setBank] = useState("");
  const [branch, setBranch] = useState("");
  const [accNum, setAccNum] = useState("");
  const [accName, setAccName] = useState("");
  const [description, setDescription] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [bankData, setBankData] = useState([]);

  const [changeFreeIcon, setChangeFreezeIcon] = useState(false);

  const [bankApiSuccess, setBankApiSuccess] = useState(false);
  const [bankApiSuccessMsg, setBankApiSuccessMsg] = useState("");
  const [bankApiError, setBankApiError] = useState(false);
  const [bankApiErrorMsg, setBankApiErrorMsg] = useState("");
  const [bankError, setBankError] = useState(false);

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [load, setLoad] = useState(false);
  const [loadingCard, setLoadingCard] = useState(false);

  const [loadEmployer, employers] = useHttp(
    `${config.baseUrl}/v1/employees/filtered/employer`,
    []
  );
  const [loadPayment, payInfos] = useHttp(
    `${config.baseUrl}/v1/payment-methods/employee/paged`,
    []
  );
  const [loadCountries, countryDatas] = useHttp(
    `${config.baseUrl}/v1/countries/filtered`,
    []
  );
  const [loadCurrencies, currencyDatas] = useHttp(
    `${config.baseUrl}/v1/currencies/filtered`,
    []
  );
  const [loadTransactions, transactionDatas] = useHttp(
    `${config.baseUrl}/v1/credit-card/transactions`,
    []
  );

  const cancelPinSetup = () => setPinModal("");
  const [postalCode, setPostalCode] = useState("");
  const [stateUser, setStateUser] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [employer, setEmployer] = useState("");
  const [repayment, setRepaymentMode] = useState("");
  const [address, setAddress] = useState("");

  const getFullCard = () => {
    setLoadingCard(true);
    setLoad(true);
    axios
      .post(
        `${config.baseUrl}/v1/credit-card/card-detail`,
        {
          cardId: cardDetail.id,
          pin,
          platform: "WEB",
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadingCard(false);
        setLoad(false);
        if (res.data.status) {
          decryptCard(res.data.result);
          setSuccess(true);
          setSuccessMsg("Card Detail retrieved successfully");
          setTimeout(() => {
            setSuccess(false);
            setPinModal(false);
          }, 3000);
        } else {
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 2000);
        }
      });
  };

  const setReciepientChange = (e) => {
    setReciepient(e.target.value);
    if (e.target.value === "SELF") {
      payInfos.map((pay) => {
        if (pay.paymentOption === "BANK_ACCOUNT") {
          setCountry(pay.countryName);
          setAccNum(pay.accountNumber);
          setAccName(pay.accountName);
          setBranch(pay.branchCode);
          setCurrency(pay.currencyCode);
          setBank(pay.bankCode);
        }
      });
    } else {
      setCountry("");
      setAccNum("");
      setAccName("");
      setBranch("");
      setCurrency("");
      setBank("");
      setDescription("");
    }
  };

  const closeCreate = () => setCreateCard(false);
  const checkCard = () => {
    axios
      .get(`${config.baseUrl}/v1/credit-card/masked-card-detail`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          decryptCard(res.data.result);
          setCheckCardLoader(false);
          setLoadNoCard(false);
          setLoadMyCard(true);
        } else {
          setCheckCardLoader(false);
          setLoadNoCard(true);
          setLoadMyCard(false);
        }
      })
      .catch((err) => {
        if (err) {
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 2000);
        }
      });
  };

  const countryChange = (e) => {
    if (e.target.value) {
      setCountry(e.target.value);
      getBanksByCountry(e.target.value);
    }
  };

  const getBanksByCountry = (id) => {
    axios
      .get(`${config.baseUrl}/v1/financial-institution/banks/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setBankData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getLoanInterest = (e) => {
    axios
      .get(`${config.baseUrl}/v1/loans/interest/CREDIT_CARD/${amount}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setLoanInterest(res.data.result.interestRate);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 2000);
        }
      });
  };

  const setReciepientWithdrawChange = (e) => {
    if (e.target.value === "SELF") {
      setReciepient(e.target.value);
      setEmail(userInfo.email);
      setMobileNumber(userInfo.mobile);
    } else {
      setReciepient(e.target.value);
      setEmail("");
      setMobileNumber("");
    }
  };

  const fetchAccName = (e) => {
    e.preventDefault();
    setAccNum(e.target.value);
    const data = {
      accountNumber: accNum,
      bankCode: bank,
      accountName: accName,
      countryCode: country,
      cryptoCurrencyCode: null,
      cryptoWallet: null,
      cryptoWalletEmail: null,
      currencyCode: currency,
      level: null,
      paymentOption: null,
    };

    if (data.accountNumber && data.bankCode && data.countryCode) {
      setLoad(true);
      axios
        .post(
          `${config.baseUrl}/v1/financial-institution/account-query`,
          {
            accountBank: bank,
            accountNumber: accNum,
          },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setAccName(res.data.result.account_name);
            setBankApiSuccess(true);
            setBankApiSuccessMsg(res.data.message);
            setTimeout(() => {
              setBankApiSuccess(false);
            }, 1000);
          } else {
            setBankApiError(true);
            setBankApiErrorMsg(res.data.message);
            setTimeout(() => {
              setBankApiError(false);
            }, 1000);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setBankError(true);
      setTimeout(() => {
        setBankError(false);
      }, 2500);
    }
  };

  const postPin = (type) => {
    const dataSendMoney = {
      accountName: accName,
      accountNumber: accNum,
      amount,
      bankCode: bank,
      branchCode: branch,
      countryCode: country,
      currencyCode: currency,
      description: description,
      recipient: recipient,
    };

    const dataWithdrawMoney = {
      amount: amount,
      description: description,
      email: email,
      mobile: mobileNumber,
      recipient,
    };

    setActionType(type);
    switch (type) {
      case "withdraw Money":
        if (sendMoneyMethod === "BANK_TRANSFER") {
          if (
            dataSendMoney.accountName &&
            dataSendMoney.accountNumber &&
            dataSendMoney.amount &&
            dataSendMoney.bankCode &&
            dataSendMoney.countryCode &&
            dataSendMoney.currencyCode &&
            dataSendMoney.description &&
            dataSendMoney.recipient
          ) {
            setPinModal(true);
          } else {
            setFieldError(true);
            setTimeout(() => {
              setFieldError(false);
            }, 2500);
          }
        } else if (sendMoneyMethod === "PAYCODE") {
          if (
            dataWithdrawMoney.amount &&
            dataWithdrawMoney.description &&
            dataWithdrawMoney.email &&
            dataWithdrawMoney.mobile &&
            dataWithdrawMoney.recipient
          ) {
            setPinModal(true);
          } else {
            setFieldError(true);
            setTimeout(() => {
              setFieldError(false);
            }, 2500);
          }
        }
        break;

      case "Card Details":
        setPinModal(true);
        break;
      case "Request Credit":
        if (amount) {
          setPinModal(true);
          setWithdrawMoneyModal(false);
        }
        setPinModal(true);
        break;
      case "Card Status":
        setPinModal(true);
        break;
      case "Create New Card":
        const dataNewCard = {
          address: address,
          city,
          country,
          employerId: employer,
          postalCode,
          state: stateUser,
        };
        if (dataNewCard.city && dataNewCard.state && dataNewCard.postalCode) {
          if (dataNewCard.postalCode.length === 5) {
            setPinModal(true);
          } else {
            setPostalError(true);
            setTimeout(() => {
              setPostalError(false);
            }, 2500);
          }
        } else {
          setFieldError(true);
          setTimeout(() => {
            setFieldError(false);
          }, 2500);
        }
        break;
      default:
      // code block
    }
  };

  const getCardInfo = (e) => {
    // infoCard(e.target.value)
    const id = employers.find((v) => v.employerId === e.target.value);
    if (id) {
      setEmployer(id.id);
      setCountry(id.country);
      setAddress(id.contactAddress);
    }
    // setSalary(id.monthlyNetSalary)
    // // setCardEnrollInfo(id);
  };

  const infoCard = (id) => {
    axios
      .get(`${config.baseUrl}/v1/credit-card/enrollment-info/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setCardEnrollInfo(res.data.result);
        } else {
          setError(true);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 3000);
        }
      })
      .catch((err) => {
        if (err) {
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 2000);
        }
      });
  };

  const submitFunc = (e) => {
    e.preventDefault();
    switch (actionType) {
      case "withdraw Money":
        if (sendMoneyMethod === "BANK_TRANSFER") {
          sendMoneyFunc();
        } else {
          withdrawMoneyFunc();
        }
        break;
      case "Request Credit":
        requestCreditFunc();
        break;
      case "Card Details":
        getFullCard();
        break;
      case "Card Status":
        cardStatusFunc();
        break;
      case "Create New Card":
        CardCreateFunc();
        break;
      default:
      // code block
    }
  };

  const sendMoneyFunc = () => {
    const data = {
      accountName: accName,
      accountNumber: accNum,
      amount: amount,
      bankCode: bank,
      branchCode: branch,
      countryCode: country,
      currencyCode: currency,
      description: description,
      pin,
      platform: "WEB",
      recipient,
    };
    setLoad(true);
    axios
      .post(`${config.baseUrl}/v1/credit-card/transfer`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setBank("");
          setCountry("");
          setDescription("");
          setAmount("");
          setSendMoneyModal(false);
          setAccName("");
          setPin("");

          setAccNum("");
          setReciepient("");
          setBranch("");
          setCurrency("");
          setSuccess(true);
          setSuccessMsg(res.data.message);
          setTimeout(() => {
            setSuccess(false);
            setPinModal(false);
            window.location.reload(false);
          }, 3000);
        } else {
          setError(true);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 3000);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 2000);
        }
      });
  };

  const CardCreateFunc = () => {
    const data = {
      address: address,
      city: city,
      country: country,
      employeeId: employer,
      pin,
      platform: "WEB",
      postalCode,
      state: stateUser,
      currency,
      amount: amount,
    };
    setLoad(true);
    axios
      .post(`${config.baseUrl}/v1/credit-card/request-card`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setBank("");
          setStateUser("");
          setCity("");
          setPostalCode("");
          setCountry("");
          setDescription("");
          setAmount("");
          setSendMoneyModal(false);
          setAccName("");
          setPin("");
          setAccNum("");
          setReciepient("");
          setBranch("");
          setCurrency("");
          setSuccess(true);
          setSuccessMsg(res.data.message);
          setTimeout(() => {
            setSuccess(false);
            setPinModal(false);
            window.location.reload(false);
          }, 3000);
        } else {
          setError(true);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 3000);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 2000);
        }
      });
  };

  const withdrawMoneyFunc = () => {
    const data = {
      amount,
      description,
      email,
      mobile: mobileNumber,
      pin,
      platform: "WEB",
      recipient,
    };
    setLoad(true);
    axios
      .post(`${config.baseUrl}/v1/credit-card/paycode-withdrawal`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setAmount("");
          setDescription("");
          setEmail("");
          setMobileNumber("");
          setPin("");
          setWithdrawMoneyModal(false);
          setReciepient(false);
          setSuccess(true);
          setSuccessMsg(res.data.message);
          setTimeout(() => {
            setSuccess(false);
            window.location.reload(false);
            setPinModal(false);
          }, 3000);
        } else {
          setError(true);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 3000);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 2000);
        }
      });
  };

  const requestCreditFunc = () => {
    const data = {
      amount,
      cardId: cardDetail.id,
      employeeId: employer,
      pin,
      platform: "WEB",
    };
    setLoad(true);
    axios
      .post(`${config.baseUrl}/v1/credit-card/request-credit`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setAmount("");
          setDescription("");
          setEmail("");
          setMobileNumber("");
          setPin("");
          setWithdrawMoneyModal(false);
          setReciepient(false);
          setSuccess(true);
          setSuccessMsg(res.data.message);
          setTimeout(() => {
            setSuccess(false);
            window.location.reload(false);
            setPinModal(false);
          }, 3000);
        } else {
          setError(true);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 3000);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 2000);
        }
      });
  };

  const cardStatusFunc = () => {
    const data = {
      cardId: cardDetail.id,
      pin,
      platform: "WEB",
    };

    setLoad(true);
    axios
      .put(`${config.baseUrl}/v1/credit-card/status`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setAmount("");
          setChangeFreezeIcon(true);
          checkCard();
          setDescription("");

          setEmail("");
          setMobileNumber("");
          setPin("");
          setReciepient(false);
          setSuccess(true);
          setSuccessMsg(res.data.message);
          setTimeout(() => {
            setSuccess(false);
            window.location.reload(false);
            setPinModal(false);
          }, 3000);
        } else {
          setError(true);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 3000);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 2000);
        }
      });
  };

  const sendMoney = () => setSendMoneyModal(true);
  const withdrawMoney = () => setWithdrawMoneyModal(true);
  const cancelSendMoney = () => {
    setSendMoneyModal(false);
    setReciepient(false);
    setAmount("");
    setDescription("");
    setCountry(false);
    setCurrency(false);
    setBank(false);
    setBranch(false);
    setPaymentMethod(false);
    setAccName("");
    setAccNum("");
  };
  const cancelWithdrawMoney = () => {
    setWithdrawMoneyModal(false);
    setAmount("");
    setEmail("");
    setDescription("");
    setReciepient(false);
  };

  const decryptCard = (encryptedData) => {
    var parsedBase64Key = CryptoJS.enc.Base64.parse("QmswNDZCRWFSQ2lKNTdzVw==");
    var decryptedData = CryptoJS.AES.decrypt(encryptedData, parsedBase64Key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });

    var decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);
    setCardDetail(JSON.parse(decryptedText));
  };

  const createCardModal = () => setCreateCard(true);

  useEffect(() => {
    checkCard();
  }, []);

  return (
    <Layout>
      {checkCardLoader && (
        <div className="row">
          <div classNam="col">
            <div className="card">
              <div className="card-header">Credit Card</div>
              <div className="card-body">
                <div class="text-center">
                  <Spin />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {loadNoCard && (
        <div className="row">
          <div classNam="col">
            <div className="card">
              <div className="card-header">Credit Card</div>
              <div className="card-body">
                <div class="text-center">
                  <div>
                    <img src={master} width="30%" />
                    <div className="d-flex justify-content-center pt-4">
                      <p style={{ width: "30rem", textAlign: "center" }}>
                        Instantly request a virtual credit card like the one
                        displayed above. Use the card to pay at online stores
                        like Jumia, Konga, Apple Music, Netflix, Amazon and
                        Facebook etc before salary payday.
                      </p>
                    </div>
                    <div className="m-auto">
                      <button
                        type="submit"
                        class="btn btn-primary"
                        onClick={createCardModal}
                        style={{
                          background: "#1FC157",

                          color: "white",
                        }}
                      >
                        Create Card
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {loadMyCard && (
        <div>
          <div className="row">
            <div classNam="col">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>My Credit Card</div>
                    <div>
                      {" "}
                      <span
                        className={
                          cardDetail.status
                            ? "badge bg-success"
                            : "badge bg-danger"
                        }
                      >
                        {cardDetail.status ? "Active" : "InActive"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card-body d-flex justify-content-center">
                  <div class=" col-sm-12 col-md-4">
                    <Media
                      queries={{
                        small: "(max-width: 599px)",
                        medium: "(min-width: 600px) and (max-width: 1199px)",
                        large: "(min-width: 1200px)",
                      }}
                    >
                      {(matches) => (
                        <Fragment>
                          {matches.small && <p>I am small!</p>}
                          {matches.medium && <p>I am medium!</p>}
                          {matches.large && (
                            <div
                              className="credit-card container"
                              style={{
                                opacity: cardDetail.status ? ".9" : ".4",
                              }}
                            >
                              <div className="card-balance">
                                {" "}
                                {cardDetail.balance} {cardDetail.currency}
                              </div>
                              {load}
                              {loadingCard && (
                                <div className="text-center card-spin">
                                  <Spin />
                                </div>
                              )}
                              <div className="card-num">
                                {cardDetail.maskedPan
                                  ? cardDetail.maskedPan
                                  : cardDetail.cardPan}
                              </div>
                              <div className="d-flex">
                                <div className="card-name">
                                  {cardDetail.cardName
                                    ? cardDetail.cardName.toUpperCase()
                                    : ""}
                                </div>
                                <div className="card-date">VALID TILL</div>
                                <div className="card-cvv">
                                  {cardDetail.cvv
                                    ? "CVV : " + cardDetail.cvv
                                    : ""}
                                </div>
                                <div className="card-date-num">
                                  {cardDetail.expiration}
                                </div>
                              </div>
                            </div>
                          )}
                        </Fragment>
                      )}
                    </Media>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12">
              <div class="card ">
                <div class="card-header font-weight-bold">QUICK MENU</div>
                <div class="card-body mb-0 pb-0">
                  <div className="container">
                    <div class="row">
                      <div
                        class="col-sm-6 col-md-3"
                        onClick={sendMoney}
                        style={{ cursor: "pointer" }}
                      >
                        <div class="card card-file-manager">
                          <div class="card-file-header text-danger">
                            <i class="fas fa-file-image"></i>
                          </div>
                          <div
                            class="card-body text-center"
                            style={{ background: "#f5f5f5" }}
                          >
                            <img
                              src="https://res.cloudinary.com/didqqm6jl/image/upload/v1630534127/StaticIcons/send_money.png"
                              width="35"
                            />
                            <h6 class="pt-2 card-subtitle  mb-2 text-muted">
                              Send money
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-sm-6 col-md-3"
                        onClick={withdrawMoney}
                        style={{ cursor: "pointer" }}
                      >
                        <div class="card card-file-manager">
                          <div class="card-file-header text-primary">
                            <i class="fas fa-file-word"></i>
                          </div>
                          <div
                            class="card-body text-center"
                            style={{ background: "#f5f5f5" }}
                          >
                            <img
                              src="https://res.cloudinary.com/didqqm6jl/image/upload/v1630534127/StaticIcons/withdrawal.png"
                              width="35"
                            />
                            <h6 class="pt-2 card-subtitle mb-2 text-muted">
                              Request Credit
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-sm-6 col-md-3"
                        style={{ cursor: "pointer" }}
                        onClick={postPin.bind(this, "Card Details")}
                      >
                        <div class="card card-file-manager">
                          <div class="card-file-header text-primary">
                            <i class="fas fa-file-word"></i>
                          </div>
                          <div
                            class="card-body text-center"
                            style={{ background: "#f5f5f5" }}
                          >
                            <img
                              src="https://res.cloudinary.com/didqqm6jl/image/upload/v1629204418/StaticIcons/creditcard.png"
                              width="35"
                            />
                            <h6 class="pt-2 card-subtitle mb-2 text-muted">
                              Card Details
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-sm-6 col-md-3"
                        style={{ cursor: "pointer" }}
                        onClick={postPin.bind(this, "Card Status")}
                      >
                        <div class="card card-file-manager">
                          <div class="card-file-header text-primary">
                            <i class="fas fa-file-word"></i>
                          </div>
                          <div
                            class="card-body text-center"
                            style={{ background: "#f5f5f5" }}
                          >
                            <img
                              src={
                                cardDetail.status
                                  ? "https://res.cloudinary.com/didqqm6jl/image/upload/v1630534127/StaticIcons/freeze_card.png"
                                  : "https://res.cloudinary.com/didqqm6jl/image/upload/v1630534127/StaticIcons/unfreeze_card.png"
                              }
                              width="35"
                            />
                            <h6 class="pt-2 card-subtitle mb-2 text-muted">
                              {cardDetail.status
                                ? "Freeze Card"
                                : "Unfreeze Card"}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 col-lg-12">
              <div class="card table-widget">
                <div class="card-body">
                  <h5 class="card-title">Transaction History </h5>
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Beneficiary</th>
                          <th scope="col">Amount</th>

                          <th scope="col">Description</th>
                          <th scope="col">Purpose</th>
                          <th scope="col">Type</th>
                          <th scope="col">Date</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactionDatas.map((transaction) => {
                          return (
                            <tr>
                              <td>
                                {transaction.purpose === "FUND_CREDIT_CARD"
                                  ? transaction.cardName
                                    ? transaction.cardName
                                    : "N/A"
                                  : transaction.destAcctName
                                  ? transaction.destAcctName
                                  : "N/A"}
                              </td>
                              <td>
                                {transaction.cardCurrency}
                                {transaction.amount}
                              </td>

                              <td>{transaction.narration}</td>
                              <td>
                                {transaction.purpose
                                  ? transaction.purpose.replace(/_+/g, " ")
                                  : "NA"}
                              </td>
                              <td>{transaction.type}</td>
                              <td>
                                {transaction.createdAt
                                  ? transaction.createdAt.slice(0, 10)
                                  : ""}
                              </td>
                              <td>
                                <span
                                  className={
                                    {
                                      SUCCESS: "badge bg-success",
                                      PENDING: "badge bg-warning",
                                      PROCESS: "badge bg-warning",
                                      NEW: "badge bg-warning",

                                      FAILED: "badge bg-danger",
                                    }[transaction.status]
                                  }
                                >
                                  {transaction.status}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex flex justify-content-center align-items-center pb-5 pt-5">
                    <Pagination defaultCurrent={1} total={1} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        okText="Submit"
        title={"Create Card"}
        footer={false}
        maskClosable={false}
        width={700}
        onCancel={closeCreate}
        visible={createCard}
      >
        <div className="container">
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}

          {fieldError && (
            <p className="text-center text-danger font-weight-bold">
              Postal Code, State, and City are required
            </p>
          )}

          {postalError && (
            <p className="text-center text-danger font-weight-bold">
              Postal Code must be 5 numbers
            </p>
          )}

          {success && (
            <p
              className="text-center  font-weight-bold"
              style={{ color: "#1FC157" }}
            >
              {successMsg}
            </p>
          )}
          {error && (
            <p className="text-center text-danger font-weight-bold">
              {errorMsg}
            </p>
          )}

          {serverError && (
            <p className="text-center text-danger font-weight-bold">
              Failed to connect to SalarioPay
            </p>
          )}

          <form>
            <div className="row">
              <div class="col-sm-12 col-md-12">
                <div className="mb-3">
                  <div className="form-floating">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={getCardInfo}
                    >
                      <option selected>Select Employer</option>
                      {employers.map((employer) => {
                        return (
                          <option value={employer.employerResponse.id}>
                            {employer.employerResponse.businessName}
                          </option>
                        );
                      })}
                    </select>
                    <label for="floatingInput">Employer</label>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="row">
                <div class="col-sm-12 col-md-6">
                  <div className="mb-3">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="country name"
                        onChange={(e) => setAmount(e.target.value)}
                        onBlur={getLoanInterest}
                        value={amount}
                      />
                      <label for="floatingInput">Loan Amount</label>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div className="mb-3">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="country name"
                        disabled
                        value={loanInterest}
                      />
                      <label for="floatingInput">Interest Rate ($)</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div class="col-sm-12 col-md-6">
                  <div className="mb-3">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="country name"
                        disabled
                        value={address}
                      />
                      <label for="floatingInput">Address</label>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="country name"
                        onChange={(e) => setStateUser(e.target.value)}
                        value={stateUser}
                      />
                      <label for="floatingInput">State</label>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="country name"
                        onChange={(e) => setPostalCode(e.target.value)}
                        value={postalCode}
                      />
                      <label for="floatingInput">Postal Code</label>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div className="mb-3">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="country name"
                        onChange={(e) => setCity(e.target.value)}
                        value={city}
                      />
                      <label for="floatingInput">City</label>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="country name"
                        disabled
                        value={country}
                      />
                      <label for="floatingInput">Country</label>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="form-floating">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setCurrency(e.target.value)}
                      >
                        <option selected>Select Currency</option>
                        <option value="NGN">Naira</option>
                        <option value="USD">United State Dollar</option>
                      </select>
                      <label for="floatingInput">Card Currency</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <Button
                type="submit"
                class="btn btn-primary"
                style={{ background: "#1FC157", color: "white" }}
                onClick={postPin.bind(this, "Create New Card")}
              >
                Continue
              </Button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        title="Input Pin"
        visible={pinModal}
        okText="Skip"
        footer={null}
        maskClosable={false}
        onCancel={cancelPinSetup}
      >
        <div className="container text-center">
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}

          {error && (
            <div className="pb-3">
              <Alert message={errorMsg} type="error" showIcon />
            </div>
          )}

          {success && (
            <p
              className="text-center  font-weight-bold"
              style={{ color: "#1FC157" }}
            >
              {successMsg}
            </p>
          )}

          {serverError && (
            <p className="text-center text-danger font-weight-bold">
              Failed to connect to SalarioPay
            </p>
          )}

          <div className="text-center">
            <div>
              <label className="text-left pt-4">Pin</label>
            </div>

            <PinInput
              length={4}
              initialValue={pin}
              secret
              onChange={(value, index) => setPin(value)}
              type="numeric"
              inputMode="number"
              style={{ padding: "10px" }}
              inputStyle={{ borderColor: "green" }}
              inputFocusStyle={{ borderColor: "blue" }}
              onComplete={(value, index) => {}}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </div>
          <br />
          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={submitFunc}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        title="Send Money"
        visible={sendMoneyModal}
        okText="Skip"
        footer={null}
        maskClosable={false}
        onCancel={cancelSendMoney}
      >
        <div className="container">
          <form>
            {load && (
              <p className="text-center">
                <Spin />
              </p>
            )}

            {error && (
              <div className="pb-3">
                <Alert message={errorMsg} type="error" showIcon />
              </div>
            )}
            {bankApiError && (
              <div className="pb-3">
                <Alert message={bankApiErrorMsg} type="error" showIcon />
              </div>
            )}
            {fieldError && (
              <div className="text-center pb-3 text-danger">
                All fields are required
              </div>
            )}

            {success && (
              <div className="pb-3">
                <Alert message={successMsg} type="success" showIcon />
              </div>
            )}
            {bankApiSuccess && (
              <div className="pb-3">
                <Alert message={bankApiSuccessMsg} type="success" showIcon />
              </div>
            )}

            {bankError && (
              <div className="pb-3">
                <Alert
                  message="Country, Bank, and Account Number are required!"
                  type="error"
                  showIcon
                />
              </div>
            )}

            <div className="mb-3">
              <Controller
                name="amount"
                control={control}
                defaultValue={amount ? amount : ""}
                {...register("amount", {
                  required: "Amount is required!",
                  onChange: (e) => setAmount(e.target.value),
                  value: amount,
                })}
                render={({ field }) => (
                  <NumberFormatter
                    {...field}
                    label="Amount"
                    name="amount"
                    style={{
                      borderColor: "#f5f5f5 !important",
                      borderRadius: "10px",
                      padding: ".65rem 1rem",
                      width: "100%",
                    }}
                    // error={errors.amount?.message}
                    prefix={cardDetail ? cardDetail.currency + " " : ""}
                  />
                )}
              />
            </div>
            <div className="mb-3">
              <div className="form-floating">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  value={sendMoneyMethod}
                  onChange={(e) => setSendMoneyMethod(e.target.value)}
                >
                  <option selected>Select Method</option>
                  <option value="BANK_TRANSFER">BANK TRANSFER</option>
                  <option value="PAYCODE">PAYCODE</option>
                </select>
                <label for="floatingInput">Method</label>
              </div>
            </div>
            {sendMoneyMethod === "BANK_TRANSFER" && (
              <div>
                {/* <div className="mb-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      placeholder="country name"
                      onChange={(e) => setAmount(e.target.value)}
                      value={amount}
                    />
                    <label for="floatingInput">Amount</label>
                  </div>
                </div> */}

                <div class="mb-3">
                  <div className="form-floating">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={setReciepientChange}
                      value={recipient}
                    >
                      <option selected>Select Recipient</option>
                      <option value="SELF">Self</option>
                      <option value="OTHERS">Others</option>
                    </select>
                    <label for="floatingInput">Recipient</label>
                  </div>
                </div>
                {recipient === "OTHERS" && (
                  <div>
                    <div className="mb-3">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={countryChange}
                      >
                        <option selected>Country</option>
                        {countryDatas.map((country) => {
                          return (
                            <option value={country.code}>{country.name}</option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="mb-3">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setCurrency(e.target.value)}
                      >
                        <option selected>Select Currency</option>
                        {currencyDatas.map((currency) => {
                          return (
                            <option value={currency.currencyCode}>
                              {currency.currencyName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="mb-3">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setBank(e.target.value)}
                      >
                        <option selected>Select Bank</option>
                        {bankData.map((bank) => {
                          return (
                            <option value={bank.bankCode}>
                              {bank.bankName}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    {country !== "NG" && (
                      <div className="mb-3">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setBranch(e.target.value)}
                        >
                          <option selected>Select Branch</option>
                          <option value="SELF">Self</option>
                          <option value="OTHERS">Others</option>
                        </select>
                      </div>
                    )}

                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          value={accNum}
                          onBlur={fetchAccName}
                          onChange={(e) => setAccNum(e.target.value)}
                        />
                        <label for="floatingInput">Account Number</label>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          disabled
                          placeholder="country name"
                          onChange={(e) => setAccName(e.target.value)}
                          value={accName}
                        />
                        <label for="floatingInput">Account Name</label>
                      </div>
                    </div>
                  </div>
                )}

                {recipient === "SELF" && (
                  <>
                    {payInfos.map((pay) => {
                      if (pay.paymentOption === "BANK_ACCOUNT") {
                        return (
                          <div>
                            <div className="mb-3">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  disabled
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="country name"
                                  value={pay.countryName}
                                />
                                <label for="floatingInput">Country</label>
                              </div>
                            </div>
                            <div className="mb-3">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="floatingInput"
                                  disabled
                                  placeholder="country name"
                                  value={pay.currencyCode}
                                />
                                <label for="floatingInput">Currency</label>
                              </div>
                            </div>
                            <div className="mb-3">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="floatingInput"
                                  disabled
                                  placeholder="country name"
                                  value={pay.bankName}
                                />
                                <label for="floatingInput">Bank</label>
                              </div>
                            </div>
                            {pay.countryName !== "Nigeria" && (
                              <div className="mb-3">
                                <div className="form-floating">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="floatingInput"
                                    disabled
                                    placeholder="country name"
                                    value={pay.branchName}
                                  />
                                  <label for="floatingInput">Branch</label>
                                </div>
                              </div>
                            )}
                            <div className="mb-3">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="country name"
                                  disabled
                                  value={pay.accountNumber}
                                />
                                <label for="floatingInput">
                                  Account Number
                                </label>
                              </div>
                            </div>
                            <div className="mb-3">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="floatingInput"
                                  disabled
                                  placeholder="country name"
                                  disabled
                                  value={pay.accountName}
                                />
                                <label for="floatingInput">Account Name</label>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </>
                )}
              </div>
            )}

            {sendMoneyMethod === "PAYCODE" && (
              <div>
                {/* <div className="mb-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      placeholder="country name"
                      onChange={(e) => setAmount(e.target.value)}
                      value={amount}
                    />
                    <label for="floatingInput">Amount</label>
                  </div>
                </div> */}
                <div className="mb-3">
                  <select
                    class="form-select"
                    onChange={setReciepientWithdrawChange}
                    value={recipient}
                  >
                    <option selected>Select Reciepient</option>
                    <option value="SELF">Self</option>
                    <option value="OTHERS">Others</option>
                  </select>
                </div>
                {recipient === "OTHERS" && (
                  <div>
                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="country name"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                        <label for="floatingInput">Email</label>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="country name"
                          onChange={(e) => setMobileNumber(e.target.value)}
                          value={mobileNumber}
                        />
                        <label for="floatingInput">Mobile Number</label>
                      </div>
                    </div>
                  </div>
                )}
                {recipient === "SELF" && (
                  <div>
                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="country name"
                          onChange={(e) => setEmail(e.target.value)}
                          value={userInfo.email}
                          disabled
                        />
                        <label for="floatingInput">Email</label>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="country name"
                          onChange={(e) => mobileNumber(e.target.value)}
                          value={userInfo.mobile}
                          disabled
                        />
                        <label for="floatingInput">Mobile Number</label>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {recipient && (
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="country name"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  />
                  <label for="floatingInput">Description</label>
                </div>
              </div>
            )}

            <div className="d-flex justify-content-end">
              <Button
                type="submit"
                class="btn btn-primary"
                style={{ background: "#1FC157", color: "white" }}
                onClick={postPin.bind(this, "withdraw Money")}
              >
                Send Money
              </Button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        title="Request Credit"
        visible={withdrawoneyModal}
        okText="Skip"
        footer={null}
        maskClosable={false}
        onCancel={cancelWithdrawMoney}
      >
        <div className="container">
          <form>
            <div className="text-center">
              {error && (
                <div className="pb-3">
                  <Alert message={errorMsg} type="error" showIcon />
                </div>
              )}

              {fieldError && (
                <div className="text-center pb-3 text-danger">
                  All fields are required
                </div>
              )}

              {success && (
                <div className="pb-3">
                  <Alert message={successMsg} type="success" showIcon />
                </div>
              )}
            </div>

            <div className="mb-3">
              <Controller
                name="amount"
                control={control}
                defaultValue={amount ? amount : ""}
                {...register("amount", {
                  required: "Amount is required!",
                  onChange: (e) => setAmount(e.target.value),
                  value: amount,
                  onBlur: getLoanInterest()
                })}
                render={({ field }) => (
                  <NumberFormatter
                    {...field}
                    label="Amount"
                    name="amount"
                    style={{
                      borderColor: "#f5f5f5 !important",
                      borderRadius: "10px",
                      padding: ".65rem 1rem",
                      width: "100%",
                    }}
                    // error={errors.amount?.message}
                    prefix={cardDetail ? cardDetail.currency + " " : ""}
                  />
                )}
              />
            </div>
            {loanInterest && (
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="country name"
                    disabled
                    value={loanInterest}
                  />
                  <label for="floatingInput">Interest Rate ({cardDetail.currency})</label>
                </div>
              </div>
            )}
            <div className="mb-3">
              <div className="form-floating">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setEmployer(e.target.value)}
                >
                  <option selected>Select Employer</option>
                  {employers.map((employer) => {
                    return (
                      <option value={employer.id}>
                        {employer.employerResponse.businessName}
                      </option>
                    );
                  })}
                </select>
                <label for="floatingInput">Employer</label>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <Button
                type="submit"
                class="btn btn-primary"
                style={{ background: "#1FC157", color: "white" }}
                onClick={postPin.bind(this, "Request Credit")}
              >
                Request
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </Layout>
  );
}
