import React, { useState, useEffect } from "react";
import Tabs from "../../../employeeTabs";
import Layout from "../../../../containers/Layout";
import axios from "axios";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHttp } from "../../../../hooks/useHttps";
import config from "../../../../config";
import { Modal, Drawer, notification, Spin, DatePicker } from "antd";
import { Pagination, Alert } from "antd";
export default function LeaveRequest(props) {
  const [load, setLoad] = useState(false);
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [rowId, setRowID] = useState("");
  const [LeaveReqStatus, setLeaveReqStatus] = useState("");
  const [leaveReqDatas, setLeaveReqDatas] = useState([]);
  const [noLeaveReqDatas, setNoLeaveReqDatas] = useState(false);
  const [loadReqDatas, setLoadReqDatas] = useState(true);
  const [leaveModal, setLeaveModal] = useState(false);
  const [days, setDays] = useState("");
  const [startPeriod, setStartPeriod] = useState("");
  const [endPeriod, setEndPeriod] = useState("");
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [serverError, setServerError] = useState(false);


  //paginations
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const [fill, setFill] = useState(false);

  const showDrawer = (record, e) => {
    e.preventDefault();
    setRowID(record.id);
    setLeaveReqStatus(record.status);
    setVisibleDraw(true);
  };

  function onChangeStartDate(date, dateString) {
    setStartPeriod(date);
  }
  function onChangeEndDate(date, dateString) {
    setEndPeriod(date);
  }

  const closeModal = () => {
    setLeaveModal(false);
  };
  const closeDrawer = () => {
    setVisibleDraw(false);
  };

  const getLeavesReq = () => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/leaves/employer/paged?employerId=${sessionStorage.getItem(
          "employer_id"
        )}&page=0&size=10`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setNoLeaveReqDatas(false);
            setLoadReqDatas(false);
            setLeaveReqDatas(res.data.result);
          } else {
            setNoLeaveReqDatas(true);
            setLoadReqDatas(false);
            setLeaveReqDatas([]);
          }
        } else {
          setNoLeaveReqDatas(true);
          setLoadReqDatas(false);
          setLeaveReqDatas([]);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };
  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const deleteConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure you want to delete this leave request ? `,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteLeave();
      },
      onCancel() {
      },
    });
  };

  const statusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure you want to change status of this deduction information ? `,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        statusDeduction();
      },
      onCancel() {
      },
    });
  };

  const deleteLeave = () => {
    setLoad(true);
    axios
      .delete(`${config.baseUrl}/v1/leaves/${rowId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getLeavesReq();
          setVisibleDraw(false);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
          Notification("error", "Error", "Failed to connect to SalarioPay");
        }
      });
  };

  const handleCreateLeave = (e) => {
    e.preventDefault();
    const data = {
      employeeId: sessionStorage.getItem("employeeID"),
      endDate: endPeriod,
      prorateDays: days,
      startDate: startPeriod,
      employerId: sessionStorage.getItem("employer_id"),
    };
    if (startPeriod && endPeriod && days) {
      setLoad(true);
      axios
        .post(`${config.baseUrl}/v1/leaves`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setSuccess(true);
            setLeaveModal(false);
            setSuccessMsg(res.data.message);
            setTimeout(() => {
              setSuccess(false);
              getLeavesReq();
            }, 2500);
          } else {
            setError(true);
            setErrorMsg(res.data.message);
            setTimeout(() => {
              setError(false);
            }, 2500);
          }
        })
        .catch((err) => {
          setLoad(false);
          if (err) {
          }
        });
    } else {
      setFill(true);
      setTimeout(() => {
        setFill(false);
      }, 3000);
    }
  };

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `employerId=${sessionStorage.getItem(
      "employer_id"
    )}&page=${page - 1}&size=${pageSize}`;
    getPaged(queryString);
  };

  const getPaged = (queryString) => {
    axios
      .get(`${config.baseUrl}/v1/leaves/employer/paged?${queryString}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const data = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          setLeaveReqDatas(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  
  function itemRender(current, type, originalElement) {
    if (type === 'prev') {
      return <a>Previous</a>;
    }
    if (type === 'next') {
      return <a>Next</a>;
    }
    return originalElement;
  }



  const statusDeduction = () => {
    setLoad(true);
    axios
      .put(
        `${config.baseUrl}/v1/deductions/status/${rowId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getLeavesReq();
          setVisibleDraw(false);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
          Notification("error", "Error", "Failed to connect to SalarioPay");
        }
      });
  };

  useEffect(() => {
    getLeavesReq();
  }, []);

  return (
    <Layout>
      <Tabs />
      <div>
        <div className="col-sm-12 col-md-12">
          <div class="card">
            <div class="card-header font-weight-bold">
              <div className="d-flex justify-content-end align-items-center">
                <button
                  type="submit"
                  onClick={(e) => setLeaveModal(true)}
                  class="btn btn-primary"
                  style={{ background: "#1FC157", color: "white" }}
                >
                  Create Leave
                </button>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Start Date</th>
                      <th scope="col">End Date</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Tax</th>
                      <th scope="col">Prorate Days</th>
                      <th scope="col">Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {leaveReqDatas.map((data) => {
                      return (
                        <tr>
                          <td>
                            {data.startDate ? data.startDate.slice(0, 10) : ""}
                          </td>
                          <td>
                            {data.endDate ? data.endDate.slice(0, 10) : ""}
                          </td>
                          <td>
                            {data.currency}{" "}
                            {data.amount
                              ? Intl.NumberFormat("en-US").format(data.amount)
                              : 0}
                          </td>
                          <td>
                            {data.currency}{" "}
                            {data.tax
                              ? Intl.NumberFormat("en-US").format(data.tax)
                              : 0}
                          </td>
                          <td>{data.prorateDays}</td>
                          <td>
                            <span
                              className={
                                {
                                  ACTIVE: "badge bg-success",
                                  INACTIVE: "badge bg-danger",
                                }[data.status ? "ACTIVE" : "INACTIVE"]
                              }
                            >
                              {data.status ? "ACTIVE" : "INACTIVE"}
                            </span>
                          </td>{" "}
                          <td>
                            <button
                              type="submit"
                              onClick={showDrawer.bind(this, data)}
                              class="btn btn-primary"
                              style={{
                                background: "#1FC157",
                                color: "white",
                              }}
                            >
                              Action
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              {loadReqDatas && (
                <div className="text-center pt-5 pb-5">
                  <Spin />
                </div>
              )}
              {noLeaveReqDatas && (
                <p className="text-center pt-5 pb-5">No Leave Request Yet.</p>
              )}

              <div className="text-center pt-5 pb-5">
                <div className="text-center pagination-part pt-5">
                  <Pagination
                    current={page}
                    total={totalItems}
                    defaultPageSize={10}
                    onChange={pagination}
                    itemRender={itemRender}
                  />
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}
      >
        <div
          style={{ color: "red", cursor: "pointer" }}
          onClick={deleteConfirm}
        >
          Delete
        </div>

        <hr />

        <div
          style={{ color: "#000000", cursor: "pointer" }}
          onClick={statusConfirm}
        >
          {LeaveReqStatus ? "DISABLE" : "ENABLE"}
        </div>

        <hr />
      </Drawer>
      <Modal
        title={"Initiate Leave Request"}
        visible={leaveModal}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeModal}
      >
        <div className="container">
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}

          {success && <Alert message={successMsg} type="success" showIcon />}
          {fill && (
            <p
              className="text-center  font-weight-bold"
              style={{ color: "red" }}
            >
              Start Date, End Date, and Prorate Days are required!
            </p>
          )}
          {error && (
            <p className="text-center text-danger font-weight-bold">
              {errorMsg}
            </p>
          )}

          {serverError && (
            <p className="text-center text-danger font-weight-bold">
              Failed to connect to SalarioPay
            </p>
          )}
          <div class="">
            <div className="mb-3">
              <DatePicker
                onChange={onChangeStartDate}
                style={{
                  width: "100%",
                  height: "3.3rem",
                  borderRadius: "10px",
                }}
                placeholder="Start Date"
              />
            </div>
          </div>

          <div class="">
            <div className="mb-3">
              <DatePicker
                onChange={onChangeEndDate}
                style={{
                  width: "100%",
                  height: "3.3rem",
                  borderRadius: "10px",
                }}
                placeholder="End Date"
              />{" "}
            </div>
          </div>
          <div class="">
            <div className="mb-3">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="country name"
                  onChange={(e) => setDays(e.target.value)}
                  value={days}
                />
                <label for="floatingInput">Prorate Days</label>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end align-items-between">
          <button
            type="submit"
            class="btn btn-primary"
            style={{
              background: "#1FC157",
              marginTop: "1rem",
              color: "white",
            }}
            onClick={handleCreateLeave}
          >
            Submit
          </button>
        </div>
      </Modal>
    </Layout>
  );
}
