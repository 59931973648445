import React from "react";

export default function Dashboard() {
  return (
    <div>
      {/* <div class="row">
        <div class="col-md-6 col-xl-3">
          <div class="card stat-widget">
            <div
              class="card-body"
              style={{
                background: "#1fc157",
                color: "white",
                textAlign: "center",
                borderRadius: "20px",
              }}
            >
              <h5 class="card-title text-center text-white">Total Employees</h5>
              <div className="text-center h2 font-weight-bold">
                {employerstats.totalEmployees}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xl-3">
          <div class="card stat-widget">
            <div
              class="card-body"
              style={{
                background: "#1fc157",
                color: "white",
                textAlign: "center",
                borderRadius: "20px",
              }}
            >
              <h5 class="card-title text-center text-white">Total Payroll</h5>
              <div className="text-center h2 font-weight-bold">
                {employerstats.currency}
                {""}
                {employerstats.totalPayroll}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xl-3">
          <div class="card stat-widget">
            <div
              class="card-body"
              style={{
                background: "#1fc157",
                color: "white",
                textAlign: "center",
                borderRadius: "20px",
              }}
            >
              <h5 class="card-title text-center text-white">Total Benefit</h5>
              <div className="text-center h2 font-weight-bold">
                {" "}
                {employerstats.currency}
                {employerstats.totalBenefit}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xl-3">
          <div class="card stat-widget">
            <div
              class="card-body"
              style={{
                background: "#1fc157",
                color: "white",
                textAlign: "center",
                borderRadius: "20px",
              }}
            >
              <h5 class="card-title text-center text-white">Transactions</h5>
              <div className="text-center h2 font-weight-bold">
                {employerstats.currency}
                {employerstats.totalTransaction}
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div class="row">
        <div class="col-sm-6 col-xl-8">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Payment Statistics</h5>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-xl-4">
          <div class="card stat-widget">
            <div class="card-body">
              <h5 class="card-title">Payment Summary</h5>
              <div class="transactions-list">
                <div class="tr-item">
                  <div class="tr-company-name">
                    <div class="tr-icon tr-card-icon tr-card-bg-primary text-primary">
                      <i data-feather="thumbs-up"></i>
                    </div>
                    <div class="tr-text">
                      <h4>New post reached 7k+ likes</h4>
                      <p>02 March</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="transactions-list">
                <div class="tr-item">
                  <div class="tr-company-name">
                    <div class="tr-icon tr-card-icon tr-card-bg-info text-info">
                      <i data-feather="twitch"></i>
                    </div>
                    <div class="tr-text">
                      <h4>Developer AMA is now live</h4>
                      <p>01 March</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="transactions-list">
                <div class="tr-item">
                  <div class="tr-company-name">
                    <div class="tr-icon tr-card-icon tr-card-bg-danger text-danger">
                      <i data-feather="instagram"></i>
                    </div>
                    <div class="tr-text">
                      <h4>52 unread messages</h4>
                      <p>23 February</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="transactions-list">
                <div class="tr-item">
                  <div class="tr-company-name">
                    <div class="tr-icon tr-card-icon tr-card-bg-warning text-warning">
                      <i data-feather="shopping-bag"></i>
                    </div>
                    <div class="tr-text">
                      <h4>2 new orders from shop page</h4>
                      <p>17 February</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="transactions-list">
                <div class="tr-item">
                  <div class="tr-company-name">
                    <div class="tr-icon tr-card-icon tr-card-bg-info text-info">
                      <i data-feather="twitter"></i>
                    </div>
                    <div class="tr-text">
                      <h4>Hashtag #circl is trending</h4>
                      <p>03 February</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="card table-widget">
            <div class="card-body">
              <h5 class="card-title">Payment History </h5>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Customer</th>
                      <th scope="col">Product</th>
                      <th scope="col">Invoice</th>
                      <th scope="col">Price</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <img
                          src="../../assets/images/avatars/profile-image.png"
                          alt=""
                        />
                        Anna Doe
                      </th>
                      <td>Modern</td>
                      <td>#53327</td>
                      <td>$20</td>
                      <td>
                        <span class="badge bg-info">Shipped</span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <img
                          src="../../assets/images/avatars/profile-image.png"
                          alt=""
                        />
                        John Doe
                      </th>
                      <td>Alpha</td>
                      <td>#13328</td>
                      <td>$25</td>
                      <td>
                        <span class="badge bg-success">Paid</span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <img
                          src="../../assets/images/avatars/profile-image.png"
                          alt=""
                        />
                        Anna Doe
                      </th>
                      <td>Lime</td>
                      <td>#35313</td>
                      <td>$20</td>
                      <td>
                        <span class="badge bg-danger">Pending</span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <img
                          src="../../assets/images/avatars/profile-image.png"
                          alt=""
                        />
                        John Doe
                      </th>
                      <td>Circl Admin</td>
                      <td>#73423</td>
                      <td>$23</td>
                      <td>
                        <span class="badge bg-primary">Shipped</span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <img
                          src="../../assets/images/avatars/profile-image.png"
                          alt=""
                        />
                        Nina Doe
                      </th>
                      <td>Space</td>
                      <td>#54773</td>
                      <td>$20</td>
                      <td>
                        <span class="badge bg-success">Paid</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
