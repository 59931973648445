import React, { useEffect, useState } from "react";
import Layout from "../../../containers/Layout";
import axios from "axios";
import config from "../../../config";
import ReactDatatable from "@ashvin27/react-datatable";

const EmployerLoan = (props) => {
  const [loanData, setLoanData] = useState([]);

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    button: {
      excel: true,
      print: true,
      extra: true,
    },
  };

  const columns = [
    {
      key: "loanAmount",
      text: "Loan Amount",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "loanTerm",
      text: "Loan Term",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "interestRate",
      text: "interest Rate",
      className: "address",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "payBack",
      text: "Pay Back",
      className: "address",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "duration",
      text: "Duration",
      className: "address",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "currency",
      text: "Currency",
      className: "address",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "amount",
      text: "Amount",
      className: "address",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "interest",
      text: "Interest",
      className: "address",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      className: "address",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
  ];

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];

  const getLoanDetails = () => {
    const id = props.location.state.empId ? props.location.state.empId : "";
    axios
      .get(
        `${config.baseUrl}/v1/loans/employer/page?employerId=${id}
      `,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const getLoanData = res.data.result;
          if (getLoanData.length > 0) {
            setLoanData(
              getLoanData?.map((data) => ({
                id: data.id,
                loanAmount: data.loanAmount,
                loanTerm: data.loanTerm,
                interestRate: data.interestRate,
                payBack: data.payBack,
                duration: `${data.duration} Days`,
                currency: data.currency,
                amount: data.amount,
                interest: data.interest,
                status: data.status,
              }))
            );
          }
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    getLoanDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 class="card-title">Loan Request</h5>
              <div style={{ overflowX: "auto" }} className="pt-0">
                <ReactDatatable
                  config={configTable}
                  records={loanData}
                  columns={columns}
                  extraButtons={extraButtons}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EmployerLoan;
