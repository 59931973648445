import React from "react";
import Layout from "../../../containers/Layout";

export default function Insurance() {
  return (
    <Layout>
      <div className="row">
        <div classNam="col">
          <div className="card">
            <div className="card-header">Insurance</div>
            <div className="card-body d-flex justify-content-center" style={{height: '100vh'}}>
             <h4>Coming Soon</h4>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
