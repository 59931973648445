import {
    ERROR,
    DEDUCTION_HISTORY_REDUCER, SUCCESS,
    EMPLOYEE_DEDUCTION_HISTORY,
    LOADING,
  } from "../../../action/types";
  
  const initState = {
    loadingDeductiontHistory: false,
    data: [],
    message: ""
  };
  
  export function deductionHistoryReducer(state = initState, action) {
    switch (action.type) {
      case LOADING:
        return {
          ...state,
          loadingDeductiontHistory: true,
        };
      case EMPLOYEE_DEDUCTION_HISTORY:
        return {
          ...state,
          loadingDeductiontHistory: false,
          data: action.payload,
        };
    
      case ERROR:
        return {
          ...state,
          loadingDeductiontHistory: false,
          error: action.payload,
        };
      default:
        return state;
    }
  }

  export default deductionHistoryReducer
  