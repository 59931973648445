import "./App.css";
import Router from "./router";
import store from "./store";
import { Provider } from "react-redux";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { PrimeReactProvider, PrimeReactContext } from "primereact/api";

const App = () => {
  return (
    <div className="App">
      <Provider store={store}>
        <PrimeReactProvider>
          <Router />
        </PrimeReactProvider>
      </Provider>
    </div>
  );
};

export default App;
