import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Layout from "../../../containers/Layout";
import axios from "axios";
import config from "../../../config";
import {Pagination, Spin} from 'antd'
export default function Employers() {
  const [transHistory, setHistories] = useState([]);
  useEffect(() => {
    getTransHistory();
  }, []);

  const getTransHistory = () => {
    axios
      .get(`${config.baseUrl}/v1/vendor-payment/employee`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setHistories(res.data.result);
        } else {
        }
      })
      .catch((err) => {});
  };

  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 col-lg-12">
                  <div class="card table-widget">
                    <div class="card-body">
                      <h5 class="card-title">Merchant Payments</h5>
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">Merchant Name</th>
                              <th scope="col">Merchant Mobile</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Description</th>
                              <th scope="col">Date</th>
                              <th scope="col">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transHistory.map((info) => {
                              return (
                                <tr>
                                  <td>{info.vendorName}</td>
                                  <td>{info.vendorMobile}</td>

                                  <td>
                                    {info.currency}
                                    {info.amount}
                                  </td>
                                  <td>{info.description}</td>
                                  <td>
                                    {info.createdAt
                                      ? info.createdAt.slice(0, 10)
                                      : ""}
                                  </td>
                                  <td><span
                                  className={
                                    {
                                      SUCCESS: "badge bg-success",
                                      PENDING: "badge bg-warning",
                                      PROCESS: "badge bg-warning",
                                      NEW: "badge bg-warning",

                                      FAILED: "badge bg-danger",
                                    }[info.status]
                                  }
                                >
                                  {info.status}
                                </span></td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>

                      <div className="d-flex flex justify-content-center align-items-center pb-5 pt-5">
                        <Pagination defaultCurrent={1} total={1} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
