import React, { useState, useEffect } from "react";
import { Spin} from "antd";
import Cookie from "js-cookie";
import "./profile.css";
import Cookies from "js-cookie";
import Logo from "../../img/loggo.png";
const userRoles = Cookie.getJSON("salarioPayUserRole");
const user = Cookie.getJSON("salarioPayUserInfo");
export default function Profile() {
  const [first, setFirst] = useState(true);
  const [second, setSecondd] = useState(false);
  const [third, setThird] = useState(false);
  const [load, setLoad] = useState(false);

  var urlParams = new URLSearchParams(window.location.search);
  const currentRoleswitcher = urlParams.get("currentRole");


  useEffect(() => {
    setTimeout(() => {
      if (first) {
        setSecondd(true);
        setThird(false);
        setFirst(false);
      }
      if (second) {
        setSecondd(false);
        setThird(true);
        setFirst(false);
      }
      if (third) {
        setSecondd(false);
        setThird(false);
        setFirst(true);
      }
    }, 3000);
  }, [first, second, third]);

  const loginasProfile = (roleType) => {
    setLoad(true);
    setTimeout(() => {
      sessionStorage.setItem("activeRole", roleType);
      window.location.href = "/dashboard";
      setLoad(false);
    }, 2000);
  };

  return (
    <>
      <div class="switch-bg">
        <div class="row">
          <div class="col-md-7 col-12">
            <div className={first ? "img1" : second ? "img2" : "img3"}>
              <div class="d-flex justify-content-center align-items-end h-100">
                <div class="bottom-sheet-modal">
                  <div class="container-modal">
                    <div class="text-white lead-pp">
                      {first
                        ? "For your Employee Growth and Happiness"
                        : second
                        ? "For your Business Growth and Global Expansion"
                        : "Cross-Border Payroll Payout"}
                    </div>
                    {first && (
                      <div class="pt-3 text-white">
                        We help you provide salary advance, airtime loan, data
                        bundle loan, bill payment loan, online shopping
                        loan/discount, financial investment offers. They are
                        able to receive their salary/benefit payout to bank
                        account, mobile wallet and crypto wallet in 7+ supported
                        currencies while you focus on your core business.
                      </div>
                    )}
                    {second && (
                      <div class="pt-3 cool-mute">
                        Your business can reward employees with airtime, data
                        bundle, bill payment benefit payout at payday through
                        our 500+ biller partners.
                      </div>
                    )}
                    {third && (
                      <div class="pt-3 cool-mute">
                        We deliver cross-border salary & benefit payout, value
                        added service payout, payroll financing, instant health
                        insurance enrollment, pension remittance, NHF
                        remittance, Tax remittance and virtual accountant etc.
                        Most importantly we will support your hardworking
                        employees with various financial perks that will improve
                        their happiness.
                      </div>
                    )}

                    <div class="d-flex pt-2">
                      <div
                        className={
                          first
                            ? "mr2 indicator-pill-white-active"
                            : "indicator-pill-white mr2"
                        }
                      ></div>
                      <div
                        className={
                          second
                            ? "mr2 indicator-pill-white-active"
                            : "indicator-pill-white mr2"
                        }
                      ></div>
                      <div
                        className={
                          third
                            ? "mr2 indicator-pill-white-active"
                            : "indicator-pill-white mr2"
                        }
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5 col-12 pt-5">
            <div class="container-switch container">
              <div>
                <div class="img-logo text-center">
                  <img src={Logo} width="100" alt="voucher icon" />
                </div>
                <br />
                <div class="pt-2 d-flex justify-content-center align-items-center">
                  <div>
                    <div class="lead-t">
                      Welcome back, choose a profile to continue
                    </div>
                    <div class="lead-p pt-2">
                      switch between the available profiles on your account
                    </div>
                    <br />
                    <div className="text-center">{load && <Spin />}</div>

                    <div class="mt-4">
                      {userRoles?.map((d) => {
                        return (
                          <div
                            class="ac-type-border mb-3"
                            onClick={loginasProfile.bind(this, d?.name)}
                          >
                            {currentRoleswitcher === d?.name &&
                            <div className="d-flex justify-content-end align-items-center">
                                <div className="active-st ">ACTIVE</div>
                            </div>}
                            <div class="d-flex justify-content align-items-center">
                              <div class="mr2 d-flex justify-content-center align-items-top h-100">
                                <img
                                  class="mr-3"
                                  alt="business-personel"
                                  src="https://res.cloudinary.com/didqqm6jl/image/upload/v1630535226/StaticIcons/employees.png"
                                  width="36"
                                />
                              </div>
                              <div class="ml-3">
                                <div class="switch-menu">
                                  {" "}
                                  {d?.name?.replace("_"," ")} Profile
                                </div>
                                <div class="text-mute">{user?.email}</div>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      <br />
                      <div
                        onClick={() => {
                          setLoad(true);
                          setTimeout(() => {
                            Cookies.remove("salarioPayUserInfo");
                            Cookies.remove("salarioPayUserRoles");
                            sessionStorage.setItem("activeRole", "");
                            window.location.href = "/login";
                            setLoad(false);
                          }, 1500);
                        }}
                        class="d-flex justify-content-center align-items-center"
                        style={{ cursor: "pointer", color: "red" }}
                      >
                        <div class="text-center">Log Out</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
