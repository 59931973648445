import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../../containers/Layout";
import config from "../../../config";
import axios from "axios";
import { useHttp } from "../../../hooks/useHttps";
import {
  Drawer,
  Button,
  Spin,
  Alert,
  Pagination,
  Modal,
  notification,
} from "antd";
export default function LeaveRequest() {
  const employeeId = sessionStorage.getItem("employeeID");
  const [noLeaveReq, setNoLeaveReq] = useState(false);
  const [LoadRequests, setLoadRequests] = useState(false);
  const [requestsData, setRequestsData] = useState([]);
  const [employerId, setEmployer] = useState([]);
  const [load, setLoad] = useState(false);
  //paginations
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");
  const [loadingEmployers, employers] = useHttp(
    `${config.baseUrl}/v1/employees/filtered/employer`,
    []
  );

  const getLeaveHistoy = () => {
    setLoadRequests(true);

    axios
      .get(
        `${config.baseUrl}/v1/leaves/employee/paged?employeeId=${employerId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setNoLeaveReq(false);
            setLoadRequests(false);
            setRequestsData(res.data.result);
          } else {
            setNoLeaveReq(true);
            setLoadRequests(false);
            setRequestsData([]);
          }
        } else {
          setNoLeaveReq(true);
          setLoadRequests(false);
          setRequestsData([]);
        }
      })
      .catch((err) => {
        setNoLeaveReq(false);
        if (err) {
        }
      });
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `page=${page - 1}&size=${pageSize}`;
    getPaged(queryString);
  };

  const getPaged = (queryString) => {
    axios
      .get(
        `${config.baseUrl}/v1/leaves/employee/paged?employeeId=${employerId}&${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setNoLeaveReq(false);
            setLoadRequests(false);
            setRequestsData(res.data.result);
          } else {
            setNoLeaveReq(true);
            setLoadRequests(false);
            setRequestsData([]);
          }
        } else {
          setNoLeaveReq(true);
          setLoadRequests(false);
          setRequestsData([]);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  return (
    <Layout>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header">
              <div className="card-body">
                <h5 class="card-title">Leave Requests </h5>
                <div className="mb-3">
                  <div className="form-floating">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={(e) => setEmployer(e.target.value)}
                      value={employerId}
                    >
                      <option selected>Select Employer</option>
                      {employers.map((employer) => {
                        return (
                          <option value={employer.id}>
                            {employer.employerResponse.businessName}
                          </option>
                        );
                      })}
                    </select>
                    <label for="floatingInput">Employer</label>
                  </div>
                </div>
                <div class="d-flex justify-content-center align-items-center mt-5">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    style={{
                      background: "#1FC157",
                      color: "white",
                      width: "20%",
                    }}
                    onClick={getLeaveHistoy}
                  >
                    {LoadRequests ? "Fetching..." : "Search"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 col-lg-12">
          {requestsData.length > 0 && (
            <div class="card table-widget">
              <div class="card-body">
                <h5 class="card-title">Deduction History </h5>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Start Date</th>
                        <th scope="col">End Date</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Tax</th>
                        <th scope="col">Prorate Days</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {requestsData.length > 0 && (
                        <>
                          {requestsData.map((data) => {
                            return (
                              <tr>
                                <td>
                                  {data.startDate
                                    ? data.startDate.slice(0, 10)
                                    : ""}
                                </td>
                                <td>
                                  {data.endDate
                                    ? data.endDate.slice(0, 10)
                                    : ""}
                                </td>
                                <td>
                                  {data.currency}{" "}
                                  {data.amount
                                    ? Intl.NumberFormat("en-US").format(
                                        data.yearlyDeduction
                                      )
                                    : 0}
                                </td>
                                <td>
                                  {data.currency}{" "}
                                  {data.tax
                                    ? Intl.NumberFormat("en-US").format(
                                        data.yearlyDeduction
                                      )
                                    : 0}
                                </td>
                                <td>{data.prorateDays}</td>
                                <td>
                                  <span
                                    className={
                                      {
                                        ACTIVE: "badge bg-success",
                                        INACTIVE: "badge bg-danger",
                                      }[data.status ? "ACTIVE" : "INACTIVE"]
                                    }
                                  >
                                    {data.status ? "ACTIVE" : "INACTIVE"}
                                  </span>
                                </td>{" "}
                              </tr>
                            );
                          })}
                          <div className="d-flex  justify-content-center align-items-center ">
                            <Pagination
                              current={page}
                              total={totalItems}
                              defaultPageSize={10}
                              itemRender={itemRender}
                              onChange={pagination}
                            />{" "}
                          </div>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {noLeaveReq && (
        <div class="card table-widget">
          <div class="card-body">
            <p className="text-center pt-5 pb-5">No Leave Requests Yet.</p>
          </div>
        </div>
      )}
    </Layout>
  );
}
