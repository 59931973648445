import React, { useEffect, useState } from "react";
import Layout from "../../../containers/Layout";
import KycTab from "./kyc_tab";
import { useParams } from "react-router-dom";
import ReactDatatable from "@ashvin27/react-datatable";
import { Spin } from "antd";
import axios from "axios";
import config from "../../../config";

const Identities = () => {
  const [loading, setLoading] = useState(true);
  const [kycDetails, setKycDetails] = useState([]);
  const [status, setStatus] = useState("");

  const { id } = useParams();

  const getKYCData = () => {
    axios
      .get(`${config.baseUrl}/v1/kyc-details/approvals/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setStatus(res.data.result.status);
          setKycDetails(
            res.data.result.kycDetails.map((data, index) => ({
              s_n: index + 1,
              name:
                data.firstName !== null && data.lastName !== null
                  ? `${data.firstName} ${data.otherName} ${data.lastName}`
                  : data.businessName,
              identityNumber: data.identityNumber,
              kycType: data.kycType,
              kycCountry: data.kycCountry,
              dateOfBirth: data.dateOfBirth !== null ? data.dateOfBirth : "N/A",
              dateOfRegistration:
                data.dateOfRegistration !== null
                  ? data.dateOfRegistration
                  : "N/A",
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err) {
        }
      });
  };

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    button: {
      excel: true,
      print: true,
      extra: true,
    },
  };

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];

  const columns = [
    {
      key: "s_n",
      text: "S/N",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "name",
      text: "Name",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },

    {
      key: "identityNumber",
      text: "Identity Number",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "kycType",
      text: "KYC Type",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "kycCountry",
      text: "KYC Country",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "dateOfBirth",
      text: "Date Of Birth",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "dateOfRegistration",
      text: "Date Of Registration",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
  ];

  useEffect(() => {
    getKYCData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      <KycTab id={id} status={status} />
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title mb-5">Identities</h5>
              <div className="pt-0">
                {loading ? (
                  <div class="d-flex justify-content-center align-items-center">
                    <Spin />
                  </div>
                ) : (
                  <div className="pt-0" style={{ overflowX: "auto" }}>
                    <ReactDatatable
                      config={configTable}
                      records={kycDetails}
                      columns={columns}
                      extraButtons={extraButtons}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Identities;
