import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Layout from "../../../containers/Layout";
import Tab from "../../tab";
import ReactDatatable from "@ashvin27/react-datatable";
import { useHttp } from "../../../hooks/useHttps";
import {
  Drawer,
  Button,
  Spin,
  Alert,
  Pagination,
  Modal,
  notification,
} from "antd";
import axios from "axios";
import config from "../../../config";
export default function BenefitDetail(props) {
  const [benefitDetailData, setBenefitDetailData] = useState([]);
  const [load, setLoad] = useState(false);
  const [nobenefitDetailDatas, setNoBenefitDetail] = useState(false);
  const [loadbenefitDetailDatas, setLoadBenefitDetail] = useState(true);
  const [benefitStatus, setBenefitStatus] = useState("");
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [id, setID] = useState("");

  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [statusSuccess, setSuccessStatus] = useState(false);
  const [updateSwitch, setUpdateSwitch] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");

  const showDrawer = (data, e) => {
    e.preventDefault();
    setID(data.id);
    setVisibleDraw(true);
    setBenefitStatus(data.status);
  };

  const getBenefitDetail = () => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/benefit-details/employee/type/paged?employeeId=${sessionStorage.getItem(
          "employeeID"
        )}&type=AIRTIME`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const transactionsData = res.data.result;
          if (transactionsData.length > 0) {
            setBenefitDetailData(transactionsData);
            setLoadBenefitDetail(false);
            setNoBenefitDetail(false);
          } else {
            setLoadBenefitDetail(false);
            setNoBenefitDetail(true);
          }
        } else {
          setLoadBenefitDetail(false);
          setNoBenefitDetail(true);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    getBenefitDetail();
  }, []);

  const showDeleteConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure want to delete this Benefit Detail?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteBenefit();
      },
      onCancel() {
      },
    });
  };

  const retryBenefitConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure want to retry this Benefit Detail?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        retryBenefit();
      },
      onCancel() {
      },
    });
  };

  const changeStatusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure change status of this Benefit Detail?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        changeStatusFunc();
      },
      onCancel() {
      },
    });
  };

  const closeDrawer = () => {
    setVisibleDraw(false);
  };

  const changeStatusFunc = () => {
    axios
      .put(
        `${config.baseUrl}/v1/benefits/status/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          setStatusMsg(res.data.message);
          getBenefitDetail();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          getBenefitDetail();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
          setStatusMsg("Failed to connect to SalarioPay");
        }
      });
  };

  const deleteBenefit = () => {
    axios
      .delete(`${config.baseUrl}/v1/benefit-details/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getBenefitDetail();
          setVisibleDraw(false);
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const retryBenefit = () => {
    axios
      .put(
        `${config.baseUrl}/v1/benefit-details/retry/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getBenefitDetail();
          setVisibleDraw(false);
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  return (
    <Layout>
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="card table-widget">
            <div class="card-body">
              <h5 class="card-title">Airtime </h5>

              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Phone Number</th>
                      <th scope="col"> Benefit Type</th>
                      <th scope="col">Biller Name</th>
                      <th scope="col"> Bill Plan </th>

                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {benefitDetailData.length > 0 && (
                      <>
                        {benefitDetailData.map((data) => {
                          return (
                            <tr>
                              <td>
                                {data?.employeeName ? data.employeeName : "NA"}
                              </td>
                              <td>{data.employeeNumber}</td>
                              <td>
                                {data.type ? data.type.replace(/_+/g, " ") : ""}
                              </td>

                              <td>
                                {data.billerName ? data.billerName : "NA"}
                              </td>
                              <td>
                                {data.billerPlanName
                                  ? data.billerPlanName
                                  : "NA"}
                              </td>

                              <td>
                                {data.currency}{" "}
                                {data?.amount
                                  ? Intl.NumberFormat("en-US").format(
                                      data.amount
                                    )
                                  : 0}
                              </td>
                              <td>
                                <span
                                  className={
                                    {
                                      SUCCESS: "badge bg-success",
                                      NEW: "badge bg-info",
                                      FAILED: "badge bg-danger",
                                    }[data.status]
                                  }
                                >
                                  {data.status}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </table>
                {nobenefitDetailDatas && (
                  <p className="text-center pt-5 pb-5">No Benefit Detail</p>
                )}
                {loadbenefitDetailDatas && (
                  <div className="text-center pt-5 pb-5">
                    <Spin />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex justify-content-center align-items-center">
        <Pagination defaultCurrent={1} total={1} />
      </div>

      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}
      >
        <div
          style={{ color: "", cursor: "pointer" }}
          onClick={retryBenefitConfirm}
        >
          Retry
        </div>
        <hr />
        <div
          style={{ color: "red", cursor: "pointer" }}
          onClick={showDeleteConfirm}
        >
          Delete
        </div>
        <hr />
      </Drawer>
    </Layout>
  );
}
