import React, { useState, useEffect } from "react";
import Tabs from "../tab";
import Layout from "../../containers/Layout";
import logo from "../../img/logo.jpg";
import profileDefault from "../../img/profile-image.png";
import axios from "axios";
import { useHttp } from "../../hooks/useHttps";
import {
  notification,
 
} from "antd";
import config from "../../config";
export default function CompanyInfo(props) {
  //contact details
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");

  //getCountries
  const [loadCountries, countryDatas] = useHttp(
    `${config.baseUrl}/v1/countries/filtered`,
    []
  );

  //logo
  const [logo, setLogo] = useState("");
  const [displayLogo, setDisplayLogo] = useState(null);

  //loaders
  const [loadDetails, setLoadDetails] = useState(false);
  const [loadUpload, setLoadUpload] = useState(false);

  useEffect(() => {
    getcontactInfo();
  }, []);

  const getcontactInfo = () => {
    axios
      .get(`${config.baseUrl}/v1/employees/${sessionStorage.getItem('employeeID')}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const contactInfo = res.data.result;
          setFirstName(contactInfo.firstName);
          setLastName(contactInfo.lastName);
          setEmail(contactInfo.workEmail);
          setMobile(contactInfo.mobile);
          setDisplayLogo(contactInfo.photoUrl);
          setCountry(contactInfo.country);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const handlecontactInfoUpdate = (e) => {
    e.preventDefault();
    const data = {
      businessName: null,
      confirmPassword: null,
      country,
      email,
      firstName,
      lastName,
      mobile,
      password: null,
    };
    setLoadDetails(true);
    axios
      .put(`${config.baseUrl}/v1/users`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoadDetails(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          const contactInfo = res.data.result;
          setFirstName(contactInfo.firstName);
          setLastName(contactInfo.lastName);
          setEmail(contactInfo.email);
          setMobile(contactInfo.mobile);
          setCountry(contactInfo.country);
          getcontactInfo();
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoadDetails(false);
      });
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setLogo(e.target.files[0]);
    } else {
      setLogo(null);
    }
  };
  const handleFileUpdate = (e) => {
    e.preventDefault();
    if (logo) {
      let data = new FormData();
      data.append("file", logo);
      setLoadUpload(true);
      axios
        .post(`${config.baseUrl}/v1/users/upload-photo`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoadUpload(false);
          if (res.data.status) {
            Notification("success", "Success", res.data.message);
            const contactDetails = res.data.result;
            getcontactInfo();
            setDisplayLogo(contactDetails.logoUrl);
            window.location.reload(false);
          } else {
            Notification("error", "Error", res.data.message);
          }
        })
        .catch((err) => {
          setLoadUpload(false);
        });
    } else {
      Notification("error", "Error", 'logo is required');

    }
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };


  return (
    <Layout>
      <div>
        <div className="col-sm-12 col-md-12">
          <div class="card">
            <div class="profile-img pt-5 text-center">
              <img
                src={
                  displayLogo
                    ? displayLogo
                    : "https://res.cloudinary.com/didqqm6jl/image/upload/v1630245510/StaticIcons/user.png"
                }
                alt=""
                width="100"
                style={{ borderRadius: "100%" }}
              />
            </div>
            <div class="card-header font-weight-bold">
              <b>Personal Information</b>
            </div>
            <div class="card-body">
              <form>
                <div className="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="col-sm-12 col-md-12">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          First Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={firstName}
                          disabled
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="col-sm-12 col-md-12">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Last Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={lastName}
                          disabled
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        Email
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        disabled
                        value={email}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        Mobile
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        Country
                      </label>
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                      >
                        <option selected>Select</option>
                        {countryOptions}
                      </select>
                    </div>
                  </div>
                </div> */}
                <button
                  type="submit"
                  class="btn btn-primary"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={handlecontactInfoUpdate}
                >
                  {loadDetails ? (
                    <div class="spinner-border text-white" role="status">
                      <span class="sr-only">Updating...</span>
                    </div>
                  ) : (
                    "Save Changes"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-12">
          <div class="card">
            <div class="card-header font-weight-bold">
              <b>Profile Picture</b>
            </div>
            <div class="card-body">
              <form>
                <div class="mb-3">
                  <label for="formFileSm" class="form-label">
                    Select Photo
                  </label>
                  <input
                    class="form-control form-control-sm"
                    id="formFileSm"
                    type="file"
                    onChange={handleFileChange}
                  />
                </div>

                <button
                  type="submit"
                  class="btn btn-primary"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={handleFileUpdate}
                  
                >
                  {loadUpload ? (
                    <div class="spinner-border text-white" role="status">
                      <span class="sr-only">Updating...</span>
                    </div>
                  ) : (
                    "Upload"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
