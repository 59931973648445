import React, { useEffect, useState } from "react";
import Layout from "../../../containers/Layout";
import axios from "axios";
import config from "../../../config";
import ReactDatatable from "@ashvin27/react-datatable";

const EmployerAuditTrails = (props) => {
  const [auditTrailData, setAuditTrailData] = useState([]);

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    button: {
      excel: true,
      print: true,
      extra: true,
    },
  };

  const columns = [
    {
      key: "fullName",
      text: "Full Name",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "email",
      text: "Email",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "actionType",
      text: "Action Type",
      className: "address",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "ipAddress",
      text: "IP Address",
      className: "address",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "date",
      text: "Date",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
  ];

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];
  const options = { year: "numeric", month: "numeric", day: "numeric" };

  const getAuditTrailDetails = () => {
    const id = props.location.state.empId ? props.location.state.empId : "";
    axios
      .get(
        `${config.baseUrl}/v1/audit-trail/employer/paged?employerId=${id}
      `,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const getAuditTrailData = res.data.result;
          console.log(getAuditTrailData);
          if (getAuditTrailData.length > 0) {
            setAuditTrailData(
              getAuditTrailData?.map((data) => ({
                id: data.id,
                fullName: data.fullName,
                email: data.email,
                actionType: data.actionType,
                ipAddress: data.ipAddress,
                date: new Date(data.createdAt)
                  .toLocaleDateString(undefined, options)
                  .replace(/\//g, "-"),
              }))
            );
          }
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    getAuditTrailDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 class="card-title">Audit Trails</h5>
              <div style={{ overflowX: "auto" }} className="pt-0">
                <ReactDatatable
                  config={configTable}
                  records={auditTrailData}
                  columns={columns}
                  extraButtons={extraButtons}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EmployerAuditTrails;
