import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../config";
import { Select, Spin } from "antd";
import { formatNumbers } from "../../helperFunctions/functions";
import ChartSuperAdmin from "./ChartSuperAdmin";
import EmployeeChartSuperAdmin from "./EmployeeChartSuperAdmin";
const { Option } = Select;

export default function Dashboard() {
  const [totalStat, setTotalStat] = useState([]);
  const [payRollHistory, setPayRollHistory] = useState([]);
  const [indexActive, setIndexActive] = useState(0);
  const [loadPayrollHistory, setPayrollHistory] = useState(true);

  const getStats = () => {
    axios
      .get(`${config.baseUrl}/v1/dashboard/superAdmin`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setPayrollHistory(false);
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setTotalStat(res.data.result);
          }
        }
      })
      .catch((err) => {
      });
  };


  const getPayRollHistory = () => {
    axios
      .get(`${config.baseUrl}/v1/payrolls/paged`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setPayRollHistory(res.data.result);
      })
      .catch((err) => {
 
      });
  };

  function handleChange(currency) {
    totalStat.find((item, index) => {
      setIndexActive(index);
      return item.currency === currency;
    });
  }

  const DateComponent = (date) => {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString().replace(/\//g, "-");
  };

  const checkBgForStatus = (status) => {
    if (status.toUpperCase().includes("COMPLETED")) {
      return <span className="badge bg-success">{status}</span>;
    } else if (status.toUpperCase().includes("IDLE")) {
      return <span className="badge bg-info">{status}</span>;
    } else if (status.toUpperCase().includes("STARTED")) {
      return <span className="badge bg-primary">{status}</span>;
    } else if (status.toUpperCase().includes("STOPPED")) {
      return <span className="badge bg-danger">{status}</span>;
    }
  };

  useEffect(() => {
    getStats();
    getPayRollHistory();
  }, []);
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center ">
        <div>
          <b>SUMMARY</b>
        </div>
        {totalStat.length > 0 && (
          <Select
            defaultValue={totalStat[indexActive].currency}
            style={{ width: 100 }}
            onChange={handleChange}
            value={totalStat[indexActive].currency}
          >
            {totalStat.map((item, index) => {
              return (
                <Option key={index} value={item.currency}>
                  {item.currency}
                </Option>
              );
            })}
          </Select>
        )}
      </div>
      <div className="row pt-3">
        <div className="col-md-6 col-xl-3">
          <div className="card stat-widget">
            <div className="card-body">
              <h5 className="card-title text-center">Total Employers</h5>
              <div
                className="h2 text-center text-black font-weight-bold"
                style={{ fontSize: "18px" }}
              >
                {formatNumbers(totalStat[indexActive]?.totalEmployers)}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-3">
          <div className="card stat-widget">
            <div className="card-body">
              <h5 className="card-title text-center">Total Employees</h5>
              <div
                className="h2 text-center text-black font-weight-bold"
                style={{ fontSize: "18px" }}
              >
                {formatNumbers(totalStat[indexActive]?.totalEmployees)}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-3">
          <div className="card stat-widget">
            <div className="card-body">
              <h5 className="card-title text-center">Total Payroll</h5>
              <div
                className="h2 text-center text-black font-weight-bold"
                style={{ fontSize: "18px" }}
              >
                {totalStat[indexActive]?.currency}{" "}
                {totalStat[indexActive]?.totalPayroll
                  ? (totalStat[indexActive]?.totalPayroll)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                  : 0}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-xl-3">
          <div className="card stat-widget">
            <div className="card-body">
              <h5 className="card-title text-center">Transactions</h5>
              <div
                className="h2 text-center text-black font-weight-bold"
                style={{ fontSize: "18px" }}
              >
                {formatNumbers(totalStat[indexActive]?.totalTransaction)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12  col-xl-12 ">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">PAYROLL METRIC</h5>
              {totalStat[indexActive]?.currency && (
                <ChartSuperAdmin currency={totalStat[indexActive]?.currency} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-xl-12">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Employee Onboarding Metric </h5>

              <div id="">
                <EmployeeChartSuperAdmin />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div className="card table-widget">
            <div className="card-body">
              <h5 className="card-title">Payroll History </h5>
              <div className="table-responsive">
                {loadPayrollHistory ? (
                  <div
                    className="text-center pt-5 pb-5"
                    style={{ width: "100vw !important" }}
                  >
                    <Spin />
                  </div>
                ) : (
                  <>
                    {payRollHistory.length > 0 ? (
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Total Amount </th>
                            <th scope="col">Total Deduction</th>
                            <th scope="col">Total Fee</th>
                            <th scope="col">Total Employees</th>
                            <th scope="col">Start Period</th>
                            <th scope="col">End Period</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>

                        <tbody>
                          {payRollHistory
                            .slice(0, 10)
                            .map(
                              ({
                                totalPayableAmount,
                                totalDeduction,
                                totalFee,
                                totalEmployees,
                                startPeriod,
                                endPeriod,
                                status,
                                currency,
                              }) => (
                                <tr>
                                  <td>
                                    {currency}{" "}
                                    {formatNumbers(totalPayableAmount)}
                                  </td>
                                  <td>
                                    {currency} {formatNumbers(totalDeduction)}
                                  </td>
                                  <td>
                                    {currency} {formatNumbers(totalFee)}
                                  </td>
                                  <td>{totalEmployees}</td>
                                  <td>{DateComponent(startPeriod)}</td>
                                  <td>{DateComponent(endPeriod)}</td>
                                  <td>{checkBgForStatus(status)}</td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                    ) : (
                      <p className="text-center pt-5 pb-5">
                        No Payroll History
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
