import React, { useEffect, useState } from "react";
import { Spin, Pagination, DatePicker, Modal, notification } from "antd";
import axios from "axios";
import Layout from "../../../containers/Layout";
import config from "../../../config";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import TabsBeneficiary from "./tabs";
import moment from "moment";
const { RangePicker } = DatePicker;
const days = 86400000; //number of milliseconds in a day
const currDay = new Date();
const sevenDaysAgo = new Date(currDay - 7 * days);
export default function Employers() {
  let nameSearch;
  const [beneficiaryData, setBeneficiary] = useState([]);
  const [loadBeneficiary, setLoadBeneficiary] = useState(true);
  const [date, setDate] = useState(moment(sevenDaysAgo).format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(
    moment(new Date()).add(1, "days").format("YYYY-MM-DD")
  );
  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  //paginations
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const getBeneficiary = () => {
    setLoadBeneficiary(true);
    axios
      .get(`${config.baseUrl}/v1/wallets/beneficiaries`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const beneficiaryData = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          if (beneficiaryData.length > 0) {
            setBeneficiary(beneficiaryData);
            setLoadBeneficiary(false);
          } else {
            setLoadBeneficiary(false);
          }
        } else {
          setLoadBeneficiary(false);
          setBeneficiary([]);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    getBeneficiary();
  }, []);

  useEffect(() => {
    if (date && endDate) {
      getBeneficiary();
    }
  }, [date, endDate]);

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `page=${page - 1}&size=${pageSize}`;
    getPaged(queryString);
  };

  const getName = (e) => {
    nameSearch = e.target?.value;
    getBeneficiary();
  };

  const getPaged = (queryString) => {
    axios
      .get(`${config.baseUrl}/v1/audit-trail/employer/paged?${queryString}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const beneficiaryData = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          if (beneficiaryData.length > 0) {
            setBeneficiary(beneficiaryData);
            setLoadBeneficiary(false);
          } else {
            setLoadBeneficiary(false);
          }
        } else {
          setLoadBeneficiary(false);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };
  
  const onDelete = (id) => {
    Modal.confirm({
      title: `Do you want to delete this beneficiary record ?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios
          .delete(`${config.baseUrl}/v1/wallets/beneficiaries/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            if (res.data.status) {
              Notification("success", "Success", res.data.message);
              getBeneficiary()
            } else {
              Notification("error", "Error", res.data.message);
            }
          })
          .catch((err) => {
            if (err) {
            }
          });
      },
      onCancel() {},
    });
  };

  const onChange = (date, dateString) => {
    setEndDate(dateString[1]);
    setDate(dateString[0]);
  };

  return (
    <Layout>
    <TabsBeneficiary />
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="card table-widget">
            <div class="card-body">
             
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Account Name</th>
                      <th scope="col"> Account Number</th>
                      <th scope="col">Bank Name</th>
                      <th scope="col"></th>

                    </tr>
                  </thead>
                  {!loadBeneficiary && (
                    <tbody>
                      {beneficiaryData.length > 0 && (
                        <>
                          {beneficiaryData.map((data) => {
                            return (
                              <tr>
                                <td>{data.customerName}</td>
                                <td>{data.identifier}</td>

                                <td>{data.institutionName}</td>
                                <td>
                                  <button
                                    type="submit"
                                    class="btn btn-danger"
                                    style={{
                                      background: "red",
                                      color: "white",
                                    }}
                                    onClick={onDelete.bind(this,data.id)}
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      )}
                    </tbody>
                  )}
                </table>
                {beneficiaryData?.length === 0 && !loadBeneficiary && (
                  <p className="text-center pt-5 pb-5">No Beneficiaries</p>
                )}
                {loadBeneficiary && (
                  <div className="text-center pt-5 pb-5">
                    <Spin />
                  </div>
                )}
              </div>
         
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
