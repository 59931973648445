import React from "react";
import { Modal, Progress } from "antd";
export default function PaymentBilling(props) {
  return (
    <div className="text-center">
      {props.success && (
        <div>
          <Progress type="circle" percent={100} format={() => "Success"} />
          <p className="pt-4 text-success">{props.successMsg}</p>
        </div>
      )}
      {props.error && (
        <div>
          <Progress
            type="circle"
            percent={100}
            status="exception"
            format={() => "Failed"}
          />
          <p className="pt-4 text-danger">{props.errorMsg}</p>
        </div>
      )}
      {props.serverError && (
        <div>
          <Progress type="circle" percent={100} />
          <p className="pt-4 text-danger">Failed to connect to SalarioPay</p>
        </div>
      )}
    </div>
  );
}
