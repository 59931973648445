import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookie from "js-cookie";
import { Spin, notification } from "antd";
import config from "../../config";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import {
  checkPasswordStrength,
  strongPasswordText,
} from "../../helperFunctions/functions";
export default function Register(props) {
  const [loader, setLoad] = useState(false);
  const [otp, setOtp] = useState("");
  const [ref, setRef] = useState("");
  const [pass, setPass] = useState("");
  const [eye, seteye] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const [strongPasswordError, setStrongPasswordError] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const inviteRef = query.get("ref");
    setRef(inviteRef);
    validateLink();
  }, []);

  const validateLink = () => {
    const query = new URLSearchParams(props.location.search);
    const inviteRef = query.get("ref");
    axios
      .get(`${config.baseUrl}/v1/auth/invite-status/${inviteRef}`)
      .then((res) => {
        if (res.data.status) {
          setUserInfo(res.data.result);
          // setOtpAlert(true);
          // setTimeout(() => {
          //   setOtpAlert(false);
          // }, 3000);
          // setMsg(res.data.message);
        } else {
          // window.location.href = '/'
        }
      })
      .catch((err) => {
        if (err) {
          // setLoad(false);
          // setError(true);
          // setTimeout(() => {
          //   setError(false);
          // }, 1000);
        }
      });
  };

  const getOtpInvite = (e) => {
    e.preventDefault();
    const query = new URLSearchParams(props.location.search);
    const inviteRef = query.get("ref");
    setRef(inviteRef);
    setLoad(true);
    axios
      .get(`${config.baseUrl}/v1/auth/invite/${inviteRef}`)
      .then((res) => {
        setLoad(false);
        setLoad(false);
        if (res.data.status) {
          openNotificationWithIcon("success", res.data.message);
        } else {
          openNotificationWithIcon("error", res.data.message);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          openNotificationWithIcon("error", "Something went wrong, Try Again");
        }
      });
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: "Login",
      description: msg,
    });
  };

  const passwordStrength = checkPasswordStrength(pass);

  const registerComplete = (e) => {
    e.preventDefault();

    if (passwordStrength === "Weak") {
      setStrongPasswordError(true);
      // setErrorMsg("Password is weak");

      setTimeout(() => {
        setStrongPasswordError(false);
        // setError(false);
        // setErrorMsg("");
      }, 10000);
      return;
    }

    const data = {
      inviteRef: ref,
      otp,
      password: pass,
    };

    if (data.otp && data.password && userInfo.workEmail) {
      setLoad(true);
      axios
        .post(`${config.baseUrl}/v1/auth/invite/complete`, data)
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            if (res.data.result.accessToken) {
              if (res.data.result.authenticated) {
                openNotificationWithIcon("success", res.data.message);
                setTimeout(() => {
                  sessionStorage.setItem("token", res.data.result.accessToken);
                  if (res.data.result.employer) {
                    sessionStorage.setItem(
                      "employer_id",
                      res.data.result.employer.id
                    );
                  }
                  if (res.data.result.employee) {
                    sessionStorage.setItem(
                      "employeeID",
                      res.data.result.employee.id
                    );
                  }
                  if (res.data.result.user) {
                    sessionStorage.setItem("userId", res.data.result.user.id);
                  }
                  Cookie.set("salarioPayUserInfo", res.data.result.user);
                  Cookie.set("salarioPayUserRole", res.data.result.user.roles);
                  if (res.data.result.user?.roles?.length > 1) {
                    window.location.href = "/choose-profile";
                  } else {
                    sessionStorage.setItem(
                      "activeRole",
                      res.data.result.user.roles[0].name
                    );
                    window.location.href = "/dashboard";
                  }
                }, 1000);
              } else {
              }
            }
          } else {
            openNotificationWithIcon("error", res.data.message);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            openNotificationWithIcon(
              "error",
              "Something went wrong, Try Again!"
            );
          }
        });
    } else {
      openNotificationWithIcon("error", "Fields are all required");
    }
  };

  return (
    <>
      <div class="login-page">
        <div class="loader">
          <div class="spinner-grow text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-md-12 col-lg-4">
              <div class="card login-box-container">
                <div class="card-body">
                  <div class="authent-logo">
                    <img
                      src="../../assets/images/loggo.png"
                      alt=""
                      width="40%"
                    />
                  </div>
                  <div class="authent-text" style={{ color: "#222" }}>
                    <p>Welcome to SalarioPay!</p>
                    <p>
                      Kindly input your new/existing salariopay credential below
                      to accept the invitation
                    </p>
                  </div>

                  <form>
                    {loader && (
                      <p className="text-center">
                        <Spin />
                      </p>
                    )}

                    <div class="mb-3">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control"
                          id="floatingInput"
                          placeholder="Fullname"
                          value={userInfo.workEmail}
                          disabled
                        />
                        <label for="floatingInput">Work Email</label>
                      </div>
                    </div>

                    <div className="mb-2">
                      <div
                        className="mt-2  d-block w-100 d-flex align-items-center justify-content-between border"
                        style={{ borderRadius: "10px", cursor:'pointer' }}
                      >
                        <div
                          className=""
                          style={{
                            width: "90%",
                          }}
                        >
                          <div className="form-floating">
                            <input
                              type={!eye ? "password" : "text"}
                              class="form-control"
                              id="floatingPassword"
                              placeholder={
                                userInfo.existingUser
                                  ? "Input Existing password"
                                  : "New Password"
                              }
                              value={pass}
                              onChange={(e) => setPass(e.target.value)}
                            />
                            <label for="floatingPassword">
                              {userInfo.existingUser
                                ? "Input Existing password"
                                : "New Password"}
                            </label>
                          </div>
                        </div>
                        <div
                          className="d-flex align-items-center justify-content-center h-100 pointer"
                          onClick={() => seteye(!eye)}
                          style={{
                            width: "10%",
                          }}
                        >
                          {!eye ? <BsEye /> : <BsEyeSlash />}
                        </div>
                      </div>

                      {strongPasswordError &&
                        strongPasswordText.map((item) => (
                          <li className="text-danger font-weight-bold">
                            {item.text}
                          </li>
                        ))}
                    </div>
                   

                    <div class="mb-3">
                    <div className="d-flex pb-2 justify-content-end align-items-center">
                      <span
                        type="submit"
                        className="font-weight-bold xx-btn"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={getOtpInvite}
                      >
                        <b>GET OTP </b>
                      </span>
                    </div>
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control"
                          value={otp}
                          id="floatingPassword1"
                         
                          placeholder="Confirm Password"
                          onChange={(e) => setOtp(e.target.value)}
                        />
                        <label for="floatingPassword">OTP</label>
                      </div>
                    </div>

                    {/* <div class="mb-3 form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"
                      />
                      <label class="form-check-label" for="exampleCheck1">
                        I agree the <a href="#">Terms and Conditions</a>
                      </label>
                    </div> */}
                    <div class="d-grid">
                      <button
                        type="submit"
                        class="btn btn-primary m-b-xs"
                        onClick={registerComplete}
                        style={{ background: "#1FC157", color: "white" }}
                      >
                        Accept Invitation
                      </button>
                    </div>
                  </form>
                  <div class="authent-login">
                    <p>
                      Already have an account? <a href="/login">Sign in</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
