import React, { useState, useEffect } from "react";
import Tabs from "../../employeeTabs";
import Layout from "../../../containers/Layout";
import config from "../../../config";
import { Modal, notification, Drawer, Pagination, Button, Spin } from "antd";
import { useHttp } from "../../../hooks/useHttps";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "axios";
export default function EmployeeInfo(props) {
  const [visible, setVisible] = useState(false);
  const [rowId, setRowID] = useState("");
  const showDraw = (id) => {
    setVisible(true);
    setRowID(id.id);
  };

  const [noTaxDetails, setNoTaxDetails] = useState(false);
  const [loadTaxDetails, setLoadTaxDetails] = useState(true);
  const [taxDetailsData, setTaxDetailsData] = useState([]);

  //paginations
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const taxId = props.history.location.state.taxId
    ? props.history.location.state.taxId
    : "";

  useEffect(() => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/tax-details/employer/paged?employerId=${sessionStorage.getItem(
          "employer_id"
        )}&taxId=${taxId}&size=10&page=0`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setTotalItems(res.data.totalPages * 10);
            setNoTaxDetails(false);
            setLoadTaxDetails(false);
            setTaxDetailsData(res.data.result);
          } else {
            setNoTaxDetails(true);
            setLoadTaxDetails(false);
            setTaxDetailsData([]);
          }
        } else {
          setNoTaxDetails(true);
          setLoadTaxDetails(false);
          setTaxDetailsData([]);
        }
      })
      .catch((err) => {
        setNoTaxDetails(true);
        setLoadTaxDetails(false);
        if (err) {
        }
      });
  }, []);

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const closeDraw = () => {
    setVisible(false);
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  const retryTax = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure want to re-try this tax detail?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios
          .put(
            `${config.baseUrl}/v1/tax-details/retry/${rowId}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status) {
              setVisible(false);
              Notification("success", "Success", res.data.message);
              setTimeout(() => {
                window.location.reload(false);
              }, 2000);
            } else {
              Notification("error", "Error", res.data.message);
            }
          })
          .catch((err) => {
            if (err) {
              Notification("error", "Error", "Failed to connect to SalarioPay");
            }
          });
      },

      onCancel() {},
    });
  };

  const deteteTax = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure want to delete this tax detail?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios
          .delete(`${config.baseUrl}/v1/tax-details/${rowId}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            if (res.data.status) {
              setVisible(false);
              Notification("success", "Success", res.data.message);
              setTimeout(() => {
                window.location.reload(false);
              }, 2000);
            } else {
              Notification("error", "Error", res.data.message);
            }
          })
          .catch((err) => {
            if (err) {
              Notification("error", "Error", "Failed to connect to SalarioPay");
            }
          });
      },
      onCancel() {},
    });
  };

  const syncTax = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure want to sync this tax detail remittance?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios
          .put(
            `${config.baseUrl}/v1/tax-details/sync-tax-remittance-detail/${rowId}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status) {
              setVisible(false);
              Notification("success", "Success", res.data.message);
              setTimeout(() => {
                window.location.reload(false);
              }, 2000);
            } else {
              Notification("error", "Error", res.data.message);
            }
          })
          .catch((err) => {
            if (err) {
              Notification("error", "Error", "Failed to connect to SalarioPay");
            }
          });
      },

      onCancel() {},
    });
  };

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `employerId=${sessionStorage.getItem(
      "employer_id"
    )}&taxId=${taxId}&page=${page - 1}&size=${pageSize}`;
    getPaged(queryString);
  };

  const getPaged = (queryString) => {
    axios
      .get(`${config.baseUrl}/v1/tax-details/paged?${queryString}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setTotalItems(res.data.totalPages * 10);
            setNoTaxDetails(false);
            setLoadTaxDetails(false);
            setTaxDetailsData(res.data.result);
          } else {
            setNoTaxDetails(true);
            setLoadTaxDetails(false);
            setTaxDetailsData([]);
          }
        } else {
          setNoTaxDetails(true);
          setLoadTaxDetails(false);
          setTaxDetailsData([]);
        }
      })
      .catch((err) => {
        setNoTaxDetails(true);
        if (err) {
        }
      });
  };

  return (
    <Layout>
      <div>
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="card table-widget">
              <div class="card-body">
                <h5 class="card-title">Tax Details </h5>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Employee Code</th>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Tin</th>
                        <th scope="col">Tax State</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Fee</th>
                        <th scope="col">Date</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {taxDetailsData.map((data) => {
                        return (
                          <tr>
                            <td>{data.employeeCode}</td>
                            <td>{data.firstName}</td>
                            <td>{data.lastName}</td>

                            <td>{data.tin}</td>
                            <td>{data.tinState}</td>
                            <td>
                              {data.currency} {data.amount}
                            </td>
                            <td>
                              {data.currency} {data.fee}
                            </td>

                            <td>
                              {data.createdAt
                                ? data.createdAt.slice(0, 10)
                                : ""}
                            </td>
                            <td>
                              <span
                                className={
                                  {
                                    SUCCESS: "badge bg-success",
                                    FAILED: "badge bg-danger",
                                    NEW: "badge bg-info",
                                    PENDING: "badge bg-warning",
                                    PROCESS: "badge bg-warning",
                                    TIMEOUT: "badge bg-danger",
                                  }[data.status]
                                }
                              >
                                {data.status}
                              </span>
                            </td>
                            <td>
                              <Button
                                onClick={showDraw.bind(this, data)}
                                type="primary"
                                danger
                                style={{
                                  background: "#1FC157",
                                  color: "white",
                                  cursor: "pointer",
                                }}
                              >
                                Actions
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                {loadTaxDetails && (
                  <div className="text-center pt-5 pb-5">
                    <Spin />
                  </div>
                )}
                {noTaxDetails && (
                  <p className="text-center pt-5 pb-5">No Tax Details.</p>
                )}
                {taxDetailsData?.length > 0 &&
                <div className="text-center mt-5 mb-5">
                  <Pagination
                    current={page}
                    total={totalItems}
                    defaultPageSize={10}
                    onChange={pagination}
                    itemRender={itemRender}
                  />{" "}
                </div>}
              </div>
            </div>
          </div>
        </div>

        <Drawer
          title="Actions"
          placement="right"
          visible={visible}
          onClose={closeDraw}
        >
          <div
            style={{ color: "#000000", cursor: "pointer" }}
            onClick={retryTax}
          >
            Retry 
          </div>
          <hr />
          <div
            style={{ color: "#000000", cursor: "pointer" }}
            onClick={syncTax}
          >
            Sync Detail
          </div>
          <hr />
          <div style={{ color: "red", cursor: "pointer" }} onClick={deteteTax}>
            {" "}
            Delete 
          </div>
          <hr />
        </Drawer>
      </div>
    </Layout>
  );
}
