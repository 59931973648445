import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Layout from "../../../containers/Layout";
import ReactDatatable from "@ashvin27/react-datatable";
import { Pagination, Spin, Modal } from "antd";

import axios from "axios";
import config from "../../../config";
export default function Employers(props) {
  const [loadcategory, setLoadcategory] = useState(true);
  const [categoryData, setcategoryData] = useState(false);
  const [nocategory, setNocategory] = useState(false);
  const [categories, setcategories] = useState([]);
  const [serverError, setServerError] = useState([]);

  const getCats = () => {
    const code = props.location.state.code ? props.location.state.code : "";
    axios
      .get(
        `${config.baseUrl}/v1/vendors/category?categoryCode=${code}&page=0&size=1
      `,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setLoadcategory(false);
            setcategoryData(true);
            setNocategory(false);
          } else {
            setLoadcategory(false);
            setcategoryData(false);
            setNocategory(true);
          }
          setcategories(res.data.result);
        } else {
          setLoadcategory(false);
          setcategoryData(false);
          setNocategory(true);
        }
      })
      .catch((err) => {
        if (err) {
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 2000);
        }
      });
  };

  useEffect(() => {
    getCats();
  }, []);

  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div className="card-header">Merchants</div>
            <div class="card-body mb-0 pb-0">
              {categoryData && (
                <div class="row">
                  {categories.map((category) => {
                    return (
                      <div
                        class="col-sm-6 col-md-3"
                        style={{ cursor: "pointer" }}
                      >
                        <Link
                          to={{
                            pathname: "/vendor-information",
                            state: { id: category.id },
                          }}
                        >
                          <div class="card card-file-manager">
                            <div class="card-file-header text-danger">
                              <img src={category.logoUrl} className="w-50" />
                            </div>
                            <div class="card-body mb-0 pb-4 pt-4">
                              <h6 class="card-subtitle text-center text-muted">
                                {category.businessName}
                              </h6>{" "}
                              <br />
                              
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}

                  <div className="d-flex justify-content-center align-items-end pb-5">
                    <Pagination defaultCurrent={1} total={50} />
                  </div>
                </div>
              )}

              {nocategory && (
                <div className="row">
                  <div className="text-center pb-5">
                    <p className="text-center">
                      Merchants Not Available 
                    </p>
                    <hr />
                  </div>
                </div>
              )}
              {loadcategory && (
                <div className="row">
                  <div className="text-center pb-5">
                    <Spin />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal okText="Submit" footer={false} maskClosable={false}></Modal>
    </Layout>
  );
}
