import React, { Fragment, useEffect, useState } from "react";
import Layout from "../../../containers/Layout";
import { Alert, Spin, Button, Drawer, Modal } from "antd";
import { useParams } from "react-router-dom";
import ReactDatatable from "@ashvin27/react-datatable";
import axios from "axios";
import config from "../../../config";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const RolePrivilege = () => {
  const [loading, setLoading] = useState(true);
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [rolePrivilegesData, setRolePrivileges] = useState([]);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deletedMsg, setDeletedMsg] = useState("");
  const [roleFor, setRoleFor] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [error, setError] = useState(false);
  const [errorAddRolePrivilege, seErrorAddRolePrivilege] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [loadingAddRole, setLoadingAddRole] = useState(false);
  const [loadingDeleteRole, setLoadingDeleteRole] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [addPrivilegesFlag, setAddPrivilegesFlag] = useState(false);
  const [privilegeData, setPrivilegeData] = useState([]);
  const [formData, setFormData] = useState({
    privilege: "",
  });

  const handleChange = (field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  const params = useParams();

  const { id } = params;

  const showDrawer = (record, e) => {
    e.preventDefault();
    console.log(record);
    setVisibleDraw(true);
    setFormData((prevState) => ({
      ...prevState,
      privilege: record.id,
    }));
  };

  const closeDrawer = () => {
    setVisibleDraw(false);
    setFormData((prevState) => ({
      ...prevState,
      privilege: "",
    }));
  };

  const closeAdd = () => {
    setAddPrivilegesFlag(false);
    setFormData((prevState) => ({
      ...prevState,
      privilege: "",
    }));
  };

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    button: {
      excel: true,
      print: true,
      extra: true,
    },
  };

  const columns = [
    {
      key: "name",
      text: "Name",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <Button
              type="primary"
              danger
              style={{
                background: "#1FC157",
                color: "white",
                cursor: "pointer",
              }}
              onClick={showDrawer.bind(this, record)}>
              Actions
            </Button>
          </Fragment>
        );
      },
    },
  ];

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];

  const getRolePrivilegeData = () => {
    axios
      .get(`${config.baseUrl}/v1/roles/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setRoleFor(res.data.result.name);
          setRolePrivileges(
            res.data.result.privileges.map((item) => ({
              id: item.id,
              name: item.name,
              status: item.status ? "Enabled" : "Disabled",
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err) {
        }
      });
  };

  const getListPrivilege = () => {
    axios
      .get(`${config.baseUrl}/v1/privileges`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log("Dennis ", res);
        if (res.data.status) {
          setPrivilegeData(
            res.data.result.map((data) => ({
              id: data.id,
              name: data.name,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const addRolePrivilege = (e) => {
    e.preventDefault();
    if (formData.privilege === "") {
      setFieldError(true);
      setTimeout(() => {
        setFieldError(false);
      }, 1500);

      return;
    }
    setLoadingAddRole(true);
    axios
      .put(
        `${config.baseUrl}/v1/roles/privilege/add?privilegeId=${formData.privilege}&roleId=${id} `,
        null,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadingAddRole(false);
        if (res.data.status) {
          closeAdd();
          setSuccess(true);
          setSuccessMsg(res.data.message);
          getRolePrivilegeData();
          setTimeout(() => {
            setSuccess(false);
          }, 2000);
        } else {
          seErrorAddRolePrivilege(true);
          setErrorMsg(res.data.message);
          setVisibleDraw(false);
          setTimeout(() => {
            seErrorAddRolePrivilege(false);
          }, 2500);
        }
      })
      .catch((err) => {
        if (err) {
          setVisibleDraw(false);
          setLoadingAddRole(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 2000);
        }
      });
  };

  const addPrivilege = () => {
    setAddPrivilegesFlag(true);
  };

  const deleteRolePrivilege = () => {
    setLoadingDeleteRole(true);
    axios
      .put(
        `${config.baseUrl}/v1/roles/privilege/remove?privilegeId=${formData.privilege}&roleId=${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadingDeleteRole(false);
        setVisibleDraw(false);

        if (res.data.status) {
          closeAdd();
          setDeleteSuccess(true);
          getRolePrivilegeData();
          setDeletedMsg(res.data.message);
          setTimeout(() => {
            setDeleteSuccess(false);
          }, 2000);
        } else {
          setError(true);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        if (err) {
          setVisibleDraw(false);
          setLoadingDeleteRole(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 2000);
        }
      });
  };

  const showDeleteConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure delete this privilege?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteRolePrivilege();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const privilegeOption = privilegeData.map((item) => {
    return <option value={item.id}>{item.name}</option>;
  });

  useEffect(() => {
    getRolePrivilegeData();
    getListPrivilege();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title mb-5">
                {`${
                  roleFor ? `Role Privileges For ${roleFor}` : "Role Privileges"
                }`}
              </h5>
              <div className="d-flex justify-content-end align-items-center">
                <button
                  className="btn btn-info m-b-xs mr-2"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={addPrivilege}>
                  Add Privilege
                </button>
              </div>
              <div className="pt-0">
                {loading ? (
                  <div class="d-flex justify-content-center align-items-center">
                    <Spin />
                  </div>
                ) : (
                  <div>
                    <div className="text-center">
                      {deleteSuccess && (
                        <div className="pb-4">
                          <Alert message={deletedMsg} type="success" showIcon />
                        </div>
                      )}
                      {success && (
                        <div className="pb-4">
                          <Alert message={successMsg} type="success" showIcon />
                        </div>
                      )}
                      {error && (
                        <div className="pb-4">
                          <Alert message={errorMsg} type="error" showIcon />
                        </div>
                      )}
                      {serverError && (
                        <p className="text-center text-danger font-weight-bold">
                          <Alert
                            message={"Failed to connect to SalarioPay"}
                            type="error"
                            showIcon
                          />
                        </p>
                      )}
                    </div>
                    <ReactDatatable
                      config={configTable}
                      records={rolePrivilegesData}
                      columns={columns}
                      extraButtons={extraButtons}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}>
        {loadingDeleteRole ? (
          <div class="d-flex justify-content-start align-items-center">
            <Spin />
          </div>
        ) : (
          <div
            style={{ color: "red", cursor: "pointer" }}
            onClick={showDeleteConfirm}>
            Remove Privileges
          </div>
        )}
      </Drawer>
      <Modal
        title={"Add Privilege"}
        visible={addPrivilegesFlag}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeAdd}>
        <form>
          {loadingAddRole && (
            <p className="text-center">
              <Spin />
            </p>
          )}
          {success && (
            <div className="pb-3">
              <Alert message={successMsg} type="success" showIcon />
            </div>
          )}
          {errorAddRolePrivilege && (
            <div className="pb-3">
              <Alert message={errorMsg} type="error" showIcon />
            </div>
          )}
          {fieldError && (
            <div className="pb-3">
              <Alert message="All fields are required!" type="error" showIcon />
            </div>
          )}
          {serverError && (
            <p className="text-center text-danger font-weight-bold">
              Failed to connect to SalarioPay
            </p>
          )}
          <div>
            <div class="mb-3">
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => handleChange("privilege", e.target.value)}
                value={formData.privilege}>
                <option selected>Privilege</option>
                {privilegeOption}
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={addRolePrivilege}>
              Submit
            </button>
          </div>
        </form>
      </Modal>
    </Layout>
  );
};

export default RolePrivilege;
