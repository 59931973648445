import {
    ERROR,
    EMPLOYEE_PAYMENT_HISTORY, SUCCESS,
    LOADING,
  } from "../../../action/types";
  
  const initState = {
    loadingPaymentHistory: false,
    data: [],
    message: ""
  };
  
  export function paymentHistoryReducer(state = initState, action) {
    switch (action.type) {
      case LOADING:
        return {
          ...state,
          loadingPaymentHistory: true,
        };
      case EMPLOYEE_PAYMENT_HISTORY:
        return {
          ...state,
          loadingPaymentHistory: false,
          data: action.payload,
        };
    //   case VERIFICATION_DETAILS:
    //     return {
    //       ...state,
    //       loadingKyc: false,
    //       details: action.payload,
    //     };
      case ERROR:
        return {
          ...state,
          loadingPaymentHistory: false,
          error: action.payload,
        };
      default:
        return state;
    }
  }

  export default paymentHistoryReducer
  