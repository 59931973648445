import { useState } from "react";
import NumberFormat from "react-number-format";

export const NumberFormatter = ({
  onChange,
  value,
  name,
  label,
  error,
  style,
  defaultValue,
  prefix,
  ...rest
}) => {
  const [currency, setCurrency] = useState(value / 100);
  return (
    <>
      <label className="d-block text-dark font-weight-normal" htmlFor={name}>
        {label}
      </label>

      <NumberFormat
        {...rest}
        value={currency}
        defaultValue={defaultValue}
        thousandSeparator={true}
        decimalScale={2}
        onValueChange={(target) => {
          setCurrency(target.floatValue);
          onChange(target.floatValue);
        }}
        isNumericString
        prefix={prefix || ""}
        style={
          error
            ? {
                ...style,
                borderColor: "#E4E6EF",
                borderRadius: "10px",
                padding: ".65rem 1rem",
                width: "100%",
              }
            : {
                ...style,
              }
        }
      />
      {error && <p className="validate-error ">{error}</p>}
    </>
  );
};
