import React, { Fragment, useEffect, useState } from "react";
import Layout from "../../../containers/Layout";
import {
  Spin,
  Alert,
  Button,
  Modal,
  notification,
  Pagination,
  Select,
  Drawer,
} from "antd";
import Cookie, { set } from "js-cookie";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Tab from "../../tab";
import axios from "axios";
import config from "../../../config";
import { useHttp } from "../../../hooks/useHttps";
const { Option } = Select;
export default function Employers() {
  const [paymentStructure, setPaymentMethods] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [payCategory, setPayCategory] = useState("");
  const [payCal, setPayCal] = useState("");
  const [payFormular, setPayFormular] = useState("");
  const [payType, setPayType] = useState("");
  const [country, setCountry] = useState("");
  const [employerCountries, setEmployerCountries] = useState([]);
  const [payValue, setPayValue] = useState("");
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [id, setID] = useState({});
  const [updateSwitch, setUpdateSwitch] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  //paginations
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [load, setLoad] = useState(false);
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [rowId, setRowID] = useState("");
  const [paystructureStatus, setPaystrucutreStatus] = useState("");

  const [loadPayCal, payCalDatas] = useHttp(
    `${config.baseUrl}/v1/options/VALUE_TYPE`,
    []
  );
  const [loadPayType, payTypeDatas] = useHttp(
    `${config.baseUrl}/v1/options/PAY_TYPE`,
    []
  );

  const [loadGroupName, groupNameDatas] = useHttp(
    `${config.baseUrl}/v1/pay-structures/payGroup/${sessionStorage.getItem(
      "employer_id"
    )}`,
    []
  );

  const showDrawer = (record, e) => {
    e.preventDefault();
    setRowID(record.id);
    setPaystrucutreStatus(record.status);
    setVisibleDraw(true);
    setPayCal(record.payCal);
    setPayType(record.payType);
    setPayCategory(record?.payCategory);
    setCountry(record?.country);
    setPayFormular(record.payFormular);
    setPayValue(record.payValue);
    setUpdateFlag(true);
  };
  const closeDrawer = () => {
    setVisibleDraw(false);
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  const getPaystructure = (country) => {
    setLoad(true);
    axios
      .get(
        `${
          config.baseUrl
        }/v1/pay-structures/paged?&employerId=${sessionStorage.getItem(
          "employer_id"
        )}&page=0&size=10&country=${country}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setTotalItems(res.data.totalPages * 10);
          var resp = res.data.result;
          setPaymentMethods(
            resp.map((data) => ({
              createdAt: data?.createdAt,
              id: data.id,
              country: data?.country,
              employerId: data.employerId,
              groupName: data.groupName,
              payType: data.payType,
              payValue: data.payValue,
              payCal: data.payCal,
              payFormular: data.payFormular,
              status: data?.status,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
        }
      });
  };

  const openUpdateModal = () => {
    setOpenModal(true);
    setUpdateSwitch(true);
    closeDrawer();
  };

  const closeModal = () => {
    setOpenModal(false);
    setUpdateFlag(false);
    setPayCal("");
    setPayType("");
    setPayValue("");
    setPayCategory("");
    setCountry("");
  };

  const updatePaystructure = (e) => {
    e.preventDefault();

    const data = {
      employerId: sessionStorage.getItem("employer_id"),
      gpayGroupId: sessionStorage.getItem("paygroup_id"),
      country,
      payType,
      category: payCategory,
      payValue,
    };
    setLoad(true);
    axios
      .put(`${config.baseUrl}/v1/pay-structures/${rowId}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getPaystructure(employerCountries[0].code);
          closeModal();
          setSuccessMsg(res.data.message);
          setVisibleDraw(false);
        } else {
          Notification("error", "Error", res.data.message);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };

  const getCountriesInfo = () => {
    axios
      .get(
        `${config.baseUrl}/v1/employers/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const countryInfo = res.data.result.countries;
          setEmployerCountries(countryInfo);
          getPaystructure(countryInfo[0].code);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const deleteConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure you want to delete this pay structure detail ? `,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deletePayStructure();
      },
      onCancel() {},
    });
  };

  const statusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure you want to change status of this pay structure  ? `,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        statusPayStructure();
      },
      onCancel() {},
    });
  };

  const deletePayStructure = (id) => {
    setLoad(true);
    axios
      .delete(`${config.baseUrl}/v1/pay-structures/${rowId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        setVisibleDraw(false)
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getPaystructure(employerCountries[0].code);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
          Notification("error", "Error", "Failed to connect to SalarioPay");
        }
      });
  };

  const statusPayStructure = (id) => {
    setLoad(true);
    axios
      .put(
        `${config.baseUrl}/v1/pay-structures/status/${rowId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        setVisibleDraw(false)
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getPaystructure(employerCountries[0].code);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
          Notification("error", "Error", "Failed to connect to SalarioPay");
        }
      });
  };

  const createPaystructure = () => {
    setOpenModal(true);
    setUpdateSwitch(false);
    setPayCal("");
    setPayType("");
    setPayValue("");
  };

  useEffect(() => {
    getCountriesInfo();
  }, []);

  const addPaystructure = (e) => {
    e.preventDefault();

    if (payValue <= 0) {
      Notification("error", "Error", "Pay value cannot be 0 or less than 0");
      setErrorMsg("");
      setTimeout(() => {
        setError(false);
        setErrorMsg("");
      }, 2500);
      return null;
    }

    const data = {
      employerId: sessionStorage.getItem("employer_id"),
      payGroupId: sessionStorage.getItem("paygroup_id"),
      payType,
      payValue,
      category: payCategory,
      country,
    };

    if (data.payType && data.payValue && country && payCategory) {
      setLoad(true);
      axios
        .post(`${config.baseUrl}/v1/pay-structures`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setPayValue("");
            setPayFormular("");
            setPayType("");
            setPayCal("");
            setGroupName("");
            Notification("success", "Success", res.data.message);
            closeModal();
            getPaystructure(employerCountries[0].code);
          } else {
            Notification("success", "Success", res.data.message);
            setErrorMsg(res.data.message);
            setTimeout(() => {
              setError(false);
            }, 2500);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setFieldError(true);
      setTimeout(() => {
        setFieldError(false);
      }, 1500);
    }
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const getPaged = (queryString) => {
    axios
      .get(`${config.baseUrl}/v1/pay-structures/paged?${queryString}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setTotalItems(res.data.totalPages * 10);
          var resp = res.data.result;
          setPaymentMethods(
            resp.map((data) => ({
              createdAt: data?.createdAt,
              id: data.id,
              employerId: data.employerId,
              groupName: data.groupName,
              payType: data.payType,
              payValue: data.payValue,
              payCal: data.payCal,
              payFormular: data.payFormular,
              status: data?.status ? "Enabled" : "Disabled",
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `page=${page - 1}&groupId=${sessionStorage.getItem(
      "paygroup_id"
    )}&employerId=${sessionStorage.getItem("employer_id")}&size=${pageSize}`;
    getPaged(queryString);
  };

  var allowancePercentageTotal = paymentStructure?.filter((d) =>
    d?.payType?.includes("ALLOWANCE")
  );
  var deductionPercentageTotal = paymentStructure?.filter((d) =>
    d?.payType?.includes("DEDUCTION")
  );

  var computedPercentageTotalAllowance = allowancePercentageTotal.reduce(
    (n, { payValue }) => n + Number(payValue),
    0
  );

  var computedPercentageTotalDeduction = deductionPercentageTotal.reduce(
    (n, { payValue }) => n + Number(payValue),
    0
  );

  return (
    <Layout>
      <Tab />
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="card">
            <div class="">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-top">
                  <div>
                    <h5 class="card-title px-3">
                      Manage Pay Structures{" "}
                      {employerCountries.length > 0 && (
                        <Select
                          defaultValue={employerCountries[0].code}
                          style={{ width: 100 }}
                          onChange={(val) => getPaystructure(val)}
                          value={employerCountries[0].code}
                        >
                          {employerCountries.map((country) => {
                            return (
                              <Option key={country.code} value={country.code}>
                                {country.code}
                              </Option>
                            );
                          })}
                        </Select>
                      )}
                    </h5>
                  </div>
                  <div></div>
                </div>
                <div style={{ padding: "30px" }}>
                  <button
                    className="btn btn-info m-b-xs "
                    style={{ background: "#1FC157", color: "white" }}
                    onClick={createPaystructure}
                  >
                    Add Pay Structure
                  </button>{" "}
                </div>
              </div>

              {load && (
                <p className="text-center py-5">
                  <Spin />
                </p>
              )}
              {!load && (
                <div className="container">
                  {paymentStructure?.length > 0 ? (
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Pay Type</th>
                            <th scope="col">Percentage</th>
                            <th scope="col">Date</th>
                            <th scope="col">Status</th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paymentStructure.map((data) => {
                            return (
                              <tr>
                                <td>
                                  {data.payType
                                    ? data.payType.replace(/_+/g, " ")
                                    : ""}
                                </td>

                                <td>{data.payValue}%</td>
                                <td>
                                  {data.createdAt
                                    ? data.createdAt.slice(0, 10)
                                    : ""}
                                </td>
                                <td>
                                  <span
                                    className={
                                      {
                                        Enabled: "badge bg-success",
                                        Disabled: "badge bg-danger",
                                        ACTIVE: "badge bg-success",
                                        COMPLETED: "badge bg-success",
                                        SUCCESS: "badge bg-success",
                                        FAILED: "badge bg-danger",
                                        IDLE: "badge bg-info",
                                        PENDING: "badge bg-warning",
                                        PROCESSING: "badge bg-warning",
                                        INACTIVE: "badge bg-danger",
                                      }[data.status ? "ACTIVE" : "INACTIVE"]
                                    }
                                  >
                                    {data.status ? "ACTIVE" : "INACTIVE"}
                                  </span>
                                </td>
                                <td>
                                  <button
                                    type="submit"
                                    onClick={showDrawer.bind(this, data)}
                                    className="btn btn-primary"
                                    style={{
                                      background: "#1FC157",
                                      color: "white",
                                    }}
                                  >
                                    Action
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                          {paymentStructure?.length > 0 && (
                            <>
                              <td></td>
                              <td>
                                <div className="">
                                  <div style={{ fontWeight: 600 }}>
                                    ALLOWANCE:{" "}
                                    {computedPercentageTotalAllowance}%
                                  </div>
                                </div>
                                <div className="pt-2">
                                  <div style={{ fontWeight: 600 }}>
                                    DEDUCTION:{" "}
                                    {computedPercentageTotalDeduction}%{" "}
                                  </div>
                                </div>
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div>
                      <p className="text-center pt-5 pb-5">
                        No Pay Structure Record.
                      </p>
                    </div>
                  )}

                  <div className="text-center mt-5 mb-5">
                    <Pagination
                      current={page}
                      total={totalItems}
                      defaultPageSize={10}
                      onChange={pagination}
                      itemRender={itemRender}
                    />{" "}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={updateSwitch ? "Update Pay Structure " : "Add Pay Structure "}
        visible={openModal}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeModal}
      >
        <form>
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}

          {fieldError && (
            <div className="pb-3">
              <Alert message="All fields are required!" type="error" showIcon />
            </div>
          )}
          {serverError && (
            <p className="text-center text-danger font-weight-bold">
              Failed to connect to SalarioPay
            </p>
          )}

          {updateSwitch ? (
            <>
              <div>
                <div>
                  <div className="mb-3">
                    <label>Pay Type</label>
                    <select
                      onChange={(e) => setPayType(e.target.value)}
                      class="form-select"
                      aria-label="Default select example"
                      disabled={updateFlag ? true : false}
                      value={payType}
                    >
                      <option selected>Select Pay Type</option>
                      {payTypeDatas.map((element) => {
                        return (
                          <option value={element.code}>
                            {" "}
                            {element.value
                              ? element.value.replace(/_+/g, " ")
                              : ""}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="mb-3">
                    <label>Pay Category</label>
                    <select
                      onChange={(e) => setPayCategory(e.target.value)}
                      class="form-select"
                      aria-label="Default select example"
                      value={payCategory}
                    >
                      <option selected>Select Pay Category </option>
                      <option selected>ALLOWANCE </option>
                      <option selected>DEDUCTION </option>
                    </select>
                  </div>

                  <div>
                    <div className="mb-3">
                      <label>Percentage</label>
                      <div className="">
                        <input
                          type="number"
                          className="form-control"
                          id="floatingInput"
                          placeholder=""
                          onChange={(e) => setPayValue(e.target.value)}
                          value={payValue}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label>Country </label>
                    <select
                      onChange={(e) => setCountry(e.target.value)}
                      class="form-select"
                      aria-label="Default select example"
                      value={country}
                    >
                      <option selected>Select Country </option>
                      {employerCountries.map((element) => {
                        return (
                          <option value={element.code}>{element.name}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div>
              <div>
                <div className="mb-3">
                  <label>Pay Type</label>

                  <select
                    onChange={(e) => setPayType(e.target.value)}
                    class="form-select"
                    aria-label="Default select example"
                    value={payType}
                  >
                    <option selected>Select Pay Type</option>
                    {payTypeDatas.map((element) => {
                      return (
                        <option value={element.code}>
                          {" "}
                          {element.value
                            ? element.value.replace(/_+/g, " ")
                            : ""}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="mb-3">
                  <label>Pay Category</label>
                  <select
                    onChange={(e) => setPayCategory(e.target.value)}
                    class="form-select"
                    aria-label="Default select example"
                    value={payCategory}
                  >
                    <option selected>Select Pay Category </option>
                    <option selected>ALLOWANCE </option>
                    <option selected>DEDUCTION </option>
                  </select>
                </div>

                <div>
                  <div className="mb-3">
                    <label>Percentage</label>
                    <div className="">
                      <input
                        type="number"
                        className="form-control"
                        id="floatingInput"
                        placeholder=""
                        onChange={(e) => setPayValue(e.target.value)}
                        value={payValue}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label>Country </label>
                  <select
                    onChange={(e) => setCountry(e.target.value)}
                    class="form-select"
                    aria-label="Default select example"
                    value={country}
                  >
                    <option selected>Select Country </option>
                    {employerCountries.map((element) => {
                      return (
                        <option value={element.code}>{element.name}</option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          )}

          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={updateSwitch ? updatePaystructure : addPaystructure}
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>

      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}
      >
        <div
          style={{ color: "#000000", cursor: "pointer" }}
          onClick={openUpdateModal}
        >
          Update
        </div>

        <hr />
        <div
          style={{ color: "#000000", cursor: "pointer" }}
          onClick={deleteConfirm}
        >
          Delete
        </div>

        <hr />

        <div
          style={{ color: "#000000", cursor: "pointer" }}
          onClick={statusConfirm}
        >
          {paystructureStatus ? "Disable" : "Enable"}
        </div>

        <hr />
      </Drawer>
    </Layout>
  );
}
