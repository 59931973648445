import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Layout from "../../../../containers/Layout";
import ReactDatatable from "@ashvin27/react-datatable";
import {
  Drawer,
  Button,
  Spin,
  Alert,
  Pagination,
  Modal,
  notification,
  DatePicker,
} from "antd";
import axios from "axios";
import config from "../../../../config";
import Tab from "./tab";
export default function Taxes(props) {
  const [taxesData, setTaxesData] = useState([]);
  const [load, setLoad] = useState(false);
  const [noTaxes, setNoTaxes] = useState(false);
  const [loadTaxes, setLoadTaxes] = useState(true);
  const employeeId = props.history.location.state
    ? props.history.location.state
    : "";
  const [taxSlipModal, setTaxSlipModal] = useState(false);
  const [loadPdf, setLoadPdf] = useState(false);
  const [startPeriod, setStartPeriod] = useState("");
  const [endPeriod, setEndPeriod] = useState("");

  const closeTaxSlipModal = () => {
    setTaxSlipModal(false);
  };

  function onChangeDateStart(date, dateString) {
    setStartPeriod(dateString);
  }

  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const getPaged = (queryString) => {
    axios
      .get(
        `${config.baseUrl}/v1/tax-details/employee/paged?employeeId=${employeeId}${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const transactionsData = res.data.result;
          if (transactionsData.length > 0) {
            setTaxesData(transactionsData);
            setLoadTaxes(false);
            setNoTaxes(false);
          } else {
            setLoadTaxes(false);
            setNoTaxes(true);
          }
        } else {
          setLoadTaxes(false);
          setNoTaxes(true);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `page=${page - 1}&size=${pageSize}`;
    getPaged(queryString);
  };

  function onChangeDateEnd(date, dateString) {
    setEndPeriod(dateString);
  }

  const getTransactions = () => {
    axios
      .get(
        `${config.baseUrl}/v1/tax-details/employee/paged?employeeId=${employeeId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const transactionsData = res.data.result;
          if (transactionsData.length > 0) {
            setTaxesData(transactionsData);
            setLoadTaxes(false);
            setNoTaxes(false);
          } else {
            setLoadTaxes(false);
            setNoTaxes(true);
          }
        } else {
          setLoadTaxes(false);
          setNoTaxes(true);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const downLoadPdf = (e) => {
    e.preventDefault();
    if (startPeriod && endPeriod) {
      setLoadPdf(true);
      downloadEmployeeAllowances();
    }
  };

  const downloadEmployeeAllowances = () => {
    const data = {
      start: startPeriod + "T00:00:00.00Z",
      end: endPeriod + "T00:00:00.00Z",
    };
    fetch(
      `${config.baseUrl}/v1/tax-details/tax-slip/download/${employeeId}/${data.start}/${data.end}`,
      {
        headers: new Headers({
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        }),
      }
    ).then((response) => {
      setLoadPdf(false);
      const filename = "tax.pdf";
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      });
    });
  };

  useEffect(() => {
    getTransactions();
  }, []);

  return (
    <Layout>
      <Tab />
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="card table-widget">
            <div class="card-body">
              <div class=" d-flex justify-content-between align-items-center">
                <h5 class="card-title pt-2">Taxes </h5>
                {taxesData.length > 0 && (
                  <button
                    type="submit"
                    className="btn btn-info m-b-xs"
                    style={{ background: "#1FC157", color: "white" }}
                    onClick={(e) => setTaxSlipModal(true)}
                  >
                    Download Pdf
                  </button>
                )}
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Employee Code</th>
                      <th scope="col">First Name</th>
                      <th scope="col">Last Name</th>
                      <th scope="col">Tin</th>
                      <th scope="col">Tax State</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Fee</th>
                      <th scope="col">Date</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {taxesData.map((data) => {
                      return (
                        <>
                          <tr>
                            <td>{data.employeeCode}</td>
                            <td>{data.firstName}</td>
                            <td>{data.lastName}</td>

                            <td>{data.tin}</td>
                            <td>{data.tinState}</td>
                            <td>
                              {data.currency} {data.amount}
                            </td>
                            <td>
                              {data.currency} {data.fee}
                            </td>

                            <td>
                              {data.createdAt
                                ? data.createdAt.slice(0, 10)
                                : ""}
                            </td>
                            <td>
                              <span
                                className={
                                  {
                                    SUCCESS: "badge bg-success",
                                    FAILED: "badge bg-danger",
                                    NEW: "badge bg-info",
                                    PENDING: "badge bg-warning",
                                    PROCESS: "badge bg-warning",
                                    TIMEOUT: "badge bg-danger",
                                  }[data.status]
                                }
                              >
                                {data.status}
                              </span>
                            </td>
                          </tr>
                          <div className="text-center pagination-part pt-5">
                            <Pagination
                              current={page}
                              total={totalItems}
                              defaultPageSize={10}
                              onChange={pagination}
                              itemRender={itemRender}
                            />
                          </div>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              {noTaxes && (
                <div class="card table-widget">
                  <div class="card-body">
                    <p className="text-center pt-5 pb-5">No Taxes Yet</p>
                  </div>
                </div>
              )}
              {loadTaxes && (
                <div className="text-center pt-5 pb-5">
                  <Spin />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={"Download Pension Slip"}
        visible={taxSlipModal}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeTaxSlipModal}
      >
        <div className="mb-3">
          <DatePicker
            onChange={onChangeDateStart}
            style={{
              width: "100%",
              height: "3.3rem",
              borderRadius: "10px",
            }}
            placeholder="Select Start Date"
          />
        </div>
        <div className="mb-3">
          <DatePicker
            onChange={onChangeDateEnd}
            style={{
              width: "100%",
              height: "3.3rem",
              borderRadius: "10px",
            }}
            placeholder="Select End Date"
          />
        </div>
        <br />
        <div className="d-flex flex justify-content-end align-items-center ">
          <button
            type="submit"
            className="btn btn-info m-b-xs"
            style={{ background: "#1FC157", color: "white" }}
            onClick={downLoadPdf}
          >
            {!loadPdf ? "Submit" : "Submitting"}
          </button>
        </div>
      </Modal>
    </Layout>
  );
}
