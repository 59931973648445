import React, { Fragment, useState, useEffect } from "react";
import { NumberFormatter } from "../../../containers/NumberFormat";
import Layout from "../../../containers/Layout";
import { Controller, useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import { Button, Space, Spin, Menu, Alert, message, Modal } from "antd";
import axios from "axios";
import config from "../../../config";
import { useHttp } from "../../../hooks/useHttps";
import Cookie from "js-cookie";
import Carousel from "react-grid-carousel";
const userInfo = Cookie.getJSON("salarioPayUserInfo")
  ? Cookie.getJSON("salarioPayUserInfo")
  : "";
export default function Employers() {
  const [selectedBiller, setSelected] = useState(false);
  const [select, setSelect] = useState(false);
  const [topupType, setTopupType] = useState("");
  const [employerInfo, setEmployerInfo] = useState({});
  const [otherNum, setOtherNum] = useState("");
  const [amount, setAmt] = useState("");
  const [pinModal, setPinModal] = useState(false);
  const [pin, setPin] = useState("");
  const [load, setLoad] = useState(false);
  const [fill, setFill] = useState(false);
  const [billerID, setBillerID] = useState("");
  const [interestRate, setLoanInterest] = useState("");
  const [paymentOption, setPaymentOption] = useState("");
  const [plans, setPlans] = useState([]);
  const [bundlePlan, setBundlePlan] = useState("");
  const [dataplans, setDataPlans] = useState("");
  const [loading, employers] = useHttp(
    `${config.baseUrl}/v1/employees/filtered/employer`,
    []
  );

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm({ mode: "onBlur" });

  const [loadBillers, setLoadBillers] = useState(true);
  const [billerDatas, setBillerDatas] = useState(false);
  const [noBillers, setNoBillers] = useState(false);

  const [employer, setEmployer] = useState("");
  const [billers, setBillers] = useState([]);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [fieldError, setFieldError] = useState(false);

  useEffect(() => {
    getBillers();
  }, []);

  const cancelPinSetup = () => {
    setPinModal(false);
    setPin("");
  };

  const handleChangePin = (pin) => setPin(pin);

  const bundlePlanChange = (e) => {
    setBundlePlan(e.target.value);
    const amtPlan = plans.find((v) => v.id === e.target.value);
    if (amtPlan) {
      setAmt(amtPlan.amount);
      getLoanInterest(amtPlan.amount);
    }
  };

  const getBundle = () => {
    const data = {
      amount,
      billerId: select,
      frequency: "string",
      mobile: otherNum,
      bundleId: bundlePlan,
      paymentOption,
      pin,
      productCode: "DATA_BUNDLE",
      recipient: topupType,
      employeeId: employer,
      platform: "WEB",
    };
    setLoad(true);
    axios
      .post(`${config.baseUrl}/v1/vas-payment/data-bundle`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setSuccessMsg(res.data.message);
          setSuccess(true);
          setAmt("");
          setTopupType("");
          setOtherNum("");
          setPaymentOption("");
          setTimeout(() => {
            setSuccess(false);
            setPinModal(false);
            setPin("");
          }, 2500);
        } else {
          setErrorMsg(res.data.message);
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 2000);
        }
      });
  };

  const getLoanInterest = (amt) => {
    axios
      .get(`${config.baseUrl}/v1/loans/interest/DATA_BUNDLE/${amt}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setLoanInterest(res.data.result.interestRate);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 2000);
        }
      });
  };

  const selectBiller = (code, billerID) => {
    setSelected(billerID);
    setSelect(billerID);
    setBillerID(billerID);
    getPlans(billerID);
  };

  const postPin = () => {
    // e.preventDefult();
    const data = {
      paymentOption: paymentOption,
      topupType: topupType,
      bundlePlan: bundlePlan,
      amount: amount,
    };
    if (paymentOption && topupType && bundlePlan && amount && employer) {
      setPinModal(true);
    } else {
      setFill(true);
      setTimeout(() => {
        setFill(false);
      }, 2500);
    }
  };

  const getBillers = () => {
    axios
      .get(
        `${config.baseUrl}/v1/biller/type/DATA_BUNDLE
      `,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setLoadBillers(false);
            setBillerDatas(true);
            setNoBillers(false);
          } else {
            setLoadBillers(false);
            setBillerDatas(false);
            setNoBillers(true);
          }
          setBillers(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 2000);
        }
      });
  };

  const getPlans = (id) => {
    axios
      .get(`${config.baseUrl}/v1/biller-plan/filtered/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setPlans(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 2000);
        }
      });
  };

  const getEmployerInfo = (e) => {
    const employerData = employers.find(
      (employer) => employer.id === e.target.value
    );
    setEmployerInfo(employerData);
    setEmployer(e.target.value);
  };
  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body mb-0 pb-0">
              <h5>Network Operators</h5>
              <br />
              {billerDatas && (
                 <Carousel cols={4} rows={1} gap={30} loop autoplay={3000}>
                 {billers.map((biller) => {
                   return (
                     <Carousel.Item>
                       <div
                         class="col-12"
                         style={{ cursor: "pointer" }}
                         onClick={selectBiller.bind(
                           this,
                           biller.code,
                           biller.id
                         )}
                       >
                         <div
                           class="card card-file-manager"
                           style={{
                             border:
                               selectedBiller === biller.id
                                 ? "1px solid #1FC157"
                                 : "",
                           }}
                         >
                           <div class="card-file-header text-danger">
                             <img src={biller.icon} className="w-25" />
                           </div>
                           <div class="card-body mb-0 pb-4 pt-4">
                             <h6 class="card-subtitle text-center text-muted">
                               {biller.name}
                             </h6>
                           </div>
                         </div>
                       </div>
                     </Carousel.Item>
                   );
                 })}
               </Carousel>
            
              )}{" "}
              {noBillers && (
                <div className="row">
                  <div className="text-center pb-5">
                    <p className="text-center">Biller Not Available Yet</p>
                    <hr />
                  </div>
                </div>
              )}
              {loadBillers && (
                <div className="row">
                  <div className="text-center pb-5">
                    <Spin />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {plans.length > 0 && (
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-body ">
                <h5>Data Plan Topup Details</h5>
                <br />
                {fill && (
                  <div className="pb-4">
                    <Alert
                      message="All fields are required"
                      type="error"
                      showIcon
                    />
                  </div>
                )}
                <form>
                  <div className="mb-3">
                    <div className="form-floating">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={getEmployerInfo}
                        value={employer}
                      >
                        <option selected>Select Employer</option>
                        {employers.map((employer) => {
                          return (
                            <option value={employer.id}>
                              {employer.employerResponse.businessName}
                            </option>
                          );
                        })}
                      </select>
                      <label for="floatingInput">Employer</label>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="form-floating">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={bundlePlanChange}
                        value={bundlePlan}
                      >
                        <option selected>Select Plan</option>
                        {plans.map((plan) => {
                          return <option value={plan.id}>{plan.name}</option>;
                        })}
                      </select>
                      <label for="floatingInput">Data Plan</label>
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="country name"
                        value={ amount && employerInfo ? employerInfo.salaryCurrency + " " + amount : 0}
                        disabled
                      />
                      <label for="floatingInput">Amount</label>
                    </div>
                  </div>

                  

                  <div className="mb-3">
                    <div className="form-floating">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setTopupType(e.target.value)}
                      >
                        <option selected>Select Recipient</option>
                        <option value="TOPUP_SELF">Topup Self</option>
                        <option value="TOPUP_OTHERS">Topup Others</option>
                      </select>
                      <label for="floatingInput">Recipient</label>
                    </div>
                  </div>

                  {topupType === "TOPUP_OTHERS" && (
                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="country name"
                          onChange={(e) => setOtherNum(e.target.value)}
                          value={otherNum}
                        />
                        <label for="floatingInput">Phone Number</label>
                      </div>
                    </div>
                  )}
                  {topupType === "TOPUP_SELF" && (
                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="country name"
                          onChange={(e) => setOtherNum(e.target.value)}
                          value={userInfo.mobile}
                          disabled
                        />
                        <label for="floatingInput">Phone Number</label>
                      </div>
                    </div>
                  )}

                  <div className="mb-3">
                    <div className="form-floating">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setPaymentOption(e.target.value)}
                      >
                        <option selected>Select Payment Option</option>
                        <option value="PAYDAY_LOAN">Payday Loan</option>
                        <option value="PAYDAY_SCHEDULE">Payday Schedule</option>
                      </select>
                      <label for="floatingInput">Payment Option</label>
                    </div>
                  </div>

                  {paymentOption === "PAYDAY_LOAN" && (
                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="country name"
                          value={employerInfo.salaryCurrency + ' ' + interestRate}
                          disabled
                        />
                        <label for="floatingInput">Interest Rate</label>
                      </div>
                    </div>
                  )}

                  <div className="d-flex justify-content-end">
                    <Button
                      type="submit"
                      class="btn btn-primary"
                      style={{ background: "#1FC157", color: "white" }}
                      onClick={postPin}
                    >
                      Continue
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        title="Input Pin"
        visible={pinModal}
        okText="Skip"
        footer={null}
        maskClosable={false}
        onCancel={cancelPinSetup}
      >
        <div className="container text-center">
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}

          {success && (
            <p
              className="text-center  font-weight-bold"
              style={{ color: "#1FC157" }}
            >
              {successMsg}
            </p>
          )}
          {error && (
            <p className="text-center text-danger font-weight-bold">
              {errorMsg}
            </p>
          )}

          {serverError && (
            <p className="text-center text-danger font-weight-bold">
              Failed to connect to SalarioPay
            </p>
          )}

          <div className="text-center">
            <div>
              <label className="text-left pt-4">Pin</label>
            </div>

            <div className="mt-4 d-flex justify-content-center align-items-center">
              <OtpInput
                value={pin}
                onChange={handleChangePin}
                isInputNum
                isInputSecure
                inputStyle={{
                  padding: "15px",
                  border: ".9px solid green",
                  width: "50px",
                }}
                numInputs={4}
                separator={<span>-</span>}
              />
            </div>
          </div>
          <br />
          <br />
          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={getBundle}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
}
