import React, { useEffect, useState } from "react";
import Layout from "../../../containers/Layout";
import ReactDatatable from "@ashvin27/react-datatable";
import axios from "axios";
import config from "../../../config";

const LoanManagement = () => {
  const [loanManagementData, setLoanManagementData] = useState([]);

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    button: {
      excel: true,
      print: true,
      extra: true,
    },
  };

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];

  const columns = [
    {
      key: "customerName",
      text: "Customer Name",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "currency",
      text: "Currency",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "productCode",
      text: "Product Code",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "interestAmount",
      text: "Interest Amount",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "interestRate",
      text: "Interest Rate",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "loanAmount",
      text: "Loan Amount",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "repaymentAmount",
      text: "Repayment Amount",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "date",
      text: "Date",
      className: "address",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    // {
    //   key: "action",
    //   text: "Action",
    //   className: "action",
    //   width: 100,
    //   align: "left",
    //   sortable: false,
    //   cell: (record) => {
    //     return (
    //       <Fragment>
    //         <Button
    //           type="primary"
    //           danger
    //           style={{
    //             background: "#1FC157",
    //             color: "white",
    //             cursor: "pointer",
    //           }}
    //           onClick={showDrawer.bind(this, record)}>
    //           Actions
    //         </Button>
    //       </Fragment>
    //     );
    //   },
    // },
  ];

  const options = { year: "numeric", month: "numeric", day: "numeric" };

  const getLoan = () => {
    axios
      .get(`${config.baseUrl}/v1/loans/page?page=0&size=500`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const loanManagementData = res.data.result;

          setLoanManagementData(
            loanManagementData.map((data) => ({
              id: data.id,
              customerName: data.customerName,
              currency: data.currency,
              productCode: data.productCode,
              interestAmount: data.interestAmount,
              interestRate: data.interestRate,
              loanAmount: data.loanAmount,
              repaymentAmount: data.repaymentAmount,
              status: data.status,
              // status: data.status ? "Enabled" : "Disabled",
              date: new Date(data.createdAt)
                .toLocaleDateString(undefined, options)
                .replace(/\//g, "-"),
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    getLoan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title mb-0">Loans</h5>
              <div className="pt-5" style={{ overflowX: "auto" }}>
                <ReactDatatable
                  config={configTable}
                  records={loanManagementData}
                  columns={columns}
                  extraButtons={extraButtons}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LoanManagement;
