import React, { useState, useEffect } from 'react'
import config from '../../../config'
import axios from 'axios'
import { Switch, Spin, Progress, Select } from 'antd'
import { Modal } from 'antd'
const { Option } = Select;


export default function Upgrade () {
  const [subscritions, setSubscriptions] = useState([])
  const [toggleSwitch, setToggleSwitch] = useState()
  const [subCheck, setSubCheck] = useState({})

  const [cycle, setCycle] = useState('')
  const [planID, setPlanID] = useState('')
  const [type, setType] = useState('')

  const [loadSubscriptions, setLoadSubscription] = useState(true)
  const [noSubscription, setNoSubscription] = useState(false)

  const [subscriptionUpgardeStatus, setSubscriptionUpgardeStatus] = useState(
    false
  )
  const [yearShow, setYearShow] = useState(false);
  const [monthShow, setMonthShow] = useState(true);
  const [existing, showExisting] = useState(false)
  const [subDetails, setSubDetails] = useState({});
  const [subType,setSubType] = useState("")
  const [subscriptionActivateModal, setActivationModal] = useState(false);

  const [employerWallets, setEmployerWallets] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [currencyType, setCurrencyType] = useState("");

  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [success, setSuccess] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [serverError, setServerError] = useState(false)
  const [serverErrorMsg, setServerErrorMsg] = useState('')

  const [load, setLoad] = useState(false)

  const onChange = checked => {
    setToggleSwitch(checked)
  }

  const cancelOnActivate = () => {
    setActivationModal(false);
  };

  const onChangeYearly = checked => {
    setToggleSwitch(checked)
  }

  function handleChange(currency) {
    setCurrencyType(currency);
    const currencySelctedInfo = currencies.find(
      (wallet) => wallet.currency === currency
    );
    console.log(currencySelctedInfo)
    setEmployerWallets([
      {
        currency: currencySelctedInfo.currency,
        walletId: currencySelctedInfo.id,
        

      },
    ]);
   
  }

  const getSubscriptionWallets = () => {
    axios
      .get(
        `${config.baseUrl}/v1/wallets/currencies/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setEmployerWallets(res.data.result);
            setCurrencies(res.data.result);
          } else {
          }
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getSubscriptions = () => {
    axios
      .get(`${config.baseUrl}/v1/subscription-plans/filtered`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
      })
      .then(res => {
        if (res.data.status) {
          const subscriptions = res.data.result
          if (subscriptions.length > 0) {
            setSubscriptions(subscriptions)
            setLoadSubscription(false)
            setNoSubscription(false)
          } else {
            setLoadSubscription(false)
            setNoSubscription(true)
          }
        } else {
          showExisting(true)
        }
      })
      .catch(err => {
        if (err) {
        }
      })
  }

  const upgradeSubscription = ( e) => {
    e.preventDefault()
    const data = {
      cycle: !toggleSwitch ? "MONTHLY" : "YEARLY",
      planId: subDetails.id,
      type: subType,
      employerId: sessionStorage.getItem("employer_id"),
      walletId: employerWallets.length > 0 ? employerWallets[0].id : employerWallets[0].walletId,
    }
    console.log(data)
    setLoad(true)
    axios
      .put(`${config.baseUrl}/v1/subscriptions/upgrade`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
      })
      .then(res => {
        setLoad(false)
        if (res.data.status) {
          setSuccess(true)
          setSuccessMsg(res.data.message)
          setSubscriptionUpgardeStatus(true)
          setActivationModal(false)
          showExisting(false)
          setError(false)
          // setTimeout(() => {
          //   setSuccess(false);
          // }, 2500);
        } else {
          setError(true)
          setSubscriptionUpgardeStatus(true)
          setSuccess(false)
          setErrorMsg(res.data.message)
          // setTimeout(() => {
          //   setError(false);
          // }, 2500);
        }
      })
      .catch(err => {
        if (err) {
          setLoad(false)
          setServerError(true)
          setTimeout(() => {
            setServerError(false)
          }, 1500)
        }
      })
  }

  const cancelSubcriptionUpgrade = () => setSubscriptionUpgardeStatus(false)

  const onActivateSubscription = (type, subscription) => {
    if (subscription) {
      setSubDetails(subscription);
      setSubType(type)
      setActivationModal(true);
    }
  };
  const showYearly = () => {
    setYearShow(true);
    setMonthShow(false);
  }

  const showMonthly = () => {
    setYearShow(false);
    setMonthShow(true);
  }
  useEffect(() => {
    getSubscriptions()
    getSubscriptionWallets()
  }, [])
  return (
    <div>
      <div class='row'>
        <div class='col'>
          <div class='card'>
            <div class='card-body'>
              {loadSubscriptions && (
                <div className='text-center pt-5 pb-5'>
                  <Spin />
                </div>
              )}

              {noSubscription && (
                <div className='text-center pt-5 pb-5'>No Subscriptions</div>
              )}

              {subscritions.length > 0 && (
                <>
                      <div class="d-flex justify-content-center align-items-center mb-5">
                    <div class="d-flex justify-content align-items-center">
                        {" "}

                        <button
                          type="button"
                          class="btn btn-primary"
                          style={{
                            background: monthShow ? "#1FC157" : "#f3f3f3",
                            color: monthShow ? "white" : "#000",
                          }}
                          onClick={showMonthly}
                        >
                          Monthly
                        </button>
                        {"  "}
                        <button
                          type="button"
                          class="btn btn-primary"
                          onClick={showYearly}
                          style={{
                            background: yearShow ? "#1FC157" : "#f3f3f3",
                            color: yearShow  ? "white" : "#000",
                          }}
                        >
                          Yearly
                        </button>
                      </div>
                    </div>
                  <br />

                  <div class='row'>
                    {subscritions.map(subscription => {
                      return (
                        <div class='col m-b-sm'>
                          <ul class='list-group io-pricing-table'>
                            <li class='list-group-item'>
                              <h4>{subscription.name}</h4>
                              <p>{subscription.size} employers</p>
                            </li>
                            {monthShow ? (
                              <li class='list-group-item'>
                                <h4>
                                  &#8358;{' '}
                                  {Intl.NumberFormat('en-US').format(
                                    subscription.monthlyPrice
                                  )}
                                </h4>
                                <span>per month</span>
                              </li>
                            ) : (
                              <li class='list-group-item'>
                                <h4>
                                  &#8358;
                                  {Intl.NumberFormat('en-US').format(
                                    subscription.yearlyPrice
                                  )}
                                </h4>
                                <span>per year</span>
                              </li>
                            )}
                            <li class='list-group-item'>Employee Onboarding</li>
                            <li class='list-group-item'>
                              Salary/Benefit payout
                            </li>
                            <li class='list-group-item'>VAS Payout</li>
                            <li class='list-group-item'>Health Insurance</li>
                            <li class='list-group-item'>
                              Pension Contribution
                            </li>
                            <li class='list-group-item'>NHF Remittance</li>
                            <li class='list-group-item'>Taxes Remittance </li>
                            <li class='list-group-item'>
                              Payroll Financing Loan
                            </li>

                            <li class='list-group-item d-flex justify-content-center align-items-center'>
                              <button
                                type='button'
                                class='btn btn-primary'
                                style={{
                                  background: '#1FC157',
                                  color: 'white',
                                  width: '55%'
                                }}
                                onClick={onActivateSubscription.bind(
                                  this,
                                  'PAID',
                                  subscription
                                )}
                              >
                                Upgrade
                              </button>
                            </li>
                          </ul>
                        </div>
                      )
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>


      <Modal
            title={"Upgrade Subscription"}
            visible={subscriptionActivateModal}
            okText="Submit"
            footer={false}
            maskClosable={false}
            onCancel={cancelOnActivate}
          >
            <div className="mb-3">
              <div>Subscription Name :</div>
              <div>{subDetails.name}</div>
            </div>
            <div className="mb-3">
              <div>Size :</div>
              <div>{subDetails.size}</div>
            </div>
            <div className="mb-3">
              <div>
                <label>Select Currency</label>
              </div>
              <div>
                {currencies.length > 0 && (
                  <Select
                    defaultValue={employerWallets[0].currency}
                    style={{ width: 100 }}
                    onChange={handleChange}
                    value={employerWallets[0].currency}
                  >
                    {currencies.map((currency) => {
                      return (
                        <Option key={currency.id} value={currency.currency} key={currency.id}>
                          {currency.currency}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-primary"
                style={{
                  background: "#1FC157",
                  color: "white",
                }}
                onClick={upgradeSubscription}
              >
                {load ?  <div class='spinner-border text-white' role='status'>
                        <span class='sr-only'>Updating...</span>
                      </div>: "Upgrade"}
              </button>
            </div>
          </Modal>

      <Modal
        title={'Subscription  Upgrade Status'}
        visible={subscriptionUpgardeStatus}
        okText='Submit'
        footer={false}
        maskClosable={false}
        onCancel={cancelSubcriptionUpgrade}
      >
        <div className='text-center'>
          {success && (
            <div>
              <Progress type='circle' percent={100} format={() => 'Success'} />
              <p
                className='pt-4'
                style={{
                  color: '#1FC157'
                }}
              >
                {successMsg}
              </p>
            </div>
          )}

          {error && (
            <div>
              <Progress
                type='circle'
                percent={100}
                format={() => 'Failed'}
                status='exception'
              />
              <p className='pt-4 text-danger'>{errorMsg}</p>
            </div>
          )}
        </div>
      </Modal>
    </div>
  )
}
