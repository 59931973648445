import React, { useState, useEffect } from "react";
import Tabs from "../tab";
import Layout from "../../containers/Layout";
import profileDefault from "../../img/profile-image.png";
import axios from "axios";
import { useHttp } from "../../hooks/useHttps";
import {
  Spin,
  Modal,
  notification,
  Checkbox,
  Button,
  Select,
  Space,
  Alert,
} from "antd";
import config from "../../config";
import { useImperativeHandle } from "react/cjs/react.production.min";
import { NavLink } from "react-router-dom";
const { Option } = Select;
export default function CompanyInfo(props) {
  //contact details
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [userId, setUserId] = useState("");

  //business info
  const [bisName, setBisName] = useState("");
  const [category, setCategory] = useState("");
  const [website, setWebsite] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [currency, setCurrency] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [address, setAddress] = useState("");
  const [rcnum, setRcNum] = useState("");
  const [contactInformation, setContactInfo] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [postCode, setPostCode] = useState([]);

  const [loadCurency, currenciesDatas] = useHttp(
    `${config.baseUrl}/v1/currencies/filtered`,
    []
  );

  const [loadVendors, vendorCategories] = useHttp(
    `${config.baseUrl}/v1/vendors/all-categories`,
    []
  );
  //logo
  const [logo, setLogo] = useState("");
  const [displayLogo, setDisplayLogo] = useState(null);

  //cac && tin
  const [cacDoc, setCacDoc] = useState("");

  //loaders
  const [loadDetails, setLoadDetails] = useState(false);
  const [loadBusiness, setLoadBusiness] = useState(false);
  const [loadUpload, setLoadUpload] = useState(false);
  const [loadUploadCac, setLoadUploadCac] = useState(false);

  useEffect(() => {
    getContactDetails();
    getCounties();
  }, []);

  const children = [];
  vendorCategories.map((category) => {
    children.push(
      <Option key={category.code}>
        <img src={category.icon} width="16" /> {category.name}
      </Option>
    );
  });

  function handleChange(value) {
    setCategory(value);
  }

  const getCounties = () => {
    axios
      .get(`${config.baseUrl}/v1/countries/filtered`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setCountryData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getContactDetails = () => {
    axios
      .get(`${config.baseUrl}/v1/vendors`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const contactDetails = res.data.result;
          setContactInfo(contactDetails);
          setFirstName(contactDetails.firstName);
          setLastName(contactDetails.lastName);
          setBisName(contactDetails.businessName);
          setCategory(contactDetails.category);
          setWebsite(contactDetails.website);
          setCountry(contactDetails.country);
          setState(contactDetails.state);
          setCity(contactDetails.city);
          setEmail(contactDetails.email);
          setMobile(contactDetails.phone);
          setAddress(contactDetails.address);
          setCurrency(contactDetails.currency);
          setRcNum(contactDetails.rcNumber);
          setDisplayLogo(contactDetails.logoUrl);
          setCategoryData(contactDetails.category);
          setUserId(contactDetails.userId);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const handleContactDetailsUpdate = (e) => {
    e.preventDefault();
    console.log(useImperativeHandle);
    const data = {
      address: null,
      businessName: null,
      cacUrl: null,
      category: null,
      city: null,
      country: null,
      currency: null,
      email,
      firstName,
      lastName,
      logoUrl: null,
      phone: mobile,
      postcode: null,
      rcNumber: null,
      state: null,
      userId: userId,
      website: null,
    };
    setLoadDetails(true);
    axios
      .put(`${config.baseUrl}/v1/vendors/contact-detail`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoadDetails(false);
        if (res.data.status) {
          const contactDetails = res.data.result;
          setFirstName(contactDetails.firstName);
          setLastName(contactDetails.lastName);
          setEmail(contactDetails.email);
          setMobile(contactDetails.mobile);
          Notification("success", "Success", res.data.message);
          getContactDetails();
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoadDetails(false);
        console.log(err);
      });
  };

  const handleBusinessDetailsUpdate = (e) => {
    console.log(category)
    e.preventDefault();
    const data = {
      address,
      businessName: bisName,
      city,
      country,
      firstName: null,
      id: props.userID,
      lastName: null,
      mobile: null,
      email: null,
      rcNumber: rcnum,
      state,
      phone: null,
      currency,
      category,
      userId,
      postcode: postCode,
      website,
    };
    setLoadBusiness(true);
    axios
      .put(`${config.baseUrl}/v1/vendors/business-detail`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoadBusiness(false);
        if (res.data.status) {
          const contactDetails = res.data.result;
          setBisName(contactDetails.businessName);
          setWebsite(contactDetails.website);
          setCountry(contactDetails.country);
          setState(contactDetails.state);
          setPostCode(contactDetails.postcode);
          setCity(contactDetails.city);
          setAddress(contactDetails.address);
          setRcNum(contactDetails.rcnum);
          Notification("success", "Success", res.data.message);
          getContactDetails();
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoadBusiness(false);
        console.log(err);
      });
  };

  const handleFileUpdate = (e) => {
    e.preventDefault();
    if (logo) {
      let data = new FormData();
      data.append("file", logo);
      setLoadUpload(true);
      axios
        .post(`${config.baseUrl}/v1/vendors/logo`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoadUpload(false);
          if (res.data.status) {
            Notification("success", "Success", res.data.message);
            setDisplayLogo(res.data.result.logoUrl);
            getContactDetails();
            setTimeout(() => {
              window.location.reload(false);
            }, 2500);
          } else {
            Notification("error", "Error", res.data.message);
          }
        })
        .catch((err) => {
          setLoadUpload(false);
          console.log(err);
        });
    } else {
      Notification("error", "Error", "Company Logo is required to be uploaded");
    }
  };

  const handleFileUpdateCac = (e) => {
    e.preventDefault();
    if (cacDoc) {
      let data = new FormData();
      data.append("file", cacDoc);
      setLoadUploadCac(true);
      axios
        .post(
          `${config.baseUrl}/v1/vendors/upload-cac
      `,
          data,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setLoadUploadCac(false);
          if (res.data.status) {
            Notification("success", "Success", res.data.message);
            const contactDetails = res.data.result;
            getContactDetails();
            setDisplayLogo(contactDetails.logoUrl);
          } else {
            Notification("error", "Error", res.data.message);
          }
        })
        .catch((err) => {
          setLoadUploadCac(false);
          console.log(err);
        });
    } else {
      Notification("error", "Error", "CAC Document is required to be uploaded");
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setLogo(e.target.files[0]);
    } else {
      setLogo(null);
    }
  };
  const handleFileChangeCac = (e) => {
    if (e.target.files[0]) {
      setCacDoc(e.target.files[0]);
    } else {
      setCacDoc(null);
    }
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const countryOptions = countryData.map((country) => {
    return <option value={country.code}>{country.name}</option>;
  });

  console.log(categoryData);

  return (
    <Layout>
      <div>
        <div className="col-sm-12 col-md-12">
          <div class="card">
            <div class="profile-img text-center pt-3">
              <img
                src={displayLogo}
                alt=""
                width="150"
                style={{ borderRadius: "100%" }}
              />
            </div>
            <div class="card-header font-weight-bold">
              <b>Contact Details</b>
            </div>
            <div class="card-body">
              <form>
                <div className="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        disabled={contactInformation.firstName ? true : false}
                        value={firstName}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        value={lastName}
                        disabled={contactInformation.lastName ? true : false}
                        onChange={(e) => setLastName(e.target.value)}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        Email
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        disabled={contactInformation.email ? true : false}
                        value={email}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        Mobile
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        value={mobile}
                        disabled={contactInformation.phone ? true : false}
                        onChange={(e) => setMobile(e.target.value)}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  class="btn btn-primary"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={handleContactDetailsUpdate}
                >
                  {loadDetails ? (
                    <div class="spinner-border text-white" role="status">
                      <span class="sr-only">Updating...</span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-12">
          <div class="card">
            <div class="card-header font-weight-bold">
              <b>Bussiness Details</b>
            </div>
            <div class="card-body">
              <form>
                <div className="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        Business Name
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        disabled={
                          contactInformation.businessName ? true : false
                        }
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={(e) => setBisName(e.target.value)}
                        value={bisName}
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        Category
                      </label>
                      <Select
                        mode="multiple"
                        allowClear
                        defaultValue={categoryData}
                        style={{ width: "100%" }}
                        placeholder="Please select"
                        onChange={handleChange}
                      >
                        {children}
                      </Select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        Country
                      </label>
                      <select
                        class="form-select"
                        disabled={contactInformation.country ? true : false}
                        aria-label="Default select example"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                      >
                        <option selected>Select</option>
                        {countryOptions}
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        Currency
                      </label>
                      <select
                        class="form-select"
                        disabled={contactInformation.currency ? true : false}
                        aria-label="Default select example"
                        value={currency}
                        onChange={(e) => setCurrency(e.target.value)}
                      >
                        <option selected>Select</option>
                        {currenciesDatas.map((currency) => {
                          <option value={currency.currencyCode}>
                            {currency.currencyName}
                          </option>;
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        State
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        disabled={contactInformation.state ? true : false}
                        id="exampleInputEmail1"
                        onChange={(e) => setState(e.target.value)}
                        value={state}
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        City
                      </label>
                      <input
                        type="text"
                        value={city}
                        class="form-control"
                        disabled={contactInformation.city ? true : false}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        Address
                      </label>
                      <input
                        type="text"
                        value={address}
                        class="form-control"
                        disabled={contactInformation.address ? true : false}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        Website
                      </label>
                      <input
                        type="text"
                        value={website}
                        class="form-control"
                        disabled={contactInformation.website ? true : false}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        Post Code
                      </label>
                      <input
                        type="number"
                        disabled={contactInformation.postcode ? true : false}
                        class="form-control"
                        onChange={(e) => setPostCode(e.target.value)}
                        value={postCode}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        RC Number
                      </label>
                      <input
                        type="number"
                        disabled={contactInformation.rcNumber ? true : false}
                        class="form-control"
                        onChange={(e) => setRcNum(e.target.value)}
                        value={rcnum}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  class="btn btn-primary"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={handleBusinessDetailsUpdate}
                >
                  {loadBusiness ? (
                    <div class="spinner-border text-white" role="status">
                      <span class="sr-only">Updating...</span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-12">
          <div class="card">
            <div class="card-header font-weight-bold">
              <b>Business Logo</b>
            </div>
            <div class="card-body">
              <form>
                <div class="mb-3">
                  <label for="formFileSm" class="form-label">
                    Upload Business Logo
                  </label>
                  <input
                    class="form-control form-control-sm"
                    id="formFileSm"
                    type="file"
                    onChange={handleFileChange}
                  />
                </div>

                <button
                  type="submit"
                  class="btn btn-primary"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={handleFileUpdate}
                >
                  {loadUpload ? (
                    <div class="spinner-border text-white" role="status">
                      <span class="sr-only">Updating...</span>
                    </div>
                  ) : (
                    "Upload Logo"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-12">
          <div class="card">
            <div class="card-header font-weight-bold">
              <b>Corporate Affairs Comission Certificate (CAC)</b>
            </div>
            <div class="card-body">
              <form>
                <div class="mb-3">
                  <label for="formFileSm" class="form-label">
                    Upload CAC Document
                  </label>
                  <input
                    class="form-control form-control-sm"
                    id="formFileSm"
                    type="file"
                    onChange={handleFileChangeCac}
                  />
                </div>

                <button
                  type="submit"
                  class="btn btn-primary"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={handleFileUpdateCac}
                >
                  {loadUploadCac ? (
                    <div class="spinner-border text-white" role="status">
                      <span class="sr-only">Updating...</span>
                    </div>
                  ) : (
                    "Upload CAC"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
