import React, { useEffect, useState } from "react";
import KycTab from "./kyc_tab";
import Layout from "../../../containers/Layout";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../config";
import { Spin } from "antd";

const PersonalInformation = () => {
  const [kycDetails, setKycDetails] = useState({
    title: "",
    position: "",
    firstName: "",
    lastName: "",
    otherName: "",
    dateofbirth: "",
    phoneNumber: "",
    email: "",
    gender: "",
    maritalStatus: "",
    residentialAddress: "",
    residentialState: "",
    residentialCity: "",
    residentialPostalcode: "",
    residentialCountry: "",
    status: "",
  });
  const [loading, setLoading] = useState(true);

  const {
    title,
    position,
    firstName,
    lastName,
    otherName,
    dateofbirth,
    phoneNumber,
    email,
    gender,
    maritalStatus,
    residentialAddress,
    residentialState,
    residentialCity,
    residentialPostalcode,
    residentialCountry,
    status,
  } = kycDetails;

  const { id } = useParams();

  const getKYCData = () => {
    axios
      .get(`${config.baseUrl}/v1/kyc-details/approvals/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setKycDetails((prevState) => ({
            ...prevState,
            title: res.data.result.title,
            position: res.data.result.position,
            firstName: res.data.result.firstName,
            lastName: res.data.result.lastName,
            otherName: res.data.result.otherName,
            dateofbirth: res.data.result.dateOfBirth,
            phoneNumber: res.data.result.phoneNumber,
            email: res.data.result.email,
            gender: res.data.result.gender,
            maritalStatus: res.data.result.maritalStatus,
            residentialAddress: res.data.result.residentialAddress,
            residentialState: res.data.result.residentialState,
            residentialCity: res.data.result.residentialCity,
            residentialPostalcode: res.data.result.residentialPostalCode,
            residentialCountry: res.data.result.residentialCountry,
            status: res.data.result.status,
          }));
        } else {
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err) {
        }
      });
  };

  useEffect(() => {
    getKYCData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      <KycTab id={id} status={status} />
      {loading ? (
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center align-items-center">
              <Spin />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header font-weight-bold">
                <b>Personal Information</b>
              </div>
              <div class="card-body">
                <form>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Title
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          value={title}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          First Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={firstName}
                          disabled
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Other Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          value={otherName}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Last Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          value={lastName}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Position
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={position}
                          disabled
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Date of birth
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          value={dateofbirth}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Phone number
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={phoneNumber}
                          disabled
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Email
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          value={email}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Gender
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={gender}
                          disabled
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Marital Status
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          value={maritalStatus}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Residential address
                        </label>
                        <textarea
                          type="text"
                          class="form-control"
                          value={residentialAddress}
                          disabled
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Residential state
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          value={residentialState}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Residential city
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={residentialCity}
                          disabled
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Residential postal code
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          value={residentialPostalcode}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Residential country
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={residentialCountry}
                          disabled
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default PersonalInformation;
