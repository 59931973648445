import React, { useEffect, useState } from "react";
import Layout from "../../../containers/Layout";
import axios from "axios";
import config from "../../../config";
import ReactDatatable from "@ashvin27/react-datatable";

const EmployerPayroll = (props) => {
  const [transactionData, setTransactionData] = useState([]);

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    button: {
      excel: true,
      print: true,
      extra: true,
    },
  };

  const columns = [
    {
      key: "destAcctName",
      text: "Destination Name",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "destAcctNumber",
      text: "Destination Account",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "purpose",
      text: "Purpose",
      className: "address",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "type",
      text: "Type",
      className: "address",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "amount",
      text: "Amount",
      className: "address",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "chargedAmount",
      text: "Charged Amount",
      className: "address",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "processorFee",
      text: "Processor Fee",
      className: "address",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      className: "address",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "date",
      text: "Date",
      className: "address",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
  ];

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];
  const options = { year: "numeric", month: "numeric", day: "numeric" };

  const getTransactionDetails = () => {
    const id = props.location.state.empId ? props.location.state.empId : "";
    axios
      .get(
        `${config.baseUrl}/v1/transactions/employer/paged?employerId=${id}
      `,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const getTransactionsData = res.data.result;
          if (getTransactionsData.length > 0) {
            setTransactionData(
              getTransactionsData?.map((data) => ({
                id: data.id,
                destAcctName: data.destAcctName,
                destAcctNumber: data.destAcctNumber,
                purpose: data.purpose,
                type: data.type,
                amount: `${data.destCurrency} ${data.amount}`,
                chargedAmount: `${data.destCurrency} ${data.chargedAmount}`,
                processorFee: `${data.destCurrency} ${data.processorFee}`,
                status: data.status,
                date: new Date(data.createdAt)
                  .toLocaleDateString(undefined, options)
                  .replace(/\//g, "-"),
              }))
            );
          }
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };
  console.log(transactionData);
  useEffect(() => {
    getTransactionDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 class="card-title">Transactions</h5>
              <div style={{ overflowX: "auto" }} className="pt-0">
                <ReactDatatable
                  config={configTable}
                  records={transactionData}
                  columns={columns}
                  extraButtons={extraButtons}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EmployerPayroll;
