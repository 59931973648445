import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";

export const NumberFormatter = ({
  onChange,
  value,
  name,
  label,
  error,
  style,
  prefix,
  ...rest
}) => {

  const [currency, setCurrency] = useState(0);

  useEffect(() => {
    setCurrency(value)
  },[value])
  return (
    <>
      
      <NumberFormat
        {...rest}
        value={currency + ".00"}
        thousandSeparator={true}
        class="form-control"
        decimalScale={2}
        onValueChange={(target) => {
          setCurrency(target.floatValue);
          onChange(target.floatValue);
        }}
        isNumericString
        prefix={prefix || ""}
        
      />
    </>
  );
};
