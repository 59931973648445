import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Layout from "../../../containers/Layout";
import axios from "axios";
import config from "../../../config";
import { Drawer, Spin, Pagination } from "antd";
export default function Employers() {
  const [visible, setVisible] = useState(false);

  const [loadEmployers, setLoadEmployers] = useState(true);
  const [employers, setEmployers] = useState([]);
  const [noEmployers, setNoEmployers] = useState(false);

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  //paginations
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `page=${page - 1}&size=${pageSize}`;
    getPaged(queryString);
  };

  const getPaged = (queryString) => {
    axios
      .get(`${config.baseUrl}/v1/employees/filtered/employer?${queryString}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.result.length > 0) {
          setEmployers(res.data.result);
          setTotalItems(res.data.totalPages * 10);
          setNoEmployers(false);
          setLoadEmployers(false);
        } else {
          setNoEmployers(true);
          setEmployers([]);
          setLoadEmployers(false);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    getEmployers();
  }, []);

  const getEmployers = () => {
    axios
      .get(`${config.baseUrl}/v1/employees/filtered/employer`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.result.length > 0) {
          setEmployers(res.data.result);
          setTotalItems(res.data.totalPages * 10);
          setNoEmployers(false);
          setLoadEmployers(false);
        } else {
          setNoEmployers(true);
          setEmployers([]);
          setLoadEmployers(false);
        }
      })
      .catch((err) => {});
  };

  const [load, setLoad] = useState(false);
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [rowId, setRowID] = useState("");
  const [deductionStatus, setDeductionStatus] = useState("");
  const [deductionDatas, setDeductionDatas] = useState([]);
  const [noDeductions, setNoDeductionDatas] = useState(false);
  const [loadDeductions, setLoadDeductionDatas] = useState(true);
  const [employeeInfo, setEmployeeInfo] = useState({});

  const showDrawer = (record, e) => {
    e.preventDefault();
    setRowID(record.id);
    setEmployeeInfo(record);
    setDeductionStatus(record.status);
    setVisibleDraw(true);
  };
  const closeDrawer = () => {
    setVisibleDraw(false);
  };
  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 col-lg-12">
                  <div class="card table-widget">
                    <div class="card-body">
                      <h5 class="card-title">Employers </h5>
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">Business Name</th>
                              <th scope="col">Industry</th>

                              <th scope="col">Support Email</th>
                              <th scope="col">Company Website</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {employers.map((info) => {
                              return (
                                <tr>
                                  <td>{info.employerResponse.businessName}</td>
                                  <td>{info.employerResponse.industry}</td>

                                  <td>{info.employerResponse.supportEmail}</td>
                                  <td>
                                    <a
                                      className="text-info"
                                      href={`https://${info.employerResponse.website}`}
                                      target="_blank"
                                    >
                                      {info.employerResponse.website}
                                    </a>
                                  </td>
                                  <td>
                                    <button
                                      type="submit"
                                      onClick={showDrawer.bind(this, info)}
                                      class="btn btn-primary"
                                      style={{
                                        background: "#1FC157",
                                        color: "white",
                                      }}
                                    >
                                      Action
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <br />
                      {loadEmployers && (
                        <div className="text-center pb-5 pt-5">
                          <Spin />
                        </div>
                      )}
                     
                      
                    </div>
                  </div>
                  {noEmployers && (
                    <div className="row">
                      <div className="text-center pb-5 pt-5">
                        <p className="text-center">
                          No Employer Yet
                        </p>
                        <hr />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}
      >
        <Link
          to={{ pathname: "/employer_employee-profile", state: employeeInfo }}
        >
          <div style={{ color: "#000", cursor: "pointer" }}>
            Employee Profile
          </div>
        </Link>
        <hr />
        <Link to={{ pathname: "/employer_employee-allowances", state: rowId }}>
          <div style={{ color: "#000", cursor: "pointer" }}>Allowances</div>
        </Link>
        <hr />
        <Link to={{ pathname: "/employer_employee-deductions", state: rowId }}>
          <div style={{ color: "#000", cursor: "pointer" }}>Deductions</div>
        </Link>

        <hr />
        <Link to={{ pathname: "/employer_employee-pensions", state: rowId }}>
          <div style={{ color: "#000", cursor: "pointer" }}>Pensions</div>
        </Link>
        <hr />
        <Link to={{ pathname: "/employer_employee-taxes", state: rowId }}>
          <div style={{ color: "#000", cursor: "pointer" }}>Taxes</div>
        </Link>

        <hr />
        <Link to={{ pathname: "/leave_request", state: rowId }}>
          <div style={{ color: "#000", cursor: "pointer" }}>Leave Request</div>
        </Link>
        <hr />
      </Drawer>
    </Layout>
  );
}
