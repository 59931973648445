import React, { useEffect, useState } from "react";
import Layout from "../../../containers/Layout";
import { useParams } from "react-router-dom";
import KycTab from "./kyc_tab";
import axios from "axios";
import config from "../../../config";
import { Spin } from "antd";

const BusinessInformation = () => {
  const [kycDetails, setKycDetails] = useState({
    businessName: "",
    dateofRegistration: "",
    businessAddress: "",
    businessState: "",
    businessCity: "",
    businessPostalcode: "",
    businessCountry: "",
    industry: "",
    size: "",
    website: "",
    supportEmail: "",
    registerType: "",
    ownership: "",
    fiscalMonth: "",
    accountType: "",
    status: "",
  });
  const [loading, setLoading] = useState(true);

  const {
    businessName,
    dateofRegistration,
    businessAddress,
    businessState,
    businessCity,
    businessPostalcode,
    businessCountry,
    industry,
    size,
    website,
    supportEmail,
    registerType,
    ownership,
    fiscalMonth,
    accountType,
    status,
  } = kycDetails;

  const { id } = useParams();

  const getKYCData = () => {
    axios
      .get(`${config.baseUrl}/v1/kyc-details/approvals/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setKycDetails((prevState) => ({
            ...prevState,
            businessName: res.data.result.businessName,
            dateofRegistration: res.data.result.dateOfRegistration,
            businessAddress: res.data.result.businessAddress,
            businessState: res.data.result.businessState,
            businessCity: res.data.result.businessCity,
            businessPostalcode: res.data.result.businessPostalCode,
            businessCountry: res.data.result.businessCountry,
            industry: res.data.result.industry,
            size: res.data.result.size,
            website: res.data.result.website,
            supportEmail: res.data.result.supportEmail,
            registerType: res.data.result.registerType,
            ownership: res.data.result.ownership,
            fiscalMonth: res.data.result.fiscalMonth,
            accountType: res.data.result.accountType,
            status: res.data.result.status,
          }));
        } else {
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err) {
        }
      });
  };

  useEffect(() => {
    getKYCData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      <KycTab id={id} status={status} />
      {loading ? (
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center align-items-center">
              <Spin />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header font-weight-bold">
                <b>Business Information</b>
              </div>
              <div class="card-body">
                <form>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Business Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          value={businessName}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Date of Registration
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={dateofRegistration}
                          disabled
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Business address
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          value={businessAddress}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Business state
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={businessState}
                          disabled
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Business city
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          value={businessCity}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Business postal code
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={businessPostalcode}
                          disabled
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Business country
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          value={businessCountry}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Industry
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={industry}
                          disabled
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Size
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          value={size}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Website
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={website}
                          disabled
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Support email
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          value={supportEmail}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Register type
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={registerType}
                          disabled
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Ownership
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          value={ownership}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Fiscal month
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={fiscalMonth}
                          disabled
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Account type
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          value={accountType}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default BusinessInformation;
