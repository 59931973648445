import React, { useState, useEffect } from "react";
import { Select } from "antd";
import axios from "axios";
import config from "../../config";
import { useHttp } from "../../hooks/useHttps";
import { Spin } from "antd";
const { Option } = Select;

export default function DashboardStatus() {
  const [stats, setStats] = useState([]);
  const [load, setLoad] = useState(false);
  const [statResponse, setStatResponse] = useState(false);
  const [walletInformationsList, setWalletInformations] = useState([]);
  const [wallet, setWallet] = useState([]);
  const [employeeStats, setEmployeeStats] = useState([]);
  var currency = "NGN";

  const getEmployeeStats = () => {
    setLoad(true);
    axios
      .get(`${config.baseUrl}/v1/dashboard/employee/${currency}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setStatResponse(res.data.status);
        setLoad(false);
        if (res.data.status) {
          const Info = res.data.result;
          setStats(Info);
          setEmployeeStats(Info);
        } else {
        }
      })
      .catch((err) => {
        setStatResponse(false);
        setLoad(false);
        if (err) {
        }
      });
  };

  function handleChange(currency) {
    const walletCurrencyBalance = walletInformationsList.find(
      (wallet) => wallet.currency === currency
    );
    setWallet([
      {
        balance: walletCurrencyBalance.balance,
        currency: walletCurrencyBalance.currency,
      },
    ]);
  }

  // const getWalletInformations = () => {
  //   axios
  //     .get(`${config.baseUrl}/v1/wallets/employee`, {
  //       headers: {
  //         Authorization: `Bearer ${sessionStorage.getItem("token")}`,
  //       },
  //     })
  //     .then((res) => {
  //       if (res.data.status) {
  //         const walletInfo = res.data.result;
  //         setWalletInformations(walletInfo);
  //         setWallet([
  //           {
  //             balance: walletInfo[0].balance,
  //             currency: walletInfo[0].currency,
  //           },
  //         ]);
  //       } else {
  //       }
  //     })
  //     .catch((err) => {
  //       if (err) {
  //       }
  //     });
  // };

  const getCurrencies = () => {
    axios
      .get(`${config.baseUrl}/v1/wallets/employee/currencies`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const walletInfo = res.data.result;
          setWalletInformations(walletInfo);
          setWallet([
            {
              balance: walletInfo[0].balance,
              currency: walletInfo[0].currency,
            },
          ]);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    getEmployeeStats();
    getCurrencies();
  }, []);

  return (
    <div className="row">
      <div className="col-sm-12 col-md-12">
        <div className="card">
          {load && (
            <div className="text-center py-5">
              <Spin />
            </div>
          )}
          {statResponse && !load && (
            <div>
              <div className="d-flex justify-content-end align-items-center px-3 py-3">
                {walletInformationsList?.length > 0 && (
                  <Select
                    defaultValue={walletInformationsList[0]?.currency}
                    style={{ width: 80 }}
                    onChange={handleChange}
                    value={walletInformationsList[0]?.currency}
                  >
                    {walletInformationsList?.map((walletCurrency) => {
                      return (
                        <Option
                          key={walletCurrency?.id}
                          value={walletCurrency?.currency}
                        >
                          {walletCurrency?.currency}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </div>
              <div className="col-sm-12 col-md-12">
                <div class="card">
                  <div class="">
                    <div className="container">
                      <div className="row ">
                        <div class="col-md-6 col-xl-3 text-center">
                          <div class="card stat-widget">
                            <div
                              class="card-body text-center"
                              style={{
                                background: "#1fc157",
                                color: "white",
                                textAlign: "center",
                                borderRadius: "20px",
                              }}
                            >
                              <h5 class="card-title text-center text-white">
                                Account Balance
                              </h5>
                              <div
                                className="text-center text-white h2 font-weight-bold"
                                style={{ fontSize: "18px" }}
                              >
                                {wallet[0]?.currency}{" "}
                                {stats.accountBalance
                                  ? stats.accountBalance
                                    ? stats?.accountBalance
                                        .toFixed(2)
                                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                    : 0
                                  : 0}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-xl-3 text-center">
                          <div class="card stat-widget">
                            <div
                              class="card-body text-center"
                              style={{
                                background: "#1fc157",
                                color: "white",
                                textAlign: "center",
                                borderRadius: "20px",
                              }}
                            >
                              <h5 class="card-title text-center text-white">
                                Net Salary
                              </h5>
                              <div
                                className="text-center text-white h2 font-weight-bold"
                                style={{ fontSize: "18px" }}
                              >
                                {wallet[0]?.currency}{" "}
                                {stats.netSalary
                                  ? stats.netSalary
                                    ? stats?.netSalary
                                        .toFixed(2)
                                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                    : 0
                                  : 0}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-xl-3">
                          <div class="card stat-widget">
                            <div
                              class="card-body"
                              style={{
                                background: "#1fc157",
                                color: "white",
                                textAlign: "center",
                                borderRadius: "20px",
                              }}
                            >
                              <h5 class="card-title text-center text-white">
                                Total Inflow
                              </h5>
                              <div
                                className="text-center text-white h2 font-weight-bold"
                                style={{ fontSize: "18px" }}
                              >
                                {wallet[0]?.currency}{" "}
                                {stats.totalInflowTransaction
                                  ? stats.totalInflowTransaction
                                    ? stats?.totalInflowTransaction
                                        .toFixed(2)
                                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                    : 0
                                  : 0}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 col-xl-3">
                          <div class="card stat-widget">
                            <div
                              class="card-body"
                              style={{
                                background: "#1fc157",
                                color: "white",
                                textAlign: "center",
                                borderRadius: "20px",
                              }}
                            >
                              <h5 class="card-title text-center text-white">
                                Total Outflow
                              </h5>
                              <div
                                className="text-center text-white h2 font-weight-bold"
                                style={{ fontSize: "18px" }}
                              >
                                {wallet[0]?.currency}{" "}
                                {stats.totalOutflowTransaction
                                  ? stats.totalOutflowTransaction
                                    ? stats?.totalOutflowTransaction
                                        .toFixed(2)
                                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                    : 0
                                  : 0}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!statResponse && !load && (
            <div className="text-center py-5">
              <p>Dashboard Metrics Not Available</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
