import React, { useEffect, useState } from "react";
import { Spin, Pagination, DatePicker, notification, Modal } from "antd";
import axios from "axios";
import Layout from "../../containers/Layout";
import wallet from "../../img/wallet.jpg";
import config from "../../config";
import moment from "moment";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;
const days = 86400000; //number of milliseconds in a day
const currDay = new Date();
const sevenDaysAgo = new Date(currDay - 7 * days);
const userRole = sessionStorage.getItem("activeRole");
export default function Employers() {
  let nameSearch;
  const [auditsData, setAuditsData] = useState([]);
  const [loadAudit, setLoadAudit] = useState(true);
  const [cardInfo, setCardInfo] = useState([]);
  const [billingInfo, setBillingInfo] = useState([]);
  const [date, setDate] = useState(moment(sevenDaysAgo).format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(
    moment(new Date()).add(1, "days").format("YYYY-MM-DD")
  );
  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  //paginations
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const getAuditTrails = () => {
    setLoadAudit(true);
    axios
      .get(
        `${
          config.baseUrl
        }/v1/audit-trail/employee/paged?page=0&size=10&userId=${sessionStorage.getItem(
          "userId"
        )}&endDate=${endDate}&startDate=${date}&name=${
          nameSearch !== undefined ? nameSearch : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const auditsData = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          if (auditsData.length > 0) {
            setAuditsData(auditsData);
            setLoadAudit(false);
          } else {
            setLoadAudit(false);
          }
        } else {
          setLoadAudit(false);
          setAuditsData([]);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const showDeleteConfirm = (id, e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure delete this billing method ?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        cardDelete(id);
      },
      onCancel() {},
    });
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const cardDelete = (id) => {
    axios
      .delete(`${config.baseUrl}/v1/billing-methods/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getBillingInfo();
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    getBillingInfo();
  }, []);

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `page=${
      page - 1
    }&size=${pageSize}&endDate=${endDate}&startDate=${date}&name=${
      nameSearch !== undefined ? nameSearch : ""
    }`;
    getPaged(queryString);
  };

  const getBillingInfo = () => {
    setLoadAudit(true);
    axios
      .get(`${config.baseUrl}/v1/billing-methods/user`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoadAudit(false);
        if (res.data.status) {
          const billingInfo = res.data.result;
          if (billingInfo.length > 0) {
            setBillingInfo(billingInfo);
            setCardInfo(billingInfo);
          } else {
            setBillingInfo([]);
          }
        }
      })
      .catch((err) => {
        setLoadAudit(false);
        if (err) {
        }
      });
  };

  const getName = (e) => {
    nameSearch = e.target?.value;
    getAuditTrails();
  };

  const getPaged = (queryString) => {
    axios
      .get(`${config.baseUrl}/v1/audit-trail/employer/paged?${queryString}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const auditsData = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          if (auditsData.length > 0) {
            setAuditsData(auditsData);
            setLoadAudit(false);
          } else {
            setLoadAudit(false);
          }
        } else {
          setLoadAudit(false);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const onChange = (date, dateString) => {
    setEndDate(dateString[1]);
    setDate(dateString[0]);
  };

  return (
    <Layout>
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="card table-widget">
            <div class="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5 class="card-title">Billing Methods </h5>
                </div>
              </div>
              <div class="container">
                {billingInfo.length > 0 && (
                  <div className="">
                    {cardInfo.map((cards) => {
                      if (cards.type === "CARD") {
                        return (
                          <div>
                            <div class="post">
                              <div class="post-header">
                                <img src={wallet} alt="" />
                                <div class="post-info">
                                  <span class="post-author">{cards.name}</span>
                                  <span class="post-date">
                                    {cards.cardFirst6Digits}*********
                                    {cards.cardLast4Digits}
                                  </span>
                                  <br />
                                  <span class="post-date">
                                    {cards.cardIssuer}
                                  </span>
                                  <br />
                                  <span class="post-date">
                                    {cards.currency}
                                  </span>
                                  <br />

                                  <b
                                    style={{
                                      color:
                                        cards.level === "PRIMARY"
                                          ? "green"
                                          : "orange",
                                    }}
                                  >
                                    {cards.level}
                                  </b>
                                </div>
                                <div class="post-header-actions">
                                  <button
                                    type="submit"
                                    class="btn btn-danger"
                                    onClick={showDeleteConfirm.bind(
                                      this,
                                      cards.id
                                    )}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>
                        );
                      } else {
                        return (
                          <div>
                            <div class="post">
                              <div class="post-header">
                                <img src={wallet} alt="" />
                                <div class="post-info">
                                  <span
                                    class="post-date"
                                    style={{ fontWeight: "100 !important" }}
                                  >
                                    {cards.accountName}
                                  </span>

                                  <br />

                                  <span class="post-date">
                                    {cards?.bankName +
                                      "-" +
                                      cards.accountNumber}
                                  </span>
                                  <br />

                                  <span class="post-date">
                                    {cards.currency}
                                  </span>
                                </div>
                                <div class="post-header-actions">
                                  <button
                                    type="submit"
                                    class="btn btn-danger"
                                    onClick={showDeleteConfirm.bind(
                                      this,
                                      cards.id
                                    )}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>
                        );
                      }
                    })}
                  </div>
                )}
                {billingInfo?.length === 0 && !loadAudit && (
                  <p className="text-center pt-5 pb-5">No Billing Methods</p>
                )}
                {loadAudit && (
                  <div className="text-center pt-5 pb-5">
                    <Spin />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
