import moment from "moment";
// Countdown timer helper
const padTime = (time) => {
  return String(time).length === 1 ? `0${time}` : `${time}`;
};

export const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes}:${padTime(seconds)}`;
};

export const generalErrMsg = "An error occurred, please try again later";

function formatEpochDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export const formatDate = (date) => {
  return new Date(formatEpochDate(date)).getTime();
};

export const convertDate = (date) => {
  return moment(new Date(date)).format("YYYY-MM-DD");
};

export const formatDollars = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
export const formatNaira = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "NGN",
});

export const tableDate = (date) => {
  return moment(date).format("D MMM, YYYY");
};

const maskPart = (part) => {
  const length = part.length;
  if (length > 8) {
    return (
      part.substr(0, 2) +
      part.substr(2, length - 2).replace(/\w/g, "*") +
      part.substr(-2, 2)
    );
  }
  if (length > 4) {
    return (
      part.substr(0, 1) +
      part.substr(1, length - 1).replace(/\w/g, "*") +
      part.substr(-1, 1)
    );
  }
  return part.replace(/\w/g, "*");
};

export const maskEmailAddress = (emailAddress) => {
  return emailAddress.replace(
    /([\w.]+)@([\w.]+)(\.[\w.]+)/g,
    (_m, p1, p2, p3) => {
      return maskPart(p1) + "@" + p2 + p3;
    }
  );
};

export const formatNumbers = (num) => {
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const strongPasswordText = [
  {
    text: "Password should have a minimum of 8 characters.",
  },
  {
    text: "Include at least one uppercase letter.",
  },
  {
    text: "Include at least one lowercase letter.",
  },
  {
    text: "Include at least one digit.",
  },
  {
    text: "Include at least one special character.",
  },
];

export const checkPasswordStrength = (password) => {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasDigit = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);

  if (
    password.length >= minLength &&
    hasUpperCase &&
    hasLowerCase &&
    hasDigit &&
    hasSpecialChar
  ) {
    return "Strong";
  } else {
    return "Weak";
  }
};
