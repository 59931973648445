import React, { useState, useEffect } from "react";
import Layout from "../../../containers/Layout";
import logo from "../../../img/logo.jpg";
import profileDefault from "../../../img/profile-image.png";
import axios from "axios";
import {
  Spin,
  Modal,
  notification,
  Checkbox,
  Button,
  Space,
  Alert,
} from "antd";
import config from "../../../config";
export default function CompanyInfo(props) {
  //business info
  const [bisName, setBisName] = useState("");
  const [employerInfo, setEmployerInfo] = useState("");

  //logo
  const [logo, setLogo] = useState("");
  const [displayLogo, setDisplayLogo] = useState(null);

  useEffect(() => {
    getEmployerDetails();
    console.log(props.location.state.rowId);
  }, []);

  const getEmployerDetails = () => {
    const id = props.location.state.rowId ? props.location.state.rowId : "";
    axios
      .get(`${config.baseUrl}/v1/employers/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const Details = res.data.result;
          setEmployerInfo(Details);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  return (
    <Layout>
      {employerInfo && (
        <div>
          <div className="col-sm-12 col-md-12">
            <div class="card">
              <div class="profile-img text-center">
                <img
                  src={displayLogo ? displayLogo : profileDefault}
                  alt=""
                  width="150"
                  style={{ borderRadius: "100%" }}
                />
              </div>
              <div class="card-header font-weight-bold">
                <b>Personal Information</b>
              </div>
              <div class="card-body">
                <form>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Tilte
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                          value={employerInfo.title}
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          First Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          disabled
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={employerInfo.firstName}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Last Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={employerInfo.lastName}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Email
                        </label>
                        <input
                          type="text"
                          disabled
                          class="form-control"
                          value={employerInfo.supportEmail}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Mobile Number
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={employerInfo.mobile}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Address
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={employerInfo.address}
                          disabled
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          City
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={employerInfo.city}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Country
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={employerInfo.country}
                          id="exampleInputEmail1"
                          disabled
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          State
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={employerInfo.state}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header font-weight-bold">
                <b>Bussiness Details</b>
              </div>
              <div class="card-body">
                <form>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Business Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={employerInfo.businessName}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Industry
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={employerInfo.industry}
                          id="exampleInputEmail1"
                          disabled
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Size
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={employerInfo.size}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Support Email
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={employerInfo.supportEmail}
                          id="exampleInputEmail1"
                          disabled
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Nhf Number
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={employerInfo.nhfNumber}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Ownership
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={employerInfo.ownership}
                          id="exampleInputEmail1"
                          disabled
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Position
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={employerInfo.position}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          RC Number
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={employerInfo.rcNumber}
                          id="exampleInputEmail1"
                          disabled
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Register Type
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={employerInfo.registerType}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Tax ID
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={employerInfo.taxId}
                          id="exampleInputEmail1"
                          disabled
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Website
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={employerInfo.website}
                          id="exampleInputEmail1"
                          disabled
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header font-weight-bold">
                <b>Bank Information</b>
              </div>
              <div class="card-body">
                <form>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Bvn
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={employerInfo.bvn}
                          id="exampleInputEmail1"
                          disabled
                          aria-describedby="emailHelp"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
