import React, { useEffect, useState, useRef } from "react";
import {
  Spin,
  Pagination,
  DatePicker,
  Modal,
  Input,
  Tag,
  theme,
  notification,
  Drawer,
  Button,
} from "antd";
import axios from "axios";
import Layout from "../../../containers/Layout";
import { PlusOutlined } from "@ant-design/icons";
import config from "../../../config";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";
import { TweenOneGroup } from "rc-tween-one";
const { RangePicker } = DatePicker;
const days = 86400000; //number of milliseconds in a day
const currDay = new Date();
const sevenDaysAgo = new Date(currDay - 7 * days);
export default function Employers() {
  let nameSearch;
  const [auditsData, setInvitesData] = useState([]);
  const [loadAudit, setLoadInvites] = useState(true);
  const [openNew, setOpenNew] = useState(false);
  const [rowId, setrowId] = useState("");
  const [totalData, setTotalData] = useState(0);
  const [date, setDate] = useState(moment(sevenDaysAgo).format("YYYY-MM-DD"));
  const [showDrawer, setDrawer] = useState(false);
  const { token } = theme.useToken();
  const [tags, setTags] = useState([]);
  const [rowStatus, setRowStatus] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);
  const [load, setLoad] = useState(false);

  const [endDate, setEndDate] = useState(
    moment(new Date()).add(1, "days").format("YYYY-MM-DD")
  );
  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  //paginations
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const getInvites = () => {
    setLoadInvites(true);
    axios
      .get(
        `${
          config.baseUrl
        }/v1/onboarding-link/invites?employerId=${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const auditsData = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          if (auditsData.length > 0) {
            setInvitesData(auditsData);
            setLoadInvites(false);
          } else {
            setLoadInvites(false);
          }
        } else {
          setLoadInvites(false);
          setInvitesData([]);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    getInvites();
  }, []);

  useEffect(() => {
    if (date && endDate) {
      getInvites();
    }
  }, [date, endDate]);

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `page=${
      page - 1
    }&size=${pageSize}&endDate=${endDate}&startDate=${date}&name=${
      nameSearch !== undefined ? nameSearch : ""
    }`;
    getPaged(queryString);
  };

  const getName = (e) => {
    nameSearch = e.target?.value;
    getInvites();
  };

  const getPaged = (queryString) => {
    axios
      .get(`${config.baseUrl}/v1/audit-trail/employer/paged?${queryString}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const auditsData = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          if (auditsData.length > 0) {
            setInvitesData(auditsData);
            setLoadInvites(false);
          } else {
            setLoadInvites(false);
          }
        } else {
          setLoadInvites(false);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const onshowDrawer = (data) => {
    setrowId(data?.id);
    setRowStatus(data?.reviewStatus);
    sessionStorage?.setItem("onboardID", data?.id);
    setDrawer(true);
  };

  const handleOnboardEmployessInvite = () => {
    setLoad(true);
    axios
      .post(
        `${config.baseUrl}/v1/onboarding-link/send-invite`,
        {
          employerId: sessionStorage.getItem("employer_id"),
          workEmails: tags,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setOpenNew(false);
          setInputVisible(false);
          setInputValue("");
          setTags([]);
          Notification("success", "Success", res.data.message);
          getInvites();
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
        }
      });
  };

  const deleteInvite = () => {
    Modal.confirm({
      title: "Are you sure you want to delete Invite sent this employee?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      onOk() {
        axios
          .delete(`${config.baseUrl}/v1/onboarding-link/invite/${rowId}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            if (res.data.status) {
              Notification("success", "Success", res.data.message);
              setDrawer(false);
              getInvites();
            } else {
              Notification("error", "Error", res.data.message);
            }
          })
          .catch((err) => {
            if (err) {
            }
          });
      },
      onCancel() {},
    });
  };

  const resendInvite = () => {
    Modal.confirm({
      title:
        "Are you sure you want to resend Invite to this employee work email?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      onOk() {
        axios
          .post(
            `${
              config.baseUrl
            }/v1/onboarding-link/resend-invite?employerId=${sessionStorage?.getItem(
              "employer_id"
            )}&onboardingLinkId=${rowId}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status) {
              Notification("success", "Success", res.data.message);
              getInvites();
              setDrawer(false)
              
            } else {
              Notification("error", "Error", res.data.message);
            }
          })
          .catch((err) => {
            if (err) {
            }
          });
      },
      onCancel() {},
    });
  };

  const onChange = (date, dateString) => {
    setEndDate(dateString[1]);
    setDate(dateString[0]);
  };

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);
  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    console.log(newTags);
    setTags(newTags);
  };

  const validateEmail = (email) => {
    // Regular expression to validate an email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const showInput = () => {
    setInputVisible(true);
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setIsValid(validateEmail(e.target.value));
  };

  const handleInputConfirm = () => {
    if (inputValue && isValid) {
      if (inputValue && tags.indexOf(inputValue) === -1) {
        setTags([...tags, inputValue]);
      }
      setInputVisible(false);
      setInputValue("");
    } else {
      Notification("error", "Error", "Email is invalid");
    }
  };
  const forMap = (tag) => (
    <span
      key={tag}
      style={{
        display: "inline-block",
      }}
    >
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    </span>
  );
  const tagChild = tags.map(forMap);
  const tagPlusStyle = {
    background: token.colorBgContainer,
    borderStyle: "dashed",
    cursor: "pointer",
  };

  return (
    <Layout>
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="card table-widget">
            <div class="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5 class="card-title">Self Onboarding </h5>
                </div>
                <div className="px-3">
                  <button
                    className="btn btn-info m-b-xs "
                    onClick={() => {
                      setOpenNew(true);
                    }}
                    style={{
                      background: "#1FC157",
                      color: "white",
                      width: "100%",
                    }}
                  >
                    Invite Employee
                  </button>{" "}
                </div>

                {/* <div className="px-3  row">
                  <div className="col-md-6">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search by name"
                      onBlur={getName}
                    />
                  </div>
                  <div className="col-md-6">
                    <RangePicker
                      onChange={onChange}
                      style={{ height: "3rem", borderRadius: "10px" }}
                      disabledDate={(current) => current.isAfter(moment())}
                    />
                  </div>
                </div> */}
              </div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col"> Work Email</th>
                      <th scope="col">Invite Status</th>
                      <th scope="col">Review Status</th>
                      <th scope="col">Date Invited</th>

                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  {!loadAudit && (
                    <tbody>
                      {auditsData.length > 0 && (
                        <>
                          {auditsData.map((data) => {
                            return (
                              <tr>
                                <td>{data.workEmail}</td>
                                <td>
                                  {" "}
                                  <span
                                    className={
                                      {
                                        SENT: "badge bg-success",
                                        STARTED: "badge bg-warning",
                                        ACCEPTED: "badge bg-primary",
                                        APPROVED: "badge bg-success",
                                        FAILED: "badge bg-danger",
                                        IDLE: "badge bg-info",
                                        PENDING: "badge bg-warning",
                                        PROCESSING: "badge bg-warning",
                                        DECLINED: "badge bg-danger",
                                      }[data.inviteStatus]
                                    }
                                  >
                                    {data.inviteStatus}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    className={
                                      {
                                        ACCEPTED: "badge bg-success",
                                        STARTED: "badge bg-warning",
                                        ACCEPTED: "badge bg-primary",
                                        APPROVED: "badge bg-success",
                                        FAILED: "badge bg-danger",
                                        IDLE: "badge bg-info",
                                        PENDING: "badge bg-warning",
                                        PROCESSING: "badge bg-warning",
                                        DECLINED: "badge bg-danger",
                                      }[data.reviewStatus]
                                    }
                                  >
                                    {data.reviewStatus}
                                  </span>
                                </td>
                                <td>
                                  {" "}
                                  {moment(data.createdAt).format(
                                    "MMM Do YY, hh:mm A"
                                  )}
                                </td>
                                <td>
                                  <button
                                    type="submit"
                                    className="btn btn-info m-b-xs"
                                    style={{
                                      background: "#1FC157",
                                      color: "white",
                                    }}
                                    onClick={onshowDrawer.bind(this, data)}
                                  >
                                    Action
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      )}
                    </tbody>
                  )}
                </table>
                {auditsData?.length === 0 && !loadAudit && (
                  <p className="text-center pt-5 pb-5">
                   No Self Onboarding Records{" "}
                  </p>
                )}
                {loadAudit && (
                  <div className="text-center pt-5 pb-5">
                    <Spin />
                  </div>
                )}
              </div>
              {auditsData?.length > 0 && (
                <div className="d-flex  justify-content-center align-items-center pt-5 pb-5">
                  <Pagination
                    current={page}
                    total={totalItems}
                    defaultPageSize={10}
                    itemRender={itemRender}
                    onChange={pagination}
                  />{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={"Invite Employee(s)"}
        visible={openNew}
        footer={false}
        width={500}
        onCancel={() => {
          setOpenNew(false);
        }}
        maskClosable={false}
      >
        <div className="container">
          {load && (
            <div className="text-center py-4">
              <Spin />
            </div>
          )}
          <div class="">
            <div class="mb-3">
              <>
                <div
                  style={{
                    marginBottom: 16,
                  }}
                >
                  <TweenOneGroup
                    appear={false}
                    enter={{
                      scale: 0.8,
                      opacity: 0,
                      type: "from",
                      duration: 100,
                    }}
                    leave={{
                      opacity: 0,
                      width: 0,
                      scale: 0,
                      duration: 200,
                    }}
                    onEnd={(e) => {
                      if (e.type === "appear" || e.type === "enter") {
                        e.target.style = "display: inline-block";
                      }
                    }}
                  >
                    {tagChild}
                  </TweenOneGroup>
                </div>
                {inputVisible ? (
                  <div className="">
                    <label>Input work email and click Enter </label>
                    <Input
                      ref={inputRef}
                      type="text"
                      style={{
                        height: "38px",
                        borderRadius: "5px",
                        width: "100%",
                      }}
                      value={inputValue}
                      onChange={handleInputChange}
                      onBlur={handleInputConfirm}
                      onPressEnter={handleInputConfirm}
                    />
                    {/* <button
                      type="submit"
                      className="btn btn-info m-b-xs"
                      style={{ background: "#1FC157", color: "white" }}
                      onClick={handleInputConfirm}
                    >
                      Submit
                    </button> */}
                  </div>
                ) : (
                  <Tag onClick={showInput} style={tagPlusStyle}>
                    <PlusOutlined /> Click to Add Work Email(s)
                  </Tag>
                )}
              </>
            </div>
            {tags?.length > 0 && (
              <div className="d-flex justify-content-end align-items-center">
                <button
                  type="submit"
                  className="btn btn-info m-b-xs"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={handleOnboardEmployessInvite}
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        </div>
      </Modal>

      <Drawer
        title="Actions"
        placement="right"
        visible={showDrawer}
        onClose={() => {
          setDrawer(false);
        }}
      >
        {rowStatus !== "APPROVED" && (
          <>
            <Link
              to={{ pathname: `/employee-work-info`, state: { id: rowId } }}
            >
              <div style={{ cursor: "pointer", color: "#000000" }}>
                Approve/Decline
              </div>
            </Link>
            <hr />
          </>
        )}
        <Link
          to={{ pathname: `/employee-personal-info`, state: { id: rowId } }}
        >
          <div style={{ color: "#000000", cursor: "pointer" }}>
            Manage Onboarding Details
          </div>
        </Link>
        {rowStatus !== 'APPROVED' &&
        <div>
        <hr />
        <div
          style={{ cursor: "pointer", color: "#000000" }}
          onClick={resendInvite}
        >
          Resend Invite
        </div>
        <hr />
        <div style={{ cursor: "pointer", color: "red" }} onClick={deleteInvite}>
          Delete Invite
        </div>
        <hr />
        </div>}
      </Drawer>
    </Layout>
  );
}
