import {combineReducers} from 'redux';
import employeePaymentHistoryReducer from './App/Employee/paymentHistoryReducer';
import employeeTransactionHistoryReducer from './App/Employee/transactionHistoryReducers';
import employeeDeductionHistoryReducer from './App/Employee/deductionHistoryReducer';
import defaultReducer from './default'

export default combineReducers({
    employeepaymentHistory: employeePaymentHistoryReducer,
    employeeDeductionHistory: employeeDeductionHistoryReducer,
    employeeTransactiontHistory: employeeTransactionHistoryReducer,
    system: defaultReducer,
});