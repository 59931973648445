import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getDeductionHistories } from "../../../redux/action/employeeActions";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Layout from "../../../containers/Layout";
import { useHttp } from "../../../hooks/useHttps";
import config from "../../../config";
import {
  Drawer,
  Button,
  Spin,
  Alert,
  Pagination,
  Modal,
  notification,
} from "antd";
export default function Deduction_History() {
  const dispatch = useDispatch();
  const [loadingEmployers, employers] = useHttp(
    `${config.baseUrl}/v1/employees/filtered/employer`,
    []
  );

  const [noDeductionHistory, setNoDeductions] = useState(false);
  const [employeeId, setEmployer] = useState(false);
  const [load, setLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const [loadDeductions, setLoadDeductions] = useState(true);
  const [deductionHistoryData, setDeductions] = useState([]);

  const searchDeductions = () => {
    setLoad(true);
    axios
      .get(
        `${config.baseUrl}/v1/deductions/employee?employeeId=${employeeId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setNoDeductions(false);
            setTotalItems(res.data.totalPages * 10);
            setLoadDeductions(false);
            setDeductions(res.data.result);
          } else {
            setNoDeductions(true);
            setLoadDeductions(false);
            setDeductions([]);
          }
        } else {
          setNoDeductions(true);
          setLoadDeductions(false);
          setDeductions([]);
        }
      })
      .catch((err) => {
        setLoad(false);
        setNoDeductions(false);
        if (err) {
        }
      });
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `page=${page - 1}&size=${pageSize}`;
    getPaged(queryString);
  };

  const getPaged = (queryString) => {
    axios
      .get(
        `${config.baseUrl}/v1/deductions/employee?employeeId=${employeeId}&${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setNoDeductions(false);
            setTotalItems(res.data.totalPages * 10);
            setLoadDeductions(false);
            setDeductions(res.data.result);
          } else {
            setNoDeductions(true);
            setLoadDeductions(false);
            setDeductions([]);
          }
        } else {
          setNoDeductions(true);
          setLoadDeductions(false);
          setDeductions([]);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  return (
    <Layout>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header">
              <div className="card-body">
                <h5 class="card-title">Deductions</h5>
                <div className="mb-3">
                  <div className="form-floating">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={(e) => setEmployer(e.target.value)}
                      value={employeeId}
                    >
                      <option selected>Select Employer</option>
                      {employers.map((employer) => {
                        return (
                          <option value={employer.id}>
                            {employer.employerResponse.businessName}
                          </option>
                        );
                      })}
                    </select>
                    <label for="floatingInput">Employer</label>
                  </div>
                </div>
                <div class="d-flex justify-content-center align-items-center mt-5">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    style={{
                      background: "#1FC157",
                      color: "white",
                      width: "20%",
                    }}
                    onClick={searchDeductions}
                  >
                    {load ? "Searching" : "Search"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-12">
          {deductionHistoryData.length > 0 && (
            <div class="card table-widget">
              <div class="card-body">
                <h5 class="card-title">Deduction History </h5>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Deduction Type</th>
                        <th scope="col">Category</th>
                        <th scope="col">Monthly Deduction</th>
                        <th scope="col">Yearly Deduction</th>
                        <th scope="col">Date</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {deductionHistoryData.length > 0 && (
                        <>
                          {deductionHistoryData.map((data) => {
                            return (
                              <tr>
                                <td>
                                  {data.deductionType
                                    ? data.deductionType.replace(/_+/g, " ")
                                    : ""}
                                </td>
                                <td>
                                  {data.category
                                    ? data.category.replace("_", " ")
                                    : ""}
                                </td>

                                <td>
                                  {data.currency}
                                  {data.monthlyDeduction}
                                </td>
                                <td>
                                  {data.currency}
                                  {data.yearlyDeduction}
                                </td>
                                <td>
                                  {data.createdAt
                                    ? data.createdAt.slice(0, 10)
                                    : ""}{" "}
                                </td>

                                <td>
                                  <span
                                    className={
                                      {
                                        ENABLED: "badge bg-success",
                                        DISABLED: "badge bg-danger",
                                      }[data.status ? "ENABLED" : "DISABLED"]
                                    }
                                  >
                                    {data.status ? "ENABLED" : "DISABLED"}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      )}
                    </tbody>
                  </table>
                  <div className="text-center pagination-part pt-5">
                    <Pagination
                      current={page}
                      total={totalItems}
                      defaultPageSize={10}
                      onChange={pagination}
                      itemRender={itemRender}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}


          {noDeductionHistory && (
            <div class="card table-widget">
              <div class="card-body">
                <p className="text-center pt-5 pb-5">
                  No Deduction History Yet
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}
