/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import Layout from "../../../containers/Layout";
import axios from "axios";
import config from "../../../config";
import { Alert, Modal, Select, Spin, DatePicker, notification } from "antd";
import { useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import Location from "../../../containers/Location/index";
import { ThreeDots } from "react-loader-spinner";
import { useHttp } from "../../../hooks/useHttps";
const { Option } = Select;
const role = sessionStorage.getItem("activeRole");
let pro;
const Accounts = () => {
  const [walletInformation, setWalletInformation] = useState([]);
  const [walletCurrencyType, setWalletCurrencyType] = useState(
    walletInformation[0]?.currency
  );
  const [loadingCountries, countryDatas] = useHttp(
    `${config.baseUrl}/v1/countries/filtered`,
    []
  );
  const [addWalletModal, setOpenAddWallet] = useState(false);
  const [indWalletInformation, setIndWalletInformation] = useState([]);
  const [country, setCountry] = useState("");
  const [accountName, setAccountName] = useState("");
  const [currency, setCurrency] = useState("");
  const [currencyData, setCurrencyData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [state, setState] = useState("");
  const [load, setLoad] = useState(false);
  const [loadSendMoney, setLoadSendMoney] = useState(false);
  const [locationInfo, setLocationInfo] = useState({});
  const [residentialAddress, setResidentialAddress] = useState("");
  const [residentialCity, setResidentialCity] = useState("");
  const [residentialState, setResidentialState] = useState("");
  const [residentialPostalCode, setResidentialPostalCode] = useState("");
  const [residentialAddressManual, setResidentialManaulAddress] =
    useState(false);
  const [buusinesslAddressManual, setBusinesssManaulAddress] = useState(false);
  const [businessCity, setBusinessCity] = useState("");
  const [businessState, setBusinessState] = useState("");
  const [businessPostal, setBusinessPostalCode] = useState("");
  const [bvn, setBvn] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");
  const [responseMsgSendMoney, setResponseMsgSendMoney] = useState("");
  const [sendMoneyToggle, setSendMoneyToggle] = useState(false);
  const [receiveMoneyToggle, setReceiveMoneyToggle] = useState(false);
  const [sendMoneyData, setSendMoneyData] = useState({
    amount: "",
    countryCode: "",
    currencyCode: "",
    customerIdentifier: "",
    customerName: "",
    institutionCode: "",
    institutionName: "",
    institutionBranchCode: "",
    narration: "",
    platform: "",
    walletId: "",
  });
  const [loadUploadFront, setUploadFront] = useState(false);
  const [loadUploadBack, setUploadBack] = useState(false);
  const [loadUploadCert, setUploadCert] = useState(false);
  const [loadUploadUtility, setUploadUtility] = useState(false);
  const [sendMoneyCurrency, setSendMoneyCurrency] = useState([]);
  const [sendMoneyBtnDisabled, setSendMoneyBtnDisabled] = useState(true);
  const [sendMoneyBenefitiaryBank, setSendMoneyBenefitiaryBank] = useState([]);
  const [sendMoneyBenefitiaryBranchGhana, setSendMoneyBenefitiaryBranchGhana] =
    useState([]);
  const [sendMoneyAccountLookup, setSendMoneyAccountLookup] = useState([]);
  const [sendMoneyFeeLookup, setSendMoneyFeeLookup] = useState([]);
  const [loadTransferFee, setLoadTransferFee] = useState(false);
  const [loadTransferAccountName, setLoadTransferAccountName] = useState(false);

  const [city, setCity] = useState("");
  const [postalCode, setPostal] = useState("");
  const [frontLogo, setFrontLogo] = useState("");
  const [backLogo, setBackLogo] = useState("");
  const [certificateDoc, setCertDoc] = useState("");
  const [utilityDoc, setutilityDoc] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [showCamera, setShowCamera] = useState(false);
  const [personalAddress, setPersonalAddress] = useState("");
  const [dataIdentities, setDataIdentities] = useState([]);
  const [pin, setPin] = useState("");
  const camera = useRef(null);
  const [image, setImage] = useState(null);
  const [kycMode, setKycMode] = useState("");
  const [identity1, setIdentity1] = useState("");
  const [identity2, setIdentity2] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [userChecklists, setUserChecklist] = useState({});
  const [openPersonalKyc, setOpenPersonalKyc] = useState(false);
  const [fiscalMonth, setFiscalMonth] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [msg, setMsg] = useState("");
  const [employerChecklist, setEmployerChecklist] = useState(false);
  const [employeeChecklist, setEmployeeChecklist] = useState(false);
  const [vendorChecklist, setVendorChecklist] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [fill, setFill] = useState(false);
  const [required, setRequired] = useState(false);
  const [kycModal, setKycModal] = useState(false);
  const [personalIdentity, setPersonalIdentity] = useState("");
  const [businessIdentity, setBusinessIdentity] = useState("");
  const [kycId, setKycId] = useState("");
  const [countryBusiness, setCountryBusiness] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [position, setPosition] = useState("");
  const [otherName, setOthertName] = useState("");
  const [gender, setGender] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pvcState, setPVCState] = useState("");
  const [otpValue, setOtpValue] = useState("");
  const [businessAddress, setBizAddress] = useState("");
  const [pinConfirm, setPinConfirm] = useState("");
  const [dob, setDob] = useState("");
  const [dobReg, setDobReg] = useState("");
  const [identityDetails, setIdentityDetails] = useState("");
  const [businessIdentityDetails, setBusinessIdentityDetails] = useState("");
  const [identityType, setIdentityType] = useState([
    {
      name: "Bank Verification Number",
      type: "BVN",
    },
    {
      name: "National Identification Number",
      type: "NIN",
    },
  ]);
  const [defaultVerificationList, setDefaultVerificationList] = useState([]);
  const [businessIdentityType, setBusinessIdentityType] = useState([]);
  const [confirmationResult, setConfirmationResult] = useState({});
  const [contactInformation, setContactInfo] = useState({});
  const [lookUpKycModal, setOpenLookUpKycModal] = useState(false);
  const [selectedIdentityType, setSelectedIdentity] = useState("");
  const [selectedBusinessdentityType, setSelectedBusinessIdentityType] =
    useState({});

  const [businessName, setBusinessName] = useState("");
  const [address, setAddress] = useState("");
  const [dateOfRegistration, setDateOfRegistration] = useState("");
  const [industry, setIndustry] = useState("");
  const [size, setSize] = useState("");
  const [website, setWebsite] = useState("");
  const [supportEmail, setSupportEmail] = useState("");
  const [registerType, setRegisterType] = useState("");
  const [nhfNumber, setNhfNumber] = useState("");
  const [ownership, setOwnership] = useState("");
  const [penComCode, setPenComCode] = useState("");
  const [taxId, setTaxId] = useState("");
  const [contactDetails, setContactDetails] = useState({});
  const [steps, setSteps] = useState("personal");
  const [process, setProcess] = useState("business");

  const [disabledField, setDisabledField] = useState({
    currencyCode: true,
    institutionBranchCode: true,
    institutionName: true,
    customerIdentifier: true,
    amount: true,
  });
  const [sendMoneyAccountLookupMsg, setSendMoneyAccountLookupMsg] = useState(
    []
  );
  const [refetchWalletInfoFlag, setRefetchWalletInfoFlag] = useState(false);

  const getWalletInformations = () => {
    axios
      .get(`${config.baseUrl}/v1/wallets`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const walletInfo = res.data.result;
          setWalletInformation(walletInfo);
          setIndWalletInformation(walletInfo);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const handleChange = (value) => {
    setWalletCurrencyType(value);
    const walletCurrencyBalance = walletInformation.find(
      (wallet) => wallet.currency === value
    );

    setIndWalletInformation([
      {
        balance: walletCurrencyBalance.balance,
        currency: walletCurrencyBalance.currency,
      },
    ]);
  };

  const openAddWallet = () => {
    setOpenAddWallet(true);
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const handleBvnChange = (event) => {
    setBvn(event.target.value.slice(0, 12));
  };

  const getCurrenciesByCountry = (e) => {
    setCountry(e.target.value);
    const countryCode = e.target.value;
    axios
      .get(`${config.baseUrl}/v1/currencies/filtered/${countryCode}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setCurrencyData(res.data.result);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getCurrenciesByCountrySendMoney = () => {
    axios
      .get(
        `${config.baseUrl}/v1/currencies/filtered/${sendMoneyData.countryCode}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setSendMoneyCurrency(res.data.result);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getBenefitiaryBankSendMoney = () => {
    axios
      .get(
        // `${config.baseUrl}/v1/financial-institution/banks/GH`,
        `${config.baseUrl}/v1/financial-institution/banks/${sendMoneyData.countryCode}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setSendMoneyBenefitiaryBank(res.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const getConfirmation = (typeKyc, e) => {
    console.log(dataIdentities);
    const dataPersonal = {
      country,
      dateOfBirth: dob,
      firstName,
      identities: [
        {
          identityNumber: identity1,
          identityType: identityType[0]?.type,
        },
        {
          identityNumber: identity2,
          identityType: identityType[1]?.type,
        },
      ],
      lastName,
      otherName: otherName,
    };
    const dataBusiness = {
      businessName: businessName,
      country: countryBusiness,
      identities: [
        {
          identityNumber: businessIdentity,
          identityType: selectedBusinessdentityType,
        },
      ],
    };
    if (typeKyc === "personal") {
      console.log(dataPersonal);
      if (
        dataPersonal.country &&
        dataPersonal.identities &&
        dataPersonal.firstName &&
        otherName &&
        dataPersonal.lastName &&
        dataPersonal.dateOfBirth &&
        residentialCity &&
        residentialPostalCode &&
        residentialState &&
        residentialAddress &&
        frontLogo &&
        backLogo
      ) {
        setLoad(true);
        confirmPersonal(dataPersonal);
      } else {
        Notification("error", "Error", "All fields are required!");
      }
    } else {
      if (
        dataBusiness.businessName &&
        dataBusiness.country &&
        dataBusiness.identities &&
        businessCity &&
        businessState &&
        businessPostal &&
        certificateDoc &&
        utilityDoc
      ) {
        setLoad(true);
        confirmBusiness(dataBusiness);
      } else {
        Notification("error", "Error", "All fields are required!");
      }
    }
  };

  const dynamicIdentityListBusiness = () => {
    console.log(defaultVerificationList);
    var arr = [];
    for (var i = 0; i < defaultVerificationList?.length; i++) {
      arr?.push({
        identityNumber: defaultVerificationList[i].identityNumber,
        identityType: defaultVerificationList[i]?.identityType,
        kycId: defaultVerificationList[i]?.kycId,
        verifyType: defaultVerificationList[i]?.verifyType,
        verifyValue: confirmationResult.verifyBy === "OTP" ? pin : dobReg,
      });
    }
    var arrayBiz = arr?.filter((d) => d?.identityType === "CAC");
    return arrayBiz;
  };

  const closeLookupModal = () => {
    setOpenLookUpKycModal(false);
  };

  const handleChangePin = (pin) => setPin(pin);

  const onChangeDateOfReg = (date, dateString) => {
    setDobReg(dateString);
  };

  const getContactDetails = () => {
    axios
      .get(
        `${config.baseUrl}/v1/employers/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const contactDetails = res.data.result;
          setContactDetails(contactDetails);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getChecklists = () => {
    axios
      .get(`${config.baseUrl}/v1/users/checklist`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLocationInfo({
          city: "",
          longitude: "",
          clearAddress: "",
          lga: "",
          state: "",
          accuracy: "",
          postalcode: "",
        });
        if (res.data.status) {
          setSteps(res.data.result.contactVerified ? "business" : "personal");
          setUserChecklist(res.data.result);
          if (
            res.data.result.contactVerified &&
            res.data.result.businessVerified
          ) {
            setKycModal(false);
          }
          if (role === "EMPLOYEE") {
            if (
              !res.data.result.pinEnabled ||
              !res.data.result.paymentMethodEnabled ||
              !res.data.result.contactVerified
            ) {
              setEmployeeChecklist(true);
              return;
            }
          }
          if (role === "EMPLOYER") {
            if (
              res.data.result.pinEnabled &&
              res.data.result.billEnabled &&
              res.data.result.employeeOnboard &&
              res.data.result?.contactVerified &&
              res.data.result?.businessVerified
            ) {
              setEmployerChecklist(false);
            } else {
              setEmployerChecklist(true);
            }
          }
          if (role === "VENDOR") {
            if (
              !res.data.result.pinEnabled ||
              !res.data.result.paymentMethodEnabled ||
              !(
                res.data.result?.contactVerified &&
                res.data.result?.businessVerified
              )
            ) {
              setVendorChecklist(true);
              return;
            }
          }
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const closeEmployeePersonal = () => {
    setOpenPersonalKyc(false);
  };

  const onChange = (date, dateString) => {
    setDob(dateString);
  };

  const getBusinessKyc = (country) => {
    setCountryBusiness(country);
    axios
      .get(
        `${config.baseUrl}/v1/kyc-details/businessKycTypes?country=${country}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setBusinessIdentityType(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const businessDefaultVerify = (action) => {
    setLoad(true);
    axios
      .post(
        `${config.baseUrl}/v1/kyc-details/settlement/verifyBusinessDetail`,
        {
          businessAddress: businessAddress,
          businessPostalCode: businessPostal,
          identities:
            action === "2"
              ? dynamicIdentityListBusiness()
              : defaultVerificationList?.filter(
                  (d) => d?.identityType === "CAC"
                ),

          businessCity: businessCity,
          businessState: businessState,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          setSteps("personal");
          setOpenLookUpKycModal(false);
          setOpenPersonalKyc(false);
          setOpenAddWallet(false);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
        }
      });
  };

  const personalDefaultVerifyEmployee = (action) => {
    setLoad(true);
    const data = {
      address: address,
      state: state,
      city: city,
      otherName: otherName,
      title: title,
      position: position,
      postalCode: postalCode,
      gender: gender,
      maritalStatus: maritalStatus,
      country,
      identities:
        action === "2" ? dynamicIdentityList() : defaultVerificationList,
    };

    axios
      .post(`${config.baseUrl}/v1/kyc-details/verifyPersonalDetail`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getChecklists();
          closeEmployeePersonal();
          setOpenLookUpKycModal(false);
          setOpenPersonalKyc(false);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
        }
      });
  };

  const personalDefaultVerify = (action) => {
    setLoad(true);
    const data = {
      residentialAddress: residentialAddress,
      residentialState: residentialState,
      residentialCity: residentialCity,
      residentialPostalCode: residentialPostalCode,
      residentialCountry: country,
      identities:
        action === "2" ? dynamicIdentityList() : defaultVerificationList,
    };
    axios
      .post(
        `${config.baseUrl}/v1/kyc-details/settlement/verifyPersonalDetail`,
        data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          setSteps("business");
          setLocationInfo({
            city: "",
            longitude: "",
            clearAddress: "",
            lga: "",
            state: "",
            accuracy: "",
            postalcode: "",
          });
        } else {
          if (res.data.message === "KYC already verified") {
            Notification("success", "Success", res.data.message);
            setSteps("business");
          } else {
            Notification("error", "Error", res.data.message);
          }
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
        }
      });
  };

  const backToPersonalKyc = () => {
    setSteps("personal");
  };

  const setBusinessKycIdentityType = (e) => {
    setSelectedBusinessIdentityType(e.target.value);
    const getIdentityTypeDetails = businessIdentityType.find(
      (identity) => identity.type === e.target.value
    );
    console.log(getIdentityTypeDetails);
    setBusinessIdentityDetails(getIdentityTypeDetails.name);
  };

  const dynamicIdentityList = () => {
    console.log(defaultVerificationList);
    var arr = [];
    for (var i = 0; i < defaultVerificationList?.length; i++) {
      arr?.push({
        identityNumber: defaultVerificationList[i].identityNumber,
        identityType: defaultVerificationList[i]?.identityType,
        kycId: defaultVerificationList[i]?.kycId,
        verifyType: defaultVerificationList[i]?.verifyType,
        verifyValue: confirmationResult.verifyBy === "OTP" ? pin : dobReg,
      });
    }
    return arr;
  };

  const confirmPersonal = (data) => {
    axios
      .post(`${config.baseUrl}/v1/kyc-details/lookUpKycDetail`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setMsg(res.data.message);
          setCity("");
          setState("");
          setLocationInfo({
            city: "",
            longitude: "",
            clearAddress: "",
            lga: "",
            state: "",
            accuracy: "",
            postalcode: "",
          });
          setPersonalAddress("");
          setPostal("");
          setCountry("");
          Notification("success", "Success", res.data.message);
          for (var i = 0; i < res.data.result?.length; i++) {
            defaultVerificationList.push({
              kycId: res.data.result[i]?.id,
              identityNumber: res.data.result[i]?.identityNumber,
              identityType: res.data?.result[i]?.kycType,
              verifyType: res.data.result[i].verifyType,
              firstName: res.data.result[i]?.firstName,
              lastName: res.data.result[i].lastName,
              verifyValue: "",
            });
          }
          getDefaultVerificationListByNone(res.data.result);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
        }
      });
  };

  const handleFileChangeFront = (e) => {
    if (e.target.files[0]) {
      setFrontLogo(e.target.files[0]);
      if (role === "EMPLOYER") {
        onUploadDoc(e.target.files[0], "GOVT_ID_CARD_FRONT");
      } else {
        onUploadDocEmployee(e.target.files[0], "GOVT_ID_CARD_FRONT");
      }
    } else {
      setFrontLogo(null);
    }
  };

  const handleFileChangeBack = (e) => {
    if (e.target.files[0]) {
      setBackLogo(e.target.files[0]);
      if (role === "EMPLOYER") {
        onUploadDoc(e.target.files[0], "GOVT_ID_CARD_BACK");
      } else {
        onUploadDocEmployee(e.target.files[0], "GOVT_ID_CARD_BACK");
      }
    } else {
      setBackLogo(null);
    }
  };

  const handleFileChangeUtility = (e) => {
    if (e.target.files[0]) {
      setutilityDoc(e.target.files[0]);
      if (role === "EMPLOYER") {
        onUploadDoc(e.target.files[0], "UTILITY_BILL");
      } else {
        onUploadDocEmployee(e.target.files[0], "UTILITY_BILL");
      }
    } else {
      setutilityDoc(null);
    }
  };

  const handleFileChangeCertificate = (e) => {
    if (e.target.files[0]) {
      setCertDoc(e.target.files[0]);
      onUploadDocEmployee(e.target.files[0], "CERTIFICATE_OF_INCORPORATION");
    } else {
      setCertDoc(null);
    }
  };

  const onUploadDocEmployee = (typeDoc, type) => {
    const data = new FormData();
    data.append("file", typeDoc);
    if (type === "GOVT_ID_CARD_FRONT") {
      setUploadFront(true);
    } else if (type === "GOVT_ID_CARD_BACK") {
      setUploadBack(true);
    } else if (type === "CERTIFICATE_OF_INCORPORATION") {
      setUploadCert(true);
    } else if (type === "UTILITY_BILL") {
      setUploadUtility(true);
    }
    axios
      .put(`${config.baseUrl}/v1/users/upload-document/${type}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        setUploadFront(false);
        setUploadBack(false);
        setUploadUtility(false);
        setUploadCert(false);

        if (res.data.status) {
          Notification("success", "Success", res.data.message);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        setUploadFront(false);
        setUploadBack(false);
        setUploadUtility(false);
        setUploadCert(false);
        console.log(err);
      });
  };

  const onUploadDoc = (typeDoc, type) => {
    const data = new FormData();
    data.append("file", typeDoc);
    if (type === "GOVT_ID_CARD_FRONT") {
      setUploadFront(true);
    } else if (type === "GOVT_ID_CARD_BACK") {
      setUploadBack(true);
    } else if (type === "CERTIFICATE_OF_INCORPORATION") {
      setUploadCert(true);
    } else if (type === "UTILITY_BILL") {
      setUploadUtility(true);
    }
    axios
      .put(
        `${
          config.baseUrl
        }/v1/employers/upload-document/${type}/${sessionStorage.getItem(
          "employer_id"
        )}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        setUploadFront(false);
        setUploadBack(false);
        setUploadUtility(false);
        setUploadCert(false);

        if (res.data.status) {
          Notification("success", "Success", res.data.message);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        setUploadFront(false);
        setUploadBack(false);
        setUploadUtility(false);
        setUploadCert(false);
        console.log(err);
      });
  };

  const getDefaultVerificationListByNone = (arr) => {
    var filterTypeVerifyTypeNone = arr?.filter((x) => x?.verifyType === "NONE");
    if (filterTypeVerifyTypeNone?.length > 0) {
      personalDefaultVerify("1");
    } else {
      setConfirmationResult(arr);
      setOpenLookUpKycModal(true);
    }
  };

  const confirmBusiness = (data) => {
    axios
      .post(`${config.baseUrl}/v1/kyc-details/lookUpKycDetail`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        var responseArr = [];
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          for (var i = 0; i < res.data.result?.length; i++) {
            defaultVerificationList.push({
              kycId: res.data.result[i]?.id,
              identityNumber: res.data.result[i]?.identityNumber,
              identityType: res.data?.result[i]?.kycType,
              verifyType: res.data.result[i].verifyType,
              businessName: res.data.result[i].businessName,
            });

            responseArr.push({
              kycId: res.data.result[i]?.id,
              identityNumber: res.data.result[i]?.identityNumber,
              identityType: res.data?.result[i]?.kycType,
              verifyType: res.data.result[i].verifyType,
              businessName: res.data.result[i].businessName,
            });
            if (res.data.result[i]?.verifyType === "NONE") {
              businessDefaultVerify("1");
            } else {
              setOpenLookUpKycModal(true);
              setConfirmationResult(responseArr[0]);
            }
          }
          // if (res.data.result.verifyBy === "NONE") {
          //   setKycId(res.data.result.id);
          //   setOpenLookUpKycModal(true);
          //   personalDefaultVerify(
          //     res.data.result.id,
          //     businessIdentity,
          //     selectedBusinessdentityType
          //   );
          // } else {
          //   setConfirmationResult(res.data.result);
          // }
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
        }
      });
  };

  const getBenefitiaryBranchSendMoney = () => {
    axios
      .get(
        `${config.baseUrl}/v1/financial-institution/branches/${
          sendMoneyData.institutionName.split("<->")[0]
        }`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          setSendMoneyBenefitiaryBranchGhana(res.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFee = () => {
    const parts = sendMoneyData.platform.split(" ");
    const walletId = parts[parts.length - 1];

    setLoadTransferFee(true);
    axios
      .get(
        `${config.baseUrl}/v1/wallets/fee/${walletId}/BANK_ACCOUNT/${sendMoneyData.currencyCode}/${sendMoneyData.amount}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadTransferFee(false);
        if (res.data.status) {
          setSendMoneyFeeLookup(res.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadTransferFee(false);
      });
  };

  const getAccountLookupSendMoney = () => {
    setLoadTransferAccountName(true);
    const data = {
      accountBank: sendMoneyData.institutionName.split("<->")[2],
      accountNumber: sendMoneyData.customerIdentifier,
      countryCode: sendMoneyData.countryCode,
    };

    axios
      .post(`${config.baseUrl}/v1/financial-institution/account-query`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoadTransferAccountName(false);
        if (res.data.status) {
          setSendMoneyAccountLookup(res.data.result);
          setSendMoneyData((prevState) => ({
            ...prevState,
            customerName: res.data.result.accountName,
          }));
        } else {
          console.log(res);
          setSendMoneyAccountLookupMsg(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadTransferAccountName(false);
      });
  };

  const getCountryDetails = () => {
    setLoad(true);
    axios
      .get(`${config.baseUrl}/v1/countries/filtered`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setCountryData(res.data.result);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
        }
      });
  };

  const addNewWallet = () => {
    const data = {
      accountName,
      bvn,
      country,
      currency,
    };

    if (!accountName || !country || !currency) {
      setAlertError(true);
      setTimeout(() => {
        setAlertError(false);
      }, 2500);
    } else {
      axios
        .post(`${config.baseUrl}/v1/wallets`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setSuccess(true);
            // setPurpose("");
            setBvn("");
            setAccountName("");
            setCurrency("");
            setCountry("");
            setResponseMsg(res.data.message);
            setTimeout(() => {
              setSuccess(false);
              setOpenAddWallet(false);
              getWalletInformations();
            }, 2500);
          } else {
            setError(true);
            setResponseMsg(res.data.message);
            setTimeout(() => {
              setError(false);
            }, 2500);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const cancelAddWallet = () => {
    setOpenAddWallet(false);
    setBvn("");
    setAccountName("");
    setCurrency("");
    setCountry("");
  };

  const cancelSendMoney = () => {
    setSendMoneyToggle(false);
    // setBvn("");
    // setAccountName("");
    // setCurrency("");
    // setCountry("");
  };

  const cancelReceiveMoney = () => {
    setReceiveMoneyToggle(false);
  };

  const sendMoneyFunc = (e) => {
    e.preventDefault();
    setSendMoneyToggle(true);
  };

  const receiveMoneyFunc = (e) => {
    e.preventDefault();
    setReceiveMoneyToggle(true);
  };

  const handleSendMoneyChange = (field, value) => {
    setSendMoneyData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const sendMoney = () => {
    setLoadSendMoney(true);

    const data = {
      amount: sendMoneyData.amount,
      countryCode: sendMoneyData.countryCode,
      currencyCode: sendMoneyData.currencyCode,
      customerIdentifier: sendMoneyData.customerIdentifier,
      customerName: sendMoneyData.customerName,
      institutionCode: sendMoneyData.institutionCode,
      institutionName: sendMoneyData.institutionName.split("<->")[1],
      institutionBranchCode: sendMoneyData.institutionBranchCode,
      narration: sendMoneyData.narration,
      platform: "WEB",
      walletId: sendMoneyData.walletId,
    };

    axios
      .post(`${config.baseUrl}/v1/wallets/transfer/bank-account`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoadSendMoney(false);
        if (res.data.status) {
          setSuccess(true);
          setSendMoneyData((prevState) => ({
            ...prevState,
            amount: "",
            countryCode: "",
            currencyCode: "",
            customerIdentifier: "",
            customerName: "",
            institutionCode: "",
            institutionName: "",
            narration: "",
            pin: "",
            platform: "",
            walletId: "",
          }));
          setResponseMsgSendMoney(res.data.message);
          setRefetchWalletInfoFlag(!refetchWalletInfoFlag);
          setTimeout(() => {
            setSendMoneyToggle(false);
          }, 2500);
        } else {
          setError(true);
          setResponseMsgSendMoney(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(steps, "its now here");

  useEffect(() => {
    const parts = sendMoneyData.platform.split(" ");
    const walletId = parts[parts.length - 1];

    setSendMoneyData((prevState) => ({
      ...prevState,
      walletId: walletId,
      institutionCode: sendMoneyData.institutionName.split("<->")[2],
    }));
  }, [sendMoneyData.platform, sendMoneyData.institutionName]);

  useEffect(() => {
    getWalletInformations();
  }, [refetchWalletInfoFlag]);

  useEffect(() => {
    pro = "personal";
    getCountryDetails();
  }, []);

  useEffect(() => {
    if (sendMoneyData.countryCode !== "") {
      getCurrenciesByCountrySendMoney();
      getBenefitiaryBankSendMoney();
    }
  }, [sendMoneyData.countryCode]);

  useEffect(() => {
    if (
      sendMoneyData.institutionName !== "" &&
      sendMoneyData.countryCode === "GH"
    ) {
      getBenefitiaryBranchSendMoney();
    }
  }, [sendMoneyData.institutionName]);

  useEffect(() => {
    if (
      sendMoneyData.institutionName !== "" &&
      sendMoneyData.customerIdentifier.length === 10
    ) {
      getAccountLookupSendMoney();
    }
  }, [sendMoneyData.institutionName, sendMoneyData.customerIdentifier]);

  useEffect(() => {
    if (sendMoneyData.currencyCode !== "" && sendMoneyData.amount !== "") {
      let timer;

      clearTimeout(timer);

      timer = setTimeout(() => {
        getFee();
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [sendMoneyData.currencyCode, sendMoneyData.amount]);

  useEffect(() => {
    if (sendMoneyData.countryCode !== "") {
      setDisabledField((prevState) => ({
        ...prevState,
        currencyCode: false,
        // institutionCode: false,
        institutionName: false,
        customerIdentifier: false,
      }));
    }
    if (sendMoneyData.platform !== "") {
      setDisabledField((prevState) => ({
        ...prevState,
        amount: false,
      }));
    }
  }, [sendMoneyData.countryCode, sendMoneyData.walletId]);

  useEffect(() => {
    if (sendMoneyData.institutionName !== "") {
      setDisabledField((prevState) => ({
        ...prevState,
        institutionBranchCode: false,
      }));
    }
  }, [sendMoneyData.institutionName]);

  useEffect(() => {
    if (
      sendMoneyData.platform !== "" &&
      sendMoneyData.countryCode !== "" &&
      sendMoneyData.currencyCode !== "" &&
      sendMoneyData.customerIdentifier !== "" &&
      sendMoneyData.customerName !== "" &&
      sendMoneyData.institutionName !== "" &&
      sendMoneyData.narration !== "" &&
      sendMoneyData.platform !== "" &&
      sendMoneyData.walletId !== ""
    ) {
      setSendMoneyBtnDisabled(false);
    } else {
      setSendMoneyBtnDisabled(true);
    }
  }, [sendMoneyData]);

  return (
    <Layout>
      <div>
        <div className="col-sm-12 col-md-12">
          <div class="card">
            <div class="card-header font-weight-bold">
              <div class="d-flex justify-content-between align-items-center">
                <div className="d-flex gap-3 align-items-center">
                  <b>ACCOUNT BALANCE</b>
                  {walletInformation.length > 0 && (
                    <Select
                      defaultValue={walletInformation[0]?.currency}
                      style={{ width: 80 }}
                      onChange={handleChange}
                      value={walletCurrencyType}
                    >
                      {walletInformation.map((currencyCode) => {
                        return (
                          <Option
                            key={currencyCode.id}
                            value={currencyCode.currency}
                          >
                            {currencyCode.currency}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </div>
                <button
                  type="submit"
                  className="btn btn-info m-b-xs"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={openAddWallet}
                >
                  + Add Account
                </button>
              </div>
            </div>
            <div class="card-body">
              <div className="text-center py-3">
                <h2>
                  {indWalletInformation.length > 0
                    ? indWalletInformation[0].currency
                    : ""}{" "}
                  {Intl.NumberFormat("en-US").format(
                    indWalletInformation.length > 0
                      ? indWalletInformation[0].balance
                      : 0
                  )}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title="Add Account"
          visible={addWalletModal}
          okText="Submit"
          footer={false}
          width={1000}
          maskClosable={false}
          onCancel={cancelAddWallet}
        >
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}

          {error && (
            <div className="pb-3">
              <Alert message={responseMsg} type="error" showIcon />
            </div>
          )}

          {success && (
            <div className="pb-3">
              <Alert message={responseMsg} type="success" showIcon />
            </div>
          )}

          {alertError && (
            <div className="pb-3">
              <Alert message="All fields are required!" type="error" showIcon />
            </div>
          )}

          <br />
          {steps === "personal" && (
            <div>
              <div className="font-weight-bold text-center pb-3">
                Personal Details
              </div>
              <br />
              <div className="row">
                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      disabled={contactDetails.firstName ? true : false}
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      disabled={contactDetails.lastName ? true : false}
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Other Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      disabled={contactDetails.middleName ? true : false}
                      value={
                        contactDetails ? contactDetails.middleName : otherName
                      }
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={(e) => setOthertName(e.target.value)}
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Date of Birth
                    </label>
                    {contactDetails.dateOfBirth ? (
                      <input
                        type="text"
                        class="form-control"
                        value={
                          contactDetails ? contactDetails.dateOfBirth : dob
                        }
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        disabled
                        onChange={(e) => setOthertName(e.target.value)}
                      />
                    ) : (
                      <DatePicker
                        onChange={onChange}
                        style={{ height: "3rem", width: "100%" }}
                      />
                    )}
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <label for="" class="">
                        Residential Address
                      </label>
                      <div
                        style={{ color: "#1fc157", cursor: "pointer" }}
                        onClick={() => {
                          setResidentialManaulAddress(
                            !residentialAddressManual
                          );
                          setLocationInfo({});
                          setBusinessCity("");
                          setBusinessState("");
                          setBusinessPostalCode("");
                          setResidentialAddress("");
                          setResidentialPostalCode("");
                          setResidentialState("");
                          setResidentialCity("");
                        }}
                      >
                        {residentialAddressManual
                          ? "Enter Address Automatically"
                          : "Enter Address Manually"}
                      </div>
                    </div>

                    {residentialAddressManual ? (
                      <input
                        type="text"
                        class="form-control mt-2"
                        value={residentialAddress}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={(e) => setResidentialAddress(e.target.value)}
                      />
                    ) : (
                      <div className="pt-2">
                        <Location
                          setAddress={setResidentialAddress}
                          setCity={setResidentialCity}
                          setState={setResidentialState}
                          setPostal={setResidentialPostalCode}
                          setLocationInfo={setLocationInfo}
                          register={register}
                          control={control}
                          errors={errors}
                          watch={watch}
                          reset={reset}
                          setValue={setValue}
                          registerVal={undefined}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Residential City
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      disabled={locationInfo?.city ? true : false}
                      value={residentialCity}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={(e) => setResidentialCity(e.target.value)}
                    />
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Residential Postal Code
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={residentialPostalCode}
                      disabled={locationInfo?.postalcode ? true : false}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={(e) => setResidentialPostalCode(e.target.value)}
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Residential State
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      disabled={locationInfo?.state ? true : false}
                      value={residentialState}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={(e) => setResidentialState(e.target.value)}
                    />
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Residential Country
                    </label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={(e) => setCountry(e.target.value)}
                      value={country}
                    >
                      <option selected>Select</option>
                      {countryDatas?.length > 0 ? (
                        countryDatas.map((country) => {
                          return (
                            <option value={country.code}>{country.name}</option>
                          );
                        })
                      ) : (
                        <option></option>
                      )}
                    </select>
                  </div>
                </div>

                {identityType?.length > 0 && (
                  <div class="col-sm-12 col-md-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        {identityType[0]?.name}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        onChange={(e) => setIdentity1(e.target.value)}
                        value={identity1}
                      />
                    </div>
                  </div>
                )}

                {identityType?.length > 0 && (
                  <div class="col-sm-12 col-md-6">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        {identityType[1]?.name}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        onChange={(e) => setIdentity2(e.target.value)}
                        value={identity2}
                      />
                    </div>
                  </div>
                )}
                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="formFileSma" class="form-label">
                      Upload Govt ID Front (Driver License, PVC , International
                      Passport , NIN)
                    </label>
                    <input
                      class="form-control form-control-sm"
                      id="formFileSma"
                      type="file"
                      onChange={handleFileChangeFront}
                    />

                    <ThreeDots
                      visible={loadUploadFront}
                      height="80"
                      width="80"
                      color="#4fa94d"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="formFileSmb" class="form-label">
                      Upload Govt Back (Driver License, PVC, International
                      Passport , NIN)
                    </label>
                    <input
                      class="form-control form-control-sm"
                      id="formFileSmb"
                      type="file"
                      onChange={handleFileChangeBack}
                    />

                    <ThreeDots
                      visible={loadUploadBack}
                      height="80"
                      width="80"
                      color="#4fa94d"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn btn-info m-b-xs"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={getConfirmation.bind(this, "personal")}
                >
                  {load ? (
                    <ThreeDots
                      visible={load}
                      height="30"
                      width="40"
                      color="#ffffff"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  ) : (
                    "Continue"
                  )}
                </button>
              </div>
            </div>
          )}
          {steps === "business" && (
            <div>
              <div className="font-weight-bold text-center pb-3">
                Business Details
              </div>
              <br />
              <div className="row">
                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Country
                    </label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={(e) => getBusinessKyc(e.target.value)}
                      value={countryBusiness}
                    >
                      <option selected>Select</option>
                      {countryDatas?.length > 0 ? (
                        countryDatas.map((country) => {
                          return (
                            <option value={country.code}>{country.name}</option>
                          );
                        })
                      ) : (
                        <option></option>
                      )}
                    </select>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Identity Type
                    </label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={setBusinessKycIdentityType}
                      // value={selectedBusinessdentityType}
                    >
                      <option selected>Select</option>
                      {businessIdentityType?.length > 0 ? (
                        businessIdentityType.map((identity) => {
                          return (
                            <option value={identity.type}>
                              {identity.name}
                            </option>
                          );
                        })
                      ) : (
                        <option></option>
                      )}
                    </select>
                  </div>
                </div>

                {businessIdentityType?.length > 0 && (
                  <div class="col-sm-12 col-md-12">
                    <div class="mb-3">
                      <label for="exampleInputEmail1" class="form-label">
                        {businessIdentityDetails}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={businessIdentity}
                        onChange={(e) => setBusinessIdentity(e.target.value)}
                      />
                    </div>
                  </div>
                )}
                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Business Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={businessName}
                      id="exampleInputEmail1"
                      onChange={(e) => setBusinessName(e.target.value)}
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <label for="" class="">
                        Business Address
                      </label>
                      <div
                        style={{ color: "#1fc157", cursor: "pointer" }}
                        onClick={() => {
                          setBusinesssManaulAddress(!buusinesslAddressManual);
                          setLocationInfo({});
                          setBusinessCity("");
                          setBusinessState("");
                          setBizAddress("");
                          setBusinessPostalCode("");
                          setResidentialAddress("");
                          setResidentialState("");
                          setResidentialPostalCode("");
                          setResidentialCity("");
                        }}
                      >
                        {buusinesslAddressManual
                          ? "Enter Address Automatically"
                          : "Enter Address Manually"}
                      </div>
                    </div>
                    {buusinesslAddressManual ? (
                      <input
                        type="text"
                        class="form-control mt-2"
                        value={businessAddress}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        onChange={(e) => setBizAddress(e.target.value)}
                      />
                    ) : (
                      <div className="pt-2">
                        <Location
                          setAddress={setBizAddress}
                          setCity={setBusinessCity}
                          setState={setBusinessState}
                          setPostal={setBusinessPostalCode}
                          setLocationInfo={setLocationInfo}
                          register={register}
                          control={control}
                          errors={errors}
                          watch={watch}
                          reset={reset}
                          setValue={setValue}
                          registerVal={undefined}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Business City
                    </label>
                    <input
                      type="text"
                      disabled={locationInfo?.city ? true : false}
                      class="form-control"
                      onChange={(e) => setBusinessCity(e.target?.value)}
                      value={businessCity}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Business State
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      disabled={locationInfo?.state ? true : false}
                      onChange={(e) => setBusinessState(e.target?.value)}
                      value={businessState}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Business Postal Code
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      disabled={locationInfo?.postalcode ? true : false}
                      onChange={(e) => setBusinessPostalCode(e.target?.value)}
                      value={businessPostal}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="formFileSma" class="form-label">
                      Upload Certificate of Incorporation
                    </label>
                    <input
                      class="form-control form-control-sm"
                      id="formFileSma"
                      type="file"
                      onChange={handleFileChangeCertificate}
                    />

                    <ThreeDots
                      visible={loadUploadCert}
                      height="80"
                      width="80"
                      color="#4fa94d"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="mb-3">
                    <label for="formFileSmb" class="form-label">
                      Upload Utility Bill (Electricity Bill, Waste Bill, Bank
                      Statement)
                    </label>
                    <input
                      class="form-control form-control-sm"
                      id="formFileSmb"
                      type="file"
                      onChange={handleFileChangeUtility}
                    />
                    <ThreeDots
                      visible={loadUploadUtility}
                      height="80"
                      width="80"
                      color="#4fa94d"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="d-flex justify-content-between align-items-center ">
                  <button
                    type="submit"
                    className="btn btn-info m-b-xs"
                    style={{ background: "#1FC157", color: "white" }}
                    onClick={backToPersonalKyc}
                    disabled={userChecklists}
                  >
                    Previous
                  </button>
                  <button
                    type="submit"
                    className="btn btn-info m-b-xs"
                    style={{ background: "#1FC157", color: "white" }}
                    onClick={getConfirmation.bind(this, "business")}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* <form onSubmit={handleSubmit(addNewWallet)}>
            <div className="mb-3">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Account name"
                  onChange={(e) => setAccountName(e.target.value)}
                  value={accountName}
                />
                <label for="floatingInput">Account Name</label>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-floating">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  value={country}
                  onChange={getCurrenciesByCountry}
                >
                  <option selected>Select </option>
                  {countryData?.map((country) => {
                    return <option value={country.code}>{country.name}</option>;
                  })}
                </select>
                <label for="floatingInput">Country</label>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-floating">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                >
                  <option selected>Select </option>
                  {currencyData.map((currency) => {
                    return (
                      <option value={currency.currencyCode}>
                        {currency.currencyName}
                      </option>
                    );
                  })}
                </select>
                <label for="floatingInput">Currency</label>
              </div>
            </div>
            {country === "NG" && (
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="number"
                    name="bvn"
                    value={bvn}
                    maxLength={12}
                    className="form-control"
                    {...register("bvn", {
                      required: "BVN is required!",
                      maxLength: {
                        value: 11,
                        message: "BVN must be 11 digits",
                      },
                      minLength: {
                        value: 11,
                        message: "BVN must be 11 digits",
                      },
                      onChange: handleBvnChange,
                      value: bvn,
                    })}
                  />
                  <label for="floatingInput">Bvn</label>
                  {errors.bvn && (
                    <span className="validate-error">{errors.bvn.message}</span>
                  )}
                </div>
              </div>
            )}
            <br />
            <div className="d-flex justify-content-end align-items-center">
              <button
                type="submit"
                className="btn btn-info m-b-xs"
                style={{ background: "#1FC157", color: "white" }}
              >
                Submit
              </button>
            </div>
          </form> */}
        </Modal>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12">
          <div className="card ">
            <div className="card-header font-weight-bold">FUND ACCOUNT</div>
            <div className="card-body mb-0 pb-0">
              <div className="container">
                <div
                  className="row d-flex justify-content-center align-items-center"
                  style={{ width: "100%" }}
                >
                  <div
                    class="col-sm-12 col-md-4"
                    style={{ cursor: "pointer" }}
                    onClick={sendMoneyFunc}
                  >
                    <div class="card folder">
                      <div
                        class="card-body"
                        style={{
                          background: "#f5f5f5",
                          borderRadius: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "full",
                          flexDirection: "column",
                        }}
                      >
                        <div class="folder-icon ">
                          <img
                            src="https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/airtime.png"
                            alt=""
                            width="40"
                          />
                        </div>
                        <div class="folder-info">
                          <p className="h5 pt-3">Send Money</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-sm-12 col-md-4"
                    style={{ cursor: "pointer" }}
                    onClick={receiveMoneyFunc}
                  >
                    <div class="card folder">
                      <div
                        class="card-body"
                        style={{
                          background: "#f5f5f5",
                          borderRadius: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "full",
                          flexDirection: "column",
                        }}
                      >
                        <div class="folder-icon ">
                          <img
                            src="https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/databundle.png"
                            alt=""
                            width="40"
                          />
                        </div>
                        <div class="folder-info">
                          <p className="h5 pt-3">Receive Money</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Modal
                title={"Send Money"}
                visible={sendMoneyToggle}
                okText="Submit"
                footer={false}
                maskClosable={false}
                onCancel={cancelSendMoney}
              >
                {loadSendMoney && (
                  <p className="text-center">
                    <Spin />
                  </p>
                )}

                {error && (
                  <div className="pb-3">
                    <Alert
                      message={responseMsgSendMoney}
                      type="error"
                      showIcon
                    />
                  </div>
                )}

                {success && (
                  <div className="pb-3">
                    <Alert
                      message={responseMsgSendMoney}
                      type="success"
                      showIcon
                    />
                  </div>
                )}
                <div className="container">
                  <form onSubmit={handleSubmit(sendMoney)}>
                    <div className="mb-3">
                      <div className="form-floating">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          value={sendMoneyData.platform}
                          onChange={(e) =>
                            handleSendMoneyChange("platform", e.target.value)
                          }
                        >
                          <option selected>Select </option>
                          {walletInformation?.map((wallet) => {
                            return (
                              <option
                                value={`${wallet.accountNumber} (${wallet.currency} ${wallet.balance}) ${wallet.id}`}
                              >
                                {`${wallet.accountNumber} (${wallet.currency} ${wallet.balance})`}
                              </option>
                            );
                          })}
                        </select>
                        <label for="floatingInput">Select Account</label>
                      </div>
                    </div>
                    <div
                      className="mb-3 d-flex justify-content-between align-items-center gap-5"
                      style={{ width: "100%" }}
                    >
                      <div className="form-floating" style={{ width: "100%" }}>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          value={sendMoneyData.countryCode}
                          onChange={(e) =>
                            handleSendMoneyChange("countryCode", e.target.value)
                          }
                        >
                          <option selected>Select</option>
                          {countryData?.map((country) => {
                            return (
                              <option value={country.code}>
                                {country.name}
                              </option>
                            );
                          })}
                        </select>
                        <label for="floatingInput">Beneficiary Country</label>
                      </div>
                      <div className="form-floating" style={{ width: "100%" }}>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          value={sendMoneyData.currencyCode}
                          onChange={(e) =>
                            handleSendMoneyChange(
                              "currencyCode",
                              e.target.value
                            )
                          }
                          disabled={disabledField.currencyCode}
                        >
                          <option selected>Select </option>
                          {sendMoneyCurrency?.map((currency) => {
                            return (
                              <option value={currency.currencyCode}>
                                {currency.currencyName}
                              </option>
                            );
                          })}
                        </select>
                        <label for="floatingInput">Beneficiary Currency</label>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="form-floating">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          value={sendMoneyData.institutionName}
                          onChange={(e) =>
                            handleSendMoneyChange(
                              "institutionName",
                              e.target.value
                            )
                          }
                          disabled={disabledField.institutionName}
                        >
                          <option selected>Select </option>
                          {sendMoneyBenefitiaryBank?.map((bank) => {
                            return (
                              <option
                                value={`${bank.id}<->${bank.bankName}<->${bank.bankCode}`}
                              >
                                {bank.bankName}
                              </option>
                            );
                          })}
                        </select>
                        <label for="floatingInput">Benefitiary Bank</label>
                      </div>
                    </div>
                    {sendMoneyData.countryCode === "GH" && (
                      <div className="mb-3">
                        <div className="form-floating">
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            value={sendMoneyData.institutionBranchCode}
                            onChange={(e) =>
                              handleSendMoneyChange(
                                "institutionBranchCode",
                                e.target.value
                              )
                            }
                            disabled={disabledField.institutionBranchCode}
                          >
                            <option selected>Select </option>
                            {sendMoneyBenefitiaryBranchGhana?.map((bank) => {
                              return (
                                <option value={`${bank.id}`}>
                                  {bank.branchName}
                                </option>
                              );
                            })}
                          </select>
                          <label for="floatingInput">Benefitiary Branch</label>
                        </div>
                      </div>
                    )}
                    <div className="mb-0">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="Account name"
                          value={sendMoneyData.customerIdentifier}
                          onChange={(e) =>
                            handleSendMoneyChange(
                              "customerIdentifier",
                              e.target.value
                            )
                          }
                          disabled={disabledField.customerIdentifier}
                        />
                        <label for="floatingInput">Account Number</label>
                      </div>
                      <div
                        className="mt-2 d-flex justify-content-end gap-5"
                        style={{ width: "100%" }}
                      >
                        {loadTransferAccountName ? (
                          <p style={{ fontStyle: "italic", color: "gray" }}>
                            Loading...
                          </p>
                        ) : (
                          <>
                            {sendMoneyAccountLookup?.accountName && (
                              <p>{sendMoneyAccountLookup?.accountName}</p>
                            )}
                            {sendMoneyAccountLookupMsg?.message && (
                              <p style={{ color: "red" }}>
                                {sendMoneyAccountLookupMsg?.message}
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="mb-0">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="Amount"
                          value={sendMoneyData.amount}
                          onChange={(e) =>
                            handleSendMoneyChange("amount", e.target.value)
                          }
                          disabled={disabledField.amount}
                        />
                        <label for="floatingInput">Amount</label>
                      </div>
                      <div
                        className="mt-2 d-flex justify-content-end gap-5"
                        style={{ width: "100%" }}
                      >
                        {loadTransferFee ? (
                          <p style={{ fontStyle: "italic", color: "gray" }}>
                            Loading...
                          </p>
                        ) : (
                          <p>
                            {sendMoneyFeeLookup.merchantFee
                              ? sendMoneyFeeLookup.merchantFee
                              : 0}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mb-0">
                      <div className="form-floating">
                        <textarea
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="Narration"
                          value={sendMoneyData.narration}
                          onChange={(e) =>
                            handleSendMoneyChange("narration", e.target.value)
                          }
                        ></textarea>
                        <label for="floatingInput">Narration</label>
                      </div>
                    </div>
                    <button
                      type="submit"
                      disabled={sendMoneyBtnDisabled}
                      className="btn btn-info m-b-xs mt-3"
                      style={{ background: "#1FC157", color: "white" }}
                      // onClick={() =>
                      //   handleMono({
                      //     onClose: () => null,
                      //     onSuccess: (response) => {
                      //       setDirectDebitCode(response.code);
                      //       setFundModal(true);
                      //       setLoadFee(false);
                      //       setDirectDebitModal(false);
                      //     },
                      //   })
                      // }
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </Modal>
              <Modal
                title={"Receive Money"}
                visible={receiveMoneyToggle}
                okText="Submit"
                footer={false}
                maskClosable={false}
                onCancel={cancelReceiveMoney}
              >
                <div className="container">
                  {walletInformation.map((wallet) => (
                    <>
                      <div className="d-flex justify-content-between align-items-center">
                        <p>Currency</p>
                        <Select
                          defaultValue={walletInformation[0]?.currency}
                          style={{ width: 80 }}
                          onChange={handleChange}
                          value={walletCurrencyType}
                        >
                          {walletInformation.map((currencyCode) => {
                            return (
                              <Option
                                key={currencyCode.id}
                                value={currencyCode.currency}
                              >
                                {currencyCode.currency}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                      <br />
                      <div className="d-flex justify-content-between align-items-center">
                        <p>Account Name</p>
                        <p>{wallet.accountName}</p>
                      </div>
                      <br />
                      <div className="d-flex justify-content-between align-items-center">
                        <p>Account Number</p>
                        <p>{wallet.accountNumber}</p>
                      </div>
                      <br />
                      <div className="d-flex justify-content-between align-items-center">
                        <p>Bank Name</p>
                        <p>{wallet.bankName}</p>
                      </div>
                    </>
                  ))}
                </div>
              </Modal>
              <Modal
                title={false}
                visible={lookUpKycModal}
                footer={false}
                maskClosable={false}
                onCancel={closeLookupModal}
              >
                <div className="pt-5">
                  {load && (
                    <div class="text-center">
                      <Spin />
                      <p className="text-info font-weight-bold">
                        Verification in progress
                      </p>
                    </div>
                  )}

                  <br />

                  {confirmationResult?.verifyType === "NONE" ||
                    (confirmationResult?.verifyType === "OTP" && (
                      <div className="">
                        {steps === "personal" ? (
                          <div className="">
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                First Name
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                value={confirmationResult.firstName}
                                disabled
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                              />
                            </div>
                            <div class="mb-3">
                              <label
                                for="exampleInputEmail1"
                                class="form-label"
                              >
                                Last Name
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                value={confirmationResult.lastName}
                                disabled
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                              />
                            </div>
                          </div>
                        ) : (
                          <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label">
                              Business Name
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              value={confirmationResult.companyName}
                              disabled
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                            />
                          </div>
                        )}

                        {confirmationResult?.verifyType === "OTP" && (
                          <div>
                            <label>Otp</label>
                            <OtpInput
                              value={pin}
                              onChange={handleChangePin}
                              isInputNum
                              isInputSecure
                              inputStyle={{
                                padding: "15px",
                                border: ".9px solid green",
                                width: "50px",
                              }}
                              numInputs={5}
                              separator={<span>-</span>}
                            />
                          </div>
                        )}
                      </div>
                    ))}

                  {confirmationResult?.verifyType ===
                    "DATE_OF_REGISTRATION" && (
                    <div>
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Business Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={confirmationResult.businessName}
                          disabled
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div class="col-sm-12 col-md-12">
                        <div>
                          <label for="exampleInputEmail1" class="form-label">
                            Business Date of Registration{" "}
                          </label>
                          <DatePicker
                            onChange={onChangeDateOfReg}
                            style={{ height: "3rem", width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <br />
                  <div className="d-flex justify-content-end">
                    {confirmationResult?.verifyType !== "NONE" && (
                      <button
                        type="submit"
                        className="btn btn-info m-b-xs"
                        style={{ background: "#1FC157", color: "white" }}
                        onClick={
                          steps === "business"
                            ? businessDefaultVerify.bind(this, "2")
                            : personalDefaultVerify.bind(this, "2")
                        }
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Accounts;
