import React, { useState } from "react";
import axios from "axios";
import Cookie from "js-cookie";
import { Spin, Modal, Button, Space, Alert, notification } from "antd";
import OtpInput from "react-otp-input";
import { useHttp } from "../../hooks/useHttps";
import config from "../../config";
export default function Register(props) {
  const [loader, setLoad] = useState(false);
  const [cpass, setCpass] = useState("");
  const [email, setEmail] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [country, setCountry] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [mobile, setMobile] = useState("");
  const [pass, setPass] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [sucessMsg, setSuccessMsg] = useState("");
  const [msg, setMsg] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [otpValue, setOtpValue] = useState(false);
  const [otpBox, setOtpBox] = useState(false);
  const [otpAlert, setOtpAlert] = useState(false);
  const [loadRegister, setLoadRegister] = useState(false);
  const [requireFields, setRequired] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);

  const [loading, countryDatas] = useHttp(
    `${config.baseUrl}/v1/countries/filtered`,
    []
  );
  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };
  const handleChange = (otp) => setOtpValue(otp);

  const register = (e) => {
    e.preventDefault();
    const data = {
      confirmPassword: cpass,
      email: email,
      firstName: fname,
      lastName: lname,
      mobile: mobile,
      password: pass,
      businessName,
      country,
    };

    if (
      data.firstName &&
      data.confirmPassword &&
      data.email &&
      data.lastName &&
      data.mobile &&
      data.password &&
      data.businessName &&
      data.country
    ) {
      if (data.password === data.confirmPassword) {
        setLoad(true);
        axios
          .post(`${config.baseUrl}/v1/auth/register-vendor`, data)
          .then((res) => {
            setLoad(false);
            if (res.data.result) {
              // setSuccess(true);
              sessionStorage.setItem("tempReg", res.data.result.accessToken);
              // setSuccessMsg(res.data.message);
              // setTimeout(() => {
              //   setSuccess(false)
              // },1000)
              setOtpBox(true);
            } else {
              setError(true);
              setErrorMsg(res.data.message);
              setTimeout(() => {
                setError(false);
              }, 1000);
            }
          })
          .catch((err) => {
            if (err) {
              setLoad(false);
              setError(true);
              setTimeout(() => {
                setError(false);
              }, 1000);
            }
          });
      } else {
        Notification(
          "error",
          "Error",
          "Password and Confirm Password are different"
        );
        // setPasswordMatch(true)
        // setTimeout(() => {
        //   setPasswordMatch(false)
        // },2000)
      }
    } else {
      setRequired(true);
      setTimeout(() => {
        setRequired(false);
      }, 2000);
    }
  };

  const registerWithOtp = (e) => {
    e.preventDefault();
    const data = {
      token: otpValue,
      email,
    };
    if (data.token) {
      setLoadRegister(true);
      axios
        .post(`${config.baseUrl}/v1/auth/verify-register`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("tempReg")}`,
          },
        })
        .then((res) => {
          setLoadRegister(false);
          if (res.data.status) {
            setSuccess(true);
            setOtpValue("");
            setSuccessMsg(res.data.message);
            window.location.href = "/login";
          } else {
            setError(true);
            setErrorMsg("Invalid Code!");
            setTimeout(() => {
              setError(false);
            }, 1000);
          }
        })
        .catch((err) => {
          if (err) {
            setLoadRegister(false);
            setError(true);
            setTimeout(() => {
              setError(false);
            }, 1000);
          }
        });
    } else {
      setOtpAlert(true);
      setTimeout(() => {
        setOtpAlert(false);
      }, 1500);
    }
  };

  const countryOptions = countryDatas.map((country) => {
    return <option value={country.code}>{country.name}</option>;
  });

  return (
    <>
      <div class="login-page">
        <div class="loader">
          <div class="spinner-grow text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-md-12 col-lg-4">
              <div class="card login-box-container">
                <div class="card-body">
                  <div class="authent-logo">
                    <img
                      src="../../assets/images/loggo.png"
                      alt=""
                      width="40%"
                    />
                  </div>
                  <div class="authent-text" style={{ color: "#222" }}>
                    <p>Welcome to SalarioPay!</p>
                    <p>Enter your details to create your vendor account</p>
                  </div>

                  <form>
                    {loader && (
                      <p className="text-center">
                        <Spin />
                      </p>
                    )}
                    {success && (
                      <p
                        style={{ color: "#1FC157" }}
                        className="text-center font-weight-bold"
                      >
                        {sucessMsg}
                      </p>
                    )}
                    {error && (
                      <div className="pb-4 text-center font-weight-bold">
                        <Alert message={errorMsg} type="error" showIcon />
                      </div>
                    )}
                    {requireFields && (
                      <div className="pb-4 text-center font-weight-bold">
                        <Alert
                          message="All fields are required"
                          type="error"
                          showIcon
                        />
                      </div>
                    )}
                    {passwordMatch && (
                      <div className="pb-4 text-center font-weight-bold">
                        <Alert
                          message="Password and Confirm Password are different"
                          type="error"
                          showIcon
                        />
                      </div>
                    )}

                    <div class="mb-3">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control"
                          id="floatingInput"
                          placeholder="Fullname"
                          value={businessName}
                          onChange={(e) => setBusinessName(e.target.value)}
                        />
                        <label for="floatingInput">Bussiness Name</label>
                      </div>
                    </div>
                    <div class="mb-3">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setCountry(e.target.value)}
                        value={country}
                      >
                        <option selected>Select Country</option>
                        {countryOptions}
                      </select>
                    </div>
                    <div class="mb-3">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control"
                          id="floatingInput"
                          placeholder="Fullname"
                          onChange={(e) => setFname(e.target.value)}
                        />
                        <label for="floatingInput">First Name</label>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control"
                          id="floatingInput"
                          placeholder="Fullname"
                          onChange={(e) => setLname(e.target.value)}
                        />
                        <label for="floatingInput">Last Name</label>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="form-floating">
                        <input
                          type="email"
                          class="form-control"
                          id="floatingInput1"
                          placeholder="name@example.com"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <label for="floatingInput">Email address</label>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="form-floating">
                        <input
                          type="number"
                          class="form-control"
                          id="floatingInput"
                          placeholder="Fullname"
                          onChange={(e) => setMobile(e.target.value)}
                        />
                        <label for="floatingInput">Mobile</label>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="form-floating">
                        <input
                          type="password"
                          class="form-control"
                          id="floatingPassword"
                          placeholder="Password"
                          onChange={(e) => setPass(e.target.value)}
                        />
                        <label for="floatingPassword">Password</label>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="form-floating">
                        <input
                          type="password"
                          class="form-control"
                          id="floatingPassword1"
                          placeholder="Confirm Password"
                          onChange={(e) => setCpass(e.target.value)}
                        />
                        <label for="floatingPassword">Confirm Password</label>
                      </div>
                    </div>

                    <div class="mb-3 form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"
                      />
                      <label class="form-check-label" for="exampleCheck1">
                        I agree the <a href="#">Terms and Conditions</a>
                      </label>
                    </div>
                    <div class="d-grid">
                      <button
                        type="submit"
                        class="btn btn-primary m-b-xs"
                        onClick={register}
                        style={{ background: "#1FC157", color: "white" }}
                      >
                        Register
                      </button>
                    </div>
                  </form>
                  <div class="authent-login">
                    <p>
                      Already have an account? <a href="/login">Sign in</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Verify Otp Code"
        visible={otpBox}
        width={520}
        okText="Submit"
        footer={false}
      >
        <div className="container">
          {loadRegister && (
            <p className="text-center">
              <Spin />
            </p>
          )}
          {success ? (
            <p>
              <Alert
                message="Success!"
                description={sucessMsg}
                type="success"
                showIcon
              />
            </p>
          ) : (
            <p>
              To complete your registration input the code sent to{" "}
              <span className="text-primary font-weight-bold">{email}</span> to
              gain access to your account.
            </p>
          )}
          {error && (
            <div className="pb-4 text-center font-weight-bold">
              <Alert message={errorMsg} type="error" showIcon />
            </div>
          )}

          {otpAlert && (
            <div className="pb-4">
              <Alert message="Code is required!" type="error" showIcon />
            </div>
          )}

          <div className="">
            <OtpInput
              value={otpValue}
              onChange={handleChange}
              numInputs={5}
              inputStyle={{
                textAlign: "center",
                width: "6rem",
                height: "3rem",
                fontSize: "25px",
                borderRadius: "7px",
              }}
              separator={<span>-</span>}
            />
          </div>
          <hr />
          <div className="d-flex justify-content-end align-items-center">
            <Button
              type="success"
              style={{ background: "#1FC157", color: "white" }}
              onClick={registerWithOtp}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
