import React, { useState } from "react";
import Layout from "../../../containers/Layout";
import { useHttp } from "../../../hooks/useHttps";
import config from "../../../config";
import { useHistory } from "react-router-dom";

export default function Benefits() {
  const [selectedBiller, setSelectedBiller] = useState("");
  const [loadingEmployers, employers] = useHttp(
    `${config.baseUrl}/v1/employees/filtered/employer`,
    []
  );
  const history = useHistory();

  const onSelectBiller = (type) => {
    setSelectedBiller(type);
    if (type === "AIRTIME") {
      history.push("/airtime_benefits");
    } else {
      history.push("/data-bundle_benefits");
    }
  };

  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div className="card-header">BENEFITS</div>
            <div class="card-body mb-0 pb-0">
              <div className="row">
                <div
                  class="col-12 col-md-3"
                  onClick={onSelectBiller.bind(this, "AIRTIME")}
                >
                  <div
                    class="card card-file-manager text-center"
                    style={{
                      cursor: "pointer",
                      border:
                        selectedBiller === "AIRTIME" ? "1px solid #1FC157" : "",
                    }}
                  >
                    <div class="card-file-header text-danger">
                      <img
                        src={
                          "http://res.cloudinary.com/didqqm6jl/image/upload/v1691091301/category_icon/fyjgrzdctrwdvigo7xif.png"
                        }
                        className="w-25"
                      />
                    </div>
                    <div class="card-body mb-0 pb-4 pt-4">
                      <h6 class="card-subtitle text-center text-muted">
                        AIRTIME
                      </h6>
                    </div>
                  </div>
                </div>
                <div
                  class="col-12 col-md-3"
                  onClick={onSelectBiller.bind(this, "DATA_BUNDLE")}
                >
                  <div
                    class="card card-file-manager text-center"
                    style={{
                      cursor: "pointer",
                      border:
                        selectedBiller === "DATA_BUNDLE"
                          ? "1px solid #1FC157"
                          : "",
                    }}
                  >
                    <div class="card-file-header text-danger">
                      <img
                        src={
                          "http://res.cloudinary.com/didqqm6jl/image/upload/v1691091365/category_icon/gujmsi9hxnzuwo5qcdmo.png"
                        }
                        className="w-25"
                      />
                    </div>
                    <div class="card-body mb-0 pb-4 pt-4">
                      <h6 class="card-subtitle text-center text-muted">
                        DATA BUNDLE
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
