import React, { useState, createRef, useEffect } from "react";
import axios from "axios";
import { Spin, Modal, Button, Alert, Select, notification } from "antd";
import { Link } from "react-router-dom";
import { useHttp } from "../hooks/useHttps";
import PinInput from "react-pin-input";
import ReCaptchaV2 from "react-google-recaptcha";
import config from "../config";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useForm, Controller } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";
const { Option } = Select;
export default function Register(props) {
  const [loader, setLoad] = useState(false);
  const [cpass, setCpass] = useState("");
  const [email, setEmail] = useState("");
  const [fname, setFname] = useState("");
  const [eye, seteye] = useState(false);
  const [eye2, seteye2] = useState(false);
  const [lname, setLname] = useState("");
  const [country, setCountry] = useState([]);
  const [mobile, setMobile] = useState("");
  const [terms, setTerms] = useState(false);
  const [pass, setPass] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [sucessMsg, setSuccessMsg] = useState("");
  const [accType, setAccType] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [otpValue, setOtpValue] = useState(false);
  const [otpBox, setOtpBox] = useState(false);
  const [otpAlert, setOtpAlert] = useState(false);
  const [loadRegister, setLoadRegister] = useState(false);
  const [source, setSource] = useState([])
  const [requireFields, setRequired] = useState(false);
  const [resendOtpModal, setResendOtpModal] = useState(false);
  const recaptchaRef = createRef();
  const [form, setForm] = useState(null);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const [loadingTypes, accountTypeList] = useHttp(
    `${config.baseUrl}/v1/options/ACCOUNT_TYPE`,
    []
  );

  const closeOptResned = () => {
    setResendOtpModal(false);
    setEmail("");
  };

  const closeOptpBox = () => {
    setOtpBox(false);
  };

  const handleToken = (token) => {
    setForm((currentForm) => {
      return { ...currentForm, token };
    });
  };
  const handleExpire = () => {
    setForm((currentForm) => {
      return { ...currentForm, token: null };
    });
    window.location.reload();
  };

  const resendOtpToEmail = () => {
    if (email) {
      setLoad(true);
      axios
        .get(`${config.baseUrl}/v1/auth/resend-otp/${email}`)
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            Notification("success", "Success", res.data.message);
            // setSuccess(true);
            // setSuccessMsg(res.data.message);
            // setTimeout(() => {
            //   setSuccess(false)
            // },1000)
            setOtpBox(true);
          } else {
            setError(true);
            setLoad(false);
            setErrorMsg(res.data.message);
            setTimeout(() => {
              setError(false);
            }, 3000);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setError(true);
            setTimeout(() => {
              setError(false);
            }, 1000);
          }
        });
    }
  };

  const [loading, countryDatas] = useHttp(
    `${config.baseUrl}/v1/countries/filtered`,
    []
  );
  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const registerEmployee = () => {
    const data = {
      confirmPassword: cpass,
      countries: country,
      email: email,
      mobile: mobile,
      accountType: accType,
      firstName: fname,
      lastName: lname,
      password: pass,
    };
    if (
      data.confirmPassword &&
      data.countries &&
      data.email &&
      terms &&
      data.mobile &&
      data.firstName &&
      data.lastName &&
      data.password &&
      data.countries.length > 0
    ) {
      if (data.password === data.confirmPassword) {
        setLoad(true);
        axios
          .post(`${config.baseUrl}/v1/auth/register`, data)
          .then((res) => {
            setLoad(false);
            if (res.data.status) {
              setOtpBox(true);
              setFname("");
              setLname("");
              Notification("success", "Success", res.data.message);
              // setSuccess(true);
              // sessionStorage.setItem("tempReg", res.data.result.accessToken);
              // setSuccessMsg(res.data.message);
              // setTimeout(() => {
              //   setSuccess(false)
              // },1000)
            } else {
              Notification("error", "Error", res.data.message);
              setErrorMsg(res.data.message);
              setTimeout(() => {
                setError(false);
              }, 1000);
            }
          })
          .catch((err) => {
            if (err) {
              Notification("error", "Error", "Connection Error");
              setLoad(false);
              setError(true);
              setTimeout(() => {
                setError(false);
              }, 1000);
            }
          });
      } else {
        // Notification(
        //   "error",
        //   "Error",
        //   "Password and Confirm Password are different"
        // );
      }
    } else {
      setRequired(true);
      setTimeout(() => {
        setRequired(false);
      }, 3000);
    }
  };

  const registerEmployer = () => {
    const data = {
      confirmPassword: cpass,
      countries: country,
      email: email,
      mobile: mobile,
      accountType: accType,
      firstName: fname,
      referral: JSON?.stringify(source),
      lastName: lname,
      password: pass,
    };

    if (
      data.confirmPassword &&
      data.countries &&
      data.email &&
      data.mobile &&
      data.password && data?.referral &&
      data.countries.length > 0
    ) {
      if (data.password === data.confirmPassword) {
        if (terms) {
          setLoad(true);
          axios
            .post(`${config.baseUrl}/v1/auth/register`, data)
            .then((res) => {
              setLoad(false);
              if (res.data.status) {
                setOtpBox(true);
                setFname("");
                setLname("");
                Notification("success", "Success", res.data.message);

                // setSuccess(true);
                // sessionStorage.setItem("tempReg", res.data.result.accessToken);
                // setSuccessMsg(res.data.message);
                // setTimeout(() => {
                //   setSuccess(false)
                // },1000)
              } else {
                setError(true);
                Notification("error", "Error", res.data.message);
                setErrorMsg(res.data.message);
                setTimeout(() => {
                  setError(false);
                }, 1000);
              }
            })
            .catch((err) => {
              if (err) {
                Notification("error", "Error", "Connection Error");
                setLoad(false);
                setError(true);
                setTimeout(() => {
                  setError(false);
                }, 1000);
              }
            });
        } else {
        }
      } else {
        // Notification(
        //   "error",
        //   "Error",
        //   "Password and Confirm Password are different"
        // );
      }
    } else {
      setRequired(true);
      setTimeout(() => {
        setRequired(false);
      }, 3000);
    }
  };

  const checkChange = (e) => {
    if (e.target.checked) {
      setTerms(true);
    } else {
      setTerms(false);
    }
  };

  const countrySelect = [];
  countryDatas.map((country) => {
    countrySelect.push(<Option key={country.code}>{country.name}</Option>);
  });
  const handleChangeCountries = (value) => {
    setCountry(value);
    setValue("country", value);
  };
  const registerWithOtp = (e) => {
    e.preventDefault();
    const data = {
      token: otpValue,
      email,
    };
    if (data.token) {
      setLoadRegister(true);
      axios
        .post(`${config.baseUrl}/v1/auth/verify-register`, data)
        .then((res) => {
          setLoadRegister(false);
          if (res.data.status) {
            setSuccess(true);
            setOtpValue("");
            setSuccessMsg(res.data.message);
            window.location.href = "/login";
          } else {
            setError(true);
            setErrorMsg("Invalid Code!");
            setTimeout(() => {
              setError(false);
            }, 1000);
          }
        })
        .catch((err) => {
          if (err) {
            setLoadRegister(false);
            setError(true);
            setTimeout(() => {
              setError(false);
            }, 1000);
          }
        });
    } else {
      setOtpAlert(true);
      setTimeout(() => {
        setOtpAlert(false);
      }, 1500);
    }
  };

  const confirmCaptha = () => {
    if (!form?.token) {
      Notification("error", "Error", "Captha is required");
      return;
    }

    if (accType === "EMPLOYEE") {
      registerEmployee();
    } else {
      registerEmployer();
    }
  };

  useEffect(() => {
    if (requireFields) {
      const timeoutId = setTimeout(() => {
        setRequired(false);
      }, 5000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [requireFields]);

  const countryOptions = countryDatas?.map((c) => ({
    label: c?.name,
    value: c?.code,
  }));

  return (
    <>
      <div class="login-page">
        <div class="loader">
          <div class="spinner-grow text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-md-12 col-lg-4">
              <div class="card login-box-container">
                <div class="card-body">
                  <div class="authent-logo">
                    <img
                      src="../../assets/images/loggo.png"
                      alt=""
                      width="40%"
                    />
                  </div>
                  <div class="authent-text" style={{ color: "#222" }}>
                    <p>Welcome to SalarioPay!</p>
                    <p>Enter your details to create your account</p>
                  </div>

                  <form>
                    <div className="mb-3">
                      <div className="form-floating">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          {...register("accType", {
                            required: "Account Type is required is required!",
                            onChange: (e) => setAccType(e.target.value),
                            value: accType,
                          })}
                          value={accType}
                        >
                          <option selected value="0">
                            Select Account Type
                          </option>
                          {accountTypeList.map((type) => {
                            return (
                              <option value={type.code}>{type.value}</option>
                            );
                          })}
                        </select>
                        <label for="floatingInput">Account Type</label>
                      </div>
                      {errors.accType && (
                        <span className="validate-error">
                          {errors.accType.message}
                        </span>
                      )}
                    </div>
                    {accType.length > 0 && (
                      <div class="mb-3">
                        <div class="form-floating">
                          <input
                            type="email"
                            class="form-control"
                            {...register("email", {
                              required: "Email is required!",
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9_.+-]+\.[a-zA-Z0-9_.+-]{2,}$/i,
                                message: "Invalid Email Format",
                              },
                              onChange: (e) => setEmail(e.target.value),
                              value: email,
                            })}
                            value={email}
                          />

                          <label for="floatingInput">
                            {
                              {
                                EMPLOYER: "Business Email",
                                MERCHANT: "Business Email",
                                EMPLOYEE: "Personal Email",
                              }[accType]
                            }
                          </label>
                          {errors.email && (
                            <span className="validate-error">
                              {errors.email.message}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                    {accType === "EMPLOYEE" && (
                      <div>
                        <div class="mb-3">
                          <div class="form-floating">
                            <input
                              type="text"
                              class="form-control"
                              {...register("fname", {
                                required: "First Name is required!",
                                onChange: (e) => setFname(e.target.value),
                                value: fname,
                              })}
                              maxLength="64"
                            />
                            <label for="floatingInput">First Name</label>
                          </div>
                          {errors.fname && (
                            <span className="validate-error">
                              {errors.fname.message}
                            </span>
                          )}
                        </div>
                        <div class="mb-3">
                          <div class="form-floating">
                            <input
                              type="text"
                              class="form-control"
                              value={lname}
                              {...register("lname", {
                                required: "Last Name is required!",
                                onChange: (e) => setLname(e.target.value),
                                value: lname,
                              })}
                              maxLength="64"
                            />
                            <label for="floatingInput">Last Name</label>
                          </div>
                          {errors.lname && (
                            <span className="validate-error">
                              {errors.lname.message}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                    <div class="mb-3">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control"
                          value={mobile}
                          {...register("mobile", {
                            required: "Mobile Number is required!",
                            onChange: (e) =>
                              setMobile(e.target.value.replace(/[^0-9+]/g, "")),
                            value: mobile,
                          })}
                          maxLength="15"
                        />

                        <label for="floatingInput">Mobile</label>
                      </div>
                      {errors.mobile && (
                        <span className="validate-error">
                          {errors.mobile.message}
                        </span>
                      )}
                    </div>

                    <div class="mb-3">
                      <div
                        className="mt-2  d-block w-100 d-flex align-items-center justify-content-between border"
                        style={{ borderRadius: "10px" }}
                      >
                        <div
                          className=""
                          style={{
                            width: "90%",
                          }}
                        >
                          <div className="form-floating">
                            <input
                              type={!eye ? "password" : "text"}
                              class="form-control"
                              style={{ border: 0 }}
                              {...register("password", {
                                required: "Password is required!",
                                onChange: (e) => setPass(e.target.value),
                                pattern: {
                                  value:
                                    /^^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}/i,
                                  message:
                                    "Invalid Password Format, must contain 1 Capital letters, number, and special character",
                                },
                              })}
                              maxLength="64"
                            />
                            <label for="floatingPassword">Password</label>
                          </div>

                        </div>
                        <div
                          className="d-flex align-items-center justify-content-center h-100 pointer"
                          onClick={() => seteye(!eye)}
                          style={{
                            width: "10%",
                          }}
                        >
                          {!eye ? <BsEye /> : <BsEyeSlash />}
                        </div>
                      </div>
                      {errors.password && (
                        <span className="validate-error">
                          {errors.password.message}
                        </span>
                      )}
                    </div>

                    <div className="mb-3">
                      <div
                        className="mt-2  d-block w-100 d-flex align-items-center justify-content-between border"
                        style={{ borderRadius: "10px" }}
                      >
                        <div
                          className=""
                          style={{
                            width: "90%",
                          }}
                        >
                          <div class="form-floating">
                            <input
                              type={!eye2 ? "password" : "text"}
                              class="form-control"
                              maxLength="64"
                              style={{ border: 0 }}
                              {...register("cpassword", {
                                required: "Confirm Password is required!",
                                validate: (value) =>
                                  value === pass ||
                                  "The passwords do not match",
                                onChange: (e) => setCpass(e.target.value),
                              })}
                            />
                            <label for="floatingPassword">
                              Confirm Password
                            </label>
                          </div>

                        </div>
                        <div
                          className="d-flex align-items-center justify-content-center h-100 pointer"
                          onClick={() => seteye2(!eye2)}
                          style={{
                            width: "10%",
                          }}
                        >
                          {!eye2 ? <BsEye /> : <BsEyeSlash />}
                        </div>
                      </div>
                      {errors.cpassword && (
                        <span className="validate-error">
                          {errors.cpassword.message}
                        </span>
                      )}
                    </div>
                    <div class="mb-3">
                      <Controller
                        name="country"
                        control={control}
                        {...register("country", {
                          required: "Country is required!",
                        })}
                        render={({ field }) => (
                          <Select
                            {...field}
                            value={country}
                            mode="multiple"
                            onChange={handleChangeCountries}
                            listHeight={400}
                            style={{ width: "100%" }}
                            options={countryOptions}
                            placeholder={
                              accType === "EMPLOYEE"
                                ? "Select Countries of Residence"
                                : "Select Countries of Operation"
                            }
                            showSearch
                            optionFilterProp="children"
                          />
                        )}
                      />
                      {errors.country && (
                        <span className="validate-error">
                          {errors.country.message}
                        </span>
                      )}
                    </div>
                    <div class="mb-3">
                      <Controller
                        name="country"
                        control={control}
                        {...register("source", {
                          required: "How did you hear about us?",
                        })}
                        render={({ field }) => (
                          <Select
                            {...field}
                            value={source}
                            mode="single"
                            onChange={(val) => {
                              setSource(val)
                              setValue("source", val);

                            }}
                            listHeight={400}
                            style={{ width: "100%" }}
                            options={[{
                              'label': 'A friend or colleague', value: 'A friend or colleague'
                            },
                            {
                              'label': 'LinkedIn', value: 'LinkedIn'
                            }, {
                              'label': 'Facebook', value: 'Facebook'
                            }, {
                              'label': 'Instagram', value: 'Instagram'
                            }, {
                              'label': 'Google', value: 'Google'
                            },
                            {
                              'label': 'Bing', value: 'Bing'
                            },
                            {
                              'label': 'Event or webinar', value: 'Event or webinar'
                            },
                            {
                              'label': 'Blog or Article', value: 'Blog or Article'
                            },
                            {
                              'label': 'Email or Newsletter', value: 'Email or Newsletter'
                            },
                            {
                              'label': 'Advertisement', value: 'Advertisement'
                            },
                            {
                              'label': 'Other', value: 'Other'
                            },
                            ]}
                            placeholder={
                              "How did you hear about us?"
                            }
                            showSearch
                            optionFilterProp="children"
                          />
                        )}
                      />
                      {errors.source && (
                        <span className="validate-error">
                          {errors.source.message}
                        </span>
                      )}
                    </div>

                    <div class="mb-3 form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        {...register("chk", {
                          required: "You must agree to terms and conditions!",
                          onChange: checkChange,
                        })}
                        onChange={checkChange}
                        checked={terms}
                        id="exampleCheck1"
                      />
                      <label class="form-check-label" for="exampleCheck1">
                        I agree the{" "}
                        <a href="https://salariopay.com/terms-and-conditions">
                          Terms and Conditions
                        </a>
                      </label>
                      <div>
                        {errors.chk && (
                          <span className="validate-error">
                            {errors.chk.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="w-100 d-flex justify-content-start mt-3 align-items-center">
                      <ReCaptchaV2
                        ref={recaptchaRef}
                        sitekey="6LfI3WAnAAAAAPUGEskLIZq3h4ScLOiv1jlxz6q1"
                        onChange={handleToken}
                        onExpire={handleExpire}
                      />
                      <br />
                    </div>
                    <div class="  mt-3">
                      <button
                        type="submit"
                        className="btn btn-info w-100 m-b-xs"
                        onClick={handleSubmit(confirmCaptha)}
                        style={{ background: "#1FC157", color: "white" }}
                      >
                        {loader ? (
                          <div className="d-flex justify-content-center align-items-center">
                            <ThreeDots
                              visible={loader}
                              height="20"
                              width="50"
                              color="#ffffff"
                              radius="9"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                            />
                          </div>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </form>
                  <div class="authent-login">
                    <p>
                      Already have an account? <Link to="/login">Sign in</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Resend Otp Code"
        visible={resendOtpModal}
        okText="Submit"
        footer={false}
        onCancel={closeOptResned}
      >
        <div className="">
          {success && (
            <p
              style={{ color: "#1FC157" }}
              className="text-center font-weight-bold"
            >
              {sucessMsg}
            </p>
          )}
          {error && (
            <div className="pb-4 text-center font-weight-bold">
              <Alert message={errorMsg} type="error" showIcon />
            </div>
          )}
          <div class="mb-3">
            <div class="form-floating">
              <input
                type="email"
                class="form-control"
                onChange={(e) => setEmail(e.target.value)}
              />
              <label for="floatingInput">Email address</label>
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <Button
              type="success"
              style={{ background: "#1FC157", color: "white" }}
              onClick={resendOtpToEmail}
            >
              {loader ? "Sending..." : "Submit"}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        title="Verify Otp Code"
        visible={otpBox}
        width={520}
        okText="Submit"
        footer={false}
        onCancel={closeOptpBox}
      >
        <div className="container">
          {loadRegister && (
            <p className="text-center">
              <Spin />
            </p>
          )}
          {success ? (
            <p>
              <Alert
                message="Success!"
                description={sucessMsg}
                type="success"
                showIcon
              />
            </p>
          ) : (
            <p>
              To complete your registration input the code sent to{" "}
              <span className="text-primary font-weight-bold">{email}</span> to
              gain access to your account.
            </p>
          )}
          {error && (
            <div className="pb-4 text-center font-weight-bold">
              <Alert message={errorMsg} type="error" showIcon />
            </div>
          )}

          {otpAlert && (
            <div className="pb-4">
              <Alert message="Code is required!" type="error" showIcon />
            </div>
          )}

          <div className="mb-3 d-flex justify-content-center align-items-center">
            <PinInput
              length={5}
              initialValue={otpValue}
              secret
              onChange={(value, index) => setOtpValue(value)}
              type="numeric"
              inputMode="number"
              style={{ padding: "10px" }}
              inputStyle={{ borderColor: "green" }}
              inputFocusStyle={{ borderColor: "blue" }}
              onComplete={(value, index) => { }}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </div>
          <hr />
          <div className="d-flex justify-content-between align-items-center">
            <Button
              type="success"
              style={{ background: "#1FC157", color: "white", width: "130px" }}
              onClick={resendOtpToEmail}
            >
              Resend Otp
            </Button>
            <Button
              type="success"
              style={{ background: "#1FC157", color: "white", width: "130px" }}
              onClick={registerWithOtp}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
