import moment from "moment";
// Countdown timer helper
const padTime = (time) => {
  return String(time).length === 1 ? `0${time}` : `${time}`;
};

export const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes}:${padTime(seconds)}`;
};

export const generalErrMsg = "Failed to connect to Salariopay!";

export const formatDate = (date) => {
  return moment(date, "YYYY.MM.DD").unix();
};

export const convertDate = (date) => {
  return moment(new Date(date)).format("YYYY-MM-DD");
};

export const formatDollars = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
export const formatNaira = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "NGN",
});
