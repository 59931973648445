import React, { useEffect, useState, Fragment } from "react";
import Layout from "../../../containers/Layout";
import { Drawer, Button, Spin, Alert, Modal } from "antd";
import ReactDatatable from "@ashvin27/react-datatable";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../../config";

const Options = () => {
  const [optionsData, setOptionsData] = useState([]);
  const [optionFilter, setOptionFilter] = useState([]);
  const [seletedOption, setSeletedOption] = useState("");
  const [createOption, setCreateOption] = useState(false);
  const [deleteSuccess, setDelete] = useState(false);
  const [statusSuccess, setSuccessStatus] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");
  const [optionStatus, setOptionStatus] = useState("");
  const [drawerText, setDrawerText] = useState("");

  const [visibleDraw, setVisibleDraw] = useState(false);
  const [updateSwitch, setUpdateSwitch] = useState(false);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [fieldError, setFieldError] = useState(false);

  const [createOptionData, setCreateOptionData] = useState({
    id: "",
    code: "",
    group: "",
    name: "",
    value: "",
  });

  const { id, code, group, name, value } = createOptionData;

  const closeAdd = () => {
    setCreateOption(false);
    setCreateOptionData((prevState) => ({
      ...prevState,
      id: "",
      code: "",
      group: "",
      name: "",
      value: "",
    }));
  };

  const showDrawer = (record, e) => {
    e.preventDefault();
    setCreateOptionData(record);
    setOptionStatus(record.status);
    setVisibleDraw(true);
  };

  const columns = [
    {
      key: "id",
      text: "ID",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "code",
      text: "Code",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "group",
      text: "Group",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "name",
      text: "Name",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "value",
      text: "Value",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "date",
      text: "Date",
      className: "address",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <Button
              type="primary"
              danger
              style={{
                background: "#1FC157",
                color: "white",
                cursor: "pointer",
              }}
              onClick={showDrawer.bind(this, record)}
            >
              Actions
            </Button>
          </Fragment>
        );
      },
    },
  ];

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];

  const getAllOptionsFilter = () => {
    axios
      .get(`${config.baseUrl}/v1/options/name/filtered`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setSeletedOption(res.data.result[0]);
            setOptionFilter(res.data.result);
          } else {
          }
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const options = { year: "numeric", month: "numeric", day: "numeric" };

  const getAllOptions = () => {
    axios
      .get(`${config.baseUrl}/v1/options/paged?name=${seletedOption}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const getOption = res.data.result;
          if (getOption.length > 0) {
            setOptionsData(
              getOption.map((data) => ({
                id: data.id,
                code: data.code,
                group: data.group,
                status: data.status ? "Enabled" : "Disabled",
                name: formatName(data.name),
                value: data.value,
                date: new Date(data.createdAt)
                  .toLocaleDateString(undefined, options)
                  .replace(/\//g, "-"),
              }))
            );
          } else {
          }
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    button: {
      excel: true,
      print: true,
      extra: true,
    },
  };

  const createOptionsModal = () => {
    setCreateOption(true);
    setUpdateSwitch(false);
  };

  const handleInputChange = (property, value) => {
    setCreateOptionData((prevData) => ({
      ...prevData,
      [property]: value,
    }));
  };

  const addOption = (e) => {
    e.preventDefault();

    if (!code || !group || !name || !value) {
      setFieldError(true);
      setTimeout(() => {
        setFieldError(false);
      }, 1500);
      return;
    }
    setLoad(true);

    axios
      .post(`${config.baseUrl}/v1/options`, createOptionData, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setCreateOptionData((prevState) => ({
            ...prevState,
            code: "",
            group: "",
            name: "",
            value: "",
          }));

          setSuccess(true);
          setSuccessMsg(res.data.message);

          setTimeout(() => {
            setSuccess(false);
            setCreateOption(false);
            getAllOptionsFilter();
            getAllOptions();
          }, 2500);
        } else {
          setError(true);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err) {
          setLoad(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };

  const editOption = (e) => {
    e.preventDefault();

    setLoad(true);
    axios
      .put(`${config.baseUrl}/v1/options/${id}`, createOptionData, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setSuccess(true);
          setSuccessMsg(res.data.message);
          setTimeout(() => {
            setSuccess(false);
            getAllOptionsFilter();
            getAllOptions();
            setCreateOption(false);
          }, 2000);
        } else {
          setError(true);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };

  useEffect(() => {
    getAllOptionsFilter();
  }, []);

  useEffect(() => {
    if (seletedOption?.length > 0) {
      getAllOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seletedOption, optionFilter]);

  const closeDrawer = () => setVisibleDraw(false);
  const showDeleteConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure delete this Option?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteOptions();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const deleteOptions = () => {
    axios
      .delete(`${config.baseUrl}/v1/options/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setDelete(true);
          getAllOptionsFilter();
          getAllOptions();
          setVisibleDraw(false);
          setTimeout(() => {
            setDelete(false);
          }, 2000);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const updateOptionSwitch = () => {
    setUpdateSwitch(true);
    setCreateOption(true);
    setVisibleDraw(false);
  };

  const changeStatusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure change status of this Option?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        changeStatusFunc();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const changeStatusFunc = () => {
    axios
      .put(
        `${config.baseUrl}/v1/options/status/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        },
      )
      .then((res) => {
        if (res.data.status) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          setStatusMsg(res.data.message);
          getAllOptions();
          getAllOptionsFilter();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
        if (err) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
          setStatusMsg("Failed to connect to SalarioPay");
        }
      });
  };

  const formatName = (text) => {
    return text?.replace("_", " ");
  };

  useEffect(() => {
    if (optionStatus === "Enabled") {
      setDrawerText("Disable");
    } else if (optionStatus === "Disabled") {
      setDrawerText("Enable");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeStatusConfirm]);

  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title mb-0">Options</h5>
              <div className="d-flex justify-content-end align-items-center gap-3">
                <select
                  defaultValue={optionFilter[0]?.name}
                  className="form-select w-xl m-b-xs"
                  aria-label="Default select example"
                  onChange={(e) => setSeletedOption(e.target?.value)}
                >
                  {optionFilter.map((item) => (
                    <option value={item} key={item}>
                      {formatName(item)}
                    </option>
                  ))}
                </select>
                <button
                  className="btn btn-info m-b-xs mr-2"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={createOptionsModal}
                >
                  Add Option
                </button>{" "}
              </div>
              <div className="pt-0">
                <div className="text-center">
                  {deleteSuccess && (
                    <div className="pb-4">
                      <Alert message="Option Deleted" type="error" showIcon />
                    </div>
                  )}
                  {statusSuccess && (
                    <div className="pb-4">
                      <Alert
                        message={statusMsg}
                        type={
                          drawerText.toLowerCase().includes("enable")
                            ? "success"
                            : "error"
                        }
                        showIcon
                      />
                    </div>
                  )}
                </div>
                <div className="pt-0" style={{ overflowX: "auto" }}>
                  <ReactDatatable
                    config={configTable}
                    records={optionsData}
                    columns={columns}
                    extraButtons={extraButtons}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}
      >
        <div
          style={{ color: "red", cursor: "pointer" }}
          onClick={showDeleteConfirm}
        >
          Delete Option
        </div>
        <hr />
        <div style={{ cursor: "pointer" }} onClick={updateOptionSwitch}>
          Edit Option
        </div>
        <hr />
        <div style={{ cursor: "pointer" }} onClick={changeStatusConfirm}>
          {drawerText}
        </div>
      </Drawer>
      <Modal
        title={!updateSwitch ? "Add Option" : "Update Option"}
        visible={createOption}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeAdd}
      >
        <form>
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}
          {success && (
            <div className="pb-3">
              <Alert message={successMsg} type="success" showIcon />
            </div>
          )}
          {error && (
            <div className="pb-3">
              <Alert message={errorMsg} type="error" showIcon />
            </div>
          )}
          {fieldError && (
            <div className="pb-3">
              <Alert message="All fields are required!" type="error" showIcon />
            </div>
          )}
          {serverError && (
            <p className="text-center text-danger font-weight-bold">
              Failed to connect to SalarioPay
            </p>
          )}
          {!updateSwitch ? (
            <div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Option code"
                    onChange={(event) =>
                      handleInputChange("code", event.target.value)
                    }
                    value={code}
                  />
                  <label for="floatingInput">Option code</label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Group name"
                    onChange={(event) =>
                      handleInputChange("group", event.target.value)
                    }
                    value={group}
                  />
                  <label for="floatingInput">Group Name</label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Name"
                    onChange={(event) =>
                      handleInputChange("name", event.target.value)
                    }
                    value={name}
                  />
                  <label for="floatingInput">Name</label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="value"
                    onChange={(event) =>
                      handleInputChange("value", event.target.value)
                    }
                    value={value}
                  />
                  <label for="floatingInput">Value</label>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Option code"
                    onChange={(event) =>
                      handleInputChange("code", event.target.value)
                    }
                    value={code}
                  />
                  <label for="floatingInput">Option code</label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Group name"
                    onChange={(event) =>
                      handleInputChange("group", event.target.value)
                    }
                    value={group}
                  />
                  <label for="floatingInput">Group Name</label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Name"
                    onChange={(event) =>
                      handleInputChange("name", event.target.value)
                    }
                    value={name}
                  />
                  <label for="floatingInput">Name</label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="value"
                    onChange={(event) =>
                      handleInputChange("value", event.target.value)
                    }
                    value={value}
                  />
                  <label for="floatingInput">Value</label>
                </div>
              </div>
            </div>
          )}
          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={!updateSwitch ? addOption : editOption}
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>
    </Layout>
  );
};

export default Options;
