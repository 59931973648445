import React, { useEffect, useState } from "react";
import Layout from "../../../containers/Layout";
import KycTab from "./kyc_tab";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../config";
import { Spin, Modal } from "antd";

const Documents = () => {
  const { id } = useParams();

  const [kycDetails, setKycDetails] = useState({
    governmentIDFront: "",
    governmentIDBack: "",
    utilityBill: "",
    certificateofIncorporation: "",
    status: "",
  });
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [imageToViewInfo, setImageToViewInfo] = useState({
    title: "",
    image: "",
  });

  const openModal = (title, imageLINK) => {
    setImageToViewInfo((prevState) => ({
      ...prevState,
      title: title,
      image: imageLINK,
    }));
    setShowModal(true);
  };

  const closeModal = () => {
    setImageToViewInfo((prevState) => ({
      ...prevState,
      title: "",
      image: "",
    }));
    setShowModal(false);
  };

  const {
    governmentIDFront,
    governmentIDBack,
    utilityBill,
    certificateofIncorporation,
    status,
  } = kycDetails;

  const getKYCData = () => {
    axios
      .get(`${config.baseUrl}/v1/kyc-details/approvals/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setKycDetails((prevState) => ({
            ...prevState,
            governmentIDFront: res.data.result.govtIdFrontUrl,
            governmentIDBack: res.data.result.govtIdBackUrl,
            utilityBill: res.data.result.utilityBillUrl,
            certificateofIncorporation: res.data.result.cacUrl,
            status: res.data.result.status,
          }));
        } else {
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err) {
        }
      });
  };

  useEffect(() => {
    getKYCData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <KycTab id={id} status={status} />
      {loading ? (
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center align-items-center">
              <Spin />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header font-weight-bold">
                <b>Documents</b>
              </div>
              <div class="card-body">
                <form>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Government ID (Front)
                        </label>
                        <div
                          className=""
                          onClick={() =>
                            openModal(
                              "Government ID (Front)",
                              governmentIDFront
                            )
                          }>
                          <img
                            src={governmentIDFront}
                            alt="Government ID (Front)"
                            style={{
                              width: "100%",
                              height: "20rem",
                              objectFit: "cover",
                              borderRadius: "1rem",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Government ID (Back)
                        </label>
                        <div
                          className=""
                          onClick={() =>
                            openModal(" Government ID (Back)", governmentIDBack)
                          }>
                          <img
                            src={governmentIDBack}
                            alt=" Government ID (Back)"
                            style={{
                              width: "100%",
                              height: "20rem",
                              objectFit: "cover",
                              borderRadius: "1rem",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Utility Bill
                        </label>
                        <div
                          className=""
                          onClick={() =>
                            openModal("   Utility Bill", utilityBill)
                          }>
                          <img
                            src={utilityBill}
                            alt="   Utility Bill"
                            style={{
                              width: "100%",
                              height: "20rem",
                              objectFit: "cover",
                              borderRadius: "1rem",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="mb-3">
                        <label for="exampleInputEmail1" class="form-label">
                          Certificate of Incorporation
                        </label>
                        <div
                          className=""
                          onClick={() =>
                            openModal(
                              "Certificate of Incorporation",
                              certificateofIncorporation
                            )
                          }>
                          <img
                            src={certificateofIncorporation}
                            alt="Certificate of Incorporation"
                            style={{
                              width: "100%",
                              height: "20rem",
                              objectFit: "cover",
                              borderRadius: "1rem",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Modal
            title={imageToViewInfo.title}
            visible={showModal}
            footer={false}
            maskClosable={false}
            onCancel={closeModal}>
            <img
              src={imageToViewInfo.image}
              alt={imageToViewInfo.title}
              style={{
                width: "100%",
                height: "fit",
                objectFit: "contain",
                borderRadius: "1rem",
              }}
            />
          </Modal>
        </>
      )}
    </Layout>
  );
};

export default Documents;
