import React, { useState, useEffect } from "react";
import Tabs from "../../tab";
import {
  Spin,
  Modal,
  Checkbox,
  Button,
  Space,
  Alert,
  Progress,
  Select,
} from "antd";
import Cookie from "js-cookie";
import Layout from "../../../containers/Layout";
import axios from "axios";
import config from "../../../config";
import UpgradeSubsciption from "./subscription_upgrade";
const { Option } = Select;
const userChecklists = Cookie.getJSON("userInfo");
export default function ExistingSubscription(props) {
  const [subscription, setSubscription] = useState([]);
  const [openSub, setOpenSub] = useState(false);

  const [subscriptionUpgardeStatus, setSubscriptionUpgardeStatus] =
    useState(false);
  const [existing, showExisting] = useState(false);
  const [employerWallets, setEmployerWallets] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [currencyType, setCurrencyType] = useState("");
  const [subscriptionActivateModal, setActivationModal] = useState(false);

  const [subDetails, setSubDetails] = useState({});
  const [subType, setSubType] = useState("");

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [serverErrorMsg, setServerErrorMsg] = useState("");

  const [load, setLoad] = useState(false);

  const readExistingSub = () => {
    const token = sessionStorage.getItem("token");
  };

  function handleChange(currency) {
    setCurrencyType(currency);
    const currencySelctedInfo = currencies.find(
      (wallet) => wallet.currency === currency
    );
    console.log(currencySelctedInfo);
    setEmployerWallets([
      {
        currency: currencySelctedInfo.currency,
        walletId: currencySelctedInfo.id,
      },
    ]);
  }

  const getSubscriptionWallets = () => {
    axios
      .get(
        `${config.baseUrl}/v1/wallets/currencies/${sessionStorage.getItem(
          "employer_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setEmployerWallets(res.data.result);
            setCurrencies(res.data.result);
          } else {
          }
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const onActivateSubscription = () => {
    setActivationModal(true);
  };

  const cancelOnActivate = () => {
    setActivationModal(false);
  };

  useEffect(() => {
    readExistingSub();
    getSubscriptionWallets();
  }, []);

  const upgradeSub = () => {
    setOpenSub(true);
  };

  const closeUpgardeSub = () => {
    setOpenSub(false);
  };

  const renewSubscription = (e) => {
    e.preventDefault();

    setLoad(true);
    axios
      .put(
        `${config.baseUrl}/v1/subscriptions/renew/${sessionStorage.getItem(
          "employer_id"
        )}/${
          employerWallets.length > 0
            ? employerWallets[0].id
            : employerWallets[0].walletId
        }`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setSuccess(true);
          setSuccessMsg(res.data.message);
          setSubscriptionUpgardeStatus(true);
          showExisting(false);
          setError(false);
          // setTimeout(() => {
          //   setSuccess(false);
          // }, 2500);
        } else {
          setError(true);
          setSubscriptionUpgardeStatus(true);
          setSuccess(false);
          setErrorMsg(res.data.message);
          // setTimeout(() => {
          //   setError(false);
          // }, 2500);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };
  const cancelSubcriptionUpgrade = () => {
    if (success) {
      window.location.reload(false);
    }
    setSubscriptionUpgardeStatus(false);
  };

  return (
    <>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div className="text-center">{load && <Spin />}</div>
              <div class="row">
                <div class="col-8">
                  <h4>Subscription </h4>
                </div>

                <div class="col-4">
                  <h4
                    class="float-end"
                    style={{
                      color: !props.subscription.status ? "orange" : "green",
                    }}
                  >
                    {props.subscription.status ? "ACTIVE" : "INACTIVE"} (
                    {props.subscription.isTrial ? "TRIAL" : "PAID"})
                  </h4>
                </div>
              </div>
              <div class="invoice-details">
                <div class="row">
                  <div class="col">
                    <p class="info">Date:</p>
                    <p>
                      {props.subscription.startDate &&
                        props.subscription.startDate.slice(0, 10)}
                    </p>
                  </div>
                  <div class="col">
                    <p class="info">ID:</p>
                    <p>{props.subscription.id}</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="table-responsive">
                  <table class="table invoice-table">
                    <thead>
                      <tr>
                        <th scope="col">Plan</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Cycle</th>
                        <th scope="col">Start Date</th>
                        <th scope="col">End Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          {props.subscription.plan
                            ? props.subscription.plan.name
                            : "name"}
                        </th>
                        <td>
                          $
                          {Intl.NumberFormat("en-US").format(
                            props.subscription.amount
                          )}
                        </td>
                        <td>{props.subscription.cycle}</td>
                        <td>
                          {props.subscription.startDate &&
                            props.subscription.startDate.slice(0, 10)}
                        </td>
                        <td>
                          {props.subscription.startDate &&
                            props.subscription.endDate.slice(0, 10)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <div className="d-flex justify-content-end">
                    {!props.subscription.status && (
                      <button
                        type="submit"
                        class="btn btn-primary mr-3"
                        style={{ background: "#1FC157", color: "white" }}
                        onClick={onActivateSubscription}
                      >
                        Renew
                      </button>
                    )}{" "}
                    <button
                      type="submit"
                      class="btn btn-primary ml-5"
                      style={{ background: "#1FC157", color: "white" }}
                      onClick={upgradeSub}
                    >
                      Change Plan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={"Select Subscription Plan to Upgrade"}
        width={1200}
        visible={openSub}
        okText="Submit"
        onCancel={closeUpgardeSub}
        footer={false}
        maskClosable={false}
      >
        <UpgradeSubsciption />
      </Modal>

      <Modal
        title={"Renew Subscription"}
        visible={subscriptionActivateModal}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={cancelOnActivate}
      >
        <div className="mb-3">
          <div>
            <label>Select Currency</label>
          </div>
          <div>
            {currencies.length > 0 && (
              <Select
                defaultValue={employerWallets[0].currency}
                style={{ width: 100 }}
                onChange={handleChange}
                value={employerWallets[0].currency}
              >
                {currencies.map((currency) => {
                  return (
                    <Option
                      key={currency.id}
                      value={currency.currency}
                      key={currency.id}
                    >
                      {currency.currency}
                    </Option>
                  );
                })}
              </Select>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-primary"
            style={{
              background: "#1FC157",
              color: "white",
            }}
            onClick={renewSubscription}
          >
            {load ? (
              <div class="spinner-border text-white" role="status">
                <span class="sr-only">Updating...</span>
              </div>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Modal>

      <Modal
        title={"Subscription Renewal Status"}
        visible={subscriptionUpgardeStatus}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={cancelSubcriptionUpgrade}
      >
        <div className="text-center">
          {success && (
            <div>
              <Progress type="circle" percent={100} format={() => "Success"} />
              <p
                className="pt-4"
                style={{
                  color: "#1FC157",
                }}
              >
                {successMsg}
              </p>
            </div>
          )}

          {error && (
            <div>
              <Progress
                type="circle"
                percent={100}
                format={() => "Failed"}
                status="exception"
              />
              <p className="pt-4 text-danger">{errorMsg}</p>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}
