import React from 'react'

export default function DropDown (props) {
  return (
    <>
      <a class="dropdown-item" href="/profile">
        <i data-feather="user"></i>Profile
      </a>

      <a class="dropdown-item" href="/security">
        <i data-feather="unlock"></i>Security
      </a>
      <a class="dropdown-item" onClick={props.logout}>
        <i data-feather="log-out"></i>Logout
      </a>
    </>
  );
}
