import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import config from "../../config";

const EmployeeChartSuperAdmin = () => {
  const [chartData, setChartdata] = useState([]);
  const getOnBoardingMetric = () => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/dashboard/employer/employee-summary?employerId=${sessionStorage.getItem(
          "employeeID",
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        },
      )
      .then((res) => {
        if (res.data.status) {
          setChartdata(
            res.data.result.map((d) => ({
              name: d.monthName,
              uv: d.employeeCount,
              amt: d.employeeCount,
            })),
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };
  useEffect(() => {
    getOnBoardingMetric();
  }, []);
  return (
    <BarChart
      width={1300}
      height={400}
      data={chartData}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />

      <Bar type="monotone" dataKey="v" fill="#1FC157" />
      <Bar type="monotone" dataKey="uv" fill="#1FC157" />
    </BarChart>
  );
};

export default EmployeeChartSuperAdmin;
