import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Layout from "../../../../containers/Layout";
import ReactDatatable from "@ashvin27/react-datatable";
import {
  Drawer,
  Button,
  Spin,
  Alert,
  Pagination,
  Modal,
  notification,
} from "antd";
import axios from "axios";
import config from "../../../../config";
import Tab from "../../workhub/tabs";
export default function Allowances(props) {
  const [allowanceesDatas, setAllowanceesDatas] = useState([]);
  const [load, setLoad] = useState(false);
  const [noAllowancees, setNoAllowancees] = useState(false);
  const [loadAllowanceesDatas, setLoadAllowancees] = useState(true);
  const [allowancesInfo, setAllowancesInfo] = useState({});
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const employeeId = props.history.location.state
    ? props.history.location.state
    : "";

  const [loadPdf, setLoadPdf] = useState(false);

  const getAllowances = () => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/allowances/employee?employeeId=${sessionStorage.getItem(
          "employeeID"
        )}&page=0&size=50`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const transactionsData = res.data.result;
          if (transactionsData.length > 0) {
            setAllowancesInfo(res.data);
            setAllowanceesDatas(transactionsData);
            setLoadAllowancees(false);
            setTotalItems(res.data.totalPages * 10);
            setNoAllowancees(false);
          } else {
            setLoadAllowancees(false);
            setNoAllowancees(true);
          }
        } else {
          setLoadAllowancees(false);
          setNoAllowancees(true);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const downLoadPdf = (e) => {
    e.preventDefault();
    setLoadPdf(true);
    downloadEmployeeAllowances();
  };

  const downloadEmployeeAllowances = () => {
    fetch(
      `${
        config.baseUrl
      }/v1/allowances/allowanceslip/download/${sessionStorage.getItem(
        "employeeID"
      )}`,
      {
        headers: new Headers({
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        }),
      }
    ).then((response) => {
      setLoadPdf(false);
      const filename = "allowances.pdf";
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      });
    });
  };

  const getPaged = (queryString) => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/allowances/employee?employeeId=${sessionStorage.getItem(
          "employeeID"
        )}&${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const data = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          setAllowanceesDatas(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `page=${page - 1}&size=${pageSize}`;
    getPaged(queryString);
  };

  useEffect(() => {
    getAllowances();
  }, []);

  return (
    <Layout>
      <Tab />
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="card table-widget">
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="card-title ">Allowances </h5>
                {allowanceesDatas.length > 0 && (
                  <div className="px-4">
                    <button
                      type="submit"
                      disabled={loadPdf}
                      className="btn btn-info m-b-xs"
                      style={{ background: "#1FC157", color: "white" }}
                      onClick={downLoadPdf}
                    >
                      {loadPdf ? "Downloading" : "Download Pdf"}
                    </button>
                  </div>
                )}
              </div>

              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col"> Montly</th>
                      <th scope="col">Yearly</th>
                      <th scope="col">Date</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  {allowanceesDatas.length > 0 && (
                    <tbody>
                      <>
                        {allowanceesDatas.map((data) => {
                          return (
                            <tr>
                              <td>
                                {data.allowanceType
                                  ? data.allowanceType.replace(/_+/g, " ")
                                  : ""}
                              </td>
                              <td>
                                {data.currency}{" "}
                                {data.monthlyAllowance
                                  ? Intl.NumberFormat("en-US").format(
                                      data.monthlyAllowance
                                    )
                                  : ""}
                              </td>
                              <td>
                                {data.currency}{" "}
                                {data.yearlyAllowance
                                  ? Intl.NumberFormat("en-US").format(
                                      data.yearlyAllowance
                                    )
                                  : ""}
                              </td>

                              <td>
                                {data.createdAt
                                  ? data.createdAt.slice(0, 10)
                                  : ""}
                              </td>

                              <td>
                                <span
                                  className={
                                    {
                                      SUCCESS: "badge bg-success",
                                      FAILED: "badge bg-danger",
                                    }[data.status ? "SUCCESS" : "FAILED"]
                                  }
                                >
                                  {data.status ? "ACTIVE" : "INACTIVE"}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td></td>
                          <td>
                            {allowanceesDatas?.length > 0 && (
                              <h5 style={{ fontWeight: "bold" }}>
                                NGN{" "}
                                {allowancesInfo?.totalMonthly
                                  ? allowancesInfo?.totalMonthly
                                      .toFixed(2)
                                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                  : 0}
                              </h5>
                            )}
                          </td>
                          <td>
                            {allowanceesDatas?.length > 0 && (
                              <h5 style={{ fontWeight: "bold" }}>
                                NGN{" "}
                                {allowancesInfo?.totalYearly
                                  ? allowancesInfo?.totalYearly
                                      .toFixed(2)
                                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                  : 0}
                              </h5>
                            )}
                          </td>

                          <td></td>

                          <td></td>
                        </tr>

                        <div className="text-center pagination-part py-5 d-flex justify-content-center align-items-center">
                          <div className="d-flex justify-content-center">
                            <Pagination
                              current={page}
                              total={totalItems}
                              defaultPageSize={50}
                              onChange={pagination}
                              itemRender={itemRender}
                            />
                          </div>
                        </div>
                      </>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
            {loadAllowanceesDatas && (
              <div className="text-center pt-5 pb-5">
                <Spin />
              </div>
            )}
            {noAllowancees && (
              <p className="text-center pt-5 pb-5">No Allowances History Yet</p>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
