import React, { Fragment, useEffect, useState } from "react";
import Layout from "../../../containers/Layout";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ReactDatatable from "@ashvin27/react-datatable";
import { Drawer, Button, Spin, Alert, Modal } from "antd";
import axios from "axios";
import config from "../../../config";
export default function Employers() {
  const [loanProductData, setLoanProductData] = useState([]);
  const [createLoanProduct, setCreateLoanProduct] = useState(false);
  const [loanName, setLoanName] = useState("");
  const [loanCap, setLoanCap] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [loanTerm, setLoanTerm] = useState("");
  const [creditScoreCap, setCreditScoreCap] = useState("");
  const [code, setCode] = useState("");
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [deleteSuccess, setDelete] = useState(false);
  const [rowId, setRowID] = useState("");
  const [statusSuccess, setSuccessStatus] = useState(false);
  const [updateSwitch, setUpdateSwitch] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");
  const [loanProductStatus, setLoanProductStatus] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [currencyData, setCurrencyData] = useState([]);
  const [load, setLoad] = useState(false);
  const [disableCurrency, setDisableCurrency] = useState(true);

  const closeAdd = () => {
    setCreateLoanProduct(false);
    setLoanCap("");
    setLoanTerm("");
    setCreditScoreCap("");
    setLoanName("");
    setCode("");
    setInterestRate("");
    setCountryCode("");
    setCurrencyCode("");
  };

  const showDrawer = (record, e) => {
    e.preventDefault();
    setRowID(record.id);
    setLoanCap(record.loanCap);
    setLoanTerm(record.loanTerm);
    setCreditScoreCap(record.creditScoreCap);
    setLoanName(record.loanName);
    setLoanProductStatus(record.status);
    setCode(record.code);
    setInterestRate(record.interestRate);
    setCountryCode(record.interestRate);
    setCurrencyCode(record.interestRate);
    setVisibleDraw(true);
  };

  const closeDrawer = () => setVisibleDraw(false);
  const showDeleteConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure delete this Loan Product ?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteLoanProduct();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const changeStatusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure change status of this Loan Product ?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        changeStatusFunc();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const deleteLoanProduct = () => {
    axios
      .delete(`${config.baseUrl}/v1/loan-product/${rowId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setDelete(true);
          getLoanProducts();
          setVisibleDraw(false);
          setTimeout(() => {
            setDelete(false);
          }, 2000);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const updateCurrencySwitch = () => {
    setUpdateSwitch(true);
    setCreateLoanProduct(true);
    setVisibleDraw(false);
  };

  const changeStatusFunc = () => {
    axios
      .put(
        `${config.baseUrl}/v1/loan-product/status/${rowId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          setStatusMsg(res.data.message);
          getLoanProducts();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
          setStatusMsg("Failed to connect to SalarioPay");
        }
      });
  };

  const createLoanProductModal = () => {
    setCreateLoanProduct(true);
    setUpdateSwitch(false);
  };

  const columns = [
    {
      key: "loanName",
      text: "Product Name",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "code",
      text: "Code",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "loanCap",
      text: "Loan Cap",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "loanTerm",
      text: "Loan Term",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "creditScoreCap",
      text: "Credit Score Cap",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "interestRate",
      text: "Interest Rate",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "date",
      text: "Date",
      className: "address",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <Button
              type="primary"
              danger
              style={{
                background: "#1FC157",
                color: "white",
                cursor: "pointer",
              }}
              onClick={showDrawer.bind(this, record)}>
              Actions
            </Button>
          </Fragment>
        );
      },
    },
  ];

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];

  const options = { year: "numeric", month: "numeric", day: "numeric" };

  const getLoanProducts = () => {
    axios
      .get(`${config.baseUrl}/v1/loan-product/paged`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const loanProductData = res.data.result;

          setLoanProductData(
            loanProductData.map((data) => ({
              loanName: data.name,
              loanCap: data.loanCap,
              interestRate: data.interestRate,
              id: data.id,
              code: data.code,
              loanTerm: data.loanTerm,
              creditScoreCap: data.creditScoreCap,
              country: data.country,
              currency: data.currency,
              status: data.status ? "Enabled" : "Disabled",
              date: new Date(data.createdAt)
                .toLocaleDateString(undefined, options)
                .replace(/\//g, "-"),
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getCountries = () => {
    axios
      .get(`${config.baseUrl}/v1/countries/filtered`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setCountryData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getCurrency = () => {
    axios
      .get(`${config.baseUrl}/v1/currencies/filtered/${countryCode}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setDisableCurrency(false);
          setCurrencyData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const addNewLoanProduct = (e) => {
    e.preventDefault();
    const data = {
      code: code,
      country: countryCode,
      creditScoreCap: creditScoreCap,
      currency: currencyCode,
      interestRate: interestRate,
      loanCap: loanCap,
      loanTerm: loanTerm,
      name: loanName,
    };
    if (
      data.code &&
      data.country &&
      data.creditScoreCap &&
      data.currency &&
      data.interestRate &&
      data.loanCap &&
      data.loanTerm &&
      data.name
    ) {
      setLoad(true);
      axios
        .post(`${config.baseUrl}/v1/loan-product`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setLoanCap("");
            setLoanTerm("");
            setLoanName("");
            setCode("");
            setInterestRate("");
            setCreditScoreCap("");
            setCountryCode("");
            setCurrencyCode("");
            setSuccess(true);
            setSuccessMsg(res.data.message);
            setTimeout(() => {
              setSuccess(false);
              setCreateLoanProduct(false);
              getLoanProducts();
            }, 2500);
          } else {
            setError(true);
            setErrorMsg(res.data.message);
            setTimeout(() => {
              setError(false);
            }, 2500);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setFieldError(true);
      setTimeout(() => {
        setFieldError(false);
      }, 1500);
    }
  };

  const editLoanProduct = (e) => {
    e.preventDefault();
    const data = {
      code: code,
      interestRate: interestRate,
      loanCap: loanCap,
      loanTerm: loanTerm,
      creditScoreCap: creditScoreCap,
      name: loanName,
    };
    setLoad(true);
    axios
      .put(`${config.baseUrl}/v1/loan-product/${rowId}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setSuccess(true);
          setSuccessMsg(res.data.message);
          setTimeout(() => {
            setSuccess(false);
            getLoanProducts();
            setCreateLoanProduct(false);
          }, 2000);
        } else {
          setError(true);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };

  useEffect(() => {
    getLoanProducts();
    getCountries();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (countryCode) getCurrency();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    button: {
      excel: true,
      print: true,
      extra: true,
    },
  };

  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title mb-0">Loan Product</h5>
              <div className="d-flex justify-content-end align-items-center">
                <button
                  className="btn btn-info m-b-xs mr-2"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={createLoanProductModal}>
                  Add Loan Product
                </button>{" "}
              </div>

              <div className="pt-0">
                <div className="text-center">
                  {deleteSuccess && (
                    <div className="pb-4">
                      <Alert
                        message="Loan Product Deleted"
                        type="error"
                        showIcon
                      />
                    </div>
                  )}
                  {statusSuccess && (
                    <div className="pb-4">
                      <Alert message={statusMsg} type="error" showIcon />
                    </div>
                  )}
                </div>
                <div className="pt-0" style={{ overflowX: "auto" }}>
                  <ReactDatatable
                    config={configTable}
                    records={loanProductData}
                    columns={columns}
                    extraButtons={extraButtons}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}>
        <div
          style={{ color: "red", cursor: "pointer" }}
          onClick={showDeleteConfirm}>
          Delete Loan Product
        </div>
        <hr />
        <div style={{ cursor: "pointer" }} onClick={updateCurrencySwitch}>
          Edit Loan Product
        </div>
        <hr />
        <div style={{ cursor: "pointer" }} onClick={changeStatusConfirm}>
          {loanProductStatus === "Enabled" ? "Disable" : "Enable"}
        </div>

        <hr />
      </Drawer>
      <Modal
        title={!updateSwitch ? "Add Loan Product" : "Update Loan Product"}
        visible={createLoanProduct}
        okText="Submit"
        x
        footer={false}
        maskClosable={false}
        onCancel={closeAdd}>
        <form>
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}
          {success && (
            <div className="pb-3">
              <Alert message={successMsg} type="success" showIcon />
            </div>
          )}
          {error && (
            <div className="pb-3">
              <Alert message={errorMsg} type="error" showIcon />
            </div>
          )}
          {fieldError && (
            <div className="pb-3">
              <Alert message="All fields are required!" type="error" showIcon />
            </div>
          )}
          {serverError && (
            <p className="text-center text-danger font-weight-bold">
              Failed to connect to SalarioPay
            </p>
          )}
          {!updateSwitch ? (
            <div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Country
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setCountryCode(e.target.value)}
                  value={countryCode}>
                  <option selected>Select</option>
                  {countryData.map((country, index) => (
                    <option value={country.code} key={index}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Currency
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setCurrencyCode(e.target.value)}
                  disabled={disableCurrency}
                  value={currencyCode}>
                  <option selected>Select</option>
                  {currencyData.map((currency, index) => (
                    <option value={currency.currencyCode} key={index}>
                      {currency.currencyCode}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Currency name"
                    onChange={(e) => setLoanName(e.target.value)}
                    value={loanName}
                  />
                  <label for="floatingInput">Name</label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="country name"
                    onChange={(e) => setCode(e.target.value)}
                    value={code}
                  />
                  <label for="floatingInput">Code</label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Currency code"
                    onChange={(e) => setInterestRate(e.target.value)}
                    value={interestRate}
                  />
                  <label for="floatingInput">Interest Rate</label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Currency name"
                    onChange={(e) => setLoanCap(e.target.value)}
                    value={loanCap}
                  />
                  <label for="floatingInput">Loan Cap</label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Currency name"
                    onChange={(e) => setLoanTerm(e.target.value)}
                    value={loanTerm}
                  />
                  <label for="floatingInput">Loan Term</label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Credit Score Cap"
                    onChange={(e) => setCreditScoreCap(e.target.value)}
                    value={creditScoreCap}
                  />
                  <label for="floatingInput">Credit Score Cap</label>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Country
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setCountryCode(e.target.value)}
                  value={countryCode}>
                  <option selected>Select</option>
                  {countryData.map((country, index) => (
                    <option value={country.code} key={index}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Currency
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setCurrencyCode(e.target.value)}
                  disabled={disableCurrency}
                  value={currencyCode}>
                  <option selected>Select</option>
                  {currencyData.map((currency, index) => (
                    <option value={currency.currencyCode} key={index}>
                      {currency.currencyCode}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Currency name"
                    onChange={(e) => setLoanName(e.target.value)}
                    value={loanName}
                  />
                  <label for="floatingInput">Name</label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="country name"
                    onChange={(e) => setCode(e.target.value)}
                    value={code}
                  />
                  <label for="floatingInput">Code</label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Currency code"
                    onChange={(e) => setInterestRate(e.target.value)}
                    value={interestRate}
                  />
                  <label for="floatingInput">Interest Rate</label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Currency name"
                    onChange={(e) => setLoanCap(e.target.value)}
                    value={loanCap}
                  />
                  <label for="floatingInput">Loan Cap</label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Currency name"
                    onChange={(e) => setLoanTerm(e.target.value)}
                    value={loanTerm}
                  />
                  <label for="floatingInput">Loan Term</label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Credit Score Cap"
                    onChange={(e) => setCreditScoreCap(e.target.value)}
                    value={creditScoreCap}
                  />
                  <label for="floatingInput">Credit Score Cap</label>
                </div>
              </div>
            </div>
          )}
          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={!updateSwitch ? addNewLoanProduct : editLoanProduct}>
              Submit
            </button>
          </div>
        </form>
      </Modal>
    </Layout>
  );
}
