import React, { Fragment, useEffect, useState } from "react";
import Layout from "../../../containers/Layout";
import axios from "axios";
import config from "../../../config";
import {
  Alert,
  Button,
  Tabs,
  Spin,
  Drawer,
  Checkbox,
  Pagination,
  Modal,
  notification,
} from "antd";

import ReactDatatable from "@ashvin27/react-datatable";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Employees = (props) => {
  const [employeeInfo, setEmployeeInfo] = useState("");
  const [deleteSuccess, setDelete] = useState(false);
  const [onSuccess, setOnSuccess] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");
  const [employeeData, setEmployeeData] = useState([]);
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [rowId, setRowID] = useState("");
  const [employeeStatus, setEmployeeStatus] = useState("");
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const showDrawer = (record) => {
    setVisibleDraw(true);
    console.log(record);
    setRowID(record.id);
    setEmployeeStatus(record.status);
    sessionStorage.setItem("employeeID", record.id);
    sessionStorage.setItem("employee_userId", record.user_id);
  };

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_info: true,
    show_pagination: false,
    show_length_menu: false,
    button: {
      excel: true,
      print: true,
    },
  };

  const getEmployeeDetails = () => {
    const id = props.location.state.empId ? props.location.state.empId : "";
    axios
      .get(`${config.baseUrl}/v1/employees/paged?employerId=${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const employeDatas = res.data.result;
          setEmployeeData(
            employeDatas.map((data) => ({
              employeeCode: data.employeeCode,
              firstName: data.firstName,
              lastName: data.lastName,
              otherName: data.otherName,
              user_id: data.userId,
              mobile: data.mobile,
              paymentMethod: data.methodStatus ? "ADDED" : "NOT ADDED",
              workEmail: data.workEmail,
              id: data.id,
              salary: data.annualGrossSalary
                ? data.salaryCurrency +
                  " " +
                  Intl.NumberFormat("en-US").format(data.annualGrossSalary)
                : "",
              status: data.status ? "ACTIVE" : "INACTIVE",
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const columns = [
    {
      key: "employeeCode",
      text: "Employee Code",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "firstName",
      text: "First Name",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "lastName",
      text: "Last Name",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "otherName",
      text: "Other Name",
      TrOnlyClassName: "tab-head",
      className: "rating",
      align: "left",
      sortable: true,
    },
    {
      key: "mobile",
      text: "Mobile",
      TrOnlyClassName: "tab-head",
      className: "type_of_food",
      sortable: true,
      align: "left",
    },

    {
      key: "salary",
      text: "Annual Salary",
      TrOnlyClassName: "tab-head",
      className: "type_of_food",
      sortable: true,
      align: "left",
    },
    {
      key: "status",
      text: "Status",
      TrOnlyClassName: "tab-head",
      className: "type_of_food",
      sortable: true,
      align: "left",
    },
    {
      key: "paymentMethod",
      text: "Payment Method",
      TrOnlyClassName: "tab-head",
      className: "type_of_food",
      sortable: true,
      align: "left",
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <Button
              type="primary"
              danger
              style={{
                background: "#1FC157",
                color: "white",
                cursor: "pointer",
              }}
              onClick={showDrawer.bind(this, record)}
            >
              Actions
            </Button>
          </Fragment>
        );
      },
    },
  ];

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];

  const closeDrawer = () => setVisibleDraw(false);

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `?page=${
      page - 1
    }&size=${pageSize}&employerId=${sessionStorage.getItem("employer_id")}`;
    // getPaged(queryString);
  };

  useEffect(() => {
    getEmployeeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h5 class="card-title">Employee Information</h5>

              <div className="pt-5">
                <div className="text-center">
                  {deleteSuccess && (
                    <div className="pb-4">
                      <Alert message="Employee Deleted" type="error" showIcon />
                    </div>
                  )}
                  {onSuccess && (
                    <div className="pb-4">
                      <Alert message={statusMsg} type="success" showIcon />
                    </div>
                  )}
                </div>
                <div style={{ overflowX: "auto" }} className="pb-4">
                  <ReactDatatable
                    config={configTable}
                    records={employeeData}
                    columns={columns}
                    show_pagination={false}
                    extraButtons={extraButtons}
                  />
                </div>

                <div className="text-center pagination-part pt-5">
                  <Pagination
                    current={page}
                    total={totalItems}
                    defaultPageSize={10}
                    onChange={pagination}
                    itemRender={itemRender}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}
      >
        <Link to="/view_employee">
          <div style={{ color: "#000", cursor: "pointer" }}>View Employee</div>
        </Link>
        <hr />

        <div
          style={{ color: "red", cursor: "pointer" }}
          //   onClick={deleteEmployee}
        >
          Delete Employee
        </div>
        <hr />
        <div
          style={{ color: "#1FC157", cursor: "pointer" }}
          //   onClick={resendInvite}
        >
          Resend Invite
        </div>
        <hr />
        {/* <div style={{ cursor: "pointer" }} onClick={requestPaymentMethod}>
          Request Payment Method
        </div>

        <hr /> */}

        {/* <div style={{ cursor: "pointer" }} onClick={changeStatus}>
          {employeeStatus === "ACTIVE" ? "Disable" : "Enable"}
        </div>
        <hr /> */}
      </Drawer>
    </Layout>
  );
};

export default Employees;
