import React, { useState, Fragment, useEffect } from "react";
import Layout from "../../../containers/Layout";
import { Alert, Spin, Button, Drawer, Modal } from "antd";
import ReactDatatable from "@ashvin27/react-datatable";
import axios from "axios";
import config from "../../../config";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const Privileges = () => {
  const [loading, setLoading] = useState(true);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deletedMsg, setDeletedMsg] = useState("");
  const [statusSuccess, setSuccessStatus] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");
  const [drawerText, setDrawerText] = useState("");
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [createPrivilegesFlag, setCreatePrivilegesFlag] = useState(false);
  const [updateSwitch, setUpdateSwitch] = useState(false);
  const [privilegesData, setPrivileges] = useState([]);
  const [load, setLoad] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    id: "",
    status: "",
  });

  const showDrawer = (record, e) => {
    e.preventDefault();
    setVisibleDraw(true);
    setFormData((prevState) => ({
      ...prevState,
      name: record.name,
      id: record.id,
      status: record.status,
    }));
  };

  const closeDrawer = () => {
    setVisibleDraw(false);
    setFormData((prevState) => ({
      ...prevState,
      name: "",
      id: "",
      status: "",
    }));
  };

  const handleChange = (field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const closeAdd = () => {
    setCreatePrivilegesFlag(false);
    setFormData((prevState) => ({
      ...prevState,
      name: "",
      id: "",
      status: "",
    }));
  };

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    button: {
      excel: true,
      print: true,
      extra: true,
    },
  };

  const columns = [
    {
      key: "name",
      text: "Name",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <Button
              type="primary"
              danger
              style={{
                background: "#1FC157",
                color: "white",
                cursor: "pointer",
              }}
              onClick={showDrawer.bind(this, record)}>
              Actions
            </Button>
          </Fragment>
        );
      },
    },
  ];

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];

  const getPrivileges = () => {
    axios
      .get(`${config.baseUrl}/v1/privileges/paged`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setPrivileges(
            res.data.result.map((item) => ({
              id: item.id,
              name: item.name,
              status: item.status ? "Enabled" : "Disabled",
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const deletePrivilege = () => {
    axios
      .delete(`${config.baseUrl}/v1/privileges/${formData.id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setDeleteSuccess(true);
          getPrivileges();
          setDeletedMsg(res.data.message);
          closeDrawer();
          setTimeout(() => {
            setDeleteSuccess(false);
          }, 2000);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const changeStatusFunc = () => {
    axios
      .put(`${config.baseUrl}/v1/privileges/status/${formData.id}`, null, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setSuccessStatus(true);
          closeDrawer();
          setStatusMsg(res.data.message);
          getPrivileges();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setSuccessStatus(true);
          closeDrawer();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
          setStatusMsg("Failed to connect to SalarioPay");
        }
      });
  };

  const createPrivileges = () => {
    setCreatePrivilegesFlag(true);
    setUpdateSwitch(false);
  };

  const updatePrivilege = () => {
    setUpdateSwitch(true);
    setCreatePrivilegesFlag(true);
    setVisibleDraw(false);
  };

  const addPrivilege = (e) => {
    e.preventDefault();
    const data = {
      name: formData.name,
    };
    if (data.name) {
      setLoad(true);
      axios
        .post(`${config.baseUrl}/v1/privileges `, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setFormData((prevState) => ({
              ...prevState,
              name: "",
              id: "",
              status: "",
            }));
            setSuccess(true);
            setSuccessMsg(res.data.message);
            getPrivileges();
            setTimeout(() => {
              setSuccess(false);
              setCreatePrivilegesFlag(false);
            }, 2000);
          } else {
            setError(true);
            setErrorMsg(res.data.message);
            setTimeout(() => {
              setError(false);
            }, 2500);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setFieldError(true);
      setTimeout(() => {
        setFieldError(false);
      }, 1500);
    }
  };

  const editPrivilege = (e) => {
    e.preventDefault();
    const data = {
      name: formData.name,
    };
    if (data.name) {
      setLoad(true);
      axios
        .put(`${config.baseUrl}/v1/privileges/${formData.id}`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setFormData((prevState) => ({
              ...prevState,
              name: "",
              id: "",
              status: "",
            }));
            setSuccess(true);
            setSuccessMsg(res.data.message);
            getPrivileges();
            setTimeout(() => {
              setSuccess(false);
              setCreatePrivilegesFlag(false);
            }, 2000);
          } else {
            setError(true);
            setErrorMsg(res.data.message);
            setTimeout(() => {
              setError(false);
            }, 2500);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setFieldError(true);
      setTimeout(() => {
        setFieldError(false);
      }, 1500);
    }
  };

  const showDeleteConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure delete this privilege?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deletePrivilege();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const changeStatusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure change status of this privilege?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        changeStatusFunc();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  useEffect(() => {
    getPrivileges();
  }, []);

  useEffect(() => {
    if (formData.status === "Enabled") {
      setDrawerText("Disable");
    } else if (formData.status === "Disabled") {
      setDrawerText("Enable");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleDraw]);
  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title mb-5">Privileges</h5>
              <div className="d-flex justify-content-end align-items-center">
                <button
                  className="btn btn-info m-b-xs mr-2"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={createPrivileges}>
                  Create Privilege
                </button>{" "}
              </div>
              <div className="pt-0">
                {loading ? (
                  <div class="d-flex justify-content-center align-items-center">
                    <Spin />
                  </div>
                ) : (
                  <div>
                    <div className="text-center">
                      {deleteSuccess && (
                        <div className="pb-4">
                          <Alert message={deletedMsg} type="success" showIcon />
                        </div>
                      )}
                      {statusSuccess && (
                        <div className="pb-4">
                          <Alert
                            message={statusMsg}
                            type={
                              drawerText.toLowerCase().includes("enable")
                                ? "success"
                                : "error"
                            }
                            showIcon
                          />
                        </div>
                      )}
                    </div>
                    <ReactDatatable
                      config={configTable}
                      records={privilegesData}
                      columns={columns}
                      extraButtons={extraButtons}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}>
        <div
          style={{ color: "red", cursor: "pointer" }}
          onClick={showDeleteConfirm}>
          Delete Privileges
        </div>
        <hr />
        <div style={{ cursor: "pointer" }} onClick={updatePrivilege}>
          Edit Privileges
        </div>
        <hr />
        <div style={{ cursor: "pointer" }} onClick={changeStatusConfirm}>
          {drawerText}
        </div>
      </Drawer>
      <Modal
        title={!updateSwitch ? "Create Privilege" : "Update Privilege"}
        visible={createPrivilegesFlag}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeAdd}>
        <form>
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}
          {success && (
            <div className="pb-3">
              <Alert message={successMsg} type="success" showIcon />
            </div>
          )}
          {error && (
            <div className="pb-3">
              <Alert message={errorMsg} type="error" showIcon />
            </div>
          )}
          {fieldError && (
            <div className="pb-3">
              <Alert message="All fields are required!" type="error" showIcon />
            </div>
          )}
          {serverError && (
            <p className="text-center text-danger font-weight-bold">
              Failed to connect to SalarioPay
            </p>
          )}
          <div>
            <div className="mb-3">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Name"
                  onChange={(e) => handleChange("name", e.target.value)}
                  value={formData.name}
                />
                <label for="floatingInput">Name</label>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={!updateSwitch ? addPrivilege : editPrivilege}>
              Submit
            </button>
          </div>
        </form>
      </Modal>
    </Layout>
  );
};

export default Privileges;
