import React, { useState, useEffect } from 'react'
import Tabs from '../tab'
import { Spin, Modal, Checkbox, Button, Space, Alert } from 'antd'
import Cookie from 'js-cookie'
import Layout from '../../containers/Layout'
import BillingPayment from './paymentbilling'
import axios from 'axios'
import config from '../../config'
import CompanyInformation from './company_information'
const userChecklists = Cookie.getJSON('salarioPayUserInfo')
export default function Profile () {
  const [verifyStatus, setVerifyStatus] = useState(false)
  const [cardInfo, setCardInfo] = useState(false)
  const [successMsg, setSuccessMsg] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [serverError, setServerError] = useState(false)
  const [apiData, setData] = useState([{ tnxRef: '', status: '', transID: '' }])
  const url = window.location.href

  const cancelStatus = () => setVerifyStatus(false)

  const verifyBilling = () => {
    const token = sessionStorage.getItem('token')
    var res = url.slice(36)
    var DATA = res.split('&')

    setData({ ...apiData, tnxRef: DATA[1], status: DATA[2], transID: DATA[2] })
    axios
      .get(
        `${config.baseUrl}/v1/billing-methods/verify-card/${DATA[2] &&
          DATA[2].slice(15)}/${DATA[1] && DATA[1].slice(7)}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(res => {
        if (res.data.status) {
          setVerifyStatus(true)
          setCardInfo(res.data.result)
          setSuccessMsg(res.data.message)
          setSuccess(true)
          setError(false)
          // setTimeout(() => {
          //   setSuccess(false)
          // },2000)
        } else {
          setErrorMsg(res.data.message)
          setVerifyStatus(true)
          setSuccess(false)
          setError(true)
          // setTimeout(() => {
          //   setError(false)
          // },2000)
        }
      })
      .catch(err => {
        setServerError(true)
        // setTimeout(() => {
        //   setServerError(false)
        // },2000)
      })
  }

  useEffect(() => {
    if (url.length > 35) {
      verifyBilling()
    }
  }, [])

  return (
    <>
      <div class='loader'>
        <div class='spinner-grow text-primary' role='status'>
          <span class='sr-only'>Loading...</span>
        </div>
      </div>
      <Tabs userID={userChecklists.id} cardInformation={cardInfo} />
      <CompanyInformation cardInformation={cardInfo} />
      <div>
        <Modal
          title='Payment Confirmation Status'
          visible={true}
          okText='Submit'
          footer={false}
          maskClosable={false}
          onCancel={cancelStatus}
        >
          <div className='container'>
            <BillingPayment
              success={success}
              error={error}
              successMsg={successMsg}
              errorMsg={errorMsg}
              serverError={serverError}
            />
          </div>
        </Modal>
      </div>
    </>
  )
}
