import React, { Fragment, useEffect, useState, useRef } from "react";
import OtpInput from "react-otp-input";
import Layout from "../../../containers/Layout";
import { useHttp } from "../../../hooks/useHttps";
import LoanInterest from "./loan_interest";
import { Button, Spin, Select, Alert, Modal, notification, Switch } from "antd";
import axios from "axios";
import config from "../../../config";
const { Option } = Select;

export default function Employers() {
  const [loading, employers] = useHttp(
    `${config.baseUrl}/v1/employees/filtered/employer`,
    []
  );
  const [selectedBiller, setSelected] = useState(false);
  const [billCategory, setBillerCategory] = useState([]);
  const [openCountryBillermoddal, setOpenCountryBillerModdal] = useState(false);
  const [plans, setPlans] = useState([]);
  const [beneficiaryCheck, setBeneficiaryCheck] = useState(false);
  const billEndRef = useRef(null);
  const [beneficiariesList, setBeneficiaryList] = useState([]);
  const completeEndRef = useRef(null);
  const [showBeneficiary, setShowBeneficiary] = useState(false);
  const [loadingBillers, setLoadingBillers] = useState(false);
  const [billerCountry, setBillerCountry] = useState("");
  const [exactBeneficiaries, setExactBeneficiaries] = useState([]);
  const [billType, setBillerTypes] = useState([]);
  const [amount, setAmt] = useState("");
  const [interestRate, setLoanInterest] = useState("");
  const [walletCurrenciesList, setWalletInformations] = useState([]);
  const [bankInformation, setBankInforrmation] = useState([]);
  const [billerType, setBillerType] = useState("");
  const [countryData, setCountryData] = useState([]);
  const [loadBillType, setLoadBillType] = useState(false);
  const [pinModal, setPinModal] = useState(false);
  const [billTypeSelected, setBillTypeSelected] = useState("");
  const [pin, setPin] = useState("");
  const [load, setLoad] = useState(false);
  const [fill, setFill] = useState(false);
  const [billerID, setBillerID] = useState("");
  const [paymentOption, setPaymentOption] = useState("");
  const [catName, setCatName] = useState("");
  const [labelName, setLabelName] = useState("");
  const [selectedCountryProps, setCountryProps] = useState("");

  const [bundlePlan, setBundlePlan] = useState("");
  const [customerNo, setCustomerNo] = useState("");

  const [customerName, setCustomerName] = useState("");

  const [loadBills, setLoadBills] = useState(true);
  const [billsPayments, setBillsPayments] = useState(false);
  const [noBills, setNoBills] = useState(false);

  useEffect(() => {
    getCounties();
    getBillCategories();
    getWalletInformations();
  }, []);

  const cancelPinSetup = () => {
    setPinModal(false);
    setPin("");
  };

  const getBillerName = (id) => {
    console.log(billCategory);
    var getBillerInfo = billType?.find((bill) => bill.id === id);
    return getBillerInfo.name;
  };
  const payBill = () => {
    getBillerName(billTypeSelected);
    const data = {
      amount: Number(amount),
      billerCategory: selectedBiller,
      billerId: Number(billTypeSelected),
      billerName: getBillerName(Number(billTypeSelected)),
      billerPlanId: Number(bundlePlan),
      customerName: customerName ? customerName : "NA",
      country: billerCountry,
      customerNumber: customerNo,
      employeeId: sessionStorage.getItem("employeeID"),
      paymentOption: paymentOption,
      pin: pin,
      platform: "WEB",
      saveBeneficiary: beneficiaryCheck,
      walletId: walletCurrenciesList[0].id,
    };

    setLoad(true);
    axios
      .post(`${config.baseUrl}/v1/bill-payment`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setAmt("");
          setPin("");
          setPinModal(false);
          setPaymentOption(false);
          setCustomerName("");
          setCustomerNo("");
          setBundlePlan(false);
          setBillTypeSelected("");
          Notification("success", "Success", res.data.message);
          setTimeout(() => {
            window.location.reload(false);
          }, 2400);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
        }
      });
  };

  const onBeneficiarySelect = (d) => {
    if (catName === d?.billerName) {
      setCustomerNo(d?.identifier);
      setShowBeneficiary(false);
    } else {
      Notification(
        "error",
        "Error",
        "Selected Beneficiary biller is different!"
      );
    }
  };

  const getbeneficiaries = (category) => {
    setLoad(true);
    axios
      .get(
        `${config.baseUrl}/v1/bill-payment/beneficiaries?billerCategory=${category}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          const beneList = res.data.result;
          setBeneficiaryList(beneList);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
        }
      });
  };

  function handleChange(currency) {
    const walletCurrencyBalance = walletCurrenciesList.find(
      (wallet) => wallet.currency === currency
    );
    setWalletInformations([
      {
        balance: walletCurrencyBalance.balance,
        currency: walletCurrencyBalance.currency,
      },
    ]);
    setBankInforrmation([
      {
        accountName: walletCurrencyBalance.accountName,
        accountNumber: walletCurrencyBalance.accountNumber,
        bankName: walletCurrencyBalance.bankName,
        currency: walletCurrencyBalance.currency,
      },
    ]);
  }

  function handleChangeCountry(country) {
    const counryInfo = countryData.find((cont) => cont.code === country);
    setCountryProps([
      {
        name: counryInfo.name,
        code: counryInfo.code,
      },
    ]);
  }

  //n

  const selectBill = (billerID, name) => {
    setBillTypeSelected(billerID);
    const result = billType.find((obj) => obj.id === billerID);
    var exactCategoryBeneficiaries = beneficiariesList?.filter(
      (bill) => bill?.billerName === result.name
    );
    setExactBeneficiaries(exactCategoryBeneficiaries);
    setCatName(result.name);
    setLabelName(name);
    getPlans(result.categoryCode, billerID);
    setAmt("");
    setCustomerNo("");
    setPaymentOption(false);
    setCustomerName("");
  };

  const selectBiller = (billerID, billType) => {
    getbeneficiaries(billType);
    setBillerTypes([]);
    setSelected(billerID);
    setBillerID(billerID);
    setBillerType(billType);

    // getBillers(billerID);
    setAmt("");
    setCustomerNo("");
    setPaymentOption(false);
    setCustomerName("");
    setBillTypeSelected(false);
    if (!billerCountry) {
      setOpenCountryBillerModdal(true);
      setBillerTypes([]);
    } else {
      setBillerTypes([]);
      getBillers(billType);
    }
  };

  const getPlans = (cat, id) => {
    axios
      .get(`${config.baseUrl}/v1/bill-payment/biller-plans/${cat}/${id}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setPlans(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getLoanInterest = (amt) => {
    axios
      .get(
        `${config.baseUrl}/v1/loans/interest/${selectedBiller}/${amt}/${billerCountry}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setLoanInterest(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getLoanInterestAirtime = () => {
    axios
      .get(
        `${config.baseUrl}/v1/loans/interest/${selectedBiller}/${amount}/${billerCountry}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          setLoanInterest(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const validateCategory = () => {
    setLoad(true);
    axios
      .get(
        `${config.baseUrl}/v1/bill-payment/validate-bill?billerId=${billTypeSelected}&customerNumber=${customerNo}&billerPlanId=${bundlePlan}&billerCategory=${selectedBiller}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setCustomerName(res.data.result.customerName);
          Notification("success", "Success", res.data.message);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
        }
      });
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const getWalletInformations = () => {
    axios
      .get(`${config.baseUrl}/v1/wallets/employee`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const walletInfo = res.data.result;
          setWalletInformations(walletInfo);
          setBankInforrmation(walletInfo);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const bundlePlanChange = (e) => {
    setBundlePlan(e.target.value);
    const amtPlan = plans.find((v) => v.id === e.target.value);
    const accurateAmt = amtPlan.amount;
    setAmt(accurateAmt);
    getLoanInterest(accurateAmt);
  };

  const getBillers = (id) => {
    setLoadingBillers(true);
    setLoadBillType(true);
    axios
      .get(
        `${config.baseUrl}/v1/bill-payment/billers/${
          id ? id : billerID
        }/${billerCountry}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadBillType(false);
        setLoadingBillers(false);
        if (res.data.status) {
          setOpenCountryBillerModdal(false);
          setBillerTypes(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        setLoadingBillers(false);
        if (err) {
        }
      });
  };

  const getCounties = () => {
    axios
      .get(`${config.baseUrl}/v1/countries/filtered`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setCountryData(res.data.result);
          // getBillPayment(res.data.result[0].code);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const procceedBill = () => {
    if (selectedBiller !== "AIRTIME") {
      if (amount && paymentOption && customerNo && bundlePlan) {
        setPinModal(true);
      } else {
        Notification("error", "Error", "fileds are all required!");
      }
    } else {
      if (amount && paymentOption && customerNo) {
        setPinModal(true);
      } else {
        Notification("error", "Error", "fileds are all required!");
      }
    }
  };

  const getBillCategories = () => {
    axios
      .get(`${config.baseUrl}/v1/bill-payment/categories`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setLoadBills(false);
            setBillsPayments(true);
            setNoBills(false);
            setBillerCategory(res.data.result);
          } else {
            setLoadBills(false);
            setBillsPayments(false);
            setNoBills(true);
          }
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    if (
      !openCountryBillermoddal &&
      billType?.length > 0 &&
      billEndRef.current
    ) {
      // Delay the scroll action to ensure the DOM is fully updated
      setTimeout(() => {
        billEndRef.current.scrollIntoView({ behavior: "smooth" });
      }, 500); // Adjust the delay as necessary
    }
  }, [openCountryBillermoddal, billType]);

  useEffect(() => {
    if (!showBeneficiary && completeEndRef.current) {
      // Delay the scroll action to ensure the DOM is fully updated
      setTimeout(() => {
        completeEndRef.current.scrollIntoView({ behavior: "smooth" });
      }, 500); // Adjust the delay as necessary
    }
  }, [showBeneficiary]);

  useEffect(() => {
    completeEndRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [billTypeSelected]);

  const getBillPayment = (code) => {
    axios
      .get(`${config.baseUrl}/v1/bill-payment/billers/{category}/${code}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setLoadBills(false);
            setBillsPayments(true);
            setNoBills(false);
            setBillerCategory(res.data.result);
          } else {
            setLoadBills(false);
            setBillsPayments(false);
            setNoBills(true);
          }
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const onChangeBeneficiarCheck = (val) => {
    setBeneficiaryCheck(val);
  };

  const changeBeneficiaryList = (e) => {
    setShowBeneficiary(true);
  };

  const renderSwitch = (biller) => {
    switch (biller) {
      case "AIRTIME":
        return (
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body ">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>{catName}</h5>
                    <div>
                      {beneficiariesList?.length > 0 && (
                        <div className="d-flex justify-content-end mt-2">
                          <div
                            className="beneficiary-btn-send-money"
                            style={{
                              cursor: "pointer",
                              fontWeight: "lighter",
                            }}
                            onClick={changeBeneficiaryList}
                          >
                            <b>Select Beneficiary </b>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <br />
                  <form>
                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="number"
                          className="form-control"
                          id="floatingInput"
                          placeholder="country name"
                          onChange={(e) => setAmt(e.target.value)}
                          value={amount}
                          onBlur={getLoanInterestAirtime}
                        />
                        <label for="floatingInput">Amount</label>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="country name"
                          onChange={(e) => setCustomerNo(e.target.value)}
                          value={customerNo}
                        />
                        <label for="floatingInput">Phone Number</label>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="form-floating">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setPaymentOption(e.target.value)}
                        >
                          <option selected>Select Payment Option</option>
                          <option value="WALLET">
                            Wallet (
                            {bankInformation?.length > 0
                              ? bankInformation[0]?.currency +
                                " " +
                                bankInformation[0]?.balance
                              : "Balance Not Available"}
                            )
                          </option>
                          <option value="BNPL">Buy Now Pay Later</option>
                        </select>
                        <label for="floatingInput">Payment Option</label>
                      </div>
                    </div>

                    {paymentOption === "WALLET" &&
                      walletCurrenciesList.length > 1 && (
                        <div className="mb-3">
                          <label for="floatingInput">Select Wallet</label>
                          <Select
                            defaultValue={bankInformation[0]?.currency}
                            style={{ width: "100%" }}
                            value={bankInformation[0]?.currency}
                            onChange={handleChange}
                          >
                            {walletCurrenciesList?.map((walletCurrency) => {
                              return (
                                <Option
                                  key={walletCurrency?.id}
                                  value={walletCurrency?.currency}
                                >
                                  {walletCurrency?.currency}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      )}

                    {paymentOption === "BNPL" && (
                      <LoanInterest interestRate={interestRate} />
                    )}
                    <div className=" mb-3">
                      <div>
                        {" "}
                        <label for="floatingInput">Save as Beneficiary</label>
                      </div>
                      <Switch
                        checked={beneficiaryCheck}
                        onChange={onChangeBeneficiarCheck}
                        size={"small"}
                      />
                    </div>
                    {beneficiaryCheck && (
                      <div className="mb-3">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="country name"
                            value={customerName}
                            onChange={(e) => setCustomerName(e.target?.value)}
                          />
                          <label for="floatingInput">Customer Name</label>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        );

      case "DATA_BUNDLE":
        return (
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body ">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>{catName}</h5>
                    <div>
                      {beneficiariesList?.length > 0 && (
                        <div className="d-flex justify-content-end mt-2">
                          <div
                            className="beneficiary-btn-send-money"
                            style={{
                              cursor: "pointer",
                              fontWeight: "lighter",
                            }}
                            onClick={changeBeneficiaryList}
                          >
                            <b>Select Beneficiary </b>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <br />

                  <form>
                    <div className="mb-3">
                      <div className="form-floating">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={bundlePlanChange}
                          value={bundlePlan}
                        >
                          <option selected>Select Plan</option>
                          {plans.map((plan) => {
                            return <option value={plan.id}>{plan.name}</option>;
                          })}
                        </select>
                        <label for="floatingInput">Plan </label>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="form-floating">
                        {labelName !== "SWIFT" ? (
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            disabled={true}
                            placeholder="country name"
                            onChange={(e) => setAmt(e.target.value)}
                            onBlur={getLoanInterest}
                            value={amount}
                          />
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="country name"
                            disabled={false}
                            onChange={(e) => setAmt(e.target.value)}
                            onBlur={getLoanInterestAirtime}
                            value={amount}
                          />
                        )}
                        <label for="floatingInput">Amount</label>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="country name"
                          onChange={(e) => setCustomerNo(e.target.value)}
                          value={customerNo}
                        />
                        <label for="floatingInput">Customer Number</label>
                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="form-floating">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setPaymentOption(e.target.value)}
                        >
                          <option selected>Select Payment Option</option>
                          <option value="WALLET">
                            Wallet (
                            {bankInformation?.length > 0
                              ? bankInformation[0]?.currency +
                                " " +
                                bankInformation[0]?.balance
                              : "Balance Not Available"}
                            )
                          </option>
                          <option value="BNPL">Buy Now Pay Later</option>
                        </select>
                        <label for="floatingInput">Payment Option</label>
                      </div>
                    </div>

                    {paymentOption === "WALLET" &&
                      walletCurrenciesList.length > 1 && (
                        <div className="mb-3">
                          <label for="floatingInput">Select Wallet</label>
                          <Select
                            defaultValue={bankInformation[0]?.currency}
                            style={{ width: "100%" }}
                            value={bankInformation[0]?.currency}
                            onChange={handleChange}
                          >
                            {walletCurrenciesList?.map((walletCurrency) => {
                              return (
                                <Option
                                  key={walletCurrency?.id}
                                  value={walletCurrency?.currency}
                                >
                                  {walletCurrency?.currency}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      )}

                    {paymentOption === "BNPL" && (
                      <LoanInterest interestRate={interestRate} />
                    )}
                    <div className=" mb-3">
                      <div>
                        {" "}
                        <label for="floatingInput">Save as Beneficiary</label>
                      </div>
                      <Switch
                        checked={beneficiaryCheck}
                        onChange={onChangeBeneficiarCheck}
                        size={"small"}
                      />
                    </div>
                    {beneficiaryCheck && (
                      <div className="mb-3">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="country name"
                            value={customerName}
                            onChange={(e) => setCustomerName(e.target?.value)}
                          />
                          <label for="floatingInput">Customer Name</label>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        );

      case "BETTING":
        return (
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body ">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>{catName}</h5>
                    <div>
                      {beneficiariesList?.length > 0 && (
                        <div className="d-flex justify-content-end mt-2">
                          <div
                            className="beneficiary-btn-send-money"
                            style={{
                              cursor: "pointer",
                              fontWeight: "lighter",
                            }}
                            onClick={changeBeneficiaryList}
                          >
                            <b>Select Beneficiary </b>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <br />
                  {fill && (
                    <div className="pb-4">
                      <Alert
                        message="All fields are required"
                        type="error"
                        showIcon
                      />
                    </div>
                  )}
                  <form>
                    <div class="mb-3">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={bundlePlanChange}
                        value={bundlePlan}
                      >
                        <option selected>Select Plan</option>
                        {plans.map((plan) => {
                          return <option value={plan.id}>{plan.name}</option>;
                        })}
                      </select>
                    </div>
                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          onBlur={getLoanInterest}
                          placeholder="country name"
                          onChange={(e) => setAmt(e.target.value)}
                          value={amount}
                        />
                        <label for="floatingInput">Amount</label>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="country name"
                          onChange={(e) => setCustomerNo(e.target.value)}
                          value={customerNo}
                          onBlur={validateCategory}
                        />
                        <label for="floatingInput">Customer ID</label>
                      </div>
                    </div>
                    {customerName && (
                      <div className="mb-3">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            disabled
                            placeholder="country name"
                            value={customerName}
                          />
                          <label for="floatingInput">Customer Name</label>
                        </div>
                      </div>
                    )}
                    <div className="mb-3">
                      <div className="form-floating">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setPaymentOption(e.target.value)}
                        >
                          <option selected>Select Payment Option</option>
                          <option value="WALLET">
                            Wallet (
                            {bankInformation?.length > 0
                              ? bankInformation[0]?.currency +
                                " " +
                                bankInformation[0]?.balance
                              : "Balance Not Available"}
                            )
                          </option>
                          <option value="BNPL">Buy Now Pay Later</option>
                        </select>
                        <label for="floatingInput">Payment Option</label>
                      </div>
                    </div>

                    {paymentOption === "WALLET" &&
                      walletCurrenciesList.length > 1 && (
                        <div className="mb-3">
                          <label for="floatingInput">Select Wallet</label>
                          <Select
                            defaultValue={bankInformation[0]?.currency}
                            style={{ width: "100%" }}
                            value={bankInformation[0]?.currency}
                            onChange={handleChange}
                          >
                            {walletCurrenciesList?.map((walletCurrency) => {
                              return (
                                <Option
                                  key={walletCurrency?.id}
                                  value={walletCurrency?.currency}
                                >
                                  {walletCurrency?.currency}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      )}
                    {paymentOption === "BNPL" && (
                      <LoanInterest interestRate={interestRate} />
                    )}
                    <div className=" mb-3">
                      <div>
                        {" "}
                        <label for="floatingInput">Save as Beneficiary</label>
                      </div>
                      <Switch
                        checked={beneficiaryCheck}
                        onChange={onChangeBeneficiarCheck}
                        size={"small"}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        );

      case "ELECTRICITY":
        return (
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body ">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>{catName}</h5>
                    <div>
                      {beneficiariesList?.length > 0 && (
                        <div className="d-flex justify-content-end mt-2">
                          <div
                            className="beneficiary-btn-send-money"
                            style={{
                              cursor: "pointer",
                              fontWeight: "lighter",
                            }}
                            onClick={changeBeneficiaryList}
                          >
                            <b>Select Beneficiary </b>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <br />
                  {fill && (
                    <div className="pb-4">
                      <Alert
                        message="All fields are required"
                        type="error"
                        showIcon
                      />
                    </div>
                  )}
                  <form>
                    <div class="mb-3">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={bundlePlanChange}
                        value={bundlePlan}
                      >
                        <option selected>Select Plan</option>
                        {plans.map((plan) => {
                          return <option value={plan.id}>{plan.name}</option>;
                        })}
                      </select>
                    </div>
                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          onBlur={getLoanInterestAirtime}
                          placeholder="country name"
                          onChange={(e) => setAmt(e.target.value)}
                          value={amount}
                        />
                        <label for="floatingInput">Amount</label>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="country name"
                          onChange={(e) => setCustomerNo(e.target.value)}
                          value={customerNo}
                          onBlur={validateCategory}
                        />
                        <label for="floatingInput"> Meter Number</label>
                      </div>
                    </div>
                    {customerName && (
                      <div className="mb-3">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="country name"
                            disabled
                            value={customerName}
                          />
                          <label for="floatingInput">Customer Name</label>
                        </div>
                      </div>
                    )}

                    <div className="mb-3">
                      <div className="form-floating">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setPaymentOption(e.target.value)}
                        >
                          <option selected>Select Payment Option</option>
                          <option value="WALLET">
                            Wallet (
                            {bankInformation?.length > 0
                              ? bankInformation[0]?.currency +
                                " " +
                                bankInformation[0]?.balance
                              : "Balance Not Available"}
                            )
                          </option>
                          <option value="BNPL">Buy Now Pay Later</option>
                        </select>
                        <label for="floatingInput">Payment Option</label>
                      </div>
                    </div>

                    {paymentOption === "WALLET" &&
                      walletCurrenciesList.length > 1 && (
                        <div className="mb-3">
                          <label for="floatingInput">Select Wallet</label>
                          <Select
                            defaultValue={bankInformation[0]?.currency}
                            style={{ width: "100%" }}
                            value={bankInformation[0]?.currency}
                            onChange={handleChange}
                          >
                            {walletCurrenciesList?.map((walletCurrency) => {
                              return (
                                <Option
                                  key={walletCurrency?.id}
                                  value={walletCurrency?.currency}
                                >
                                  {walletCurrency?.currency}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      )}

                    {paymentOption === "BNPL" && (
                      <LoanInterest interestRate={interestRate} />
                    )}
                    <div className=" mb-3">
                      <div>
                        {" "}
                        <label for="floatingInput">Save as Beneficiary</label>
                      </div>
                      <Switch
                        checked={beneficiaryCheck}
                        onChange={onChangeBeneficiarCheck}
                        size={"small"}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        );

      case "CABLE_TV":
        return (
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body ">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>{catName}</h5>
                    <div>
                      {beneficiariesList?.length > 0 && (
                        <div className="d-flex justify-content-end mt-2">
                          <div
                            className="beneficiary-btn-send-money"
                            style={{
                              cursor: "pointer",
                              fontWeight: "lighter",
                            }}
                            onClick={changeBeneficiaryList}
                          >
                            <b>Select Beneficiary </b>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <br />
                  {fill && (
                    <div className="pb-4">
                      <Alert
                        message="All fields are required"
                        type="error"
                        showIcon
                      />
                    </div>
                  )}
                  <form>
                    <div class="mb-3">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={bundlePlanChange}
                        value={bundlePlan}
                      >
                        <option selected>Select Plan</option>
                        {plans.map((plan) => {
                          return <option value={plan.id}>{plan.name}</option>;
                        })}
                      </select>
                    </div>
                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          disabled
                          onBlur={getLoanInterest}
                          placeholder="country name"
                          onChange={(e) => setAmt(e.target.value)}
                          value={amount}
                        />
                        <label for="floatingInput">Amount</label>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="country name"
                          onChange={(e) => setCustomerNo(e.target.value)}
                          value={customerNo}
                          onBlur={validateCategory}
                        />
                        <label for="floatingInput">Customer Number</label>
                      </div>
                    </div>
                    {customerName && (
                      <div className="mb-3">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            id="floatingInput"
                            placeholder="country name"
                            value={customerName}
                          />
                          <label for="floatingInput">Customer Name</label>
                        </div>
                      </div>
                    )}

                    <div className="mb-3">
                      <div className="form-floating">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setPaymentOption(e.target.value)}
                        >
                          <option selected>Select Payment Option</option>
                          <option value="WALLET">
                            Wallet (
                            {bankInformation?.length > 0
                              ? bankInformation[0]?.currency +
                                " " +
                                bankInformation[0]?.balance
                              : "Balance Not Available"}
                            )
                          </option>
                          <option value="BNPL">Buy Now Pay Later</option>
                        </select>
                        <label for="floatingInput">Payment Option</label>
                      </div>
                    </div>

                    {paymentOption === "WALLET" &&
                      walletCurrenciesList.length > 1 && (
                        <div className="mb-3">
                          <label for="floatingInput">Select Wallet</label>
                          <Select
                            defaultValue={bankInformation[0]?.currency}
                            style={{ width: "100%" }}
                            value={bankInformation[0]?.currency}
                            onChange={handleChange}
                          >
                            {walletCurrenciesList?.map((walletCurrency) => {
                              return (
                                <Option
                                  key={walletCurrency?.id}
                                  value={walletCurrency?.currency}
                                >
                                  {walletCurrency?.currency}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      )}

                    {paymentOption === "BNPL" && (
                      <LoanInterest interestRate={interestRate} />
                    )}

                    <div className=" mb-3">
                      <div>
                        {" "}
                        <label for="floatingInput">Save as Beneficiary</label>
                      </div>
                      <Switch
                        checked={beneficiaryCheck}
                        onChange={onChangeBeneficiarCheck}
                        size={"small"}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        );

      case "TOLL_FEE":
        return (
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body ">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5>{catName}</h5>
                    <div>
                      {beneficiariesList?.length > 0 && (
                        <div className="d-flex justify-content-end mt-2">
                          <div
                            className="beneficiary-btn-send-money"
                            style={{
                              cursor: "pointer",
                              fontWeight: "lighter",
                            }}
                            onClick={changeBeneficiaryList}
                          >
                            <b>Select Beneficiary </b>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <br />
                  {fill && (
                    <div className="pb-4">
                      <Alert
                        message="All fields are required"
                        type="error"
                        showIcon
                      />
                    </div>
                  )}
                  <form>
                    <div class="mb-3">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        onChange={bundlePlanChange}
                        value={bundlePlan}
                      >
                        <option selected>Select Plan</option>
                        {plans.map((plan) => {
                          return <option value={plan.id}>{plan.name}</option>;
                        })}
                      </select>
                    </div>
                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          onBlur={getLoanInterestAirtime}
                          placeholder="country name"
                          onChange={(e) => setAmt(e.target.value)}
                          value={amount}
                        />
                        <label for="floatingInput">Amount</label>
                      </div>
                    </div>

                    <div className="mb-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="country name"
                          onChange={(e) => setCustomerNo(e.target.value)}
                          value={customerNo}
                          onBlur={validateCategory}
                        />
                        <label for="floatingInput">{labelName}</label>
                      </div>
                    </div>
                    {customerName && (
                      <div className="mb-3">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            disabled
                            placeholder="country name"
                            value={customerName}
                          />
                          <label for="floatingInput">Customer Name</label>
                        </div>
                      </div>
                    )}

                    <div className="mb-3">
                      <div className="form-floating">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setPaymentOption(e.target.value)}
                        >
                          <option selected>Select Payment Option</option>
                          <option value="WALLET">
                            Wallet (
                            {bankInformation?.length > 0
                              ? bankInformation[0]?.currency +
                                " " +
                                bankInformation[0]?.balance
                              : "Balance Not Available"}
                            )
                          </option>
                          <option value="BNPL">Buy Now Pay Later</option>
                        </select>
                        <label for="floatingInput">Payment Option</label>
                      </div>
                    </div>

                    {paymentOption === "WALLET" &&
                      walletCurrenciesList.length > 1 && (
                        <div className="mb-3">
                          <label for="floatingInput">Select Wallet</label>
                          <Select
                            defaultValue={bankInformation[0]?.currency}
                            style={{ width: "100%" }}
                            value={bankInformation[0]?.currency}
                            onChange={handleChange}
                          >
                            {walletCurrenciesList?.map((walletCurrency) => {
                              return (
                                <Option
                                  key={walletCurrency?.id}
                                  value={walletCurrency?.currency}
                                >
                                  {walletCurrency?.currency}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      )}

                    {paymentOption === "BNPL" && (
                      <LoanInterest interestRate={interestRate} />
                    )}
                    <div className=" mb-3">
                      <div>
                        {" "}
                        <label for="floatingInput">Save as Beneficiary</label>
                      </div>
                      <Switch
                        checked={beneficiaryCheck}
                        onChange={onChangeBeneficiarCheck}
                        size={"small"}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return (
          <div className="text-center">Biller Information Not Available </div>
        );
    }
  };
  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>Payments</div>
                {countryData?.length > 0 && (
                  <div>
                    <b>COUNTRY</b>{" "}
                    <Select
                      defaultValue={countryData[0]?.code}
                      style={{ width: 110 }}
                      onChange={handleChangeCountry}
                      value={billerCountry}
                    >
                      {countryData?.map((countryprops) => {
                        return (
                          <Option
                            key={countryprops?.id}
                            value={countryprops?.code}
                          >
                            {countryprops?.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                )}
              </div>
            </div>
            <div class="card-body mb-0 pb-0">
              <br />
              {loadBillType && (
                <div className="row">
                  <div class="col">
                    <div className="text-center pb-3">
                      <Spin />
                    </div>
                  </div>
                </div>
              )}
              {billsPayments && (
                <div class="row">
                  {billCategory.map((biller) => {
                    return (
                      <div
                        class="col-sm-6 col-md-3"
                        onClick={selectBiller.bind(
                          this,
                          biller.code,
                          biller.code
                        )}
                      >
                        <div
                          class="card card-file-manager"
                          style={{
                            cursor: "pointer",
                            border:
                              selectedBiller === biller.code
                                ? "1px solid #1FC157"
                                : "",
                          }}
                        >
                          <div class="card-file-header text-danger">
                            <img src={biller.icon} className="w-25" />
                          </div>
                          <div class="card-body mb-0 pb-4 pt-4">
                            <h6 class="card-subtitle text-center text-muted">
                              {biller.name}
                            </h6>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {noBills && (
                <div className="text-center pb-5">
                  <p className="text-center">Biller Not Available Yet</p>
                </div>
              )}

              {loadBills && (
                <div className="row">
                  <div className="text-center pb-5">
                    <Spin />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {loadBillType && (
        <div className="row">
          <div class="col">
            <div class="card">
              <div className="text-center pb-5 py-5">
                <Spin />
              </div>
            </div>
          </div>
        </div>
      )}
      {!loadBillType && billType.length > 0 && (
        <div class="row" ref={billEndRef}>
          <div class="col">
            <div class="card">
              <div className="card-header">Billers</div>
              <div class="card-body mb-0 pb-0">
                {billType.length > 0 ? (
                  <div class="row">
                    {billType.map((biller) => {
                      return (
                        <div
                          class="col-sm-6 col-md-3"
                          onClick={selectBill.bind(
                            this,
                            biller.id,
                            biller.name
                          )}
                        >
                          <div
                            class="card card-file-manager"
                            style={{
                              cursor: "pointer",
                              border:
                                billTypeSelected === biller.id
                                  ? "1px solid #1FC157"
                                  : "",
                            }}
                          >
                            <div class="card-file-header text-danger">
                              <img src={biller.icon} className="w-25" />
                            </div>
                            <div class="card-body mb-0 pb-4 pt-4">
                              <h6 class="card-subtitle text-center text-muted">
                                {biller.name}
                              </h6>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="row">
                    <div className="text-center pb-5">
                      <p className="text-center">
                        Biller Types Not Available Yet
                      </p>
                      <hr />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {billTypeSelected && (
        <div class="row" ref={completeEndRef}>
          <div class="col">
            <div class="card">
              <div class="card-body ">
                <div>
                  {load && (
                    <p className="text-center">
                      <Spin />
                    </p>
                  )}

                  {renderSwitch(billerType)}
                </div>

                <div className="d-flex  justify-content-end">
                  <Button
                    type="submit"
                    class="btn btn-primary"
                    style={{ background: "#1FC157", color: "white" }}
                    onClick={procceedBill}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        title="Input Pin"
        visible={pinModal}
        okText="Skip"
        footer={null}
        maskClosable={false}
        onCancel={cancelPinSetup}
      >
        <div className="container text-center">
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}

          <div className="text-center" style={{ padding: "50px 0px" }}>
            <div className="d-flex justify-content-center align-items-center">
              <div>
                <OtpInput
                  value={pin}
                  onChange={(value, index) => setPin(value)}
                  isInputNum
                  isInputSecure
                  inputStyle={{
                    padding: "15px",
                    border: ".9px solid green",
                    width: "50px",
                    margin: "10px",
                  }}
                  numInputs={4}
                  separator={<span></span>}
                />
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    type="submit"
                    class="btn btn-primary mt-4 w-100"
                    style={{ background: "#1FC157", color: "white" }}
                    onClick={payBill}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title="Select Biller Country "
        visible={openCountryBillermoddal}
        okText="Skip"
        footer={null}
        maskClosable={false}
        onCancel={() => {
          setOpenCountryBillerModdal(false);
        }}
      >
        <div className="container text-center">
          <div className="text-center pb-2">{loadingBillers && <Spin />}</div>
          <div className="">
            <div className="mb-3">
              <div className="form-floating">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  value={billerCountry}
                  onChange={(e) => setBillerCountry(e.target.value)}
                >
                  <option selected>Select Country</option>
                  {countryData?.map((d) => {
                    return <option value={d?.code}>{d?.name}</option>;
                  })}
                </select>
                <label for="floatingInput">Select Biller Country</label>
              </div>
            </div>
          </div>
          <br />
          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={() => {
                if (billerCountry) {
                  getBillers();
                } else {
                  Notification("error", "Error", "Biller country is required!");
                }
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        title="Beneficiaries"
        visible={showBeneficiary}
        okText="Skip"
        footer={null}
        maskClosable={false}
        onCancel={() => {
          setShowBeneficiary(false);
        }}
      >
        <div
          className="container"
          style={{ height: "300px", overflowY: "scroll" }}
        >
          {exactBeneficiaries?.length > 0 ? (
            <div>
              {exactBeneficiaries?.map((d) => {
                return (
                  <div>
                    <div>
                      <div className="d-flex justify-content-between align-items-top">
                        <div>
                          <div className="font-weight-bold">
                            <b>{d?.billerName}</b>
                          </div>
                          <div>
                            {d?.identifier} ({d?.customerName})
                          </div>
                        </div>
                        <div>
                          <button
                            type="submit"
                            class="btn btn-primary"
                            style={{ background: "#1FC157", color: "white" }}
                            onClick={onBeneficiarySelect.bind(this, d)}
                          >
                            Choose
                          </button>
                        </div>
                      </div>
                      <div className="mt-0 pt-0">
                        {" "}
                        <hr />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="text-center py-5">No beneficiaries found.</div>
          )}
        </div>{" "}
      </Modal>
    </Layout>
  );
}
