import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";



export default function App({ currency }) {
  const [chartData, setChartdata] = useState([]);
  const getPaygroup = () => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/dashboard/employer/payroll-summary/${currency}/${sessionStorage.getItem(
          "employer_id",
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        },
      )
      .then((res) => {
        if (res.data.status) {
          setChartdata(res.data.result);
          setChartdata(
            res.data.result.map((d) => ({
              name: d.monthName,
              totalNetSalary: d.totalNetSalary,
              amt: d.totalNetSalary,
            })),
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };
  useEffect(() => {
    if (currency) {
      getPaygroup();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency]);

  return (
    <LineChart
      width={1300}
      height={400}
      data={chartData}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Line type="monotone" dataKey="" stroke="#ffffff"  />
      <Line type="monotone" dataKey="totalNetSalary" stroke="#1FC157" />
    </LineChart>
  );
}
