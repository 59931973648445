import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Layout from "../../../../containers/Layout";
import ReactDatatable from "@ashvin27/react-datatable";
import Tab from "../../workhub/tabs";
import {
  Drawer,
  Button,
  Spin,
  Alert,
  Pagination,
  Modal,
  notification,
} from "antd";
import axios from "axios";
import config from "../../../../config";
export default function Deductions(props) {
  const [deductionsData, setDeductionsData] = useState([]);
  const [load, setLoad] = useState(false);
  const [noDeductionsData, setNoDeductions] = useState(false);
  const [loadDeductions, setLoadDeductions] = useState(true);
  const employeeId = sessionStorage.getItem("employeeID");
  const [loadPdf, setLoadPdf] = useState(false);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");
  const [deductionInfo, setDeductionInfo] = useState({});

  const getPaged = (queryString) => {
    axios
      .get(
        `${config.baseUrl}/v1/deductions/employee?employeeId=${employeeId}${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const transactionsData = res.data.result;
          if (transactionsData.length > 0) {
            setDeductionsData(transactionsData);
            setTotalItems(res.data.totalPages * 10);
            setLoadDeductions(false);
            setNoDeductions(false);
          } else {
            setLoadDeductions(false);
            setNoDeductions(true);
          }
        } else {
          setLoadDeductions(false);
          setNoDeductions(true);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `page=${page - 1}&size=${pageSize}`;
    getPaged(queryString);
  };

  const getDeductions = () => {
    axios
      .get(
        `${config.baseUrl}/v1/deductions/employee?employeeId=${employeeId}&page=0&sizze=50`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const transactionsData = res.data.result;
          if (transactionsData.length > 0) {
            setDeductionInfo(res.data);
            setDeductionsData(transactionsData);
            setTotalItems(res.data.totalPages * 10);
            setLoadDeductions(false);
            setNoDeductions(false);
          } else {
            setLoadDeductions(false);
            setNoDeductions(true);
          }
        } else {
          setLoadDeductions(false);
          setNoDeductions(true);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    getDeductions();
  }, []);

  const downLoadPdf = (e) => {
    e.preventDefault();
    setLoadPdf(true);
    downloadEmployeeDeductions();
  };

  const downloadEmployeeDeductions = () => {
    fetch(
      `${config.baseUrl}/v1/deductions/deductionslip/download/${employeeId}`,
      {
        headers: new Headers({
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        }),
      }
    ).then((response) => {
      setLoadPdf(false);
      const filename = "deductions.pdf";
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      });
    });
  };
  return (
    <Layout>
      <Tab />
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="card table-widget">
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="card-title pt-2">Deductions </h5>
                {deductionsData.length > 0 && (
                  <div className="px-4">
                    <button
                      type="submit"
                      disabled={loadPdf}
                      className="btn btn-info m-b-xs"
                      style={{ background: "#1FC157", color: "white" }}
                      onClick={downLoadPdf}
                    >
                      {loadPdf ? "Downloading" : "Download Pdf"}
                    </button>
                  </div>
                )}
              </div>
              <div class="">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Type</th>
                        <th scope="col"> Montly</th>
                        <th scope="col">Yearly</th>

                        <th scope="col">Date</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {deductionsData.length > 0 && (
                        <>
                          {deductionsData.map((data) => {
                            return (
                              <tr>
                                <td>
                                  {data.deductionType
                                    ? data.deductionType.replace(/_+/g, " ")
                                    : ""}
                                </td>

                                <td>
                                  {data.currency}{" "}
                                  {data.monthlyDeduction
                                    ? Intl.NumberFormat("en-US").format(
                                        data.monthlyDeduction
                                      )
                                    : 0}
                                </td>
                                <td>
                                  {data.currency}{" "}
                                  {data.yearlyDeduction
                                    ? Intl.NumberFormat("en-US").format(
                                        data.yearlyDeduction
                                      )
                                    : 0}
                                </td>

                                <td>
                                  {data.createdAt
                                    ? data.createdAt.slice(0, 10)
                                    : ""}
                                </td>

                                <td>
                                  <span
                                    className={
                                      {
                                        SUCCESS: "badge bg-success",
                                        FAILED: "badge bg-danger",
                                      }[data.status ? "SUCCESS" : "FAILED"]
                                    }
                                  >
                                    {data.status ? "ACTIVE" : "INACTIVE"}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td></td>
                            <td>
                              {deductionsData?.length > 0 && (
                                <h5 style={{ fontWeight: "bold" }}>
                                  NGN{" "}
                                  {deductionInfo?.totalMonthly
                                    ? deductionInfo?.totalMonthly
                                        .toFixed(2)
                                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                    : 0}
                                </h5>
                              )}
                            </td>
                            <td>
                              {deductionsData?.length > 0 && (
                                <h5 style={{ fontWeight: "bold" }}>
                                  NGN{" "}
                                  {deductionInfo?.totalYearly
                                    ? deductionInfo?.totalYearly
                                        .toFixed(2)
                                        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                                    : 0}
                                </h5>
                              )}
                            </td>

                            <td></td>

                            <td></td>
                          </tr>

                          <div className="text-center pagination-part py-5">
                            <div className="d-flex justify-content-center align-items-center">
                              <Pagination
                                current={page}
                                total={totalItems}
                                defaultPageSize={10}
                                onChange={pagination}
                                itemRender={itemRender}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {loadDeductions && (
                <div className="text-center pt-5 pb-5">
                  <Spin />
                </div>
              )}
              {noDeductionsData && (
                <p className="text-center pt-5 pb-5">
                  No Deduction History Yet
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
