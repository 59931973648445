import React, { Fragment, useEffect, useState } from 'react'
import Layout from '../../../containers/Layout'
import ReactDatatable from '@ashvin27/react-datatable'
import {
  Upload,
  DatePicker,
  Dropdown,
  Drawer,
  Button,
  Space,
  Spin,
  Menu,
  Alert,
  message,
  Modal,
  notification
} from 'antd'
import { useHttp } from '../../../hooks/useHttps'
import axios from 'axios'
import PinInput from 'react-pin-input'
import config from '../../../config'
export default function Employers (props) {
  const [loadcategory, setLoadcategory] = useState(true)
  const [categoryData, setcategoryData] = useState(false)
  const [nocategory, setNocategory] = useState(false)
  const [categories, setcategories] = useState([])
  const [interestRate, setInterestRate] = useState('')
  const [vendorsCategories, setVendorsCategories] = useState([])
  const [loadvendorCategory, setLoadVendorCategory] = useState(false)
  const [novendorCategory, setNoVendorCategory] = useState(false)
  const [load, setLoad] = useState(false)
  const [pinModal, setPinModal] = useState(false)
  const [loanInterestt, setLoanInterest] = useState('')
  const [pin, setPin] = useState('')
  const [paymentOption, setPaymentOption] = useState('')

  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [success, setSuccess] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [fill, setFill] = useState(false)

  const [employer, setEmployer] = useState('')
  const [amount, setAmt] = useState('')
  const [description, setDescription] = useState('')

  const [serverError, setServerError] = useState(false)

  const [loading, employers] = useHttp(
    `${config.baseUrl}/v1/employees/filtered/employer`,
    []
  )

  const cancelPinSetup = () => {
    setPinModal(false)
    setPin('')
  }

  const getCats = () => {
    const id = props.location.state.id ? props.location.state.id : ''
    axios
      .get(
        `${config.baseUrl}/v1/vendors/${id}
      `,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
          }
        }
      )
      .then(res => {
        if (res.data.status) {
          if (res.data.result) {
            setLoadcategory(false)
            setcategoryData(true)
            setNocategory(false)
          } else {
            setLoadcategory(false)
            setcategoryData(false)
            setNocategory(true)
          }
          setcategories(res.data.result)
        } else {
        }
      })
      .catch(err => {
        if (err) {
          setServerError(true)
          setTimeout(() => {
            setServerError(false)
          }, 2000)
        }
      })
  }

  const buyVendor = () => {
    const id = props.location.state.id ? props.location.state.id : ''
    const data = {
      amount,
      description,
      employeeId: employer,
      pin,
      platform: 'WEB',
      vendorId: id
    }
    setLoad(true)
    axios
      .post(`${config.baseUrl}/v1/vendor-payment`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
      })
      .then(res => {
        setLoad(false)
        if (res.data.status) {
          setSuccessMsg(res.data.message)
          setSuccess(true)
          setAmt('')
          setPin('')
          setDescription('')
          setAmt('')
          setEmployer('')

          setTimeout(() => {
            setSuccess(false)
            setPinModal(false)
          }, 2500)
        } else {
          setErrorMsg(res.data.message)
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 2500)
        }
      })
      .catch(err => {
        setLoad(false)
        if (err) {
          setServerError(true)
          setTimeout(() => {
            setServerError(false)
          }, 2000)
        }
      })
  }

  const getLoanInterest = e => {
    axios
      .get(`${config.baseUrl}/v1/loans/interest/MARKETPLACE/${amount}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`
        }
      })
      .then(res => {
        if (res.data.status) {
          setInterestRate(res.data.result.interestRate)
        } else {
        }
      })
      .catch(err => {
        if (err) {
          setServerError(true)
          setTimeout(() => {
            setServerError(false)
          }, 2000)
        }
      })

}

  const postPin = () => {
    // e.preventDefult();
    if (description && employer && amount) {
      setPinModal(true)
    } else {
      setFill(true)
      setTimeout(() => {
        setFill(false)
      }, 2500)
    }
  }

  useEffect(() => {
    getCats()
  }, [])

  return (
    <Layout>
      <div class='row'>
        <div class='col-md-6 col-sm-12'>
          <div class='card'>
            <div class='card-body'>
              <h5 class='card-title'>Merchant Details</h5>
              <div class='profile-cover' style={{ height: '200px' }}></div>
              <div class='profile-header'>
                <div class='profile-img'>
                  <img src={categories.logoUrl} alt='' />
                </div>
                <div class='profile-name'>
                  <h3>{categories.businessName}</h3>
                </div>
              </div>

              <div className='container pt-5'>
                <div className='d-flex justify-content-between align-items-center'>
                  <h4 class='card-title'>Email</h4>
                  <h4 class='card-title'>{categories.email}</h4>
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                  <h4 class='card-title'>Phone Number</h4>
                  <h4 class='card-title'>{categories.phone}</h4>
                </div>

                <div className='d-flex justify-content-between align-items-center'>
                  <h4 class='card-title'>Address</h4>
                  <h4 class='card-title'>{categories.address}</h4>
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                  <h4 class='card-title'>City</h4>
                  <h4 class='card-title'>{categories.city}</h4>
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                  <h4 class='card-title'>Country</h4>
                  <h4 class='card-title'>{categories.country}</h4>
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                  <h4 class='card-title'>RC-Number</h4>
                  <h4 class='card-title'>{categories.rcNumber}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class='col-md-6 col-sm-12'>
          <div class='card'>
            <div class='card-body'>
              <h5 class='card-title'>Payment Details</h5>
              <div className='container'>
                <div className='mt-5'>
                  <form>
                    {fill && (
                      <p className='text-center text-danger font-weight-bold'>
                        Fileds are all required!
                      </p>
                    )}
                    <div className='mb-3'>
                      <div className='form-floating'>
                        <input
                          type='text'
                          className='form-control'
                          id='floatingInput'
                          placeholder='country name'
                          onChange={e => setAmt(e.target.value)}
                          onBlur={getLoanInterest}
                          value={amount}
                        />
                        <label for='floatingInput'>Amount</label>
                      </div>
                    </div>

                   
                    <div className='mb-3'>
                      <div className='form-floating'>
                        <textarea
                          type='text'
                          className='form-control'
                          id='floatingInput'
                          placeholder='country name'
                          onChange={e => setDescription(e.target.value)}
                          value={description}
                        />
                        <label for='floatingInput'>Description</label>
                      </div>
                    </div>
                    <div className='mb-3'>
                      <div className='form-floating'>
                        <select
                          class='form-select'
                          aria-label='Default select example'
                          onChange={e => setPaymentOption(e.target.value)}
                        >
                          <option selected>Select Payment Option</option>
                          <option value='PAYDAY_LOAN'>Payday Loan</option>
                          <option value='PAYDAY_SCHEDULE'>
                            Payday Schedule
                          </option>
                        </select>
                        <label for='floatingInput'>Payment Option</label>
                      </div>
                    </div>

                    {paymentOption === 'PAYDAY_LOAN' && (
                      <div className='mb-3'>
                        <div className='form-floating'>
                          <input
                            type='text'
                            className='form-control'
                            id='floatingInput'
                            placeholder='country name'
                            value={interestRate}
                            disabled
                          />
                          <label for='floatingInput'>Interest Rate</label>
                        </div>
                      </div>
                    )}
                     <div class='mb-3'>
                      <select
                        class='form-select'
                        aria-label='Default select example'
                        onChange={e => setEmployer(e.target.value)}
                      >
                        <option selected>Select Employer</option>
                        {employers.map(employer => {
                          return (
                            <option value={employer.id}>
                              {employer.employerResponse.businessName}
                            </option>
                          )
                        })}
                      </select>
                    </div>

                    <div className='d-flex justify-content-end'>
                      <Button
                        type='submit'
                        class='btn btn-primary'
                        style={{ background: '#1FC157', color: 'white' }}
                        onClick={postPin}
                      >
                        Continue
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title='Input Pin'
        visible={pinModal}
        okText='Skip'
        footer={null}
        maskClosable={false}
        onCancel={cancelPinSetup}
      >
        <div className='container text-center'>
          {load && (
            <p className='text-center'>
              <Spin />
            </p>
          )}

          {success && (
            <p
              className='text-center  font-weight-bold'
              style={{ color: '#1FC157' }}
            >
              {successMsg}
            </p>
          )}
          {error && (
            <p className='text-center text-danger font-weight-bold'>
              {errorMsg}
            </p>
          )}

          {serverError && (
            <p className='text-center text-danger font-weight-bold'>
              Failed to connect to SalarioPay
            </p>
          )}

          <div className='text-center'>
            <div>
              <label className='text-left pt-4'>Pin</label>
            </div>

            <PinInput
              length={4}
              initialValue={pin}
              secret
              onChange={(value, index) => setPin(value)}
              type='numeric'
              inputMode='number'
              style={{ padding: '10px' }}
              inputStyle={{ borderColor: 'green' }}
              inputFocusStyle={{ borderColor: 'blue' }}
              onComplete={(value, index) => {}}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </div>
          <br />
          <div className='d-flex justify-content-end align-items-center'>
            <button
              type='submit'
              class='btn btn-primary'
              style={{ background: '#1FC157', color: 'white' }}
              onClick={buyVendor}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </Layout>
  )
}
