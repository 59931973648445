import React, { useState, useEffect } from "react";
import Tabs from "../../../employeeTabs";
import Layout from "../../../../containers/Layout";
import axios from "axios";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHttp } from "../../../../hooks/useHttps";
import config from "../../../../config";
import { Modal, Drawer, notification, Spin } from "antd";
import { Pagination, Button } from "antd";
export default function EmployeeInfo(props) {
  const [load, setLoad] = useState(false);
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [rowId, setRowID] = useState("");
  const [paystructureStatus, setPaystrucutreStatus] = useState("");
  const [allowanceDatas, setAllowanceDatas] = useState([]);
  const [noAllowanceDatas, setNoAllowanceDatas] = useState(false);
  const [loadAllowanceDatas, setloadAllowanceDatas] = useState(true);
  const [allowancesInfo, setAllowancesInfo] = useState([]);

  //paginations
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const showDrawer = (record, e) => {
    e.preventDefault();
    setRowID(record.id);
    setPaystrucutreStatus(record.status);
    setVisibleDraw(true);
  };
  const closeDrawer = () => {
    setVisibleDraw(false);
  };

  const getAllowances = () => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/allowances/employee?employeeId=${sessionStorage.getItem(
          "employeeID"
        )}&page=0&size=10`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setNoAllowanceDatas(false);
            setloadAllowanceDatas(false);
            setAllowanceDatas(res.data.result);
            setAllowancesInfo(res.data);
          } else {
            setNoAllowanceDatas(true);
            setloadAllowanceDatas(false);
            setAllowanceDatas([]);
          }
        } else {
          setNoAllowanceDatas(true);
          setloadAllowanceDatas(false);
          setAllowanceDatas([]);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };
  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const deleteConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure you want to delete this allowance information ? `,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteAllowance();
      },
      onCancel() {},
    });
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  const statusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure you want to change status of this allowance information ? `,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        statusAllowance();
      },
      onCancel() {},
    });
  };

  const getPaged = (queryString) => {
    setloadAllowanceDatas(true);
    axios
      .get(`${config.baseUrl}/v1/allowances/employee?${queryString}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const data = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          setAllowanceDatas(res.data.result);
          setAllowancesInfo(res.data);
        } else {
          setNoAllowanceDatas(true);
          setloadAllowanceDatas(false);
          setAllowanceDatas([]);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };
  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `employeeId=${sessionStorage.getItem(
      "employeeID"
    )}&page=${page - 1}&size=${pageSize}`;
    getPaged(queryString);
  };

  const deleteAllowance = () => {
    setLoad(true);
    axios
      .delete(`${config.baseUrl}/v1/allowances/${rowId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getAllowances();
          setVisibleDraw(false);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
          Notification("error", "Error", "Failed to connect to SalarioPay");
        }
      });
  };

  const statusAllowance = () => {
    setLoad(true);
    axios
      .put(
        `${config.baseUrl}/v1/allowances/status/${rowId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getAllowances();
          setVisibleDraw(false);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
          Notification("error", "Error", "Failed to connect to SalarioPay");
        }
      });
  };

  useEffect(() => {
    getAllowances();
  }, []);

  var totalMonthlyAllowance = allowanceDatas.reduce(
    (n, { monthlyAllowance }) => n + parseInt(monthlyAllowance),
    0
  );

  var totalYearlyAllowance = allowanceDatas.reduce(
    (n, { yearlyAllowance }) => n + parseInt(yearlyAllowance),
    0
  );

  return (
    <Layout>
      <Tabs />
      <div>
        <div className="col-sm-12 col-md-12">
          <div class="card">
            <div class="card-header font-weight-bold">Allowances</div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Allowance Type</th>
                      <th scope="col">Monthly Allowance</th>
                      <th scope="col">Yearly Allowance</th>
                      <th scope="col">Date</th>
                      <th scope="col">Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allowanceDatas.map((data) => {
                      return (
                        <tr>
                          <td>
                            {data.allowanceType
                              ? data.allowanceType.replace(/_+/g, " ")
                              : ""}
                          </td>
                          <td>
                            {data.currency}{" "}
                            {data.monthlyAllowance
                              ? Intl.NumberFormat("en-US").format(
                                  data.monthlyAllowance
                                )
                              : 0}
                          </td>
                          <td>
                            {data.currency}{" "}
                            {data.yearlyAllowance
                              ? Intl.NumberFormat("en-US").format(
                                  data.yearlyAllowance
                                )
                              : 0}
                          </td>
                          <td>
                            {data.createdAt ? data.createdAt.slice(0, 10) : ""}
                          </td>
                          <td>
                            <span
                              className={
                                {
                                  ACTIVE: "badge bg-success",
                                  INACTIVE: "badge bg-danger",
                                }[data.status ? "ACTIVE" : "INACTIVE"]
                              }
                            >
                              {data.status ? "ACTIVE" : "INACTIVE"}
                            </span>
                          </td>
                          <td>
                            <button
                              type="submit"
                              onClick={showDrawer.bind(this, data)}
                              class="btn btn-primary"
                              style={{
                                background: "#1FC157",
                                color: "white",
                              }}
                            >
                              Action
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td></td>
                      <td>
                        {allowanceDatas?.length > 0 && (
                          <h5 style={{ fontWeight: "bold" }}>
                            NGN{" "}
                            {allowancesInfo?.totalMonthly
                              ? allowancesInfo?.totalMonthly
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                              : 0}
                          </h5>
                        )}
                      </td>
                      <td>
                        {allowanceDatas?.length > 0 && (
                          <h5 style={{ fontWeight: "bold" }}>
                            NGN{" "}
                            {allowancesInfo?.totalYearly
                              ? allowancesInfo?.totalYearly
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                              : 0}
                          </h5>
                        )}
                      </td>

                      <td></td>

                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {loadAllowanceDatas && (
                <div className="text-center pt-5 pb-5">
                  <Spin />
                </div>
              )}
              {noAllowanceDatas && (
                <p className="text-center pt-5 pb-5">No Allowances Yet.</p>
              )}
              {allowanceDatas?.length > 0 && (
                <div className="text-center pagination-part pt-2">
                  <Pagination
                    current={page}
                    total={totalItems}
                    defaultPageSize={10}
                    onChange={pagination}
                    itemRender={itemRender}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}
      >
        <div
          style={{ color: "red", cursor: "pointer" }}
          onClick={deleteConfirm}
        >
          Delete
        </div>

        <hr />

        <div
          style={{ color: "#000000", cursor: "pointer" }}
          onClick={statusConfirm}
        >
          {paystructureStatus ? "DISABLE" : "ENABLE"}
        </div>

        <hr />
      </Drawer>
    </Layout>
  );
}
