import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Layout from "../../../containers/Layout";
import ReactDatatable from "@ashvin27/react-datatable";
import Tab from "../../tab";
import {
  Upload,
  DatePicker,
  Dropdown,
  Drawer,
  Button,
  Space,
  Spin,
  Menu,
  Alert,
  message,
  Pagination,
  Modal,
  notification,
} from "antd";
import {useHistory} from "react-router-dom"
import axios from "axios";
import config from "../../../config";
export default function Positions(props) {
  const autoCreate = props.history.location.state ? true : false;
  const [positionData, setPositionData] = useState([]);
  const [positionCreate, setAddPosition] = useState(autoCreate);
  const [positionName, setPositionName] = useState("");
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [statusSuccess, setSuccessStatus] = useState(false);
  const [updateSwitch, setUpdateSwitch] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");
  const [rowId, setRowID] = useState("");
  const [positionStatus, setpositionStatus] = useState("");
  const history =  useHistory()

  //paginations
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [fieldError, setFieldError] = useState(false);

  const [load, setLoad] = useState(false);

  const showDrawer = (record, e) => {
    e.preventDefault();
    setRowID(record.id);
    setPositionName(record.name);
    setpositionStatus(record.status);
    setVisibleDraw(true);
  };

  const closeDrawer = () => {
    setVisibleDraw(false);
  };

  const closeAdd = () => {
    setAddPosition(false);
    setPositionName("");
  };
  const deleteRole = (id) => {
    axios
      .delete(`${config.baseUrl}/v1/positions/${rowId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          getPositions();
          setVisibleDraw(false);
          setTimeout(() => {
            setDeleteSuccess(false);
          }, 2000);
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const showDeleteConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Do you want to delete this position?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteRole();
      },
      onCancel() {
      },
    });
  };

  const changeStatusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Do you want to change the status of this position?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        changeStatusFunc();
      },
      onCancel() {
      },
    });
  };

  const updatePositionSwitch = () => {
    setUpdateSwitch(true);
    setAddPosition(true);
    setVisibleDraw(false);
  };

  const changeStatusFunc = () => {
    axios
      .put(
        `${config.baseUrl}/v1/positions/status/${rowId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          Notification("success", "Success", res.data.message);
          setVisibleDraw(false);
          setStatusMsg(res.data.message);
          getPositions();
          
        } else {
          Notification("error", "Error", res.data.message);
        }
      })
      .catch((err) => {
        if (err) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          getPositions();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
          setStatusMsg("Failed to connect to SalarioPay");
        }
      });
  };

  const createPosition = () => {
    setAddPosition(true);
    setUpdateSwitch(false);
    setPositionName("")
  };

  const columns = [
    {
      key: "name",
      text: "Position",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "date",
      text: "Date",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },

    {
      key: "status",
      text: "Status",
      TrOnlyClassName: "tab-head",
      className: "type_of_food",
      sortable: true,
      align: "left",
      cell: (record) => {
        return (
          <Fragment>
            <span
              className={
                {
                  Enabled: "badge bg-success",
                  Disabled: "badge bg-danger",
                }[record.status]
              }
            >
              {record.status}
            </span>
          </Fragment>
        );
      },
    },

    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <Button
              type="primary"
              danger
              style={{
                background: "#1FC157",
                color: "white",
                cursor: "pointer",
              }}
              onClick={showDrawer.bind(this, record)}
            >
              Actions
            </Button>
          </Fragment>
        );
      },
    },
  ];

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
      },
      onDoubleClick: (event) => {
      },
    },
  ];
  const getPositions = () => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/positions/paged?employerId=${sessionStorage.getItem(
          "employer_id"
        )}&page=0&size=10`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const data = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          setPositionData(
            data.map((data) => ({
              name: data.name,
              date: data.createdAt ? data.createdAt.slice(0, 10) : "",
              id: data.id,
              status: data.status ? "Enabled" : "Disabled",
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const addPosition = (e) => {
    e.preventDefault();
    const data = {
      employerId: sessionStorage.getItem("employer_id"),
      name: positionName,
    };
    if (data.name) {
      setLoad(true);
      axios
        .post(`${config.baseUrl}/v1/positions`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setPositionName("");
            Notification("success", "Success", res.data.message);
            setTimeout(() => {
              getPositions();
              setAddPosition(false);
              if(autoCreate){
                history.push('/employee_onboarding')
              }
            }, 2000);
          } else {
            Notification("error", "Error", res.data.message);
            setErrorMsg(res.data.message);
            setTimeout(() => {
              setError(false);
            }, 2500);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setFieldError(true);
      setTimeout(() => {
        setFieldError(false);
      }, 1500);
    }
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const editPosition = (e) => {
    e.preventDefault();
    const data = {
      employerId: sessionStorage.getItem("employer_id"),
      name: positionName,
    };
    setLoad(true);
    axios
      .put(`${config.baseUrl}/v1/positions/${rowId}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setPositionName("");
          Notification("success", "Success", res.data.message);
          setSuccessMsg(res.data.message);
          setTimeout(() => {
            setSuccess(false);
            getPositions();
            setAddPosition(false);
          }, 2000);
        } else {
          Notification("error", "Error", res.data.message);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };

  useEffect(() => {
    getPositions();
  }, []);

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    show_info: true,
    show_pagination: false,
    show_length_menu: false,
    button: {
      excel: true,
      print: true,
    },
  };

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `employerId=${sessionStorage.getItem(
      "employer_id"
    )}&page=${page - 1}&size=${pageSize}`;
    getPaged(queryString);
  };

  const getPaged = (queryString) => {
    axios
      .get(`${config.baseUrl}/v1/positions/paged?${queryString}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const data = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          setPositionData(
            data.map((data) => ({
              name: data.name,
              date: data.createdAt ? data.createdAt.slice(0, 10) : "",
              id: data.id,
              status: data.status ? "Enabled" : "Disabled",
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };
  return (
    <Layout>
      <Tab />
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <h5 class="card-title">Manage Positions</h5>
                <button
                  className="btn btn-info m-b-xs mr-2"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={createPosition}
                >
                  Add Position
                </button>{" "}
              </div>

              <div className="">
                <div className="text-center"></div>
                <div style={{ overflowX: "auto" }} className="pb-4">
                  <ReactDatatable
                    config={configTable}
                    records={positionData}
                    columns={columns}
                    extraButtons={extraButtons}
                  />
                </div>
                <div className="text-center pagination-part pt-5">
                  <Pagination
                    current={page}
                    total={totalItems}
                    defaultPageSize={10}
                    onChange={pagination}
                    itemRender={itemRender}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}
      >
        <div style={{ cursor: "pointer" }} onClick={updatePositionSwitch}>
          Edit Position
        </div>
        <hr />
        <div
          style={{ color: "red", cursor: "pointer" }}
          onClick={showDeleteConfirm}
        >
          Delete Position
        </div>
        <hr />
        <div style={{ cursor: "pointer" }} onClick={changeStatusConfirm}>
          {positionStatus === "Enabled" ? "Disable" : "Enable"}
        </div>

        <hr />
      </Drawer>
      <Modal
        title={!updateSwitch ? "Add Position" : "Update Position"}
        visible={positionCreate}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeAdd}
      >
        <form>
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}
          {success && (
            <div className="pb-3">
              <Alert message={successMsg} type="success" showIcon />
            </div>
          )}
          {error && (
            <div className="pb-3">
              <Alert message={errorMsg} type="error" showIcon />
            </div>
          )}
          {fieldError && (
            <div className="pb-3">
              <Alert message="All fields are required!" type="error" showIcon />
            </div>
          )}
          {serverError && (
            <p className="text-center text-danger font-weight-bold">
              Failed to connect to SalarioPay
            </p>
          )}
          {!updateSwitch ? (
            <div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="country name"
                    onChange={(e) => setPositionName(e.target.value)}
                    value={positionName}
                  />
                  <label for="floatingInput">Postion Name</label>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="country name"
                    onChange={(e) => setPositionName(e.target.value)}
                    value={positionName}
                  />
                  <label for="floatingInput">Position Name</label>
                </div>
              </div>
            </div>
          )}
          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={!updateSwitch ? addPosition : editPosition}
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>
    </Layout>
  );
}
