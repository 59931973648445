import React, { useState, Fragment, useEffect } from "react";
import Layout from "../../../containers/Layout";
import ReactDatatable from "@ashvin27/react-datatable";
import { Drawer, Button, Spin, Alert, Modal } from "antd";
import axios from "axios";
import config from "../../../config";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { formatNumbers } from "../../../helperFunctions/functions";

const FeeStructure = () => {
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [statusSuccess, setSuccessStatus] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");
  const [drawerText, setDrawerText] = useState("");
  const [feeStructureData, setFeeStructureData] = useState([]);
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [currencyStatus, setCurrencyStatus] = useState("");
  const [rowId, setRowID] = useState("");
  const [loading, setLoading] = useState(true);
  const [feeStructureCreate, setFeeStructureCreate] = useState(false);
  const [updateSwitch, setUpdateSwitch] = useState(false);
  const [load, setLoad] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [currencyData, setCurrencyData] = useState([]);
  const [deletedMsg, setDeletedMsg] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    currency: "",
    employeeFee: "",
    serviceFee: "",
    status: true,
  });

  const showDrawer = (record, e) => {
    e.preventDefault();
    setVisibleDraw(true);
    setRowID(record.id);
    setCurrencyStatus(record.status);
    setFormData((prevState) => ({
      ...prevState,
      name: record.name,
      currency: record.currency,
      employeeFee: record.employeeFee.split(" ")[1],
      serviceFee: record.serviceFee.split(" ")[1],
    }));
  };

  const closeDrawer = () => {
    setVisibleDraw(false);
    setFormData((prevState) => ({
      ...prevState,
      name: "",
      currency: "",
      employeeFee: "",
      serviceFee: "",
    }));
  };

  const closeAdd = () => {
    setFeeStructureCreate(false);
    setFormData((prevState) => ({
      ...prevState,
      name: "",
      currency: "",
      employeeFee: "",
      serviceFee: "",
    }));
  };

  const updateFeeStructure = () => {
    setUpdateSwitch(true);
    setFeeStructureCreate(true);
    setVisibleDraw(false);
  };

  const handleChange = (field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    button: {
      excel: true,
      print: true,
      extra: true,
    },
  };

  const columns = [
    {
      key: "name",
      text: "Name",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "employeeFee",
      text: "Employee Fee",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "serviceFee",
      text: "Service Fee",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <Button
              type="primary"
              danger
              style={{
                background: "#1FC157",
                color: "white",
                cursor: "pointer",
              }}
              onClick={showDrawer.bind(this, record)}>
              Actions
            </Button>
          </Fragment>
        );
      },
    },
  ];

  const getCurrencies = () => {
    axios
      .get(`${config.baseUrl}/v1/currencies/paged`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const currencyData = res.data.result;
          setCurrencyData(
            currencyData.map((data) => ({
              currencyCode: data.currencyCode,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getFeeStructure = () => {
    axios
      .get(`${config.baseUrl}/v1/fee-structure/paged`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoading(false);
        if (res.data.status) {
          setFeeStructureData(
            res.data.result.map((item) => ({
              id: item.id,
              name: item.name,
              currency: item.currency,
              employeeFee: formatNumbers(
                `${
                  item.employeeFee === 0
                    ? `${item.currency} 0`
                    : `${item.currency} ${item.employeeFee}`
                }`
              ),

              serviceFee: formatNumbers(
                `${
                  item.serviceFee === 0
                    ? `${item.currency} 0`
                    : `${item.currency} ${item.serviceFee}`
                }`
              ),
              status: item.status ? "Enabled" : "Disabled",
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];

  const deleteFeeStructure = () => {
    axios
      .delete(`${config.baseUrl}/v1/fee-structure/${rowId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setDeleteSuccess(true);
          getFeeStructure();
          setDeletedMsg(res.data.message);
          closeDrawer();
          setTimeout(() => {
            setDeleteSuccess(false);
          }, 2000);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const showDeleteConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure delete this fee structure?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteFeeStructure();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const changeStatusFunc = () => {
    axios
      .patch(`${config.baseUrl}/v1/fee-structure/status/${rowId}`, null, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setSuccessStatus(true);
          closeDrawer();
          setStatusMsg(res.data.message);
          getFeeStructure();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setSuccessStatus(true);
          closeDrawer();
          getCurrencies();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
          setStatusMsg("Failed to connect to SalarioPay");
        }
      });
  };

  const changeStatusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure change status of this fee structure?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        changeStatusFunc();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const createFeeStructure = () => {
    setFeeStructureCreate(true);
    setUpdateSwitch(false);
  };

  const addFeeStructure = (e) => {
    e.preventDefault();
    const data = {
      currency: formData.currency,
      employeeFee: parseInt(formData.employeeFee),
      name: formData.name,
      serviceFee: parseInt(formData.serviceFee),
      status: formData.status,
    };

    const isFormValid = () => {
      return Object.values(formData).every(
        (field) => field.toString().length > 0
      );
    };

    if (!isFormValid()) {
      setFieldError(true);
      setTimeout(() => setFieldError(false), 1500);
      return;
    }

    setLoad(true);
    axios
      .post(`${config.baseUrl}/v1/fee-structure `, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setFormData((prevState) => ({
            ...prevState,
            name: "",
            currency: "",
            employeeFee: "",
            serviceFee: "",
          }));
          setSuccess(true);
          setSuccessMsg(res.data.message);
          getFeeStructure();
          setTimeout(() => {
            setSuccess(false);
            setFeeStructureCreate(false);
            getCurrencies();
          }, 2000);
        } else {
          setError(true);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };

  const editFeeStructure = (e) => {
    e.preventDefault();
    const data = {
      currency: formData.currency,
      employeeFee: parseInt(formData.employeeFee),
      name: formData.name,
      serviceFee: parseInt(formData.serviceFee),
      status: formData.status,
    };

    const isFormValid = () => {
      return Object.values(formData).every(
        (field) => field.toString().length > 0
      );
    };

    if (!isFormValid()) {
      setFieldError(true);
      setTimeout(() => setFieldError(false), 1500);
      return;
    }

    setLoad(true);
    axios
      .put(`${config.baseUrl}/v1/fee-structure/${rowId}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setFormData((prevState) => ({
            ...prevState,
            name: "",
            currency: "",
            employeeFee: "",
            serviceFee: "",
          }));
          setSuccess(true);
          setSuccessMsg(res.data.message);
          getFeeStructure();
          setTimeout(() => {
            setSuccess(false);
            getCurrencies();
            setFeeStructureCreate(false);
          }, 2000);
        } else {
          setError(true);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };

  useEffect(() => {
    getFeeStructure();
    getCurrencies();
  }, []);

  useEffect(() => {
    if (currencyStatus === "Enabled") {
      setDrawerText("Disable");
    } else if (currencyStatus === "Disabled") {
      setDrawerText("Enable");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleDraw]);

  const currencyOptions = currencyData.map((currency) => {
    return (
      <option value={currency.currencyCode}>{currency.currencyCode}</option>
    );
  });

  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title mb-5">Fee Structure</h5>
              <div className="d-flex justify-content-end align-items-center">
                <button
                  className="btn btn-info m-b-xs mr-2"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={createFeeStructure}>
                  Create Fee Structure
                </button>{" "}
              </div>
              <div className="pt-0">
                {loading ? (
                  <div class="d-flex justify-content-center align-items-center">
                    <Spin />
                  </div>
                ) : (
                  <div>
                    <div className="text-center">
                      {deleteSuccess && (
                        <div className="pb-4">
                          <Alert message={deletedMsg} type="success" showIcon />
                        </div>
                      )}
                      {statusSuccess && (
                        <div className="pb-4">
                          <Alert
                            message={statusMsg}
                            type={
                              drawerText.toLowerCase().includes("enable")
                                ? "success"
                                : "error"
                            }
                            showIcon
                          />
                        </div>
                      )}
                    </div>

                    <ReactDatatable
                      config={configTable}
                      records={feeStructureData}
                      columns={columns}
                      extraButtons={extraButtons}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}>
        <div
          style={{ color: "red", cursor: "pointer" }}
          onClick={showDeleteConfirm}>
          Delete Fee Structure
        </div>
        <hr />
        <div style={{ cursor: "pointer" }} onClick={updateFeeStructure}>
          Edit Fee Structure
        </div>
        <hr />
        <div style={{ cursor: "pointer" }} onClick={changeStatusConfirm}>
          {drawerText}
        </div>

        <hr />
      </Drawer>
      <Modal
        title={!updateSwitch ? "Add Fee Structure" : "Update Fee Structure"}
        visible={feeStructureCreate}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeAdd}>
        <form>
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}
          {success && (
            <div className="pb-3">
              <Alert message={successMsg} type="success" showIcon />
            </div>
          )}
          {error && (
            <div className="pb-3">
              <Alert message={errorMsg} type="error" showIcon />
            </div>
          )}
          {fieldError && (
            <div className="pb-3">
              <Alert message="All fields are required!" type="error" showIcon />
            </div>
          )}
          {serverError && (
            <p className="text-center text-danger font-weight-bold">
              Failed to connect to SalarioPay
            </p>
          )}
          <div>
            <div className="mb-3">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Name"
                  disabled={!updateSwitch ? false : true}
                  onChange={(e) => handleChange("name", e.target.value)}
                  value={formData.name}
                />
                <label for="floatingInput">Name</label>
              </div>
            </div>
            <div class="mb-3">
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => handleChange("currency", e.target.value)}
                disabled={!updateSwitch ? false : true}
                value={formData.currency}>
                <option selected>Currency</option>
                {currencyOptions}
              </select>
            </div>
            <div className="mb-3">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Employee Fee"
                  onChange={(e) => handleChange("employeeFee", e.target.value)}
                  value={formData.employeeFee}
                />
                <label for="floatingInput">Employee Fee</label>
              </div>
            </div>
            <div className="mb-3">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Service Fee"
                  onChange={(e) => handleChange("serviceFee", e.target.value)}
                  value={formData.serviceFee}
                />
                <label for="floatingInput">Service Fee</label>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={!updateSwitch ? addFeeStructure : editFeeStructure}>
              Submit
            </button>
          </div>
        </form>
      </Modal>
    </Layout>
  );
};

export default FeeStructure;
