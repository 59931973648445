import Cookie from "js-cookie";
var sideMenus;
if (sessionStorage.getItem("activeRole")) {
  switch (sessionStorage.getItem("activeRole")) {
    case "EMPLOYER":
      sideMenus = [
        {
          id: 1,
          name: "Dashboard",
          path: "/dashboard",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204418/StaticIcons/dashboard.png",
        },
        {
          id: 100,
          name: "Accounts",
          path: "/accounts",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/billpayment.png",
        },

        {
          id: 2,
          name: "Employees",
          path: "/employee_onboarding",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1630535226/StaticIcons/employees.png",
        },
        {
          id: 3,
          name: "Benefits",
          path: "/employer-benefits",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1630535226/StaticIcons/employee_benefit.png",
        },

        {
          id: 4,
          name: "Payroll",
          path: "/payroll",
          tag: "coming",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1630535226/StaticIcons/payroll.png",
        },
        
        {
          id: 10,
          name: "Pension",
          path: "/pension",
          tag: "soon",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1630534127/StaticIcons/send_money.png",
        },
        {
          id: 6,
          name: "Tax",
          path: "/tax",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204418/StaticIcons/bonds.png",
        },
        {
          id: 5,
          name: "Transactions",
          path: "/transactions",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1630535226/StaticIcons/transactions.png",
        },

        {
          id: 7,
          name: "Audit Trails",
          path: "/audit-trails",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1630535226/StaticIcons/audit_trail.png",
        },
        {
          id: 101,
          name: "Teams",
          path: "/teams",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/billpayment.png",
        },

  

      ];
      break;
    case "EMPLOYEE":
      sideMenus = [
        {
          id: 1,
          name: "Dashboard",
          path: "/dashboard",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204418/StaticIcons/dashboard.png",
        },
        {
          id: 122,
          name: "Accounts",
          path: "/accounts",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/billpayment.png",
        },
        // {
        //   id: 4,
        //   name: "Employers",
        //   path: "/employee_employer",
        //   icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204742/StaticIcons/employer.png",
        // },
        {
          id: 3,
          name: "My Work Hub",
          path: "/my-work-hub",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/payslip.png",
        },
        {
          id: 12,
          name: "Benefits",
          path: "/employee-benefits",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1630535226/StaticIcons/employee_benefit.png",
        },
        {
          id: 2,
          name: "Payment Methods",
          path: "/payment_method",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/payment_method.png",
        },
        // {
        //   id: 10,
        //   name: "Deductions",
        //   path: "/employee_deduction_history",
        //   icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1630535226/StaticIcons/audit_trail.png",
        // },
        {
          id: 16,
          name: "Payments",
          path: "/payments",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1630535226/StaticIcons/audit_trail.png",
        },

        {
          id: 11,
          name: "Transactions",
          path: "/employee_transaction_history",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1630535226/StaticIcons/transactions.png",
        },
        {
          id: 27,
          name: "Audit Trails",
          path: "/audit-trails",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1630535226/StaticIcons/audit_trail.png",
        },

        // {
        //   id: 5,
        //   name: "Buy Airtime",
        //   path: "/buy_airtime",
        //   icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204405/StaticIcons/airtime.png",
        // },
        // {
        //   id: 6,
        //   name: "Buy Data Bundle",
        //   path: "/buy_databundle",
        //   icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/databundle.png",
        // },
        // {
        //   id: 7,
        //   name: "Bill Payment",
        //   path: "/bill_payment",
        //   icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204418/StaticIcons/billpayment.png",
        // },
        // {
        //     id: 8,
        //     name: 'Market Place',
        //     path: '/market_place',
        //     icon: 'https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/marketplace.png',
        // },
        // {
        //   id: 9,
        //   name: "Credit Card",
        //   path: "/credit_card",
        //   icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204418/StaticIcons/creditcard.png",
        // },

        // {
        //     id: 12,
        //     name: 'FlexiPay',
        //     path: '/flexipay',
        //     tag: 'soon',
        //     icon: 'https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/salaryadvance.png',
        // },
        // {
        //     id: 13,
        //     name: 'Pension',
        //     path: '/pension',
        //     tag: 'soon',
        //     icon: 'https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/pension.png',
        // },
        // {
        //   id: 14,
        //   name: "Leave Request",
        //   path: "/leave_request",
        //   tag: "soon",
        //   icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1629205182/StaticIcons/insurance.png",
        // },
      ];
      break;
    case "SUPER_ADMIN":
      sideMenus = [
        { id: 1, name: "Dashboard", path: "/dashboard" },
        { id: 2, name: "Accounts", path: "/all_account" },
        { id: 3, name: "Employers", path: "/employers" },
        { id: 5, name: "Super Admins", path: "/super_admins" },
        { id: 6, name: "Admins", path: "/admins" },
        { id: 7, name: "Roles", path: "/roles" },
        { id: 7, name: "KYC Approvals", path: "/kyc" },
        { id: 8, name: "Countries", path: "/countries" },
        { id: 9, name: "Currencies", path: "/currencies" },
        { id: 9, name: "Processors", path: "/processor" },
        { id: 9, name: "Privileges", path: "/privileges" },
        { id: 10, name: "Payment Options", path: "/payment_options" },
        { id: 11, name: "Loan Products", path: "/loan_product" },
        { id: 13, name: "Loans", path: "/loans" },
        { id: 13, name: "Fee Structure", path: "/fee_structure" },
        { id: 14, name: "NewsLetters", path: "/news_letter" },
        { id: 15, name: "Audit Trails", path: "/audit-trails" },
        { id: 15, name: "Transactions", path: "/transactions" },
        { id: 15, name: "Options", path: "/options" },
      ];
      break;
    case "VENDOR":
      sideMenus = [
        {
          id: 1,
          name: "Dashboard",
          path: "/dashboard",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204418/StaticIcons/dashboard.png",
        },
        {
          id: 2,
          name: "Payment Method",
          path: "/payment_method",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/payment_method.png",
        },
        {
          id: 3,
          name: "Payment History",
          path: "/vendor_payments",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1630535226/StaticIcons/payroll.png",
        },
        {
          id: 3,
          name: "Customers",
          path: "/vendor_payments",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204418/StaticIcons/cooperative.png",
        },
        {
          id: 4,
          name: "Profile",
          path: "/dashboard",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204418/StaticIcons/user.png",
        },
      ];
      break;
    case "ACCOUNTANT":
      sideMenus = [
        {
          id: 1,
          name: "Dashboard",
          path: "/dashboard",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204418/StaticIcons/dashboard.png",
        },
        {
          id: 122,
          name: "Accounts",
          path: "/accounts",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204419/StaticIcons/billpayment.png",
        },
        {
          id: 2,
          name: "Employees",
          path: "/employee_onboarding",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1630535226/StaticIcons/employees.png",
        },
        {
          id: 3,
          name: "Benefits",
          path: "/employer-benefits",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1630535226/StaticIcons/employee_benefit.png",
        },
        {
          id: 4,
          name: "Payroll",
          path: "/payroll",
          tag: "coming",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1630535226/StaticIcons/payroll.png",
        },
        {
          id: 5,
          name: "Transactions",
          path: "/transactions",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1630535226/StaticIcons/transactions.png",
        },
      ];
      break;
    case "HUMAN_RESOURCE":
      sideMenus = [
        {
          id: 1,
          name: "Dashboard",
          path: "/dashboard",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204418/StaticIcons/dashboard.png",
        },

        {
          id: 2,
          name: "Employees",
          path: "/employee_onboarding",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1630535226/StaticIcons/employees.png",
        },
       
        {
          id: 3,
          name: "Payroll",
          path: "/payroll",
          tag: "coming",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1630535226/StaticIcons/payroll.png",
        },
        {
          id: 4,
          name: "Benefits",
          path: "/employer-benefits",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1630535226/StaticIcons/employee_benefit.png",
        },
        
        {
          id: 10,
          name: "Pension",
          path: "/pension",
          tag: "soon",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1630534127/StaticIcons/send_money.png",
        },
        {
          id: 6,
          name: "Taxes",
          path: "/tax",
          icon: "https://res.cloudinary.com/didqqm6jl/image/upload/v1629204418/StaticIcons/bonds.png",
        },
      ];
      break;
    default:
      sideMenus = [];
  }
} else {
  // window.location.href = "/login";
}

export default sideMenus;
