import React, { useEffect, useState } from "react";
import config from "../../config";
import axios from "axios";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

const ChartSuperAdmin = ({ currency }) => {
  const [chartData, setChartdata] = useState([]);

  const getPayrollMetric = () => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/dashboard/employer/payroll-summary/${currency}?employerId=${sessionStorage.getItem(
          "employeeID",
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        },
      )
      .then((res) => {
        if (res.data.status) {
          setChartdata(res.data.result);
          setChartdata(
            res.data.result.map((d) => ({
              name: d.monthName,
              uv: d.totalNetSalary,
              amt: d.totalNetSalary,
            })),
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    getPayrollMetric();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency]);
  return (
    <div>
      {" "}
      <LineChart
        width={1300}
        height={400}
        data={chartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Line
          type="monotone"
          dataKey="v"
          stroke="#1FC157"
          activeDot={{ r: 8 }}
        />
        <Line type="monotone" dataKey="uv" stroke="#1FC157" />
      </LineChart>
    </div>
  );
};

export default ChartSuperAdmin;
