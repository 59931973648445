import React, { useEffect, useState } from "react";
import Layout from "../../../containers/Layout";
import { Drawer, Button, Spin, Alert, Modal } from "antd";
import axios from "axios";
import config from "../../../config";
import ReactDatatable from "@ashvin27/react-datatable";
import { Fragment } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const ProcessorManagement = () => {
  const [processorManagementData, setProcessorManagementData] = useState([]);
  const [deleteSuccess, setDelete] = useState(false);
  const [statusSuccess, setSuccessStatus] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [formData, setFormData] = useState({
    channel: "",
    processor: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [load, setLoad] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [fieldError, setFieldError] = useState(false);
  const [updateSwitch, setUpdateSwitch] = useState(false);
  const [channelData, setChannelData] = useState([]);
  const [processorData, setProcessorData] = useState([]);

  const showDrawer = (record, e) => {
    e.preventDefault();
    setFormData((prevState) => ({
      ...prevState,
      channel: record.channel,
      processor: record.processor,
    }));
    setVisibleDraw(true);
  };
  // setUpdateSwitch(false);

  const closeDrawer = () => setVisibleDraw(false);

  const closeAdd = () => {
    setShowModal(false);
    setUpdateSwitch(false);
    setFormData((prevState) => ({
      ...prevState,
      channel: "",
      processor: "",
    }));
  };

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    button: {
      excel: true,
      print: true,
      extra: true,
    },
  };

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];

  const columns = [
    {
      key: "channel",
      text: "Channel",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "processor",
      text: "Processor",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <Button
              type="primary"
              danger
              style={{
                background: "#1FC157",
                color: "white",
                cursor: "pointer",
              }}
              onClick={showDrawer.bind(this, record)}>
              Actions
            </Button>
          </Fragment>
        );
      },
    },
  ];

  const handleChange = (field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const updateProcessormgt = () => {
    setUpdateSwitch(true);
    setShowModal(true);
    setVisibleDraw(false);
  };

  const createProcessorModal = () => {
    setShowModal(true);
    setUpdateSwitch(false);
  };

  const getProcessorManagementData = () => {
    axios
      .get(`${config.baseUrl}/v1/processors`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const processorMgtData = res.data.result;

          setProcessorManagementData(
            processorMgtData.map((data) => ({
              channel: data.channel,
              processor: data.processor,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getChannelData = () => {
    axios
      .get(`${config.baseUrl}/v1/processors/channels`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          setChannelData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getProcessorData = () => {
    axios
      .get(`${config.baseUrl}/v1/processors/names`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          setProcessorData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const editProcessorMgt = (e) => {
    e.preventDefault();
    const data = {
      channel: formData.channel,
      processor: formData.processor,
    };
    setLoad(true);
    axios
      .post(`${config.baseUrl}/v1/processors`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setSuccess(true);
          setSuccessMsg(res.data.message);
          setTimeout(() => {
            setSuccess(false);
            getProcessorManagementData();
            setUpdateSwitch(false);
            setShowModal(false);
          }, 2000);
        } else {
          setError(true);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };

  const createProcessor = (e) => {
    e.preventDefault();
    const data = {
      channel: formData.channel,
      processor: formData.processor,
    };
    setLoad(true);
    axios
      .post(`${config.baseUrl}/v1/processors`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setSuccess(true);
          setSuccessMsg(res.data.message);
          setTimeout(() => {
            setSuccess(false);
            getProcessorManagementData();
            setUpdateSwitch(false);
            setShowModal(false);
          }, 2000);
        } else {
          setError(true);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };

  const deleteProcessormgt = () => {
    axios
      .delete(`${config.baseUrl}/v1/processors/${formData.channel}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setDelete(true);
          getProcessorManagementData();
          setVisibleDraw(false);
          setTimeout(() => {
            setDelete(false);
          }, 2000);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const showDeleteConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure you want to delete this Processor?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteProcessormgt();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  useEffect(() => {
    getProcessorManagementData();
    getChannelData();
    getProcessorData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title mb-0">Processors</h5>
              <div className="d-flex justify-content-end align-items-center">
                <button
                  className="btn btn-info m-b-xs mr-2"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={createProcessorModal}>
                  Add Processor
                </button>{" "}
              </div>
              <div className="pt-0">
                <div className="text-center">
                  {deleteSuccess && (
                    <div className="pb-4">
                      <Alert
                        message="Processor Deleted"
                        type="error"
                        showIcon
                      />
                    </div>
                  )}
                  {statusSuccess && (
                    <div className="pb-4">
                      <Alert message={statusMsg} type="error" showIcon />
                    </div>
                  )}
                </div>
                <div className="pt-0" style={{ overflowX: "auto" }}>
                  <ReactDatatable
                    config={configTable}
                    records={processorManagementData}
                    columns={columns}
                    extraButtons={extraButtons}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}>
        <div
          style={{ color: "red", cursor: "pointer" }}
          onClick={showDeleteConfirm}>
          Delete Processor
        </div>
        <hr />
        <div style={{ cursor: "pointer" }} onClick={updateProcessormgt}>
          Edit Processor
        </div>
      </Drawer>
      <Modal
        title={!updateSwitch ? "Create Processor" : "Update Processor"}
        visible={showModal}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeAdd}>
        <form>
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}
          {success && (
            <div className="pb-3">
              <Alert message={successMsg} type="success" showIcon />
            </div>
          )}
          {error && (
            <div className="pb-3">
              <Alert message={errorMsg} type="error" showIcon />
            </div>
          )}
          {fieldError && (
            <div className="pb-3">
              <Alert message="All fields are required!" type="error" showIcon />
            </div>
          )}
          {serverError && (
            <p className="text-center text-danger font-weight-bold">
              Failed to connect to SalarioPay
            </p>
          )}
          <div>
            <div className="mb-3">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Channel
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  disabled={!updateSwitch ? false : true}
                  onChange={(e) => handleChange("channel", e.target.value)}
                  value={formData.channel}>
                  <option selected>Select</option>
                  {channelData.map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label for="exampleInputEmail1" class="form-label">
                  Processor
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => handleChange("processor", e.target.value)}
                  value={formData.processor}>
                  <option selected>Select</option>
                  {processorData.map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={!updateSwitch ? createProcessor : editProcessorMgt}>
              Submit
            </button>
          </div>
        </form>
      </Modal>
    </Layout>
  );
};

export default ProcessorManagement;
