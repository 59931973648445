import React from "react";
import { useLocation } from "react-router-dom";
import "./tab.css";
import { Link } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
function App(props) {
  const location = useLocation();
  const locationA = window.location.pathname.replace("/", "");
  const locationB = location.pathname.replace("/", "");

  return (
    <div className="card">
      <div className="card-body pt-3 pb-3">
        <div className="link-text">
          <Nav className="d-flex justify-content-between align-items-center">
            <NavItem>
              <Link
                to="/my-work-hub"
                className={
                  locationB && locationA === "my-work-hub"
                    ? "tab-list-active"
                    : "text-dark"
                }
              >
                Payslip
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to="/employee-details"
                className={
                  locationB && locationA === "employee-details"
                    ? "tab-list-active"
                    : "text-dark"
                }
              >
                Employee Details
              </Link>
            </NavItem>

            <NavItem>
              <Link
                to="/employee-allowances"
                className={
                  locationB && locationA === "employee-allowances"
                    ? "tab-list-active "
                    : "text-dark"
                }
              >
                Allowances
              </Link>
            </NavItem>

            <NavItem>
              <Link
                to="/employee-deductions"
                className={
                  locationB && locationA === "employee-deductions"
                    ? "tab-list-active"
                    : "text-dark"
                }
              >
                Deductions
              </Link>
            </NavItem>
            {/* <NavItem>
              <Link
                to="/offboarding"
                className={
                  locationB && locationA === "offboarding"
                    ? "tab-list-active"
                    : "text-dark"
                }
              >
                Employee Offboarding
              </Link>
            </NavItem> */}
{/* 
            <NavItem>
              <Link
                to="/employer_employee-pensions"
                className={
                  locationB && locationA === "employer_employee-pensions"
                    ? "tab-list-active"
                    : "text-dark"
                }
              >
                Pensions
              </Link>
            </NavItem>

            <NavItem>
              <Link
                to="/leave_request"
                className={
                  locationB && locationA === "leave_request"
                    ? "tab-list-active"
                    : "text-dark"
                }
              >
                Leave Request
              </Link>
            </NavItem>

            <NavItem>
              <Link
                to="/employee-level"
                className={
                  locationB && locationA === "employee-level"
                    ? "tab-list-active"
                    : "text-dark"
                }
              >
                Taxes
              </Link>
            </NavItem> */}
          </Nav>
        </div>
      </div>
    </div>
  );
}

export default App;
