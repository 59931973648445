import React from 'react'
function PaymentLoanInterest({interestRate}) {
  return (
    <ul>
      <li>
        <b>Interest Rate: </b> {interestRate?.interestRate}%
      </li>
      <li>
        <b>Interest Amount: </b>
        {interestRate?.currency}{" "}
        {interestRate?.interestAmount
          ? (interestRate?.interestAmount)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")
          : 0}
      </li>
      <li>
        <b>Repayment Amount: </b>
        {interestRate?.currency}{" "}
        {interestRate?.repaymentAmount
          ? (interestRate?.repaymentAmount)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")
          : 0}
      </li>
    </ul>
  );
}
export default PaymentLoanInterest