import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Layout from "../../../containers/Layout";
import ReactDatatable from "@ashvin27/react-datatable";
import { Drawer, Button, Spin, Alert, Modal } from "antd";
import axios from "axios";
import config from "../../../config";
export default function Employers() {
  const [roleData, setRoleData] = useState([]);
  const [roleCreate, setRoleCreate] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [statusSuccess, setSuccessStatus] = useState(false);
  const [updateSwitch, setUpdateSwitch] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");
  const [rowId, setRowID] = useState("");
  const [roleStatus, setRoleStatus] = useState("");

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [drawerText, setDrawerText] = useState("");
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(false);

  const showDrawer = (record, e) => {
    e.preventDefault();
    setRowID(record.id);
    setRoleName(record.name);
    setRoleStatus(record.status);
    setVisibleDraw(true);
  };

  const closeDrawer = () => {
    setVisibleDraw(false);
  };

  const closeAdd = () => {
    setRoleCreate(false);
    setRoleName("");
  };

  const changeStatusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure change status of this Role?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        changeStatusFunc();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const updateCurrencySwitch = () => {
    setUpdateSwitch(true);
    setRoleCreate(true);
    setVisibleDraw(false);
  };

  const changeStatusFunc = () => {
    axios
      .put(`${config.baseUrl}/v1/roles/status/${rowId}`, null, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          setStatusMsg(res.data.message);
          getRoles();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          getRoles();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
          setStatusMsg("Failed to connect to SalarioPay");
        }
      });
  };

  const createRole = () => {
    setRoleCreate(true);
    setUpdateSwitch(false);
  };

  const columns = [
    {
      key: "name",
      text: "Role Name",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },

    {
      key: "status",
      text: "Status",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "date",
      text: "Date",
      className: "address",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <Button
              type="primary"
              danger
              style={{
                background: "#1FC157",
                color: "white",
                cursor: "pointer",
              }}
              onClick={showDrawer.bind(this, record)}>
              Actions
            </Button>
          </Fragment>
        );
      },
    },
  ];

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];

  const options = { year: "numeric", month: "numeric", day: "numeric" };

  const getRoles = () => {
    axios
      .get(`${config.baseUrl}/v1/roles/paged`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoading(false);

        if (res.data.status) {
          const roleData = res.data.result;
          setRoleData(
            roleData.map((data) => ({
              name: data.name,
              id: data.id,
              status: data.status ? "Enabled" : "Disabled",
              date: new Date(data.createdAt)
                .toLocaleDateString(undefined, options)
                .replace(/\//g, "-"),
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        setLoading(false);

        if (err) {
        }
      });
  };

  const addRole = (e) => {
    e.preventDefault();
    const data = {
      name: roleName,
    };
    if (data.name) {
      setLoad(true);
      axios
        .post(`${config.baseUrl}/v1/roles`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setRoleName("");
            setSuccess(true);
            setSuccessMsg(res.data.message);
            setTimeout(() => {
              setSuccess(false);
              getRoles();
              setRoleCreate(false);
            }, 2000);
          } else {
            setError(true);
            setErrorMsg(res.data.message);
            setTimeout(() => {
              setError(false);
            }, 2500);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setFieldError(true);
      setTimeout(() => {
        setFieldError(false);
      }, 1500);
    }
  };

  const editRole = (e) => {
    e.preventDefault();
    const data = {
      name: roleName,
    };
    if (data.name) {
      setLoad(true);
      axios
        .put(`${config.baseUrl}/v1/roles/${rowId}`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setRoleName("");
            setSuccess(true);
            setSuccessMsg(res.data.message);
            setTimeout(() => {
              setSuccess(false);
              getRoles();
              setRoleCreate(false);
            }, 2000);
          } else {
            setError(true);
            setErrorMsg(res.data.message);
            setTimeout(() => {
              setError(false);
            }, 2500);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setFieldError(true);
      setTimeout(() => {
        setFieldError(false);
      }, 1500);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    button: {
      excel: true,
      print: true,
      extra: true,
    },
  };

  useEffect(() => {
    if (roleStatus === "Enabled") {
      setDrawerText("Disable");
    } else if (roleStatus === "Disabled") {
      setDrawerText("Enable");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeStatusConfirm]);

  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title mb-0">Roles</h5>
              <div className="d-flex justify-content-end align-items-center">
                <button
                  className="btn btn-info m-b-xs mr-2"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={createRole}>
                  Add Role
                </button>{" "}
              </div>

              <div className="pt-0">
                {loading ? (
                  <div class="d-flex justify-content-center align-items-center">
                    <Spin />
                  </div>
                ) : (
                  <div>
                    <div className="text-center">
                      {deleteSuccess && (
                        <div className="pb-4">
                          <Alert message="Role Deleted" type="error" showIcon />
                        </div>
                      )}
                      {statusSuccess && (
                        <div className="pb-4">
                          <Alert
                            message={statusMsg}
                            type={
                              drawerText.toLowerCase().includes("enable")
                                ? "success"
                                : "error"
                            }
                            showIcon
                          />
                        </div>
                      )}
                    </div>
                    <ReactDatatable
                      config={configTable}
                      records={roleData}
                      columns={columns}
                      extraButtons={extraButtons}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}>
        <Link
          to={`/role_privileges/${rowId}`}
          style={{ color: "#5b5b5b", cursor: "pointer" }}
          onClick={updateCurrencySwitch}>
          View Privilege
        </Link>
        <hr />
        <div style={{ cursor: "pointer" }} onClick={updateCurrencySwitch}>
          Edit Role
        </div>
        <hr />
        <div style={{ cursor: "pointer" }} onClick={changeStatusConfirm}>
          {drawerText}
        </div>

        <hr />
      </Drawer>
      <Modal
        title={!updateSwitch ? "Add Role" : "Update Role"}
        visible={roleCreate}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeAdd}>
        <form>
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}
          {success && (
            <div className="pb-3">
              <Alert message={successMsg} type="success" showIcon />
            </div>
          )}
          {error && (
            <div className="pb-3">
              <Alert message={errorMsg} type="error" showIcon />
            </div>
          )}
          {fieldError && (
            <div className="pb-3">
              <Alert message="All fields are required!" type="error" showIcon />
            </div>
          )}
          {serverError && (
            <p className="text-center text-danger font-weight-bold">
              Failed to connect to SalarioPay
            </p>
          )}
          {!updateSwitch ? (
            <div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="country name"
                    onChange={(e) => setRoleName(e.target.value)}
                    value={roleName}
                  />
                  <label for="floatingInput">Role Name</label>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="country name"
                    onChange={(e) => setRoleName(e.target.value)}
                    value={roleName}
                  />
                  <label for="floatingInput">Role Name</label>
                </div>
              </div>
            </div>
          )}
          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={!updateSwitch ? addRole : editRole}>
              Submit
            </button>
          </div>
        </form>
      </Modal>
    </Layout>
  );
}
