import React, { useState } from "react";
import { useLocation, Link, useHistory } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import axios from "axios";
import config from "../../../config";
import { Modal, notification, Spin, Alert } from "antd";

const KycTab = ({ id, status }) => {
  const location = useLocation();
  const locationA = window.location.pathname.replace("/", "");
  const locationB = location.pathname.replace("/", "");
  const [loadPinApprove, setLoadPinApprove] = useState(false);
  const [loadPinDecline, setLoadPinDecline] = useState(false);
  const [feeStructureCreate, setFeeStructureCreate] = useState(false);
  const [reasonForRejection, setReasonForRejection] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [fieldError, setFieldError] = useState(false);

  const navigate = useHistory();

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: "Login",
      description: msg,
    });
  };

  const closeAdd = () => {
    setFeeStructureCreate(false);
  };

  const toggleReasonForRejection = () => {
    setFeeStructureCreate(true);
  };

  const declineKYC = (e) => {
    e.preventDefault();

    const data = {
      reason: reasonForRejection,
      status: "DECLINED",
    };

    if (data.reason) {
      setLoadPinDecline(true);
      axios
        .put(`${config.baseUrl}/v1/kyc-details/approvals/${id}`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoadPinDecline(false);
          if (res.data.status) {
            setSuccess(true);
            setSuccessMsg(res.data.message);
            setTimeout(() => {
              navigate.push("/kyc");
            }, 2500);
          } else {
            setError(true);
            setErrorMsg(res.data.message);
            setTimeout(() => {
              setError(false);
            }, 3000);
          }
        })
        .catch((err) => {
          setLoadPinDecline(false);
          if (err) {
          }
        });
    } else {
      setFieldError(true);
      setTimeout(() => {
        setFieldError(false);
      }, 2000);
    }
  };

  const approveKYC = () => {
    const data = {
      reason: "string",
      status: "APPROVED",
    };

    setLoadPinApprove(true);
    axios
      .put(`${config.baseUrl}/v1/kyc-details/approvals/${id}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoadPinApprove(false);
        openNotificationWithIcon("success", res.data.message);
        setTimeout(() => {
          navigate.push("/kyc");
        }, 2000);
      })
      .catch((err) => {
        setLoadPinApprove(false);

        if (err) {
        }
      });
  };

  return (
    <div className="card">
      <div className="card-body pt-3 pb-3">
        <div className="link-text d-flex items-center justify-content-between">
          <Nav className="d-flex align-items-center gap-5">
            <NavItem>
              <Link
                to={`/personal_information/${id}`}
                className={
                  locationB && locationA === `personal_information/${id}`
                    ? "tab-list-active"
                    : "text-dark"
                }>
                Personal Information
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to={`/business_information/${id}`}
                className={
                  locationB && locationA === `business_information/${id}`
                    ? "tab-list-active"
                    : "text-dark"
                }>
                Business Information
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to={`/documents/${id}`}
                className={
                  locationB && locationA === `documents/${id}`
                    ? "tab-list-active"
                    : "text-dark"
                }>
                Documents
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to={`/identities/${id}`}
                className={
                  locationB && locationA === `identities/${id}`
                    ? "tab-list-active"
                    : "text-dark"
                }>
                Identities
              </Link>
            </NavItem>
          </Nav>
          {status.toLowerCase() === "pending" && (
            <div className="d-flex justify-content-end align-items-center gap-3">
              {loadPinApprove ? (
                <button
                  className="btn btn-info m-b-xs mr-2"
                  style={{
                    background: "#1FC157",
                    color: "white",
                    width: "10rem",
                  }}
                  disabled>
                  <div
                    className="spinner-border text-white h-2"
                    role="status"></div>
                </button>
              ) : (
                <button
                  className="btn btn-info m-b-xs mr-2"
                  style={{
                    background: "#1FC157",
                    color: "white",
                    width: "10rem",
                    height: "3.3rem",
                  }}
                  onClick={approveKYC}>
                  Approve KYC
                </button>
              )}

              <button
                className="btn btn-info m-b-xs mr-2"
                style={{
                  background: "#1FC157",
                  color: "white",
                  width: "10rem",
                  height: "3.3rem",
                }}
                onClick={toggleReasonForRejection}>
                Decline KYC
              </button>
            </div>
          )}
        </div>
      </div>
      <Modal
        title="Reject KYC"
        visible={feeStructureCreate}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeAdd}>
        <form>
          {loadPinDecline && (
            <p className="text-center">
              <Spin />
            </p>
          )}
          {success && (
            <div className="pb-3">
              <Alert message={successMsg} type="success" showIcon />
            </div>
          )}
          {error && (
            <div className="pb-3">
              <Alert message={errorMsg} type="error" showIcon />
            </div>
          )}
          {fieldError && (
            <div className="pb-3">
              <Alert message="All fields are required!" type="error" showIcon />
            </div>
          )}
          {/* {serverError && (
            <p className="text-center text-danger font-weight-bold">
              Failed to connect to SalarioPay
            </p>
          )} */}
          <div className="mb-3">
            <div className="form-floating">
              <textarea
                type="text"
                className="form-control"
                style={{ height: "100px" }}
                id="floatingInput"
                placeholder="country name"
                onChange={(e) => setReasonForRejection(e.target.value)}
                value={reasonForRejection}
              />
              <label for="floatingInput">
                Please specify an appropriate reason for rejection
              </label>
            </div>
          </div>

          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={declineKYC}>
              Submit
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default KycTab;
