import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Layout from "../../../containers/Layout";
import ReactDatatable from "@ashvin27/react-datatable";
import {
  Upload,
  DatePicker,
  Dropdown,
  Drawer,
  Button,
  Space,
  Spin,
  Menu,
  Alert,
  message,
  Modal,
  notification,
} from "antd";
import mtn from "../../../img/mtnAirtime.png";
import glo from "../../../img/gloAirtime.jpg";
import airtel from "../../../img/airtelAirtime.png";
import etisalat from "../../../img/9mobileAirtime.png";
import axios from "axios";
import config from "../../../config";
export default function Employers() {
  const [loadcategory, setLoadcategory] = useState(true);
  const [categoryData, setcategoryData] = useState(false);
  const [nocategory, setNocategory] = useState(false);
  const [categories, setcategories] = useState([]);
  const [vendorsCategories, setVendorsCategories] = useState([]);
  const [loadvendorCategory, setLoadVendorCategory] = useState(false);
  const [novendorCategory, setNoVendorCategory] = useState(false);

  const [serverError, setServerError] = useState([]);

  const getCats = () => {
    axios
      .get(
        `${config.baseUrl}/v1/vendors/all-categories
      `,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setLoadcategory(false);
            setcategoryData(true);
            setNocategory(false);
          } else {
            setLoadcategory(false);
            setcategoryData(false);
            setNocategory(true);
          }
          setcategories(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 2000);
        }
      });
  };

  const getVendors = (id) => {
    const data = {
      categoryCode: id,
    };
    axios
      .get(`${config.baseUrl}/v1/vendors/category`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
          } else {
          }
        } else {
          setLoadcategory(false);
          setcategoryData(false);
          setNocategory(true);
        }
      })
      .catch((err) => {
        if (err) {
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 2000);
        }
      });
  };

  useEffect(() => {
    getCats();
  }, []);

  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>Categories</div>
                <Link to="/vendor_payment_history">
                  <div
                    style={{
                      border: "1px solid #1fc157",
                      background: "#1FC157",
                      color: "white",
                      padding: "7px",
                      borderRadius: "14px",
                    }}
                  >
                    Merchant Payments
                  </div>
                </Link>
              </div>
            </div>
            <div class="card-body mb-0 pb-0">
              {categoryData && (
                <div class="row">
                  {categories.map((category) => {
                    return (
                      <div
                        class="col-sm-6 col-md-3"
                        style={{ cursor: "pointer" }}
                        onClick={getVendors.bind(this, category.code)}
                      >
                        <Link
                          to={{
                            pathname: `/vendor-list`,
                            state: {
                              code: category.code,
                            },
                          }}
                        >
                          <div class="card card-file-manager">
                            <div class="card-file-header text-danger">
                              <img src={category.icon} className="w-25" />
                            </div>
                            <div class="card-body mb-0 pb-4 pt-4">
                              <h6 class="card-subtitle text-center text-muted">
                                {category.name}
                              </h6>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              )}

              {nocategory && (
                <div className="row">
                  <div className="text-center pb-5">
                    <p className="text-center">
                      category Billers Not Available Yet
                    </p>
                    <hr />
                  </div>
                </div>
              )}
              {loadcategory && (
                <div className="row">
                  <div className="text-center pb-5">
                    <Spin />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal okText="Submit" footer={false} maskClosable={false}></Modal>
    </Layout>
  );
}
