import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import PinInput from "react-pin-input";
import Layout from "../../../containers/Layout";
import { useHistory } from "react-router-dom";
import ReactDatatable from "@ashvin27/react-datatable";
import { useHttp } from "../../../hooks/useHttps";
import cardImg from "./card-new.jpeg";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import { useForm, Controller } from "react-hook-form";
import Location from "../../../containers/Location/index";
import {
  Upload,
  DatePicker,
  Dropdown,
  Drawer,
  Button,
  Space,
  Spin,
  Menu,
  Select,
  Alert,
  message,
  Modal,
} from "antd";
import axios from "axios";
import config from "../../../config";
import { notification } from "antd";
const { Option } = Select;

export default function Employers() {
  const [loading, employers] = useHttp(
    `${config.baseUrl}/v1/employees/filtered/employer`,
    []
  );
  const [loadCards, setLoadCards] = useState(false);
  const [pinModal, setOpenPin] = useState(false);
  const [cardPan, setCardPan] = useState("");
  const [type, setType] = useState("Activate");
  const [load, setLoad] = useState(false);
  const [locationInfo, setLocationInfo] = useState({});
  const [pin, setPin] = useState("");
  const [loadLocation, setLoadLocation] = useState(false);
  const [updateData, setUpdateData] = useState("");
  const [cardLists, setCardLists] = useState([]);
  const [noCards, setNoCards] = useState(false);
  const [wallet, setWallet] = useState("");
  const [postalCode, setPostal] = useState("");
  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState("");
  const [cardType, setCardType] = useState("");
  const [cardCategory, setCardCategory] = useState("");
  const [landMark, setLandMark] = useState("");
  const [address, setAddress] = useState("");
  const [walletData, setWalletData] = useState([]);
  const [openReqCard, setOpenReqCard] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [currencyData, setCurrencyData] = useState([]);
  const [openActivateCard, setOpenActivateCard] = useState(false);
  const [selectedCard, setSelectedCard] = useState("");
  const history = useHistory();

  const viewCardDetails = (id) => {
    history.push(`/card-details/${id}`);
  };

  const getCurrencies = (e) => {
    setCountry(e.target.value);
    axios
      .get(`${config.baseUrl}/v1/currencies/filtered/${e.target.value}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setCurrencyData(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const getCounties = () => {
    axios
      .get(`${config.baseUrl}/v1/countries/filtered`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setCountryData(res.data.result);
          // getBillPayment(res.data.result[0].code);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const geCards = () => {
    setLoadCards(true);
    axios
      .get(`${config.baseUrl}/v1/card`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const cards = res.data.result;
          setCardLists(cards);
          setNoCards(false);
          setLoadCards(false);
        } else {
          setCardLists([]);
          setNoCards(true);
          setLoadCards(false);
        }
      })
      .catch((err) => {
        if (err) {
          setCardLists([]);
          setNoCards(true);
          setLoadCards(false);
        }
      });
  };

  const getWalletInformations = () => {
    axios
      .get(`${config.baseUrl}/v1/wallets/employee`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const walletInfo = res.data.result;
          setWalletData(walletInfo);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    getCounties();
    getWalletInformations();
    geCards()
  }, []);

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const items = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            setOpenReqCard(true);
            setType("Request");
          }}
        >
          Request New Card
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            setOpenActivateCard(true);
            setType("Activate");
          }}
        >
          Activate Card
        </div>
      ),
    },
  ];

  const cancelPinSetup = () => {
    setOpenPin(false);
    setPin("");
  };

  const initiateActivateCard = () => {
    if (cardPan && wallet) {
      const data = {
        pan: cardPan,
        pin: "",
        walletId: wallet,
      };
      setOpenPin(true);
      setOpenActivateCard(false);
      setOpenReqCard(false);
    } else {
      Notification("error", "Error", "Field are all required");
    }
  };

  const completeActivateCard = () => {
    if (pin) {
      const data = {
        pan: cardPan,
        pin: pin,
        walletId: wallet,
      };
      setLoad(true);
      axios
        .put(`${config.baseUrl}/v1/card/activate`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            Notification("success", "Success", res.data.message);
            setTimeout(() => {
              window.location.reload(false);
            }, 2000);
          } else {
            Notification("error", "Error", res.data.message);
          }
        })
        .catch((err) => {
          setLoad(false);
          if (err) {
            Notification("error", "Error", "Something went wrong");
          }
        });
    } else {
      Notification("error", "Error", "Pin is required");
    }
  };

  const completeReqCard = () => {
    if (pin) {
      const data = {
        cardCategory: cardCategory,
        cardType: cardType,
        country: country,
        currency: currency,
        deliveryCity: locationInfo?.city,
        deliveryLandMark: landMark,
        deliveryLga: locationInfo?.lga,
        deliveryPostalCode: postalCode,
        deliveryState: locationInfo?.state,
        deliveryStreet: locationInfo?.clearAddress,
        pin: pin,
        walletId: wallet,
      };
      setLoad(true);
      axios
        .post(`${config.baseUrl}/v1/card/request`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            Notification("success", "Success", res.data.message);
            setTimeout(() => {
              window.location.reload(false);
            }, 2000);
          } else {
            Notification("error", "Error", res.data.message);
          }
        })
        .catch((err) => {
          setLoad(false);
          if (err) {
            Notification("error", "Error", "Something went wrong");
          }
        });
    } else {
      Notification("error", "Error", "Pin is required");
    }
  };

  const closeActivateCard = () => {
    setOpenActivateCard(false);
  };

  const initiateRequestCard = () => {
    if (
      wallet &&
      cardType &&
      cardCategory &&
      landMark &&
      locationInfo?.clearAddress &&
      currency &&
      country
    ) {
      setOpenPin(true);
      setOpenActivateCard(false);
      setOpenReqCard(false);
    } else {
      Notification("error", "Error", "All fields are required!");
    }
  };

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const closeReqCard = () => {
    setOpenReqCard(false);
  };

  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card" style={{height:'100%'}}>
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div>My Cards</div>
                <div>
                  <Dropdown
                    className=""
                    menu={{
                      items,
                    }}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <button
                        className="btn btn-info m-b-xs "
                        style={{
                          background: "#1FC157",
                          color: "white",
                          width: "100%",
                        }}
                      >
                        Get Started <DownOutlined />
                      </button>{" "}
                    </a>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div class="card-body mb-0 pb-5">
              <br />
              {cardLists?.length > 0 && (
                <div class="row">
                  {cardLists.map((card) => {
                    return (
                      <div
                        class="col-sm-6 col-md-4 mb-4"
                        onClick={viewCardDetails.bind(this, card)}
                        style={{ cursor: "pointer" }}
                      >
                        <div className="w-100">
                          <img src={cardImg} className="w-100" />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {noCards && cardLists?.length === 0 && (
                <div className="text-center pb-5">
                  <p className="text-center py-5">No Cards found</p>
                </div>
              )}

              {loadCards && (
                <div className="row">
                  <div className="text-center pb-5">
                    <Spin />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Activate Card"
        visible={openActivateCard}
        okText="Skip"
        footer={null}
        maskClosable={false}
        onCancel={closeActivateCard}
      >
        {load && (
          <p className="text-center">
            <Spin />
          </p>
        )}

        <div className="mb-3">
          <div className="form-floating">
            <select
              class="form-select"
              aria-label="Default select example"
              onChange={(e) => setWallet(e.target.value)}
            >
              <option selected>Select Wallet</option>
              {walletData?.map((d) => {
                return (
                  <option value={d?.id}>
                    {d?.currency} {d?.balance}
                  </option>
                );
              })}
            </select>
            <label for="floatingInput">Wallet</label>
          </div>
        </div>

        <div className="mb-3">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="floatingInput"
              onChange={(e) => setCardPan(e.target.value)}
              value={cardPan}
            />
            <label for="floatingInput">Card PAN</label>
          </div>
        </div>
        <br />
        <div className="d-flex justify-content-end align-items-center">
          <button
            type="submit"
            class="btn btn-primary"
            style={{ background: "#1FC157", color: "white" }}
            onClick={initiateActivateCard}
          >
            Submit
          </button>
        </div>
      </Modal>

      <Modal
        title="Request Card"
        visible={openReqCard}
        okText="Skip"
        footer={null}
        maskClosable={false}
        onCancel={closeReqCard}
      >
        {load && (
          <p className="text-center">
            <Spin />
          </p>
        )}

        <div className="mb-3">
          <div className="form-floating">
            <select
              class="form-select"
              aria-label="Default select example"
              onChange={getCurrencies}
            >
              <option selected>Select Country</option>
              {countryData?.map((d) => {
                return <option value={d?.code}>{d?.name}</option>;
              })}
            </select>
            <label for="floatingInput">Country</label>
          </div>
        </div>
        <div className="mb-3">
          <div className="form-floating">
            <select
              class="form-select"
              aria-label="Default select example"
              onChange={(e) => setCurrency(e.target.value)}
            >
              <option selected>Select Currency</option>
              {currencyData?.map((d) => {
                return (
                  <option value={d?.currencyCode}>{d?.currencyName}</option>
                );
              })}
            </select>
            <label for="floatingInput">Currency</label>
          </div>
        </div>
        <div className="mb-3">
          <div className="form-floating">
            <select
              class="form-select"
              aria-label="Default select example"
              onChange={(e) => setWallet(e.target.value)}
            >
              <option selected>Select Wallet</option>
              {walletData?.map((d) => {
                return (
                  <option value={d?.id}>
                    {d?.currency} {d?.balance}
                  </option>
                );
              })}
            </select>
            <label for="floatingInput">Wallet</label>
          </div>
        </div>

        <div className="mb-3">
          <div className="form-floating">
            <select
              class="form-select"
              aria-label="Default select example"
              onChange={(e) => setCardCategory(e.target.value)}
            >
              <option selected>Select Card Category</option>

              <option value="DEBIT_CARD">DEBIT CARD</option>
              <option value="CREDIT_CARD">CREDIT CARD</option>
            </select>
            <label for="floatingInput">Card Category</label>
          </div>
        </div>
        <div className="mb-3">
          <div className="form-floating">
            <select
              class="form-select"
              aria-label="Default select example"
              onChange={(e) => setCardType(e.target.value)}
            >
              <option selected>Select Card Type</option>

              <option value="PHYSICAL">Physical Card</option>
              <option value="VIRTUAL">Virtual Card</option>
            </select>
            <label for="floatingInput">Card Type</label>
          </div>
        </div>

        <div className="mb-3">
          <label for="">Address</label>
          <Location
            setLoadLocation={setLoadLocation}
            val={updateData.location}
            setLocationInfo={setLocationInfo}
            setValue={setValue}
            register={register}
            control={control}
            errors={errors}
            watch={watch}
            reset={reset}
          />
        </div>
        <div className="mb-3">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="floatingInput"
              onChange={(e) => setPostal(e.target.value)}
              value={postalCode}
            />
            <label for="floatingInput">Postal Code</label>
          </div>
        </div>

        <div className="mb-3">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="floatingInput"
              onChange={(e) => setLandMark(e.target.value)}
              value={landMark}
            />
            <label for="floatingInput">Delivery Landmark</label>
          </div>
        </div>
        <br />
        <div className="d-flex justify-content-end align-items-center">
          <button
            type="submit"
            class="btn btn-primary"
            style={{ background: "#1FC157", color: "white" }}
            onClick={initiateRequestCard}
          >
            Submit
          </button>
        </div>
      </Modal>

      <Modal
        title="Input Pin"
        visible={pinModal}
        okText="Skip"
        footer={null}
        maskClosable={false}
        onCancel={cancelPinSetup}
      >
        <div className="container text-center">
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}

          <div className="text-center">
            <div>
              <label className="text-left pt-4">Pin</label>
            </div>

            <PinInput
              length={4}
              initialValue={pin}
              secret
              onChange={(value, index) => setPin(value)}
              type="numeric"
              inputMode="number"
              style={{ padding: "10px" }}
              inputStyle={{ borderColor: "green" }}
              inputFocusStyle={{ borderColor: "blue" }}
              onComplete={(value, index) => {}}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </div>
          <br />
          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={
                type === "Activate" ? completeActivateCard : completeReqCard
              }
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
}
