import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../../containers/Layout";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ReactDatatable from "@ashvin27/react-datatable";
import { useHttp } from "../../../hooks/useHttps";
import {
  Upload,
  DatePicker,
  Dropdown,
  Drawer,
  Button,
  Space,
  Spin,
  Menu,
  Alert,
  message,
  Modal,
  notification,
} from "antd";
import axios from "axios";
import config from "../../../config";
export default function Employers() {
  const [currencyData, setCurrencyData] = useState([]);
  const [currencyCreate, setCurrencyCreate] = useState(false);
  const [currencyName, setCurrencyName] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [deleteSuccess, setDelete] = useState(false);
  const [rowId, setRowID] = useState("");
  const [statusSuccess, setSuccessStatus] = useState(false);
  const [updateSwitch, setUpdateSwitch] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");
  const [currencyStatus, setCurrencyStatus] = useState("");
  const [drawerText, setDrawerText] = useState("");

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [fieldError, setFieldError] = useState(false);

  const [load, setLoad] = useState(false);
  const [loading, countryDatas] = useHttp(
    `${config.baseUrl}/v1/countries/filtered`,
    []
  );

  const closeAdd = () => {
    setCurrencyCreate(false);
    setCurrencyName("");
    setCurrencyCode("");
    setCountryCode("");
  };

  const showDrawer = (record, e) => {
    e.preventDefault();
    setRowID(record.id);
    setCurrencyCode(record.currencyCode);
    setCurrencyName(record.currencyName);
    setCurrencyStatus(record.status);
    setCountryCode(record.countryCode);
    setVisibleDraw(true);
  };

  const closeDrawer = () => setVisibleDraw(false);
  const showDeleteConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure delete this Currency?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteCurrency();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const changeStatusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure change status of this Currency?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        changeStatusFunc();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const deleteCurrency = () => {
    axios
      .delete(`${config.baseUrl}/v1/currencies/${rowId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setDelete(true);
          getCurrencies();
          setVisibleDraw(false);
          setTimeout(() => {
            setDelete(false);
          }, 2000);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const updateCurrencySwitch = () => {
    setUpdateSwitch(true);
    setCurrencyCreate(true);
    setVisibleDraw(false);
  };

  const changeStatusFunc = () => {
    axios
      .put(`${config.baseUrl}/v1/currencies/status/${rowId}`, null, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          setStatusMsg(res.data.message);
          getCurrencies();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          getCurrencies();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
          setStatusMsg("Failed to connect to SalarioPay");
        }
      });
  };

  const createCurrency = () => {
    setCurrencyCreate(true);
    setUpdateSwitch(false);
  };

  const columns = [
    {
      key: "currencyName",
      text: "Currency Name",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "currencyCode",
      text: "Currency Code",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "countryCode",
      text: "Country Code",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      className: "postcode",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "date",
      text: "Date",
      className: "address",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <Button
              type="primary"
              danger
              style={{
                background: "#1FC157",
                color: "white",
                cursor: "pointer",
              }}
              onClick={showDrawer.bind(this, record)}>
              Actions
            </Button>
          </Fragment>
        );
      },
    },
  ];

  const options = { year: "numeric", month: "numeric", day: "numeric" };

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];

  const getCurrencies = () => {
    axios
      .get(`${config.baseUrl}/v1/currencies/paged`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const currencyData = res.data.result;

          setCurrencyData(
            currencyData.map((data) => ({
              currencyName: data.currencyName,
              currencyCode: data.currencyCode,
              countryCode: data.countryCode,
              id: data.id,
              date: new Date(data.createdAt)
                .toLocaleDateString(undefined, options)
                .replace(/\//g, "-"),
              status: data.status ? "Enabled" : "Disabled",
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const addCurrency = (e) => {
    e.preventDefault();
    const data = {
      currencyCode: currencyCode,
      currencyName: currencyName,
      countryCode,
    };
    if (data.currencyName && data.currencyCode) {
      setLoad(true);
      axios
        .post(`${config.baseUrl}/v1/currencies`, data, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setLoad(false);
          if (res.data.status) {
            setCurrencyName("");
            setCurrencyCode("");
            setSuccess(true);
            setSuccessMsg(res.data.message);
            setTimeout(() => {
              setSuccess(false);
              setCurrencyCreate(false);
              getCurrencies();
            }, 2000);
          } else {
            setError(true);
            setErrorMsg(res.data.message);
            setTimeout(() => {
              setError(false);
            }, 2500);
          }
        })
        .catch((err) => {
          if (err) {
            setLoad(false);
            setServerError(true);
            setTimeout(() => {
              setServerError(false);
            }, 1500);
          }
        });
    } else {
      setFieldError(true);
      setTimeout(() => {
        setFieldError(false);
      }, 1500);
    }
  };

  const editCurrency = (e) => {
    e.preventDefault();
    const data = {
      currencyCode,
      currencyName,
      countryCode,
    };
    setLoad(true);
    axios
      .put(`${config.baseUrl}/v1/currencies/${rowId}`, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setCurrencyName("");
          setCurrencyCode("");
          setCountryCode("");
          setSuccess(true);
          setSuccessMsg(res.data.message);
          setTimeout(() => {
            setSuccess(false);
            getCurrencies();
            setCurrencyCreate(false);
          }, 2000);
        } else {
          setError(true);
          setErrorMsg(res.data.message);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };

  useEffect(() => {
    getCurrencies();
  }, []);

  useEffect(() => {
    if (currencyStatus === "Enabled") {
      setDrawerText("Disable");
    } else if (currencyStatus === "Disabled") {
      setDrawerText("Enable");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeStatusConfirm]);

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    button: {
      excel: true,
      print: true,
      extra: true,
    },
  };

  const countryOptions = countryDatas.map((country) => {
    return <option value={country.code}>{country.name}</option>;
  });
  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title mb-0">Currencies</h5>
              <div className="d-flex justify-content-end align-items-center">
                <button
                  className="btn btn-info m-b-xs mr-2"
                  style={{ background: "#1FC157", color: "white" }}
                  onClick={createCurrency}>
                  Add Currency
                </button>{" "}
              </div>

              <div className="pt-0">
                <div className="text-center">
                  {deleteSuccess && (
                    <div className="pb-4">
                      <Alert
                        message="Currency Deleted"
                        type="success"
                        showIcon
                      />
                    </div>
                  )}
                  {statusSuccess && (
                    <div className="pb-4">
                      <Alert
                        message={statusMsg}
                        type={
                          drawerText.toLowerCase().includes("enable")
                            ? "success"
                            : "error"
                        }
                        showIcon
                      />
                    </div>
                  )}
                </div>
                <ReactDatatable
                  config={configTable}
                  records={currencyData}
                  columns={columns}
                  extraButtons={extraButtons}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}>
        <div
          style={{ color: "red", cursor: "pointer" }}
          onClick={showDeleteConfirm}>
          Delete Currency
        </div>
        <hr />
        <div style={{ cursor: "pointer" }} onClick={updateCurrencySwitch}>
          Edit Currency
        </div>
        <hr />
        <div style={{ cursor: "pointer" }} onClick={changeStatusConfirm}>
          {drawerText}
        </div>

        <hr />
      </Drawer>
      <Modal
        title={!updateSwitch ? "Add Currency" : "Update Currency"}
        visible={currencyCreate}
        okText="Submit"
        footer={false}
        maskClosable={false}
        onCancel={closeAdd}>
        <form>
          {load && (
            <p className="text-center">
              <Spin />
            </p>
          )}
          {success && (
            <div className="pb-3">
              <Alert message={successMsg} type="success" showIcon />
            </div>
          )}
          {error && (
            <div className="pb-3">
              <Alert message={errorMsg} type="error" showIcon />
            </div>
          )}
          {fieldError && (
            <div className="pb-3">
              <Alert message="All fields are required!" type="error" showIcon />
            </div>
          )}
          {serverError && (
            <p className="text-center text-danger font-weight-bold">
              Failed to connect to SalarioPay
            </p>
          )}
          {!updateSwitch ? (
            <div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Country
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setCountryCode(e.target.value)}
                  value={countryCode}>
                  <option selected>Select</option>
                  {countryOptions}
                </select>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Currency code"
                    onChange={(e) => setCurrencyCode(e.target.value)}
                    value={currencyCode}
                  />
                  <label for="floatingInput">Currency Code</label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Currency name"
                    onChange={(e) => setCurrencyName(e.target.value)}
                    value={currencyName}
                  />
                  <label for="floatingInput">Currency Name</label>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Country
                </label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setCountryCode(e.target.value)}
                  value={countryCode}>
                  <option selected>Select</option>
                  {countryOptions}
                </select>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Currency code"
                    onChange={(e) => setCurrencyCode(e.target.value)}
                    value={currencyCode}
                  />
                  <label for="floatingInput">Currency Code</label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <input
                    type="email"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Currency name"
                    onChange={(e) => setCurrencyName(e.target.value)}
                    value={currencyName}
                  />
                  <label for="floatingInput">Currency Name</label>
                </div>
              </div>
            </div>
          )}
          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={!updateSwitch ? addCurrency : editCurrency}>
              Submit
            </button>
          </div>
        </form>
      </Modal>
    </Layout>
  );
}
