import  React, {useState, useEffect} from 'react'
import axios from 'axios'
export const useHttp = (url, dependeices) => {
const [apiData, setApiData] = useState([]);
const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        axios.get(url,{
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            }
        }).
        then((res) => {
            setLoading(false)
            setApiData(res.data.result)
        }).catch
        ((err) => {
            setLoading(false)

        })
        return () => {
        }
    }, dependeices)

    return [loading,apiData]
}