import React, { useState } from "react";
import Layout from "../../../containers/Layout";
import "./payslip.css";
import { DatePicker, Pagination } from "antd";
import axios from "axios";
import { useHttp } from "../../../hooks/useHttps";
import { PayslipDocument } from "./payslipDocument";
import config from "../../../config";
import moment from "moment";
import PayslipLists from "./PaylipLists";
import Tabs from "./tabs";

export default function Payslip() {
  const [startDate, setStartPeriod] = useState("");
  const [endDate, setEndPeriod] = useState("");
  const [load, setLoad] = useState(false);
  const [payslips, setPaylips] = useState([]);
  const [noPayslips, setNoPaySlips] = useState(false);
  const [employeeId, setEmployerId] = useState("");
  const [loadingEmployers, employers] = useHttp(
    `${config.baseUrl}/v1/employees/filtered/employer`,
    []
  );

  //paginations
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `page=${page - 1}&size=${pageSize}`;
    getPaged(queryString);
  };

  const getPaged = (queryString) => {
    axios
      .get(`${config.baseUrl}/v1/payroll-details?${queryString}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setPaylips(res.data.result);
            setTotalItems(res.data.totalPages * 10);
            setNoPaySlips(false);
            setLoad(false);
          } else {
            setPaylips(res.data.result);
            setNoPaySlips(true);
            setLoad(false);
          }
        } else {
          setPaylips(res.data.result);
          setNoPaySlips(true);
          setLoad(false);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  function onChangeStartDate(date, dateString) {
    setStartPeriod(dateString);
  }
  function onChangeEndDate(date, dateString) {
    setEndPeriod(dateString);
  }

  const searchPayslip = (e) => {
    e.preventDefault();
    setLoad(true);
    axios
      .post(
        `${config.baseUrl}/v1/payroll-details`,
        {
          employeeId: sessionStorage.getItem("employeeID"),
          endPeriod: moment(endDate).toISOString(),
          startPeriod: moment(startDate).toISOString(),
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setPaylips(res.data.result);
            setNoPaySlips(false);
            setLoad(false);
          } else {
            setPaylips(res.data.result);
            setNoPaySlips(true);
            setLoad(false);
          }
        } else {
          setPaylips(res.data.result);
          setNoPaySlips(true);
          setLoad(false);
        }
      })
      .catch((err) => {
        setLoad(false);
        if (err) {
        }
      });
  };

  return (
    <Layout>
      <Tabs />

      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4 mb-3 col-12">
                    <label for="exampleInputEmail1" class="form-label">
                      Start Date{" "}
                    </label>
                    <DatePicker
                      onChange={onChangeStartDate}
                      style={{
                        height: "2.9rem",
                        borderRadius: "10px",
                        width: "100%",
                      }}
                      placeholder="Start Date"
                    />
                  </div>
                  <div className="col-md-4 mb-3 col-12">
                    <label for="exampleInputEmail1" class="form-label">
                      End Date{" "}
                    </label>
                    <DatePicker
                      onChange={onChangeEndDate}
                      style={{
                        width: "100%",
                        height: "2.9rem",
                        borderRadius: "10px",
                      }}
                      placeholder="End Date"
                    />
                  </div>
                  <div className="col-md-4   col-12">
                    <label
                      for="exampleInputEmail1"
                      class="form-label text-white"
                    >
                      End Date{" "}
                    </label>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      style={{
                        background: "#1FC157",
                        color: "white",
                        width: "100%",
                      }}
                      onClick={searchPayslip}
                    >
                      {load ? "Fetching.." : "Search"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {payslips.length > 0 && (
        <PayslipLists
          payslipsData={payslips}
          employee={employeeId}
          page={page}
          itemRender={itemRender}
          totalItems={totalItems}
          pagination={pagination}
        />
      )}

      {noPayslips && (
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header">
                <div className="card-body">
                  <p className="text-center pt-4">No Payslips found</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
