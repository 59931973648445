import React, { useState, useEffect } from "react";
import Tabs from "../../../employeeTabs";
import Layout from "../../../../containers/Layout";
import config from "../../../../config";
import { Modal, notification, Spin, Pagination, Button } from "antd";
import axios from "axios";
import { useHttp } from "../../../../hooks/useHttps";
export default function EmployeeInfo(props) {
  const [noPayrollDetailsData, setNoPayrollDetailsDatas] = useState(false);
  const [loadPayrollDetailsData, setLoadPayrollDetailsDatas] = useState(true);
  const [payrollDatas, setPayrollDetailsDatas] = useState([]);

  //paginations
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `employeeId=${sessionStorage.getItem(
      "employeeID"
    )}&page=${page - 1}&size=${pageSize}`;
    getPaged(queryString);
  };

  const getPaged = (queryString) => {
    axios
      .get(
        `${config.baseUrl}/v1/payroll-details/employee/paged?${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const data = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          setPayrollDetailsDatas(res.data.result);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  useEffect(() => {
    axios
      .get(
        `${
          config.baseUrl
        }/v1/payroll-details/employee/paged?employeeId=${sessionStorage.getItem(
          "employeeID"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setNoPayrollDetailsDatas(false);
            setLoadPayrollDetailsDatas(false);
            setPayrollDetailsDatas(res.data.result);
          } else {
            setNoPayrollDetailsDatas(true);
            setLoadPayrollDetailsDatas(false);
            setPayrollDetailsDatas([]);
          }
        } else {
          setNoPayrollDetailsDatas(true);
          setLoadPayrollDetailsDatas(false);
          setPayrollDetailsDatas([]);
        }
      })
      .catch((err) => {
        setNoPayrollDetailsDatas(true);
        if (err) {
        }
      });
  }, []);

  const downloadEmployeePayslip = (employee) => {
    fetch(
      `${config.baseUrl}/v1/payroll-details/payslip/download/${employee.employeeId}/${employee.id}`,
      {
        headers: new Headers({
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        }),
      }
    ).then((response) => {
      const filename = "payslip.pdf";
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      });
    });
  };

  return (
    <Layout>
      <Tabs />
      <div>
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="card table-widget">
            <div class="card-header font-weight-bold">Payroll Details</div>

              <div class="card-body">
                {payrollDatas.length > 0 &&
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Net Salary</th>
                        <th scope="col">Payment Option</th>
                        <th scope="col">Beneficiary Account</th>
                        <th scope="col">Beneficiary Name</th>
                        <th scope="col">Institution</th>
                        <th scope="col">Date</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payrollDatas.map((data) => {
                        return (
                          <tr>
                            <td>
                              {data.salaryCurrency}
                              {data.monthlyGrossSalary}
                            </td>
                            <td>
                              {data.paymentMethod.paymentOption
                                ? data.paymentMethod.paymentOption.replace(
                                    "_",
                                    " "
                                  )
                                : " "}
                            </td>
                            <td>
                              {data.paymentMethod.paymentOption ===
                              "BANK_ACCOUNT"
                                ? data.paymentMethod.accountIdentifier
                                : data.paymentMethod.cryptoWalletEmail}
                            </td>
                            <td>
                              {data.paymentMethod.paymentOption ===
                              "BANK_ACCOUNT"
                                ? data.paymentMethod.accountName
                                : data.paymentMethod.crytpoName}
                            </td>
                            <td>
                              {data.paymentMethod.paymentOption ===
                              "BANK_ACCOUNT"
                                ? data.paymentMethod.institutionName
                                : data.paymentMethod.cryptoWallet}
                            </td>
                            <td>
                              {data.createdAt
                                ? data.createdAt.slice(0, 10)
                                : ""}
                            </td>
                            <td>
                              <span
                                className={
                                  {
                                    SUCCESS: "badge bg-success",
                                    FAILED: "badge bg-danger",
                                    PENDING: "badge bg-warning",
                                    NEW: "badge bg-info",

                                  }[data.status]
                                }
                              >
                                {data.status}
                              </span>
                            </td>
                            <td>
                              <Button
                                onClick={downloadEmployeePayslip.bind(
                                  this,
                                  data
                                )}
                                type="primary"
                                danger
                                style={{
                                  background: "#1FC157",
                                  color: "white",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="fa fa-download"></i>{" "}
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="text-center pagination-part py-5">
                  <Pagination
                    current={page}
                    total={totalItems}
                    defaultPageSize={10}
                    onChange={pagination}
                    itemRender={itemRender}
                  />
                </div>
                </div>}

                {loadPayrollDetailsData && (
                  <div className="text-center pt-5 pb-5">
                    <Spin />
                  </div>
                )}
                {noPayrollDetailsData && (
                  <p className="text-center pt-5 pb-5">
                    No Payroll Details Yet.
                  </p>
                )}

               
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
