import React, { useState, useEffect } from "react";
import Layout from "../../../containers/Layout";
import { useHttp } from "../../../hooks/useHttps";
import axios from "axios";
import config from "../../../config";
import { notification, Select, Spin } from "antd";
const { Option } = Select;

export default function EmployeeInfo(props) {
  const processorActionId = props.history.location.state.processorActionId
    ? props.history.location.state.processorActionId
    : "";
  const processorId = props.history.location.state.processorId
    ? props.history.location.state.processorId
    : "";
  const [info, setInfo] = useState({});
  const [load, setLoad] = useState(false);
  const [processorActionsData, setProcessorsActionData] = useState([]);
  const processorAction = processorActionsData.find(
    (action) => action.id === processorActionId
  );
  const [updateStatus, setUpdateStatus] = useState(false);
  const [currencyOptions, setCurrency] = useState([]);
  const [countryOptions, setCountries] = useState([]);
  const [type, setType] = useState("");
  const [loadingTypes, types] = useHttp(
    `${config.baseUrl}/v1/processors/actionTypes`,
    []
  );
  const [priority, setPriority] = useState("");
  const [successMsg, setSuccessMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [identity, setIdentity] = useState([])
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [loadingCurrencies, currencies] = useHttp(
    `${config.baseUrl}/v1/currencies/filtered`,
    []
  );

  const [loadingCountries, countries] = useHttp(
    `${config.baseUrl}/v1/countries/filtered`,
    []
  );
  const [loadingIdentities, identities] = useHttp(
    `${config.baseUrl}/v1/processors/identities`,
    []
  );

  const getProcessorsAction = () => {
    axios
      .get(
        `${config.baseUrl}/v1/processors/action/paged?processorId=${processorId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const processorsData = res.data.result;
          setProcessorsActionData(processorsData);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const handleChangeCurrencies = (value) => {
    setCurrency(value);
  };

  const handleChangeCountries = (value) => {
    setCountries(value);
  };

  const handleChangeIdentities = (value) => {
    setIdentity(value);
  };


  const chnageUpdateState = (e) => {
    e.preventDefault();
    setUpdateStatus(true);
  };

  const countrySelect = [];
  countries.map((country) => {
    countrySelect.push(<Option key={country.code}>{country.name}</Option>);
  });

  const currencySelect = [];
  currencies.map((currency) => {
    currencySelect.push(
      <Option key={currency.currencyCode}>{currency.currencyName}</Option>
    );
  });
  const identitySelect = [];
  identities.map((identity) => {
    identitySelect.push(
      <Option key={identity}>{identity}</Option>
    );
  });


  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const chnageUpdateStateBack = (e) => {
    e.preventDefault();
    setUpdateStatus(false);
  };

  useEffect(() => {
    getProcessorsAction();
  }, []);

  const updateProcessorAction = (e) => {
    e.preventDefault();
    const data = {
      countries: countryOptions,
      currencies: currencyOptions,
      priority: priority,
      processorId: processorId,
      identities: identity,
      type: type,
    };
    setLoad(true);
    axios
      .put(
        `${config.baseUrl}/v1/processors/action/${processorActionId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.status) {
          setCountries([]);
          setCurrency([]);
          setType([]);
          setPriority("");
          Notification("success", "Success", res.data.message);
          setSuccess(true);
          setSuccessMsg(res.data.message);
          setTimeout(() => {
            setSuccess(false);
            getProcessorsAction();
            window.location.reload(false)
          }, 2000);
        } else {
          setError(true);
          Notification("error", "Error", res.data.message);
          setTimeout(() => {
            setError(false);
          }, 2500);
        }
      })
      .catch((err) => {
        if (err) {
          setLoad(false);
          setServerError(true);
          Notification("error", "Error", "Failed to connect to SalarioPay");
          setTimeout(() => {
            setServerError(false);
          }, 1500);
        }
      });
  };

  const getCountryName = (country) => {
    var countryInfo = countries.find((x) => x.code === country);
    console.log(countryInfo);
    return countryInfo.name;
  };

  const getCurrencyName = (currency) => {
    var currencyInfo = currencies.find((x) => x.currencyCode === currency);
    return currencyInfo.name;
  };

  return (
    <Layout>
      <div>
        <div className="col-sm-12 col-md-12">
          <div class="card">
            {processorAction ? (
              <div>
                <div class="profile-img text-center pt-3">
                  <img alt="" width="150" style={{ borderRadius: "100%" }} />
                </div>
                <div class="card-header font-weight-bold">
                  <b>Processor Action Details</b>
                </div>
                <div class="card-body">
                  <form>
                    {!updateStatus ? (
                      <div>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div className="mb-3">
                              <div className="form-floating">
                                <select
                                  class="form-select"
                                  disabled
                                  aria-label="Default select example"
                                  value={
                                    processorAction ? processorAction.type : ""
                                  }
                                  onChange={(e) => setType(e.target.value)}
                                >
                                  <option selected>Select</option>
                                  {types.map((type) => {
                                    return (
                                      <option value={type}>
                                        {type ? type.replace(/_+/g, " ") : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                                <label for="floatingInput">Action Type</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <div class="form-floating">
                                <input
                                  type="text"
                                  disabled
                                  class="form-control"
                                  placeholder="Confirm Password"
                                  value={
                                    processorAction
                                      ? processorAction.priority
                                      : ""
                                  }
                                />
                                <label for="floatingPassword">Priority</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <div class="form-floating">
                                <input
                                  type="text"
                                  class="form-control"
                                  disabled
                                  placeholder="Confirm Password"
                                  value={
                                    processorAction
                                      ? processorAction.status
                                        ? "Enabled"
                                        : "Disabled"
                                      : ""
                                  }
                                />
                                <label for="floatingPassword">Status</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <div class="form-floating">
                                <input
                                  type="text"
                                  class="form-control"
                                  disabled
                                  placeholder="Confirm Password"
                                  value={
                                    processorAction
                                      ? processorAction.createdAt
                                        ? processorAction.createdAt.slice(0, 10)
                                        : ""
                                      : ""
                                  }
                                />
                                <label for="floatingPassword">
                                  Date Created
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          {processorAction.countries && (
                            <div class="col-sm-12 col-md-4">
                              <p>Supporting Counties</p>
                              <ul class="">
                                {processorAction.countries.map((country) => {
                                  return <li className="mr-5">{country}</li>;
                                })}
                              </ul>
                            </div>
                          )}
                          {processorAction.currencies && (
                            <div class="col-sm-12 col-md-4">
                              <p>Supporting Currencies</p>
                              <ul class="">
                                {processorAction.currencies.map((currency) => {
                                  return <li className="mr-5">{currency}</li>;
                                })}
                              </ul>
                            </div>
                          )}
                          {processorAction.identities && (
                            <div class="col-sm-12 col-md-4">
                              <p>Identities</p>
                              <ul class="">
                                {processorAction.identities.map((identity) => {
                                  return <li className="mr-5">{identity}</li>;
                                })}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="row">
                          <div class="col-sm-12 col-md-6">
                            <div className="mb-3">
                              <div className="form-floating">
                                <select
                                  class="form-select"
                                  aria-label="Default select example"
                                  value={type}
                                  onChange={(e) => setType(e.target.value)}
                                >
                                  <option selected>Select</option>
                                  {types.map((type) => {
                                    return (
                                      <option value={type}>
                                        {type ? type.replace(/_+/g, " ") : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                                <label for="floatingInput">Action Type</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <div class="form-floating">
                                <input
                                  type="text"
                                  class="form-control"
                                  onChange={(e) => setPriority(e.target.value)}
                                  placeholder="Confirm Password"
                                  value={priority}
                                />
                                <label for="floatingPassword">Priority</label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                        <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label class="form-label">Countries</label>
                              <Select
                                mode="multiple"
                                allowClear
                                style={{ width: "100%" }}
                                placeholder="Please select"
                                onChange={handleChangeCountries}
                              >
                                {countrySelect}
                              </Select>
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <div class="mb-3">
                              <label class="form-label">Identities</label>
                              <Select
                                mode="multiple"
                                allowClear
                                style={{ width: "100%" }}
                                placeholder="Please select"
                                onChange={handleChangeIdentities}
                              >
                                {identitySelect}
                              </Select>
                            </div>
                          </div>
                        
                        </div>
                      </div>
                    )}

                    <div className="d-flex justify-content-end align-items-center">
                      {!updateStatus && (
                        <button
                          type="submit"
                          onClick={
                            updateStatus
                              ? updateProcessorAction
                              : chnageUpdateState
                          }
                          class="btn btn-primary"
                          style={{
                            background: "#1FC157",
                            marginTop: "1rem",
                            color: "white",
                          }}
                        >
                          {"Update"}
                        </button>
                      )}

                      {updateStatus && (
                        <>
                          <button
                            type="submit"
                            onClick={chnageUpdateStateBack}
                            class="btn btn-primary"
                            style={{
                              background: "#1FC157",
                              marginTop: "1rem",
                              color: "white",
                            }}
                          >
                            {"Back"}
                          </button>
                          <button
                            type="submit"
                            onClick={updateProcessorAction}
                            class="btn btn-primary"
                            style={{
                              background: "#1FC157",
                              marginTop: "1rem",
                              color: "white",
                            }}
                          >
                            {load ? (
                              <div
                                class="spinner-border text-white"
                                role="status"
                                style={{ width: "1.6rem", height: "1.6rem" }}
                              >
                                <span class="sr-only"></span>
                              </div>
                            ) : (
                              "Save Changes"
                            )}
                          </button>
                        </>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "60vh" }}
              >
                <Spin />
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
