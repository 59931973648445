import React, { useState, useEffect, useRef } from "react";
import config from "../../config";
import { Modal, notification, Spin } from "antd";
import axios from "axios";
export default function Optimizier(props) {
  const [name, setName] = useState("");
  const [load, setLoad] = useState(false);

  const Notification = (type, msgType, msg) => {
    notification[type]({
      message: msgType,
      description: msg,
    });
  };

  const addOn = (e) => {
    e.preventDefault();
    if (name) {
      const dataLevel = {
        employerId: sessionStorage.getItem("employer_id"),
        level: name,
      };
      const dataPosition = {
        employerId: sessionStorage.getItem("employer_id"),
        name: name,
      };
      const dataDepartment = {
        employerId: sessionStorage.getItem("employer_id"),
        name: name,
      };
      if (name) {
        setLoad(true);
        axios
          .post(
            `${config.baseUrl}/v1/${
              props?.name === "Department"
                ? "departments"
                : props?.name === "Position"
                ? "positions"
                : "employee-level"
            }`,
            props?.name === "Department"
              ? dataDepartment
              : props?.name === "Position"
              ? dataPosition
              : dataLevel,
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            setLoad(false);
            if (res.data.status) {
              setName("");
              Notification("success", "Success", res.data.message);
              setName("");
              props.loadAllCrus();
              props.closeModal();
            } else {
              Notification("error", "Error", res.data.message);
            }
          })
          .catch((err) => {
            if (err) {
            }
          });
      }
    } else {
      Notification("error", "Error", props?.name + " is required");
    }
  };

  return (
    <div>
      <Modal
        title={`Add ${props?.name}`}
        visible={props?.visible}
        width={520}
        okText="Submit"
        onCancel={() => {
          props.closeModal();
          setName("");
        }}
        maskClosable={false}
        footer={false}
      >
        <div className="container">
          {load && (
            <div className="text-center py-3">
              <Spin />
            </div>
          )}
          <div className="mb-3">
            <div className="form-floating">
              <input
                type="text"
                className="form-control"
                id="floatingInput"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
              <label for="floatingInput">{props.name} Name</label>
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <button
              type="submit"
              class="btn btn-primary"
              style={{ background: "#1FC157", color: "white" }}
              onClick={addOn}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
