import { createStore, applyMiddleware } from "redux";
import rootReducer from "./redux/reducer/index";
import thunk from "redux-thunk";
const middleware = [thunk];
const initialState = {
  wallet: "",
};
const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(...middleware)
);

export default store;
