import React from "react";
import { useLocation } from "react-router-dom";
import "./tab.css";
import { Link } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
function App(props) {
  const location = useLocation();
  const locationA = window.location.pathname.replace("/", "");
  const locationB = location.pathname.replace("/", "");

  return (
    <div className="card mt-4">
      <div className="card-body pt-3 pb-3">
        <div className="link-text">
          <Nav className="d-flex justify-content-between align-items-center">
            <NavItem>
              <Link
                to="/get-started/personal-info"
                className={
                  locationB && locationA === "get-started/personal-info"
                    ? "tab-list-active"
                    : "text-dark"
                }
              >
                Personal Information
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to="/get-started/payment-info"
                className={
                  locationB && locationA === "get-started/payment-info"
                    ? "tab-list-active"
                    : "text-dark"
                }
              >
                Payment Information
              </Link>
            </NavItem>

            <NavItem>
              <Link
                to="/get-started/account-info"
                className={
                  locationB && locationA === "get-started/account-info"
                    ? "tab-list-active "
                    : "text-dark"
                }
              >
                Work Information
              </Link>
            </NavItem>
          </Nav>
        </div>
      </div>
    </div>
  );
}

export default App;
