import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getPaymentHistories } from "../../../redux/action/employeeActions";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../containers/Layout";
import axios from "axios";
import config from "../../../config";
import { Spin, Pagination } from "antd";
export default function Payment_History() {
  const employeeId = sessionStorage.getItem("employeeID");
  const [loadPaymentHistory, setLoadPaymentHistory] = useState(true);
  const [noPaymentHistory, setNoPaymentHistory] = useState(false);
  const [paymentHistoryData, setPayHistoryData] = useState([]);

  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  //paginations
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `page=${page - 1}&size=${pageSize}`;
    getPaged(queryString);
  };

  const getPaged = (queryString) => {
    axios
      .get(
        `${config.baseUrl}/v1/vas-payment/history?userId=${employeeId}&${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setNoPaymentHistory(false);
            setLoadPaymentHistory(false);
            setTotalItems(res.data.totalPages * 10);
            setPayHistoryData(res.data.result);
          } else {
            setNoPaymentHistory(true);
            setLoadPaymentHistory(false);
            setPayHistoryData([]);
          }
        } else {
          setNoPaymentHistory(true);
          setLoadPaymentHistory(false);
          setPayHistoryData([]);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    axios
      .get(`${config.baseUrl}/v1/vas-payment/history?userId=${employeeId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.length > 0) {
            setNoPaymentHistory(false);
            setLoadPaymentHistory(false);
            setTotalItems(res.data.totalPages * 10);
            setPayHistoryData(res.data.result);
          } else {
            setNoPaymentHistory(true);
            setLoadPaymentHistory(false);
            setPayHistoryData([]);
          }
        } else {
          setNoPaymentHistory(true);
          setLoadPaymentHistory(false);
          setPayHistoryData([]);
        }
      })
      .catch((err) => {
        setLoadPaymentHistory(false);
        if (err) {
        }
      });
  }, []);

  return (
    <Layout>
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="card table-widget">
            <div class="card-body">
              <h5 class="card-title">Payment History </h5>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Biller</th>
                      <th scope="col">Type</th>
                      <th scope="col">Bundle</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Customer Number</th>
                      <th scope="col">Payment Option</th>
                      <th scope="col">Date</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody style={{ whiteSpace: "nowrap" }}>
                    {paymentHistoryData.length > 0 && (
                      <>
                        {paymentHistoryData.map((data) => {
                          return (
                            <tr>
                              <td>
                                {data.billerName ? data.billerName : "NA"}
                              </td>
                              <td>
                                {data.type ? data.type.replace("_", " ") : "NA"}
                              </td>
                              <td>
                                {data.bundleName ? data.bundleName : "NA"}
                              </td>
                              <td>
                                {data.currency}
                                {data.amount}
                              </td>
                              <td>
                                {data.customerNumber
                                  ? data.customerNumber
                                  : "NA"}
                              </td>

                              {data.paymentOption ? (
                                <td>
                                  {data.paymentOption
                                    ? data.paymentOption.replace("_", " ")
                                    : ""}
                                </td>
                              ) : (
                                <td>N/A</td>
                              )}

                              <td>
                                {data.createdAt
                                  ? data.createdAt.slice(0, 10)
                                  : ""}{" "}
                              </td>

                              <td>
                                {" "}
                                <span
                                  className={
                                    {
                                      SUCCESS: "badge bg-success",
                                      PENDING: "badge bg-warning",
                                      PROCESS: "badge bg-warning",
                                      NEW: "badge bg-warning",

                                      FAILED: "badge bg-danger",
                                    }[data.status]
                                  }
                                >
                                  {data.status}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                        <div className="d-flex  justify-content-center align-items-center pb-5 ">
                          <Pagination
                            current={page}
                            total={totalItems}
                            defaultPageSize={10}
                            itemRender={itemRender}
                            onChange={pagination}
                          />{" "}
                        </div>
                      </>
                    )}
                  </tbody>
                </table>

                {loadPaymentHistory && (
                  <div className="text-center pt-5 pb-5">
                    <Spin />
                  </div>
                )}
                {noPaymentHistory && (
                  <p className="text-center pt-5 pb-5">
                    No Payment History Yet
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
