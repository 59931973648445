import React, { useState } from "react";
import config from "../../../config";
import {
  Pagination,Modal
} from "antd";
import axios from 'axios'
export default function PayslipLists({ payslipsData, employee,page,totalItems,pagination,itemRender }) {
  const [load, setLoad] = useState(false);
  const [payslipHtml, setPayslipHtml] = useState("");
  const [slipView, setSlipiew] = useState(false);

  const cancelSlipView = () => {
    setSlipiew(false);
  };

 
  const downloadEmployeePayslip = (payslip) => {
    fetch(
      `${config.baseUrl}/v1/payroll-details/payslip/download/${sessionStorage.getItem('employeeID')}/${payslip}`,
      {
        headers: new Headers({
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        }),
      }
    ).then((response) => {
      const filename = "payslip.pdf";
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      });
    });
  };

  const viewPayslip = (payslip, e) => {
    e.preventDefault();
    axios
      .get(
        `${config.baseUrl}/v1/payroll-details/payslip/${sessionStorage.getItem('employeeID')}/${payslip}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.status === 200) {
          setPayslipHtml(res.data);
          setSlipiew(true);
        }
      })
      .catch((err) => {
        setLoad(false);
      });
  };

  const downloadPayslip = (payslip, e) => {
    e.preventDefault();
    setLoad(true);
    downloadEmployeePayslip(payslip);
  };

  return (
    <>
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="card table-widget">
            <div class="card-body">
              <h5 class="card-title">Payslips </h5>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Net Salary</th>
                      <th scope="col">Beneficiary Account</th>
                      <th scope="col">Beneficiary Name</th>
                      <th scope="col">Institution</th>
                      <th scope="col">Date</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payslipsData.length > 0 && (
                      <>
                        {payslipsData.map((data) => {
                          return (
                            <tr>
                              {/* <td>{data.accountName}</td>
                              <td>{data.accountNumber}</td>
                              // <td>{data.bankName}</td> */}

                              <td>
                                {data.salaryCurrency} {data.netSalary}
                              </td>
                              <td>
                                {data.paymentMethod.paymentOption ===
                                "BANK_ACCOUNT"
                                  ? data.paymentMethod.accountIdentifier
                                  : data.paymentMethod.cryptoWalletEmail}
                              </td>
                              <td>
                                {data.paymentMethod.paymentOption ===
                                "BANK_ACCOUNT"
                                  ? data.paymentMethod.accountName
                                  : data.paymentMethod.crytpoName}
                              </td>
                             
                              <td>
                              {data.paymentMethod.paymentOption ===
                                "BANK_ACCOUNT"
                                  ? data.paymentMethod.institutionName
                                  : data.paymentMethod.cryptoWalletEmail}
                              </td>
                              <td>
                                {data.createdAt
                                  ? data.createdAt.slice(0, 10)
                                  : ""}
                              </td>

                              <td>
                                <span
                                  className={
                                    {
                                      SUCCESS: "badge bg-success",
                                      FAILED: "badge bg-danger",
                                    }[data.status]
                                  }
                                >
                                  {data.status}
                                </span>
                              </td>
                              <td>
                                <button
                                  type="submit"
                                  onClick={viewPayslip.bind(this, data.id)}
                                  class="btn btn-primary"
                                  style={{
                                    background: "#1FC157",
                                    color: "white",
                                  }}
                                >
                                  View
                                </button>{" "}
                                <button
                                  type="submit"
                                  onClick={downloadPayslip.bind(this, data.id)}
                                  class="btn btn-primary"
                                  style={{
                                    background: "#1FC157",
                                    color: "white",
                                  }}
                                >
                                  {load ? "Downloading" : "Download"}{" "}
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="d-flex flex justify-content-center align-items-center pt-5 pb-5">
                <Pagination
                  current={page}
                  total={totalItems}
                  defaultPageSize={10}
                  itemRender={itemRender}
                  onChange={pagination}
                />{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={false}
        visible={slipView}
        okText="Skip"
        footer={null}
        maskClosable={false}
        width={800}
        onCancel={cancelSlipView}
      >
        <p
          dangerouslySetInnerHTML={{ __html: payslipHtml }}
          className="content blog-body-content"
        />
      </Modal>
    </>
  );
}
