import React, { useEffect, useState, Fragment } from "react";
import Layout from "../../../containers/Layout";
import ReactDatatable from "@ashvin27/react-datatable";
import axios from "axios";
import config from "../../../config";
import { Drawer, Button, Spin } from "antd";
import { Link } from "react-router-dom";

const KYC = () => {
  const [getKycData, setGetKycData] = useState([]);
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [kycId, setKycId] = useState("");
  const [loading, setLoading] = useState(true);

  const showDrawer = (record, e) => {
    console.log(record);
    e.preventDefault();
    setKycId(record.id);
    setVisibleDraw(true);
  };
  const closeDrawer = () => setVisibleDraw(false);

  const columns = [
    {
      key: "name",
      text: "Name",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "accountType",
      text: "Account Type",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "businessName",
      text: "Business Name",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "businessCity",
      text: "Business City",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "status",
      text: "Status",
      className: "address",
      TrOnlyClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <Button
              type="primary"
              danger
              style={{
                background: "#1FC157",
                color: "white",
                cursor: "pointer",
              }}
              onClick={showDrawer.bind(this, record)}>
              Actions
            </Button>
          </Fragment>
        );
      },
    },
  ];

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    button: {
      excel: true,
      print: true,
      extra: true,
    },
  };

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];

  const getKYCData = () => {
    axios
      .get(`${config.baseUrl}/v1/kyc-details/approvals`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log("Dennis", res);
        if (res.data.status) {
          setLoading(false);
          setGetKycData(
            res.data.result.map((data) => ({
              name: `${data.firstName} ${data.otherName} ${data.lastName} `,
              accountType: data.accountType,
              businessName:
                data.businessName !== null ? data.businessName : "N/A",
              businessCity:
                data.businessCity !== null ? data.businessCity : "N/A",
              status: data.status,
              id: data.id,
            }))
          );
        } else {
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err) {
        }
      });
  };

  useEffect(() => {
    getKYCData();
  }, []);

  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title mb-5">KYC Approvals</h5>
              <div className="pt-0">
                {loading ? (
                  <div class="d-flex justify-content-center align-items-center">
                    <Spin />
                  </div>
                ) : (
                  <>
                    <div className="pt-0" style={{ overflowX: "auto" }}>
                      <ReactDatatable
                        config={configTable}
                        records={getKycData}
                        columns={columns}
                        extraButtons={extraButtons}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}>
        <div>
          <Link
            to={`/personal_information/${kycId}`}
            style={{ color: "#5b5b5b", cursor: "pointer" }}>
            View Details
          </Link>
        </div>
      </Drawer>
    </Layout>
  );
};

export default KYC;
