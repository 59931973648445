import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Layout from "../../../containers/Layout";
import Tab from "../../tab";
import moment from "moment";
import ReactDatatable from "@ashvin27/react-datatable";
import { useHttp } from "../../../hooks/useHttps";
import {
  Drawer,
  Button,
  Spin,
  Alert,
  Pagination,
  Modal,
  notification,
  DatePicker,
} from "antd";
import axios from "axios";
import config from "../../../config";
const { RangePicker } = DatePicker;
const days = 86400000; //number of milliseconds in a day
const currDay = new Date();
const currDate = moment().format("YYYY-MM-DD");
const sevenDaysAgo = new Date(currDay - 7 * days);
const plus1day = new Date(currDay + 1 * days);
export default function Employers() {
  let nameSearch;
  const [auditsData, setAuditsData] = useState([]);
  const [load, setLoad] = useState(false);
  const [setNoAuditsData, setNoAudits] = useState(false);
  const [name, setName] = useState("");
  const [loadAudit, setLoadAudit] = useState(true);
  const [date, setDate] = useState(moment(sevenDaysAgo).format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(
    moment(new Date()).add(1, "days").format("YYYY-MM-DD")
  );
  function itemRender(current, type, originalElement) {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  }

  //paginations
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState("");

  const getAuditTrails = () => {
    setLoadAudit(true);
    axios
      .get(
        `${
          config.baseUrl
        }/v1/audit-trail/paged?page=0&size=10&endDate=${endDate}&startDate=${date}&name=${
          nameSearch !== undefined ? nameSearch : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          const auditsData = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          if (auditsData.length > 0) {
            setAuditsData(auditsData);
            setLoadAudit(false);
            setNoAudits(false);
          } else {
            setLoadAudit(false);
            setNoAudits(true);
          }
        } else {
          setLoadAudit(false);
          setNoAudits(true);
          setAuditsData([]);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    getAuditTrails();
  }, []);

  useEffect(() => {
    if (date && endDate) {
      getAuditTrails();
    }
  }, [date, endDate]);

  const pagination = (page, pageSize) => {
    setPage(page);
    const queryString = `page=${
      page - 1
    }&size=${pageSize}&endDate=${endDate}&startDate=${date}&name=${
      nameSearch !== undefined ? nameSearch : ""
    }`;
    getPaged(queryString);
  };

  const getName = (e) => {
    nameSearch = e.target?.value;
    getAuditTrails();
  };

  const getPaged = (queryString) => {
    axios
      .get(`${config.baseUrl}/v1/audit-trail/employer/paged?${queryString}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          const auditsData = res.data.result;
          setTotalItems(res.data.totalPages * 10);
          if (auditsData.length > 0) {
            setAuditsData(auditsData);
            setLoadAudit(false);
            setNoAudits(false);
          } else {
            setLoadAudit(false);
            setNoAudits(true);
          }
        } else {
          setLoadAudit(false);
          setNoAudits(true);
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  const onChange = (date, dateString) => {
    setEndDate(dateString[1]);
    setDate(dateString[0]);
  };

  return (
    <Layout>
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="card table-widget">
            <div class="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5 class="card-title">Audit Trails </h5>
                </div>

                <div className="px-3  row">
                  <div className="col-md-6">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search by name"
                      onBlur={getName}
                    />
                  </div>
                  <div className="col-md-6">
                    <RangePicker
                      onChange={onChange}
                      style={{ height: "3rem", borderRadius: "10px" }}
                      disabledDate={(current) => current.isAfter(moment())}
                    />
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col"> Email</th>
                      <th scope="col">Date</th>
                      <th scope="col">Action</th>
                      <th scope="col">Ip Address</th>
                    </tr>
                  </thead>
                  {!loadAudit && (
                    <tbody>
                      {auditsData.length > 0 && (
                        <>
                          {auditsData.map((data) => {
                            return (
                              <tr>
                                <td>{data.fullName}</td>
                                <td>{data.email}</td>
                                <td>
                                  {moment(data.createdAt).format(
                                    "MMM Do YY, hh:mm A"
                                  )}
                                </td>
                                <td>{data.actionType?.replace(/_+/g, " ")}</td>

                                <td>{data.ipAddress}</td>
                              </tr>
                            );
                          })}
                        </>
                      )}
                    </tbody>
                  )}
                </table>
                {auditsData?.length === 0 && !loadAudit && (
                  <p className="text-center pt-5 pb-5">No Audit Trails</p>
                )}
                {loadAudit && (
                  <div className="text-center pt-5 pb-5">
                    <Spin />
                  </div>
                )}
              </div>
              {auditsData?.length > 0 && (
                <div className="d-flex  justify-content-center align-items-center pt-5 pb-5">
                  <Pagination
                    current={page}
                    total={totalItems}
                    defaultPageSize={10}
                    itemRender={itemRender}
                    onChange={pagination}
                  />{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
