import React from "react";
import { Modal } from "antd";
const TimeoutModal = ({
  showModal,
  handleStayLoggedIn,
  logout,
}) => {
  return (
    <div>
      <Modal
        title={false}
        visible={showModal}
        okText="Submit"
        footer={false}
        maskClosable={false}
      >
        <div className="text-center pt-4 ask-creation-success">
          <p className="font-weight-normal p-0 mt-3">Session Timeout!</p>
          <i className="text-center fa fa-sign-out text-danger fa-4x"></i>
          <hr />
          <p>
            {" "}
            Your session is about to expire in 10 seconds due to inactivity. You
            will be redirected to the login page.
          </p>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <button
            type="submit"
            onClick={logout}
            class="btn btn-primary"
            style={{ background: "red", color: "white" }}
          >
            Log Out
          </button>
          <button
            type="submit"
            onClick={handleStayLoggedIn}
            class="btn btn-primary"
            style={{ background: "#1FC157", color: "white" }}
          >
            Stay Logged In
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default TimeoutModal;
