import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../../containers/Layout";
import ReactDatatable from "@ashvin27/react-datatable";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Drawer, Alert, Button, Modal } from "antd";
import axios from "axios";
import config from "../../../config";
export default function Employers() {
  const [employersData, setEmployersData] = useState([]);
  const [visibleDraw, setVisibleDraw] = useState(false);
  const [rowId, setRowId] = useState("");
  const [empId, setEmpID] = useState("");
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [statusSuccess, setSuccessStatus] = useState(false);
  const [updateSwitch, setUpdateSwitch] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");
  const [empStatus, setEmpStatus] = useState(null);

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [serverError, setServerError] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [roleStatus, setRoleStatus] = useState("");
  const [drawerText, setDrawerText] = useState("");

  const [load, setLoad] = useState(false);

  const showDrawer = (record, e) => {
    e.preventDefault();
    setRowId(record.id);
    setEmpID(record.emp);
    setEmpStatus(record.status);
    setRoleStatus(record.status);

    setVisibleDraw(true);
  };

  const closeDrawer = () => setVisibleDraw(false);

  const deleteEmployer = () => {
    axios
      .delete(`${config.baseUrl}/v1/employers/${empId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setDeleteSuccess(true);
          setVisibleDraw(false);
          getEmployers();
          setTimeout(() => {
            setDeleteSuccess(false);
          }, 2000);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };
  const changeStatusFunc = () => {
    axios
      .put(`${config.baseUrl}/v1/users/status/${rowId}`, null, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          setStatusMsg(res.data.message);
          getEmployers();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
        } else {
        }
      })
      .catch((err) => {
        if (err) {
          setSuccessStatus(true);
          setVisibleDraw(false);
          getEmployers();
          setTimeout(() => {
            setSuccessStatus(false);
          }, 2000);
          setStatusMsg("Failed to connect to SalarioPay");
        }
      });
  };

  const showDeleteConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure delete this Employer?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteEmployer();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const changeStatusConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: `Are you sure change status of this Employer?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        changeStatusFunc();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns = [
    {
      key: "businessName",
      text: "Bussiness Name",
      TrOnlyClassName: "tab-head",
      className: "rating",
      align: "left",
      sortable: true,
    },
    {
      key: "employerMobile",
      text: "Employer Mobile",
      TrOnlyClassName: "tab-head",
      className: "rating",
      align: "left",
      sortable: true,
    },
    {
      key: "email",
      text: "Email",
      TrOnlyClassName: "tab-head",
      className: "rating",
      align: "left",
      sortable: true,
    },
    {
      key: "industry",
      text: "Industry",
      TrOnlyClassName: "tab-head",
      className: "type_of_food",
      sortable: true,
      align: "left",
    },
    {
      key: "status",
      text: "Status",
      tHeadClassName: "tab-head",
      align: "left",
      sortable: true,
    },
    {
      key: "date",
      text: "Date",
      className: "address",
      TrOnlyClassName: "tab-head",
      sortable: true,
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <Fragment>
            <Button
              type="primary"
              danger
              style={{
                background: "#1FC157",
                color: "white",
                cursor: "pointer",
              }}
              onClick={showDrawer.bind(this, record)}
            >
              Actions
            </Button>
          </Fragment>
        );
      },
    },
  ];

  const extraButtons = [
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
    },
    {
      className: "btn btn-primary buttons-pdf",
      title: "Export TEst",
      children: [
        <span>
          <i
            className="glyphicon glyphicon-print fa fa-print"
            aria-hidden="true"
          ></i>
        </span>,
      ],
      onClick: (event) => {
        console.log(event);
      },
      onDoubleClick: (event) => {
        console.log("doubleClick");
      },
    },
  ];

  const options = { year: "numeric", month: "numeric", day: "numeric" };

  const getEmployers = () => {
    axios
      .get(
        `${config.baseUrl}/v1/employers/paged
      `,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        },
      )
      .then((res) => {
        if (res.data.status) {
          const employerDatas = res.data.result;
          setEmployersData(
            employerDatas.map((data) => ({
              businessName: data.businessName,
              employerMobile: data.user.mobile,
              email: data.user.email,
              emp: data.id,
              id: data.userId,
              status: data.user.status ? "Enabled" : "Disabled",
              date: new Date(data.createdAt)
                .toLocaleDateString(undefined, options)
                .replace(/\//g, "-"),
              industry: data.industry,
            })),
          );
        } else {
        }
      })
      .catch((err) => {
        if (err) {
        }
      });
  };

  useEffect(() => {
    getEmployers();
  }, []);

  useEffect(() => {
    if (roleStatus === "Enabled") {
      setDrawerText("Disable");
    } else if (roleStatus === "Disabled") {
      setDrawerText("Enable");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeStatusConfirm]);

  const configTable = {
    page_size: 10,
    length_menu: [10, 20, 50],
    button: {
      excel: true,
      print: true,
      extra: true,
    },
  };
  return (
    <Layout>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Employers</h5>

              <div className="pt-0">
                <div className="text-center">
                  {deleteSuccess && (
                    <div className="pb-4">
                      <Alert message="Employer Deleted" type="error" showIcon />
                    </div>
                  )}
                  {statusSuccess && (
                    <div className="pb-4">
                      <Alert
                        message={statusMsg}
                        type={
                          drawerText.toLowerCase().includes("enable")
                            ? "success"
                            : "error"
                        }
                        showIcon
                      />
                    </div>
                  )}
                </div>
                <div className="pt-0" style={{ overflowX: "auto" }}>
                  <ReactDatatable
                    config={configTable}
                    records={employersData}
                    columns={columns}
                    extraButtons={extraButtons}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title="Actions"
        placement="right"
        visible={visibleDraw}
        onClose={closeDrawer}
      >
        <Link to={{ pathname: "/view_employees", state: { empId } }}>
          <div style={{ color: "#5B5B5B", cursor: "pointer" }}>
            View Employees
          </div>
        </Link>
        <hr />
        <div style={{ cursor: "pointer" }} onClick={changeStatusConfirm}>
          {drawerText}
        </div>
        <hr />
        <Link to={{ pathname: "/benefits", state: { empId } }}>
          <div style={{ color: "#5B5B5B", cursor: "pointer" }}>Benefits</div>
        </Link>
        <hr />
        <Link to={{ pathname: "/employer_view_payroll", state: { empId } }}>
          <div style={{ color: "#5B5B5B", cursor: "pointer" }}>Payrolls</div>
        </Link>
        <hr />
        <Link
          to={{ pathname: "/employer_view_transactions", state: { empId } }}
        >
          <div style={{ color: "#5B5B5B", cursor: "pointer" }}>
            Transactions
          </div>
        </Link>
        <hr />
        <Link to={{ pathname: "/employer_view_audit_trail", state: { empId } }}>
          <div style={{ color: "#5B5B5B", cursor: "pointer" }}>
            Audit Trails
          </div>
        </Link>
        <hr />
        <Link to={{ pathname: "/employer_view_loan", state: { empId } }}>
          <div style={{ color: "#5B5B5B", cursor: "pointer" }}>
            Loan Request
          </div>
        </Link>
        <hr />
      </Drawer>
    </Layout>
  );
}
