import {
    ERROR,
    EMPLOYEE_PAYMENT_HISTORY, SUCCESS,
    EMPLOYEE_DEDUCTION_HISTORY,
    EMPLOYEE_TRANSACTION_HISTORY,
    LOADING,
  } from "../../../action/types";
  
  const initState = {
    loadingTransacionHistory: false,
    data: [],
    message: ""
  };
  
  export function transactionHistoryReducer(state = initState, action) {
    switch (action.type) {
      case LOADING:
        return {
          ...state,
          loadingTransacionHistory: true,
        };
      case EMPLOYEE_TRANSACTION_HISTORY:
        return {
          ...state,
          loadingTransacionHistory: false,
          data: action.payload,
        };
    //   case VERIFICATION_DETAILS:
    //     return {
    //       ...state,
    //       loadingKyc: false,
    //       details: action.payload,
    //     };
      case ERROR:
        return {
          ...state,
          loadingTransacionHistory: false,
          error: action.payload,
        };
      default:
        return state;
    }
  }

  export default transactionHistoryReducer
  